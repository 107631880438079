import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { config } from '../config'
import { Loader } from './Loader'

function getPromotions() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}


const RedirectComponent = () => {
  const { id } = useParams(); // Obtiene el ID de la URL
  const [promotion, setPromotion] = useState();

  useEffect(() => {

    getPromotions().then((result) => {
			let promotion = result.filter(function (element) {
				return element.id == id;
			})

      setPromotion(promotion)
		})

	}, [])

  useEffect(() => {
    console.log("PROMOCION NOTIFICACION REDIRECCION ");
    console.log(promotion);
    if(promotion){
      //Redirección Moderno:::
      //Redirección Tradicional:::
      // alert(promotion[0].customer.channel.description_channel);
      try{
        switch(promotion[0].customer.channel.description_channel){
          case "Tradicional":
              //Se redirige a trad.
              let url = '/CatalogoTradicional/' + id +'/' + promotion[0].name_promotion 
                      +"/" + promotion[0].offices + "/" + promotion[0].month_promotion_str +
                      " " + promotion[0].year_promotion_str + "/" + promotion[0].linea;
              window.location.replace(url)
              break;
          case "Moderno":
              // Se redirige a mod.
              // window.pathname = '/CatalogoResumen/' + id;
              window.location.replace('/CatalogoResumen/' + id)
              break;
          default:
            // window.pathname = '/';
            window.location.replace('/home')
            break;
        }
      }catch(e){
        // window.pathname = '/';
        window.location.replace('/home')
      }
      
    }
  }, [promotion]);


  return (
    
    <div>
      <Loader />
      Redirigiendo...
    </div>
  );
};

export default RedirectComponent;
