import React from "react";
import forward_arrow from "../../assets/img/forward_arrow.svg";


export const SeguimientoTableTrLinea = ({lineStr, lineNormal}) => {
  return (
<tr
id={lineStr}
onClick={(e) => {
  if (
    document
      .querySelector("#"+lineStr)
      .classList.contains("rotate_img")
  ) {
    document
      .querySelector("#"+lineStr)
      .classList.remove("rotate_img");
  } else {
    document.querySelector("#"+lineStr).classList.add("rotate_img");
  }
  document.querySelectorAll("."+lineStr).forEach((Each) => {
    if (Each.classList.contains("active")) {
      Each.classList.remove("active");
    } else {
      Each.classList.add("active");
    }
  });
}}
>
<td>
  <div className="display_flex familiap  center_justify gray blue_bg special_p">
    <a>{lineNormal}</a>
  </div>
</td>
<td colSpan={15}>
  <div
    className="display_flex  center_justify gray blue_bg special_p"
    style={{ justifyContent: "flex-start", paddingLeft: 25 }}
  >
    <img src={forward_arrow} alt="" />
  </div>
</td>
</tr>
  );
}