import {useState, useEffect} from 'react'
import {Navbar} from '../Navbar'

import {Link, useNavigate, useParams} from 'react-router-dom'
import {TopNavigatorOferta} from './TopNavigatorOferta'
import {CodigoPop} from '../CodigoPop'
import {OfertaFooter} from './OfertaFooter'

// css
import '../../assets/css/newPFOffer/ingreso-product.css'
import {IngresoProductTable} from './IngresoProductTable'
import {ModeloPopup} from './ModeloPopup'
import {DeleteRow} from './DeleteRow'
import {DeleteImpossible} from './DeleteImpossible'
import { config } from '../../config'
import { Loader } from '../Loader'
import { SearchResult } from '../SearchResult'
import set from 'date-fns/esm/set/index'
import { formatNumberES, redondearDecena } from '../../config'

// funcion para traer toda la lista de filas de la oferta

function getListOffer(id_oferta) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrlOferta + `/offer/products/`+id_oferta, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}


export const IngresoProduct = () => {
	const [CodigoPopState, setCodigoPop] = useState(false)
	const [deletestate, setdeletestate] = useState(false)
	const [deletepossiblestate, setdeletepossiblestate] = useState(false)
	const [ModeloPopupState, setModeloPopupState] = useState(false)
	const [actualizar, setActualizar] = useState(false)
	const [offers, setOffers] = useState(null)
	const [loading, setLoading] = useState(true)
	const [product, setProduct] = useState(null)
	const [rowChange, setRowChange] = useState(null)
	const [sinSimular, setSinSimular] = useState(false)
	const [executeSimulation, setExecuteSimulation] = useState(false)
	const [deleteRow, setDeleteRow] = useState(null)
	const { id_oferta } = useParams();
	const [mgPvp, setMgPvp] = useState(null)

	const simulateListOffer = async (id_oferta, data) => {
		//Guardar INFO
		setLoading(true);
		const session_token = localStorage.getItem('session');
		const response = await fetch(config.apiUrlOferta + `/offer/simulate/`+id_oferta, {
		  //mode: 'no-cors',
			headers: {
			  'Content-Type': 'application/json',
			  'Authorization': 'Bearer ' + session_token,
			  "Access-Control-Allow-Origin" : "*", 
			  "Access-Control-Allow-Credentials" : true
			},
			method: "POST",
			body: JSON.stringify(data)
		  });
		  const responseJSON = await response.json();
		  console.log("RESPUESTA SERVICIO SIMULACION");
		  console.log(responseJSON);
		  // window.location.reload();
		  // Actualizar tabla principal luego de haber agregado un producto
		  console.log("Actualizando productos...");
		  // alert("Actualizando productos......")
		  setLoading(false);
		  return responseJSON
		}


	useEffect(() => {
		getListOffer(id_oferta).then((response) => {
			console.log("response:::: list products::: ")
			console.log(response)
			setOffers(response)
			// setProducts(response)
			setLoading(false)
		})
		setActualizar(false)
	}, []) // actualizar

	useEffect(() => { // Cambio de margen general
		if(mgPvp){
			let mgcadb = mgPvp;
			console.log("ALL pvp change");
			// Lógica de row change
			const offersBackup = {...offers};
			offersBackup.data_rows.map((offer) => {
				offer.customer_margin = mgcadb // Cada vez que se cambia este, entonces se recalcula el PVP.
				offer.__customer_margin = parseFloat(mgcadb).toFixed(1)
				// Cálculo de pvp con el margen....
				// 1,19*precio estrategico unitario/(1-mg cadena)
				let pvp = redondearDecena( 1.19 * parseInt(offer.__strat_price) / (1 - (parseFloat(String(mgcadb).replace("%",""))/100)) )
				console.log("NUevo PVP::: " + pvp)
				offer.recommended_pvp = formatNumberES(pvp)
				offer.__recommended_pvp = parseInt(pvp)
				offer.strat_price_modified = 1
			});
			setOffers(offersBackup);
			// setSinSimular(true); // Aviso
			// setRowChange(null);
			// alert("Se ha cambiado el margen general "+mgPvp+"%")
			setMgPvp(null);
		}
	}, [mgPvp]);

	useEffect(() => {
		if(rowChange){
			console.log("row change");
			// Lógica de row change
			const offersBackup = {...offers};
			offersBackup.data_rows.map((offer) => {
				if (offer.id === rowChange.id) {
					offer = rowChange
				}
			});
			setOffers(offersBackup);
			setSinSimular(true); // Aviso
			setRowChange(null);
		}
	}, [rowChange]);

	// Simulación....
	useEffect(() => {
		if(executeSimulation){
			console.log("execute simulation");
			// Lógica de simulación
			simulateListOffer(id_oferta, offers).then((response) => {
				console.log("response:::: SIMULATION ")
				console.log(response)
				setOffers(response)
				setLoading(false)
				// setProducts(response)
			}).catch((error) => {
				console.log("error:::: SIMULATION ")
				console.log(error)
				setLoading(false)
				alert("Error al simular la oferta, por favor intente nuevamente.")
			})
			setExecuteSimulation(false);
			setSinSimular(false);
		}
	}, [executeSimulation]);

	return (
		<div className='CataLogoResumen ResumenDeCatalogeNew ResumenDeOferta'>
			{loading && <Loader message={'Cargando...'}></Loader>}
			{(ModeloPopupState && product && offers) && (
				<ModeloPopup setModeloPopupState={setModeloPopupState} product={product}
				setProduct = {setProduct}
				cadena = {offers.offer_data.customer}
				/>
			)}
			{deletestate && (
				<DeleteRow
					setActive={setdeletestate}
					setdeletepossiblestate={setdeletepossiblestate}
					deleteRow={deleteRow}
					setLoading={setLoading}
					setOffers={setOffers}
				/>
			)}
			{deletepossiblestate && (
				<DeleteImpossible setActive={setdeletepossiblestate} />
			)}

			{CodigoPopState && <CodigoPop setCodigoPop={setCodigoPop} />}
			{offers && <TopNavigatorOferta data={offers}/>}
			<Navbar no_need_search />
			<div className='top_area IngresoProductTopArea'>
				<div className='left_side'>
					<Link to={"/ResumenOferta/"+id_oferta}>Resumen Oferta</Link>
					<Link to={'/SeguimientoOferta/'+id_oferta}>Seguimiento Oferta</Link>
					<Link  className='active'>
						Ingreso Productos
					</Link>
				</div>
			</div>
			{ offers  && (
			<IngresoProductTable
				setdeletestate={setdeletestate}
				setModeloPopupState={setModeloPopupState}
				idOferta = {id_oferta}
				offers = {offers}
				setProduct = {setProduct}
				setRowChange = {setRowChange}
				setDeleteRow = {setDeleteRow}
				setMgPvp = {setMgPvp}
				mgPvp = {mgPvp}
			/>) }
			{ offers && (
			<OfertaFooter 
				idOferta = {id_oferta}
				offers = {offers}
				setActualizar = {setActualizar}
				sinSimular = {sinSimular}
				setExecuteSimulation = {setExecuteSimulation}
				setOffers={setOffers}
			/>
			) }
			{/* <CataLogoResumenFooter /> */}
		</div>
	)
}
