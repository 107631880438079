import React, {useState} from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import dropdownprod from '../../assets/img/newPFOffer/dropdown.svg'
import tick2 from '../../assets/img/newPFOffer/tick.svg'
import dollar from '../../assets/img/dollar.svg'
import greenarrow from '../../assets/img/newPFOffer/green-arrow.svg'
import orangearrow from '../../assets/img/orange_down.svg'
import closing from '../../assets/img/newPFOffer/close.svg'
import {ProductTableInner} from './ProductTableInner'
import { ToolTipCustomList } from '../ToolTipCustomList'
import Recomendacion2 from '../../assets/img/Recomendacion2.svg'
import Volumen from '../../assets/img/Volumen.svg'
import Volumen_Rentabilidad from '../../assets/img/Volumen_Rentabilidad.svg'
import Recomendacion from '../../assets/img/Recomendacion.svg'
import Si from '../../assets/img/Si.svg'
import No from '../../assets/img/No.svg'
import {formatNumberES, redondearDecena} from '../../config'
import { set } from 'date-fns'
import {PromotionalVariableOffer} from './PromotionalVariableOffer'

function contarOcurrencias(jsonData) {
	let vacias = 0;
	let noVacias = 0;
  
	for (const key in jsonData) {
	  if (jsonData.hasOwnProperty(key)) {
		const valor = jsonData[key];
  
		if (valor === "" || valor === "-" || valor === "0") {
		  vacias++;
		} else {
		  noVacias++;
		}
	  }
	}
  
	return { vacias, noVacias };
  }

export const ProductTableFamiliaInner = ({
	// ProductTableInnerState,
	// setProductTableInnerState,
	setdeletestate,
	setModeloPopupState,
	offer, setProduct, setRowChange, setDeleteRow
}) => {
	const [estr, setestr] = useState(formatNumberES(offer.strat_price.replace("$","").replace(".","").replace(",",".")))
	const [dcto, setdcto] = useState(offer.discount.replace("%","").replace(",","."))
	const [mgcadb, setmgcadb] = useState(parseFloat(offer.customer_margin.replace("%","").replace(",",".")).toFixed(1))
	const [recommended_pvp, setrecommended_pvp] = useState(formatNumberES(offer.recommended_pvp.replace("$","").replace(".","").replace(",",".")))
	const [ProductTableInnerState, setProductTableInnerState] = useState(null)
	const [MouseDownState, setMouseDownState] = useState(null)
	const [onEdit, setOnEdit] = useState(false)
	const [PromotionalVariable, setPromotionalVariable] = useState(false)
	const resultado = contarOcurrencias(offer.promotional_variables_json);

	// let estr = String(offer.base_price).replace(".","").replace(",",".").replace("$","") * (1 - (parseInt(e.target.value)/100))
	// let desct = parseFloat(1 - (parseInt(e.target.value)/parseInt(String(offer.base_price).replace(".","").replace(",",".").replace("$",""))), 1) * 100

	const rowChangeFunctionPrice = () => {
		let row = offer;
		let estr_ = String(offer.base_price).replace(".","").replace(",",".").replace("$","") * (1 - (parseFloat(dcto)/100))
		row.strat_price = formatNumberES(estr_)
		row.__strat_price = parseInt(estr_)
		row.discount = dcto
		row.__discount = parseFloat(dcto).toFixed(1)
		row.customer_margin = mgcadb // Cada vez que se cambia este, entonces se recalcula el PVP.
		row.__customer_margin = parseFloat(mgcadb).toFixed(1)
		// Cálculo de pvp con el margen....
		// 1,19*precio estrategico unitario/(1-mg cadena)
		let pvp = redondearDecena( 1.19 * parseFloat(estr.replace(".","").replace(",",".")) / (1 - (parseFloat(mgcadb.replace("%",""))/100)) )
		setrecommended_pvp(formatNumberES(pvp))
		row.recommended_pvp = formatNumberES(pvp)
		row.__recommended_pvp = parseInt(pvp)
		row.strat_price_modified = 1

		setRowChange(row)
	}

	const rowChangeFunctionDesc = () => {
		let row = offer;
		row.strat_price = estr
		row.__strat_price = parseInt(estr.replace(".","").replace(",",".").replace("$",""))
		let desct = parseFloat(1 - (parseInt(estr)/parseInt(String(offer.base_price).replace(".","").replace(",",".").replace("$",""))), 1) * 100
		row.discount = desct.toFixed(1)
		row.__discount = parseFloat(desct).toFixed(1)
		row.customer_margin = mgcadb // Cada vez que se cambia este, entonces se recalcula el PVP.
		row.__customer_margin = parseFloat(mgcadb).toFixed(1)
		// Cálculo de pvp con el margen....
		// 1,19*precio estrategico unitario/(1-mg cadena)
		let pvp = redondearDecena( 1.19 * parseFloat(estr.replace(".","").replace(",",".")) / (1 - (parseFloat(mgcadb.replace("%",""))/100)) )
		setrecommended_pvp(formatNumberES(pvp))
		row.recommended_pvp = formatNumberES(pvp)
		row.__recommended_pvp = parseInt(pvp)
		row.strat_price_modified = 1

		setRowChange(row)
	}

	const rowChangeFunctionMg = () => {
		let row = offer;
		row.strat_price = estr
		row.__strat_price = parseInt(estr.replace(".","").replace(",",".").replace("$",""))
		// let desct = parseFloat(1 - (parseInt(estr)/parseInt(String(offer.base_price).replace(".","").replace(",",".").replace("$",""))), 1) * 100
		row.discount = dcto
		row.__discount = parseFloat(dcto).toFixed(1)
		row.customer_margin = mgcadb // Cada vez que se cambia este, entonces se recalcula el PVP.
		row.__customer_margin = parseFloat(mgcadb).toFixed(1)
		// Cálculo de pvp con el margen....
		// 1,19*precio estrategico unitario/(1-mg cadena)
		let pvp = redondearDecena( 1.19 * parseFloat(estr.replace(".","").replace(",",".")) / (1 - (parseFloat(mgcadb.replace("%",""))/100)) )
		setrecommended_pvp(formatNumberES(pvp))
		row.recommended_pvp = formatNumberES(pvp)
		row.__recommended_pvp = parseInt(pvp)
		row.strat_price_modified = 1

		setRowChange(row)
	}

	return (
		
			<>
			{PromotionalVariable && <PromotionalVariableOffer 
				setPromotionalVariable={setPromotionalVariable}
				data={offer}
				setData={setRowChange}
				dataPromotionals={offer}
				
			/>}
			<tr className='activate familia1'>
				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={[offer.strategy_name]}
					width={'80px'} 
					showOption='show'
					/>
						{/*model, si es 1 es el check y si es 0 es la x*/}
					{offer.model ? <img src={Si} alt='' /> : <img src={No} alt='' />}
					{/* si es 0 entonces ciruclo, */}

					{(offer.strategy_name === "" || !offer.strategy_name) 
					&& 
					<img src={Recomendacion} alt='' />}
					{offer.strategy_name === "Rentabilidad" && 
					
					<img src={Recomendacion2} alt='' />}
					{offer.strategy_name === "Volumen" && 
					
					<img src={Volumen} alt='' />}
					{offer.strategy_name === "Optimizacion" && (
					
					<img src={Volumen_Rentabilidad} alt='' />
					)}
					
					.
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<a
							href='#'
							onClick={(e) => {
								setProduct(offer)
								setModeloPopupState(true)
							}}
						>
							{offer.product_code}
						</a>
					</div>
				</td>
				<td className='description'>
					<div className='display_flex  gray'>
						<p>{offer.product_description}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{offer.subfamily}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{offer.short_brand}</p>
						<img
							src={dropdownprod}
							alt=''
							style={{cursor: 'pointer'}}
							onClick={(e) => {
								setProductTableInnerState(!ProductTableInnerState)
							}}
						/>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						<p className='ele'>{offer.product_rows.length} Act.</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p className='ele'>{offer.curr_vol}</p>
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p className='ele'>{offer.opt_vol}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={[offer.tooltip_strat_vol_kg]}
					width={'80px'} 
					showOption='show'
					/>
						{offer.strat_vol}
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className={`display_flex  ${parseFloat(String(offer.var).split("%")[0].replace(",", ".")) > 0 ? 'green' : 'orange'}`} style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					
					
						{parseFloat(String(offer.var).replace("%","").replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p>
						<ToolTipCustomList 
							// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
							list={[
								String(
								parseFloat(
								parseFloat(String(offer.strat_vol).split(" ")[0].replace(",","."))
								- parseFloat(String(offer.curr_vol).split(" ")[0].replace(",","."))
								).toFixed(1)
								).replace(".",",") + " " + String(offer.curr_vol).split(",")[1].substring(1,10000)
							]}
							width={'80px'} 
							showOption='show'
							/>
							{offer.var}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+offer.tooltip_base_pxu, "$ venta: $"+offer.tooltip_base_sp]}
					width={'100px'} 
					showOption='show'
					/>
						{offer.base_price}
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+offer.tooltip_current_pxu, "$ venta: $"+offer.tooltip_current_sp]}
					width={'100px'} 
					showOption='show'
					/>
						{offer.curr_price}
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+offer.tooltip_optimization_pxu, "$ venta: $"+offer.tooltip_optimization_sp]}
					width={'100px'} 
					showOption='show'
					/>
						{offer.opt_price}
					</div>
				</td>

				<td>
					<div
						className='head_box wrap_input'
						style={{transform: 'translateX(5px)'}}
					>
						<span style={{fontSize: 11}}>$</span>
						<input
							key={offer.id+"_strprice"}
							type='text'
							value={String(estr).replace("$","")}
							onChange={(e) => {
								setOnEdit(true)
								setestr(e.target.value)
							}}
							// formatNumberES
							onKeyUp={(e) =>{
								if (e.key === 'Enter') {
									setOnEdit(false)
									// Lógica que quieres ejecutar cuando se presiona Enter
									// alert(e.target.value.replace("$","").replace(".","").replace(",","."))
									setestr(formatNumberES(e.target.value.replace("$","").replace(".","").replace(",",".")))
									let desct = parseFloat(1 - (parseInt(e.target.value.replace("$","").replace(".","").replace(",","."))/parseInt(String(offer.base_price).replace(".","").replace(",",".").replace("$",""))), 1) * 100
									console.log("Ejecutando calculo front")
									setdcto(desct.toFixed(1))
									e.target.blur();
									rowChangeFunctionDesc()
								}	
							}}	
							onBlur={(e) => {
								// Lógica que quieres ejecutar cuando se presiona Enter
								if(onEdit){
									// alert(e.target.value.replace("$","").replace(".","").replace(",","."))
									setOnEdit(false)
									setestr(formatNumberES(e.target.value.replace("$","").replace(".","").replace(",",".")))
									let desct = parseFloat(1 - (parseInt(e.target.value.replace("$","").replace(".","").replace(",","."))/parseInt(String(offer.base_price).replace(".","").replace(",",".").replace("$",""))), 1) * 100
									console.log("Ejecutando calculo front")
									setdcto(desct.toFixed(1))
									e.target.blur();
									rowChangeFunctionDesc()
								}
							}}	
						/>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div
						className='head_box wrap_input'
						style={{transform: 'translateX(5px)'}}
					>
						<input
							key={offer.id+"_dcto"}
							type='text'
							value={String(dcto).replace(".",",")}
							onChange={(e) => {
								setOnEdit(true)
								setdcto(e.target.value)
							}}
							onKeyUp={(e) =>{
								if (e.key === 'Enter') {
									// Lógica que quieres ejecutar cuando se presiona Enter
									setOnEdit(false)
									setdcto(parseFloat(e.target.value.replace(",",".")).toFixed(1))
									let estr = String(offer.base_price).replace(".","").replace(",",".").replace("$","") * (1 - (parseFloat(e.target.value.replace(",","."))/100))
									setestr(formatNumberES(estr))
									rowChangeFunctionPrice()
								}	
							}}
							onBlur={(e) => {
								if(onEdit){
									setOnEdit(false)
									setdcto(parseFloat(e.target.value.replace(",",".")).toFixed(1))
									let estr = String(offer.base_price).replace(".","").replace(",",".").replace("$","") * (1 - (parseFloat(e.target.value.replace(",","."))/100))
									setestr(formatNumberES(estr))
									rowChangeFunctionPrice()
								}
							}}
						/>
						<span style={{fontSize: 11}}>%</span>
					</div>
				</td>
				<td>
					<div className={`display_flex ${parseFloat(String(offer.var_eb).split("%")[0].replace(",", ".")) > 0 ? 'green' : 'orange'}`}>
							
					{parseFloat(String(offer.var_eb).split("%")[0].replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p className='ten'>{offer.var_eb}</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className={`display_flex  ${parseFloat(String(offer.var_ob).split("%")[0].replace(",", ".")) > 0 ? 'green' : 'orange'}`}>
					{parseFloat(String(offer.var_ob).split("%")[0].replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p className='ten'>{offer.var_ob}</p>
					</div>
				</td>

				<td>
					<div className={`display_flex   ${parseFloat(String(offer.strat_ro_pct).split("%")[0].replace(",", ".")) > 0 ? 'green' : 'orange'}`} style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px', color: '#13acaa', fontWeight: 500}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					
						
					{parseFloat(String(offer.strat_ro_pct).split("%")[0].replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p>
						<ToolTipCustomList 
							// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
							list={["RO Total: $"+offer.tooltip_total_ro, "RO Unit: $"+offer.tooltip_unit_ro, "$ Crit:"+offer.critical_price]}
							width={'120px'} 
							showOption='show'
							/>
							{offer.strat_ro_pct}</p>
					</div>
				</td>

				<td>
					<div className={`display_flex   
				${parseFloat(String(offer.incr_ro).split("$")[1].replace(".", "")) > 0 ? 'green' : 'orange'}`}>
					{parseFloat(String(offer.incr_ro).split("$")[1].replace(".","")) >
					 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p className='ten'>{offer.incr_ro}</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						
						<p className='ten'>-</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						<p className='ele'>{offer.elasticity}</p>
					</div>
				</td>
				<td>
					<div
						className='head_box wrap_input'
						style={{transform: 'translateX(5px)'}}
					>
						<input
							key={offer.id+"_mgcadb"}
							type='text'
							value={String(mgcadb).replace("%","").replace(".",",")}
							onChange={(e) => {
								setOnEdit(true)
								setmgcadb(e.target.value)
							}}
							onKeyUp={(e) =>{
								if (e.key === 'Enter') {
									// Lógica que quieres ejecutar cuando se presiona Enter
									setmgcadb(parseFloat(e.target.value.replace(",",".")).toFixed(1))
									// rowChangeFunction()
									setOnEdit(false)
									rowChangeFunctionMg()
								}	
							}}
							onBlur={(e) => {
								if(onEdit){
									setmgcadb(parseFloat(e.target.value.replace(",",".")).toFixed(1))
									// rowChangeFunction()
									setOnEdit(false)
									rowChangeFunctionMg()
								}
							}}
						/>
						<span style={{fontSize: 11}}>%</span>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						<p className='ele'>{offer.recommended_pvp}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<a href='#' className='ele'
							onClick={(e) => {
								setPromotionalVariable(true)
							}}
						>
							{resultado.noVacias}/{resultado.vacias + resultado.noVacias} 
						</a>
						<img
							src={closing}
							alt=''
							onClick={(e) => {
								setDeleteRow(offer)
								setdeletestate(true)
							}}
							style={{cursor: 'pointer'}}
						/>
					</div>
				</td>
			</tr>
			{ProductTableInnerState && 
							offer.product_rows.map((row) =>{
								return <ProductTableInner data={row}/>
							})
						}
			</>
	)
}
