import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import './select.css'

const CustomDropdown = ({ select, setSelected }) => {
	const [isActive, setIsActive] = useState(false)
	const options = ['PDF', 'Csv']
	return (
		<div className='dropdown'>
			<div
				className={`dropdown_btn ${isActive && 'is_open'}`}
				onClick={() => setIsActive(!isActive)}
			>
				{select}
				{isActive ? (
					<FontAwesomeIcon icon={faAngleDown} rotation={180} />
				) : (
					<FontAwesomeIcon icon={faAngleDown} />
				)}
			</div>
			{isActive && (
				<div className='dropdown-content'>
					{options.map((option) => (
						<div
							onClick={(e) => {
								setSelected(option)
								setIsActive(false)
							}}
							className='dropdown-item'
						>
							{option}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default CustomDropdown
