import React, { useState, useEffect } from "react";
import PromoBooster from "../assets/img/PromoBooster.svg";
import { Link, useNavigate } from "react-router-dom";
import line_bg from "../assets/img/line_bg.svg";
import eye from "../assets/img/eye.svg";
import { useLocation } from 'react-router-dom';
import { config } from '../config'

export const ResetPasswordFound = () => {
  let navigate = useNavigate();
  const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        updatePasswordStrength(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

  const location = useLocation();

  useEffect(() => {
    // Obtener el valor del token desde la URL
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    // Hacer algo con el token, por ejemplo, imprimirlo en la consola
    console.log('Token:', token);
    localStorage.setItem("session", token);
    if(token == null || token == undefined || token == ""){
      navigate("/");
    }
  }, [location.search]);


  const handleUpdate = async (e, password_) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    const response = await fetch(config.apiUrl + `/user/update/password`, {
      //mode: 'no-cors',
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
      },
      body: JSON.stringify({
        password_pb: password_,
      }),
    });
    const data = await response.json();

    if (response.ok) {
      console.log("usuario loggeado");
      console.log(data);
      navigate("/PasswordChangeSuccess");
      //login(response.data, location.state?.from)
    } else {
      console.log("contraseña o email icorrectos");
      alert("El enlace ha caducado, intenta nuevamente o contacta con el administrador.");
    }
  };

  const updatePasswordStrength = (newPassword) => {
    let strength = 0;

    const conditions = [
        (password) => password.length >= 8,                     // Longitud mínima
        (password) => /[A-Z]/.test(password),                   // Mayúsculas
        (password) => /[a-z]/.test(password),                   // Minúsculas
        (password) => /[0-9]/.test(password),                   // Números
        (password) => /[^A-Za-z0-9]/.test(password),            // Símbolos especiales
    ];

    conditions.forEach((condition) => {
        if (condition(newPassword)) {
            strength++;
        }
    });

    switch (strength) {
        case 5:
            setPasswordStrength('Muy Fuerte');
            break;
        case 4:
            setPasswordStrength('Fuerte');
            break;
        case 3:
            setPasswordStrength('Medio');
            break;
        default:
            setPasswordStrength('Bajo');
            break;
    }
};

const passwordsMatch = password && confirmPassword && password === confirmPassword;

  return (
    <div className="Login ResetPassword change-pass">
      <img src={line_bg} alt="" style={{ opacity: 0.4 }} />
      <form action="">
        <h1>Reestablece tu Contraseña</h1>
        <p>
          Las contraseñas fuertes incluyen números, letras y signos de
          puntuación.
        </p>
        <div
          className={`input_wrapper input_active`}
        >
          { /* 
          
          ${
            PassError == true
              ? "input_active"
              : PassError == false && "passerror"
          }
          
          */ }
          <input
            type="password"
            value={password}
                onChange={handlePasswordChange}
            placeholder="Ingresa tu nueva contraseña"
          />
          <img
            src={eye}
            alt=""
            onClick={(e) => {
              let parent = e.target.closest(".input_wrapper");
              let input = parent.querySelector("input");
              if (input.type == "password") {
                input.type = "text";
              } else {
                input.type = "password";
              }
            }}
          />
        </div>
        <div
          className={`input_wrapper input_active`}
          
        >
          { /* 
        
        ${
          ConfirmPassError == true
            ? "input_active"
            : ConfirmPassError == false && "passerror"
        } 
      */ }
          <input
            type="password"
            value={confirmPassword}
                onChange={handleConfirmPasswordChange}
            placeholder="Ingresa tu contraseña una vez más"
          />
          <img
            src={eye}
            alt=""
            onClick={(e) => {
              let parent = e.target.closest(".input_wrapper");

              let input = parent.querySelector("input");

              if (input.type == "password") {
                input.type = "text";
              } else {
                input.type = "password";
              }
            }}
          />
        </div>
        {passwordsMatch ? <p>Las contraseñas coinciden</p> : <p>Las contraseñas no coinciden</p>}
            <p className="error_code">Nivel de seguridad de la contraseña: {passwordStrength}</p>

        <div className="button_wrapper button_wrapper_new">
          <button onClick={(e)=>{
            e.preventDefault();
            if(passwordsMatch && 
              (passwordStrength == 'Muy Fuerte'
              || passwordStrength == 'Fuerte'
              || passwordStrength == 'Medio')
              ){
              handleUpdate(e, password);
            }else{
              alert("Las contraseñas no coinciden o no son seguras, nivel medio es lo mínimo aceptado.")
            }

          }}>Reestablecer Contraseña</button>

          <Link to={"/"}>Cancelar</Link>
        </div>
      </form>
      <img src={PromoBooster} className="logo_one" alt="" />
    </div>
  );
};
