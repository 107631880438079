import React from 'react'
import jumbo from '../../assets/img/newPFOffer/jumbo.svg'
import { config, getImgCustomer, formatMoney } from '../../config'

export const TopNavigatorOferta = ({data}) => {
	return (
		<div className='TopNavigator TopNavigatorOfertaNew TopNavigatorOferta'>
			<nav>
				<li>
					<h1>Oferta</h1>
					<p>{data.offer_data.promotion_name}</p>
				</li>
				<li>
					<h1>Fecha Sell-In</h1>
					<p>{data.offer_data.start_sellin} al {data.offer_data.end_sellin}</p>
				</li>
				<li>
					<h1>Fecha Sell-Out</h1>
					<p>{data.offer_data.start_sellout} al {data.offer_data.end_sellout}</p>
				</li>
				<li style={{borderLeft: '1px solid #ddd'}}>
					<h1>Cadena</h1>
					{getImgCustomer(data.offer_data.customer, 100)}
				</li>
				<li>
					<h1>Canal</h1>
					<p>{data.offer_data.channel}</p>
				</li>
			</nav>
		</div>
	)
}
