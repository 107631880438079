import React, { useEffect, useState } from 'react'
import Recomendacion2 from '../assets/img/Recomendacion2.svg'
import Volumen from '../assets/img/Volumen.svg'
import Volumen_Rentabilidad from '../assets/img/Volumen_Rentabilidad.svg'
import Recomendacion from '../assets/img/Recomendacion.svg'
import Si from '../assets/img/Si.svg'
import orange_down from '../assets/img/orange_down.svg'
import arrow_light_blue from '../assets/img/arrow_light_blue.svg'
import OIAct from '../assets/img/OI-Act.svg'
import Flecha from '../assets/img/Flecha.svg'
import No from '../assets/img/No.svg'
import tag from '../assets/img/tag.svg'
import recomendacion_alza from '../assets/img/recomendacion_alza.svg'
import { Tooltip } from '@mui/material'

import { ToolTipCustom } from './ToolTipCustom'
import { ToolTipCustomList } from './ToolTipCustomList'
import { set } from 'date-fns'
import { formatNumberES } from '../config'

export const VienesasRowGrayNew = ({
	setCodigoPop,
	setPromotionalVariable,
	name,
	ActiveInner,
	setActiveInner,
	value,
	line,
	data,
	setData,
	setDataPromotionals,
	setDataProduct,
	product,
}) => {
	const [MouseDownState, setMouseDownState] = useState(null)
	const [activeRow, setActiveRow] = useState(null)
	let total_promotional_values = 0
	let total_promotional_vars = 5
	const [preProp, setPreProp] = useState('')

	Object.keys(line.promotional_values).forEach((key) => {
		// console.log(key, line.promotional_values[key]);
		try {
			if (line.promotional_values[key].length > 0) {
				total_promotional_values += 1
			}
		} catch {}
	})

	const countOffices = (code_product) => {
		let counter = 0
		data.data_rows.map((EachRow) => {
			if (EachRow.code_product == code_product) {
				EachRow.offices_data_rows.map((office) => {
					if (office.active_office == 1) {
						counter++
					}
				})
			}
		})
		return counter
	}

	useEffect(() => {
		if(countOffices(line.code_product) > 0 && line.price_act > line.price_prop){
			setActiveRow(true)
		}else{
			setActiveRow(false)
		}
	}, [line])

	const updatePercent = (code_product) => {
		//Función que actualiza precio, descuentos y pvp's de padres e hijos.
		//SE DEBE BUSCAR DENTRO DE LOS ELEMENTOS DE DATA Y LUEGO SETTEAR CON SETDATA, AL MENOS EN EL CASO DE LOS HIJOS
		//PASA QUE CUANDO EL ELEMENTO ESTÁ CERRADO LOS IDES DEL DOM NO EXISTEN
		let precio_propuesto = parseFloat(
			String(document.getElementById('input_price_' + code_product).value)
				.replace('.', '')
				.replace(',', '.')
		)
		let pvp_sugerido = line.pvp_sug
		pvp_sugerido = parseFloat(
			pvp_sugerido
				.replace('$', '')
				.replace('.', '')
				.replace(',', '')
				.replace(' ', '')
		)
		let mg_pvp_general = parseFloat(
			String(document.getElementById('mg_pvp_general').value)
				.replace('.', '')
				.replace(',', '.')
		)
		let margen_cliente = parseFloat(1 + mg_pvp_general / 100).toFixed(2) //Se debe extraer del input que por defecto vendrá con 30% de margen
		console.log('Margen propuesto nuevo!!!---------')
		/*let new_pvp_sug = parseFloat(
			//precio_propuesto * 1.19 * margen_cliente
			precio_propuesto/(1-(mg_pvp_general/100))*1.19	
		).toFixed(0)*/
		//document.getElementById("father_pvp_sug_"+code_product).innerHTML = "$"+String(new_pvp_sug);
		const newData = {...data};
		newData.data_rows.map((line_) => {
			console.log('marcos', line_)
			if (line_.code_product === code_product) {
				line_.price_prop = String(precio_propuesto)
        		line_.__price_prop = String(precio_propuesto)
				//line_.pvp_sug = String(new_pvp_sug)
				//line.__pvp_sug = String(new_pvp_sug)
				line_.prop_price_modified = 1
				line_.ever_modified = 1
				line_.offices_data_rows.map((office) => {
				if(office.active_office === 1){
					office.price_prop = String(precio_propuesto)
				}
					
					//office.pvp_sug = String(new_pvp_sug)
					//office.__pvp_sug = String(new_pvp_sug)
				})
        document.getElementById('mensaje_simulador').style.display ='block'
			}
		})
		//Para guardar se deben settear data con setData y los cambios correspondientes a sus variables
		setData({ ...newData, mg_pvp: mg_pvp_general / 100 })
	}

	const onEnter = (event, code_product) => {
		if (event.key === 'Enter') {
			// alert(event.target.value)
			event.target.value = formatNumberES(event.target.value)
			updatePercent(code_product)
		}
	}

	const CapitalizeFirstLetter = (str) => {
		const arr = str.toLowerCase().split(' ')
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
		}
		const str2 = arr.join(' ')
		return str2
	}

	return (
		<tr className={`${activeRow ? 'VienesasRowActive' : 'VienesasRowGray'} active`}	
		>
			<td>
				<div className='display_flex gray '>
					{/*model, si es 1 es el check y si es 0 es la x*/}
					{line.model ? <img src={Si} alt='' /> : <img src={No} alt='' />}
					{/* si es 0 entonces ciruclo, */}
					{line.recommendation === 0 && <img src={Recomendacion} alt='' />}
					{line.recommendation === 1 && <img src={Recomendacion2} alt='' />}
					{line.recommendation === 2 && <img src={Volumen} alt='' />}
					{line.recommendation === 3 && (
						<img src={Volumen_Rentabilidad} alt='' />
					)}
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<a
						href='#'
						onClick={(e) => {
							e.preventDefault()
							setCodigoPop(true)
							//setDataProduct(product)
              setDataProduct({ ...product, office_code: null })
            }}
					>
						{name}
					</a>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{CapitalizeFirstLetter(line.description)}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{
					//line.subcategory
					line.subfamily
					}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{line.brand}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<div
						className='display_flex'
						style={{ cursor: 'pointer' }}
						onClick={(e) => {
							if (ActiveInner == value) {
								setActiveInner(null)
							} else {
								setActiveInner(value)
							}
						}}
					>
						{ActiveInner == value ? (
							<svg
								width='17'
								height='16'
								viewBox='0 0 17 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<rect
									x='2.5'
									y='2'
									width='12'
									height='12'
									rx='6'
									fill='#9AD1F8'
								/>
								<path
									d='M12.5 6L8.5 10L4.5 6'
									stroke='white'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						) : (
							<svg
								width='17'
								height='18'
								viewBox='0 0 17 18'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<rect
									x='2.5'
									y='3'
									width='12'
									height='12'
									rx='6'
									fill='#DBF0FF'
								/>
								<path
									d='M7.5 5L11.5 9L7.5 13'
									stroke='#9AD1F8'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						)}
						<p style={{ marginLeft: 5 }}>{line.offices_data_rows.length} Of.</p>
					</div>
				</div>
			</td>
			<td>
				<div className='display_flex   green'>
					<p style={{ color: '#36A7F5' }}>
						{countOffices(line.code_product)}/{line.offices_data_rows.length}
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{line.vol_act}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p style={{ color: '#13ACAA' }}>{line.vol_opt}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{line.vol_prop}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{line.vol_prop_oi}</p>
				</div>
			</td>
			<td>
				<Tooltip
					// title={`$Unidad: $${product?.tooltips?.price_act?.pxu} $Venta: $${product?.tooltips?.price_act?.pv}`}
				>

					<div className='display_flex gray cursor_row_active'
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					>
						<ToolTipCustomList 
						// value={`$Unidad: $${product?.tooltips?.price_act?.pxu} $Venta: $${product?.tooltips?.price_act?.pv}`}
						list={[`$Unidad: $${product?.tooltips?.price_act?.pxu}`, `$Venta: $${product?.tooltips?.price_act?.pv}`]}
						width={'100px'}
						/>
						<p id={'father_price_act_' + line.code_product}>
							${line.price_act}
						</p>
					</div>
				</Tooltip>
			</td>
			<td>
				<Tooltip
					// title={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
				>
					<div className='display_flex gray cursor_row_active'
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={[`$Unidad: $${product?.tooltips?.price_opt?.pxu}`,`$Venta: $${product?.tooltips?.price_opt?.pv}`]}
					width={'100px'} 
					/>
						<p style={{ color: '#13ACAA' }}> ${line.price_opt}</p>
					</div>
				</Tooltip>
			</td>
			<td>
				<Tooltip
					// title={`$Unidad: $${product?.tooltips?.price_prop?.pxu} $Venta: $${product?.tooltips?.price_prop?.pv}`}
				>
					<div
						className='display_flex  center_justify gray wrap_input ' // Se le agrega la clase cursor_row_active
						style={{ fontSize: '11px' }}
						>
						{/* <ToolTipCustom value="$-" /> */}
						$
						<input
							type='text'
							id={'input_price_' + line.code_product}
							defaultValue={line.price_prop}
							onChange={(e) => {
								e.stopPropagation();
								updatePercent(line.code_product)
							}}
							onKeyPress={(e) => {
								//e.target.value = e.target.value.replace(/\D/g, '');
								//validateNumber(e)//Activar función.
								onEnter(e, line.code_product);
							}}

							onBlur={(e) => {
								e.target.value = formatNumberES(e.target.value)
								updatePercent(line.code_product)
							}}
						/>
					</div>
				</Tooltip>
			</td>
			{/* <td>
        <div className="display_flex  center_justify gray wrap_input">
          <input type="text" value="% 0,0" />
        </div>
      </td> */}
			<td>
				<div className='display_flex  gray'>
					<p id={'father_price_percent_' + line.code_product}>
						{line.opt_act}%
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>${line.mg_act}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>${line.mg_opt}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>${line.mg_prop}</p>
				</div>
			</td>
			<td>
				<Tooltip
					// title={`RO $/UMP: ${product?.tooltips?.ro?.ro_price_kg}, RO $: ${product?.tooltips?.ro?.ro_price}, Crítico: $${product?.tooltips?.ro?.critical_price}`}
				>
					{parseFloat(
						String(line.ro_perc_prop).replace('.', '').replace(',', '.')
					) > 0 ? (
						<div className='display_flex green cursor_row_active'
						onMouseUp={(e) => {
							e.target.querySelector('.ToolTipCustom').classList.remove('show')

							clearInterval(MouseDownState)
						}}
						onMouseDown={(e) => {
							if (e.button != 0) {
								return false
							}
							let MouseDownTimeOut = setTimeout(() => {
								e.target.querySelector('.ToolTipCustom').classList.add('show')
							}, [50])

							setMouseDownState(MouseDownTimeOut)
						}}
						>
							<ToolTipCustomList 
							// value={`RO $/UMP: ${product?.tooltips?.ro?.ro_price_kg}, RO $: ${product?.tooltips?.ro?.ro_price}, Crítico: $${product?.tooltips?.ro?.critical_price}`}
							list={[`RO $/UMP: ${product?.tooltips?.ro?.ro_price_kg}`,`RO $: ${product?.tooltips?.ro?.ro_price}`,`Crítico: $${product?.tooltips?.ro?.critical_price}`]}
							width={'120px'} />
							<img src={Flecha} alt='' className='mr-5' />
							<p>{line.ro_perc_prop}</p>
						</div>
					) : (
						<div className='display_flex orange cursor_row_active'
						onMouseUp={(e) => {
							e.target.querySelector('.ToolTipCustom').classList.remove('show')

							clearInterval(MouseDownState)
						}}
						onMouseDown={(e) => {
							if (e.button != 0) {
								return false
							}
							let MouseDownTimeOut = setTimeout(() => {
								e.target.querySelector('.ToolTipCustom').classList.add('show')
							}, [50])

							setMouseDownState(MouseDownTimeOut)
						}}
						>
							<ToolTipCustom value={`RO $/UMP: ${product?.tooltips?.ro?.ro_price_kg}, RO $: ${product?.tooltips?.ro?.ro_price}, Crítico: $${product?.tooltips?.ro?.critical_price}`}
							width={'280px'} />
							<img src={orange_down} alt='' className='mr-5' />
							<p>{line.ro_perc_prop}</p>
						</div>
					)}
				</Tooltip>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{line.uplift}</p>
				</div>
			</td>
			<td>
				<Tooltip // </td>title={`RO. Incr. %: ${product?.tooltips?.ro?.ro_incre_perc}`}>
				>
					<div className='display_flex  gray cursor_row_active'
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					>
						<ToolTipCustom value={`RO. Incr. $${product?.tooltips?.ro?.ro_incre_perc}`}
						width={'130px'} />
						<p>{line.ro_incr}%</p>
					</div>
				</Tooltip>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p id={'father_pvp_sug_' + line.code_product}>
						${line.pvp_sug.split(',')[0]}
					</p>
				</div>
			</td>
			<td>
				<div
					className='display_flex editable_icon  gray'
					onClick={(e) => {
						console.log("Variables promocionales#### SETT::: "+line.product_id);
						console.log(line);
						setDataPromotionals({
							promotional_values: line.promotional_values,
							product_id: line.product_id,
							product_code: line.code_product,
						})
						setPromotionalVariable(true)
					}}
				>
					<p id={'var_promo_' + line.product_id}>
						{total_promotional_values}/{total_promotional_vars}
					</p>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 24 24'
						fill='currentColor'
						className='w-6 h-6'
					>
						<path d='M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z' />
					</svg>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<img src={OIAct} alt='' />
				</div>
			</td>
		</tr>
	)
}
