import React from 'react'

import tick from '../../../assets/img/tick.svg'
import close_sm from '../../../assets/img/close_sm.svg'

export const InnerActiveSqguimiento = ({ ActiveInner, current, office }) => {
	return (
		<>
			<tr
				className={`activate familia1 InnerActiveSqguimiento ${
					ActiveInner === current && 'activeRow'
				}`}
			>
				<td colSpan={5} className='inner_active'>
					<span></span>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>{office?.office_name}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{office?.price_act}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   green'>
						<p>{office?.price_opt}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{office?.inicial?.prop_price}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{office?.inicial?.discount}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{office?.colaborativa?.prop_price}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{office?.colaborativa?.discount}</p>
						{office?.colaborativa?.icon === 'check' && (
							<img src={tick} alt='' />
						)}
						{office?.colaborativa?.icon === 'x' && (
							<img src={close_sm} alt='' />
						)}
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>{office?.negociacion?.prop_price}</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>{office?.negociacion?.discount}</p>
						{office?.negociacion?.icon === 'check' && <img src={tick} alt='' />}
						{office?.negociacion?.icon === 'x' && <img src={close_sm} alt='' />}
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>{office?.ejecucion?.prop_price}</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>{office?.ejecucion?.discount}</p>
						{office?.ejecucion?.icon === 'check' && <img src={tick} alt='' />}
						{office?.ejecucion?.icon === 'x' && <img src={close_sm} alt='' />}
					</div>
				</td>
			</tr>
		</>
	)
}
