import React, { useEffect, useState } from "react";
import { Navbar } from "./Navbar";
import user from "../assets/img/user.svg";
import "../assets/css/BusquedadePromociones.css";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import spin from "../assets/img/spin.png";
import Cerrar from "../assets/img/Cerrar.svg";
export const ConfiguracióndelPerfil = () => {
  const [active_option, setactive_option] = useState(false);
  const [loader, setloader] = useState(true);
  const [emailUpdate, setemailUpdate] = useState(false);
  const [passwordUpdate, setpasswordUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [NameVal, setNameVal] = useState("Pedro P.");

  const [disabled, setdisabled] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloader(false);
    }, 3000);
  }, []);

  useEffect(() => {
    document
      .querySelectorAll(".ConfiguracióndelPerfilWrapper input")
      .forEach((EachInput) => {
        EachInput.addEventListener("keyup", (e) => {
          console.log("working");
          setdisabled(false);
        });
      });
  }, [show, passwordUpdate, emailUpdate, active_option]);

  return (
    <div className="Home BusquedadePromocionesWrapper ConfiguracióndelPerfilWrapper">
      {loader && (
        <div className="loader_wrappper">
          <img src={spin} alt="" />
          <p>Cargando...</p>
        </div>
      )}
      {emailUpdate && (
        <div className="update_info_wrapper">
          <div className="popup">
            <div className="close_icon" onClick={(e) => setemailUpdate(false)}>
              <img src={Cerrar} alt="" />
            </div>
            <h1>Correo Electrónico</h1>
            <div className="row_area">
              <label htmlFor="">Correo electrónico actual</label>
              <a href="#">pedro.perez@pf.com</a>
            </div>
            <div className="row_area">
              <label htmlFor="">Correo electrónico nuevo</label>
              <input type="text" placeholder="pedro.perez@pf.com" />
            </div>
            <div className="row_area">
              <label htmlFor="">Ingresa tu contraseña</label>
              <input type="password" placeholder="***********" />
            </div>
            <div className="button_wrapper">
              <button onClick={(e) => setemailUpdate(false)}>Cancelar</button>
              <button onClick={(e) => setemailUpdate(false)}>
                Cambiar mi correo electrónico
              </button>
            </div>
          </div>
        </div>
      )}

      {passwordUpdate && (
        <div className="update_info_wrapper">
          <div className="popup" style={{ height: 300 }}>
            <div
              className="close_icon"
              onClick={(e) => setpasswordUpdate(false)}
            >
              <img src={Cerrar} alt="" />
            </div>
            <h1>Cambiar Contraseña</h1>
            <div className="row_area">
              <label htmlFor="">Contraseña Actual</label>
              <input type="password" placeholder="***********" />
            </div>
            <div className="row_area">
              <label htmlFor="">Contraseña nueva</label>
              <input type="password" placeholder="***********" />
            </div>
            <div className="row_area">
              <label htmlFor="">Confirma tu contraseña</label>
              <input type="password" placeholder="***********" />
            </div>
            <div className="button_wrapper">
              <button onClick={(e) => setpasswordUpdate(false)}>
                Cancelar
              </button>
              <button onClick={(e) => setpasswordUpdate(false)}>
                Cambiar mi contraseña
              </button>
            </div>
          </div>
        </div>
      )}

      <Navbar />
      <Link className="button_back_wrapper">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_2739_29951"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2739_29951)">
            <path
              d="M7.97505 19.45C7.60838 19.45 7.29605 19.3207 7.03805 19.062C6.77938 18.804 6.65005 18.4917 6.65005 18.125C6.65005 17.7583 6.77938 17.446 7.03805 17.188C7.29605 16.9293 7.60838 16.8 7.97505 16.8H14.2C15.1834 16.8 16.0377 16.4957 16.763 15.887C17.4877 15.279 17.85 14.5 17.85 13.55C17.85 12.6 17.4877 11.8207 16.763 11.212C16.0377 10.604 15.1834 10.3 14.2 10.3H8.50005L9.92505 11.725C10.175 11.975 10.296 12.2833 10.288 12.65C10.2794 13.0167 10.15 13.325 9.90005 13.575C9.65005 13.825 9.35005 13.946 9.00005 13.938C8.65005 13.9293 8.35005 13.8 8.10005 13.55L4.42505 9.9C4.29172 9.76667 4.19605 9.62067 4.13805 9.462C4.07938 9.304 4.05005 9.14167 4.05005 8.975C4.05005 8.80833 4.07938 8.646 4.13805 8.488C4.19605 8.32933 4.29172 8.18333 4.42505 8.05L8.10005 4.375C8.35005 4.125 8.65405 4 9.01205 4C9.37072 4 9.67505 4.125 9.92505 4.375C10.1917 4.625 10.3207 4.93333 10.312 5.3C10.304 5.66667 10.175 5.975 9.92505 6.225L8.50005 7.65H14.2C15.9167 7.65 17.396 8.21667 18.638 9.35C19.8794 10.4833 20.5 11.8833 20.5 13.55C20.5 15.2167 19.8794 16.6167 18.638 17.75C17.396 18.8833 15.9167 19.45 14.2 19.45H7.97505Z"
              fill="#87D7D6"
            />
          </g>
        </svg>
        Volver al Inicio
      </Link>
      <div className="maincontentarea_BusquedadePromociones ConfiguracióndelPerfil">
        <div className="top_busqueda_area">
          <img src={user} alt="" />
          <h1>Configuración del Perfil</h1>
        </div>

        <div className="profile_wrapper">
          <div className="user_avatar_wrapper">
            <img
              src="https://cdn.pixabay.com/photo/2017/08/30/12/45/girl-2696947_1280.jpg"
              alt=""
            />

            <div
              className={`pen_icon ${active_option && "color"}`}
              onClick={(e) => {
                setactive_option(!active_option);
              }}
            >
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_2809_474671"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="11"
                  height="11"
                >
                  <rect width="11" height="11" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2809_474671)">
                  <path
                    d="M9.2356 4.33115L6.66894 1.76449L7.28769 1.14574C7.52449 0.90893 7.81294 0.790527 8.15302 0.790527C8.4928 0.790527 8.78109 0.90893 9.01789 1.14574L9.86581 2.00511C10.1026 2.24192 10.221 2.52456 10.221 2.85303C10.221 3.1815 10.1026 3.46414 9.86581 3.70094L9.2356 4.33115ZM1.50123 10.1062C1.33317 10.1062 1.19185 10.0489 1.07727 9.93428C0.962687 9.81969 0.905396 9.67837 0.905396 9.51032V7.78011C0.905396 7.69608 0.918687 7.61969 0.945271 7.55094C0.972159 7.48219 1.01616 7.41726 1.07727 7.35615L6.01581 2.41761L8.58248 4.98428L3.6554 9.93428C3.59428 9.99539 3.52935 10.0392 3.4606 10.0658C3.39185 10.0927 3.31546 10.1062 3.23144 10.1062H1.50123Z"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
            <div className="name_wrapper">
              <input
                type="text"
                value={NameVal}
                onChange={(e) => {
                  setNameVal(e.target.value);
                }}
              />
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                onClick={(e) => {
                  setemailUpdate(true);
                }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_2809_474671"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="11"
                  height="11"
                >
                  <rect width="11" height="11" fill="#87D7D6" />
                </mask>
                <g mask="url(#mask0_2809_474671)">
                  <path
                    d="M9.2356 4.33115L6.66894 1.76449L7.28769 1.14574C7.52449 0.90893 7.81294 0.790527 8.15302 0.790527C8.4928 0.790527 8.78109 0.90893 9.01789 1.14574L9.86581 2.00511C10.1026 2.24192 10.221 2.52456 10.221 2.85303C10.221 3.1815 10.1026 3.46414 9.86581 3.70094L9.2356 4.33115ZM1.50123 10.1062C1.33317 10.1062 1.19185 10.0489 1.07727 9.93428C0.962687 9.81969 0.905396 9.67837 0.905396 9.51032V7.78011C0.905396 7.69608 0.918687 7.61969 0.945271 7.55094C0.972159 7.48219 1.01616 7.41726 1.07727 7.35615L6.01581 2.41761L8.58248 4.98428L3.6554 9.93428C3.59428 9.99539 3.52935 10.0392 3.4606 10.0658C3.39185 10.0927 3.31546 10.1062 3.23144 10.1062H1.50123Z"
                    fill="#87D7D6"
                  />
                </g>
              </svg>
              <button>Aceptar</button>
            </div>

            {active_option && (
              <ul className="option_wrapper">
                <li>
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2810_474890)">
                      <mask
                        id="mask0_2810_474890"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="15"
                        height="15"
                      >
                        <rect width="15" height="15" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2810_474890)">
                        <path
                          d="M7.51575 10.1406C7.32825 10.1406 7.172 10.0781 7.047 9.95313C6.922 9.82813 6.8595 9.67188 6.8595 9.48438V4.64051L5.4375 6.06251C5.302 6.18751 5.14838 6.24738 4.97663 6.24213C4.80463 6.23688 4.65613 6.17176 4.53113 6.04676C4.39588 5.91126 4.32563 5.75501 4.32038 5.57801C4.31513 5.40101 4.38538 5.23951 4.53113 5.09351L7.047 2.57801C7.1095 2.51551 7.18238 2.46601 7.26563 2.42951C7.34888 2.39301 7.43225 2.37476 7.51575 2.37476C7.599 2.37476 7.67975 2.39301 7.758 2.42951C7.836 2.46601 7.90625 2.51551 7.96875 2.57801L10.5158 5.12501C10.651 5.26026 10.7186 5.41388 10.7186 5.58588C10.7186 5.75763 10.651 5.91126 10.5158 6.04676C10.3908 6.17176 10.237 6.23426 10.0545 6.23426C9.87225 6.23426 9.71863 6.16651 9.59363 6.03101L8.172 4.64051V9.48438C8.172 9.67188 8.10688 9.82813 7.97663 9.95313C7.84638 10.0781 7.69275 10.1406 7.51575 10.1406ZM3.49988 12.8125C3.14588 12.8125 2.83863 12.6849 2.57813 12.4296C2.31763 12.1744 2.18738 11.8645 2.18738 11.5V10.0311C2.18738 9.85413 2.2525 9.70051 2.38275 9.57026C2.513 9.44001 2.66663 9.37488 2.84363 9.37488C3.03113 9.37488 3.18738 9.44001 3.31238 9.57026C3.43738 9.70051 3.49988 9.85413 3.49988 10.0311V11.5H11.5001V10.0311C11.5001 9.85413 11.5653 9.70051 11.6955 9.57026C11.8255 9.44001 11.9791 9.37488 12.1564 9.37488C12.3439 9.37488 12.5001 9.44001 12.6251 9.57026C12.7501 9.70051 12.8126 9.85413 12.8126 10.0311V11.5C12.8126 11.8645 12.6824 12.1744 12.4219 12.4296C12.1614 12.6849 11.8541 12.8125 11.5001 12.8125H3.49988Z"
                          fill="#C5C5C5"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_2810_474890">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <p>Cambiar Foto </p>
                </li>
                <li>
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2810_474961)">
                      <mask
                        id="mask0_2810_474961"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="15"
                        height="15"
                      >
                        <rect width="15" height="15" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2810_474961)">
                        <path
                          d="M4.14075 13.453C3.7865 13.453 3.47913 13.3228 3.21863 13.0623C2.95838 12.802 2.82825 12.4948 2.82825 12.1405V3.39063H2.703C2.526 3.39063 2.375 3.32813 2.25 3.20313C2.125 3.07813 2.0625 2.92188 2.0625 2.73438C2.0625 2.55713 2.125 2.40351 2.25 2.27351C2.375 2.14326 2.526 2.07813 2.703 2.07813H5.48438C5.48438 1.91138 5.54687 1.77076 5.67188 1.65626C5.79688 1.54151 5.94788 1.48413 6.12488 1.48413H8.8905C9.06775 1.48413 9.2215 1.54151 9.35175 1.65626C9.48175 1.77076 9.54675 1.91138 9.54675 2.07813H12.3124C12.4896 2.07813 12.6433 2.14326 12.7733 2.27351C12.9035 2.40351 12.9686 2.55713 12.9686 2.73438C12.9686 2.92188 12.9035 3.07813 12.7733 3.20313C12.6433 3.32813 12.4896 3.39063 12.3124 3.39063H12.2032V12.1405C12.2032 12.4948 12.073 12.802 11.8125 13.0623C11.552 13.3228 11.2447 13.453 10.8907 13.453H4.14075ZM5.48438 10.1718C5.48438 10.3488 5.5495 10.4998 5.67975 10.6248C5.81 10.7498 5.96362 10.8123 6.14062 10.8123C6.31763 10.8123 6.47125 10.7498 6.6015 10.6248C6.73175 10.4998 6.79688 10.3488 6.79688 10.1718V5.35938C6.79688 5.18213 6.73175 5.02851 6.6015 4.89851C6.47125 4.76826 6.31763 4.70313 6.14062 4.70313C5.95312 4.70313 5.79688 4.76826 5.67188 4.89851C5.54687 5.02851 5.48438 5.18213 5.48438 5.35938V10.1718ZM8.23425 10.1718C8.23425 10.3488 8.29938 10.4998 8.42963 10.6248C8.55988 10.7498 8.7135 10.8123 8.8905 10.8123C9.06775 10.8123 9.2215 10.7498 9.35175 10.6248C9.48175 10.4998 9.54675 10.3488 9.54675 10.1718V5.35938C9.54675 5.18213 9.48175 5.02851 9.35175 4.89851C9.2215 4.76826 9.06775 4.70313 8.8905 4.70313C8.703 4.70313 8.54675 4.76826 8.42175 4.89851C8.29675 5.02851 8.23425 5.18213 8.23425 5.35938V10.1718Z"
                          fill="#C5C5C5"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_2810_474961">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <p>Eliminar Foto</p>
                </li>
              </ul>
            )}
          </div>

          <div className="box_area">
            <div className="box_inner">
              <h1>Correo Electrónico</h1>
              <div
                className="inner_row"
                onClick={(e) => {
                  setemailUpdate(true);
                }}
              >
                <p>pedro.perez@pf.com</p>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_2809_474671"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="11"
                    height="11"
                  >
                    <rect width="11" height="11" fill="#87D7D6" />
                  </mask>
                  <g mask="url(#mask0_2809_474671)">
                    <path
                      d="M9.2356 4.33115L6.66894 1.76449L7.28769 1.14574C7.52449 0.90893 7.81294 0.790527 8.15302 0.790527C8.4928 0.790527 8.78109 0.90893 9.01789 1.14574L9.86581 2.00511C10.1026 2.24192 10.221 2.52456 10.221 2.85303C10.221 3.1815 10.1026 3.46414 9.86581 3.70094L9.2356 4.33115ZM1.50123 10.1062C1.33317 10.1062 1.19185 10.0489 1.07727 9.93428C0.962687 9.81969 0.905396 9.67837 0.905396 9.51032V7.78011C0.905396 7.69608 0.918687 7.61969 0.945271 7.55094C0.972159 7.48219 1.01616 7.41726 1.07727 7.35615L6.01581 2.41761L8.58248 4.98428L3.6554 9.93428C3.59428 9.99539 3.52935 10.0392 3.4606 10.0658C3.39185 10.0927 3.31546 10.1062 3.23144 10.1062H1.50123Z"
                      fill="#87D7D6"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="box_inner">
              <h1>Contraseña</h1>
              <div className="inner_row">
                {show ? (
                  <p
                    onClick={(e) => {
                      setpasswordUpdate(true);
                    }}
                  >
                    Reactjs123
                  </p>
                ) : (
                  <p
                    onClick={(e) => {
                      setpasswordUpdate(true);
                    }}
                  >
                    **********
                  </p>
                )}

                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  onClick={(e) => {
                    setpasswordUpdate(true);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_2809_474671"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="11"
                    height="11"
                  >
                    <rect width="11" height="11" fill="#87D7D6" />
                  </mask>
                  <g mask="url(#mask0_2809_474671)">
                    <path
                      d="M9.2356 4.33115L6.66894 1.76449L7.28769 1.14574C7.52449 0.90893 7.81294 0.790527 8.15302 0.790527C8.4928 0.790527 8.78109 0.90893 9.01789 1.14574L9.86581 2.00511C10.1026 2.24192 10.221 2.52456 10.221 2.85303C10.221 3.1815 10.1026 3.46414 9.86581 3.70094L9.2356 4.33115ZM1.50123 10.1062C1.33317 10.1062 1.19185 10.0489 1.07727 9.93428C0.962687 9.81969 0.905396 9.67837 0.905396 9.51032V7.78011C0.905396 7.69608 0.918687 7.61969 0.945271 7.55094C0.972159 7.48219 1.01616 7.41726 1.07727 7.35615L6.01581 2.41761L8.58248 4.98428L3.6554 9.93428C3.59428 9.99539 3.52935 10.0392 3.4606 10.0658C3.39185 10.0927 3.31546 10.1062 3.23144 10.1062H1.50123Z"
                      fill="#87D7D6"
                    />
                  </g>
                </svg>
                {show && (
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    onClick={(e) => {
                      setShow(false);
                    }}
                    style={{ marginLeft: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 9.15011C8.91793 9.15011 9.69827 8.82894 10.341 8.18661C10.9834 7.54384 11.3045 6.7635 11.3045 5.84557C11.3045 4.92765 10.9834 4.1473 10.341 3.50454C9.69827 2.8622 8.91793 2.54104 8 2.54104C7.08207 2.54104 6.30173 2.8622 5.65896 3.50454C5.01663 4.1473 4.69546 4.92765 4.69546 5.84557C4.69546 6.7635 5.01663 7.54384 5.65896 8.18661C6.30173 8.82894 7.08207 9.15011 8 9.15011ZM8 7.62743C7.50324 7.62743 7.08207 7.45464 6.7365 7.10907C6.39093 6.7635 6.21814 6.34233 6.21814 5.84557C6.21814 5.34881 6.39093 4.92765 6.7365 4.58207C7.08207 4.2365 7.50324 4.06371 8 4.06371C8.49676 4.06371 8.91793 4.2365 9.2635 4.58207C9.60907 4.92765 9.78186 5.34881 9.78186 5.84557C9.78186 6.34233 9.60907 6.7635 9.2635 7.10907C8.91793 7.45464 8.49676 7.62743 8 7.62743ZM8 11.1911C6.43412 11.1911 4.99784 10.7916 3.69114 9.99244C2.38445 9.1933 1.38013 8.10799 0.678186 6.7365C0.613391 6.61771 0.567603 6.48013 0.540821 6.32376C0.513607 6.16695 0.5 6.00756 0.5 5.84557C0.5 5.68359 0.513607 5.52419 0.540821 5.36739C0.567603 5.21102 0.613391 5.07343 0.678186 4.95464C1.38013 3.58315 2.38445 2.49784 3.69114 1.6987C4.99784 0.899568 6.43412 0.5 8 0.5C9.56587 0.5 11.0022 0.899568 12.3089 1.6987C13.6156 2.49784 14.6199 3.58315 15.3218 4.95464C15.3866 5.07343 15.4326 5.21102 15.4598 5.36739C15.4866 5.52419 15.5 5.68359 15.5 5.84557C15.5 6.00756 15.4866 6.16695 15.4598 6.32376C15.4326 6.48013 15.3866 6.61771 15.3218 6.7365C14.6199 8.10799 13.6156 9.1933 12.3089 9.99244C11.0022 10.7916 9.56587 11.1911 8 11.1911ZM8 9.47408C9.2419 9.47408 10.3866 9.14752 11.4341 8.49438C12.4816 7.84082 13.2808 6.95788 13.8315 5.84557C13.2808 4.73326 12.4816 3.85032 11.4341 3.19676C10.3866 2.54363 9.2419 2.21706 8 2.21706C6.7581 2.21706 5.61339 2.54363 4.56587 3.19676C3.51836 3.85032 2.71922 4.73326 2.16847 5.84557C2.71922 6.95788 3.51836 7.84082 4.56587 8.49438C5.61339 9.14752 6.7581 9.47408 8 9.47408Z"
                      fill="#87D7D6"
                    />
                  </svg>
                )}
                {!show && (
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    onClick={(e) => {
                      setShow(true);
                    }}
                    style={{ marginLeft: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 9.15011C8.91793 9.15011 9.69827 8.82894 10.341 8.18661C10.9834 7.54384 11.3045 6.7635 11.3045 5.84557C11.3045 4.92765 10.9834 4.1473 10.341 3.50454C9.69827 2.8622 8.91793 2.54104 8 2.54104C7.08207 2.54104 6.30173 2.8622 5.65896 3.50454C5.01663 4.1473 4.69546 4.92765 4.69546 5.84557C4.69546 6.7635 5.01663 7.54384 5.65896 8.18661C6.30173 8.82894 7.08207 9.15011 8 9.15011ZM8 7.62743C7.50324 7.62743 7.08207 7.45464 6.7365 7.10907C6.39093 6.7635 6.21814 6.34233 6.21814 5.84557C6.21814 5.34881 6.39093 4.92765 6.7365 4.58207C7.08207 4.2365 7.50324 4.06371 8 4.06371C8.49676 4.06371 8.91793 4.2365 9.2635 4.58207C9.60907 4.92765 9.78186 5.34881 9.78186 5.84557C9.78186 6.34233 9.60907 6.7635 9.2635 7.10907C8.91793 7.45464 8.49676 7.62743 8 7.62743ZM8 11.1911C6.43412 11.1911 4.99784 10.7916 3.69114 9.99244C2.38445 9.1933 1.38013 8.10799 0.678186 6.7365C0.613391 6.61771 0.567603 6.48013 0.540821 6.32376C0.513607 6.16695 0.5 6.00756 0.5 5.84557C0.5 5.68359 0.513607 5.52419 0.540821 5.36739C0.567603 5.21102 0.613391 5.07343 0.678186 4.95464C1.38013 3.58315 2.38445 2.49784 3.69114 1.6987C4.99784 0.899568 6.43412 0.5 8 0.5C9.56587 0.5 11.0022 0.899568 12.3089 1.6987C13.6156 2.49784 14.6199 3.58315 15.3218 4.95464C15.3866 5.07343 15.4326 5.21102 15.4598 5.36739C15.4866 5.52419 15.5 5.68359 15.5 5.84557C15.5 6.00756 15.4866 6.16695 15.4598 6.32376C15.4326 6.48013 15.3866 6.61771 15.3218 6.7365C14.6199 8.10799 13.6156 9.1933 12.3089 9.99244C11.0022 10.7916 9.56587 11.1911 8 11.1911ZM8 9.47408C9.2419 9.47408 10.3866 9.14752 11.4341 8.49438C12.4816 7.84082 13.2808 6.95788 13.8315 5.84557C13.2808 4.73326 12.4816 3.85032 11.4341 3.19676C10.3866 2.54363 9.2419 2.21706 8 2.21706C6.7581 2.21706 5.61339 2.54363 4.56587 3.19676C3.51836 3.85032 2.71922 4.73326 2.16847 5.84557C2.71922 6.95788 3.51836 7.84082 4.56587 8.49438C5.61339 9.14752 6.7581 9.47408 8 9.47408Z"
                      fill="#87D7D6"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
          <div className="box_area box_area_second">
            <div className="box_inner">
              <h1>Configuración de Email</h1>
            </div>
            <div className="box_inner">
              <p>Notificarme de tickets de soporte en correo</p>
              <Switch defaultChecked />
            </div>
          </div>

          <div className="buttons_wrapper">
            <button disabled={disabled}>Guardar Cambios</button>
          </div>
        </div>
      </div>
    </div>
  );
};
