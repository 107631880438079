import React from 'react';

const SvgImage = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2921_998604)">
        <mask id="mask0_2921_998604" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
          <rect width="15" height="15" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2921_998604)">
          <path d="M1.75024 13.2499V11.8594L3.06274 10.5469V13.2499H1.75024ZM4.29687 13.2499V9.35925L5.60937 8.04675V13.2499H4.29687ZM6.84387 13.2499V8.04675L8.15637 9.375V13.2499H6.84387ZM9.40624 13.2499V9.375L10.7187 8.0625V13.2499H9.40624ZM11.9532 13.2499V6.8595L13.2657 5.547V13.2499H11.9532ZM1.75024 9.90637V8.0625L6.25024 3.57825L8.74999 6.078L13.2657 1.54688V3.39075L8.74999 7.92188L6.25024 5.42175L1.75024 9.90637Z" fill="#C5C5C5" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2921_998604">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgImage;
