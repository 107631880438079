import React, { createContext, useEffect, useState } from 'react'
import { config } from '../config'

export const DataResumeLineContext = createContext()

export const DataResumeLineProvider = ({ children }) => {
	const [data, setData] = useState([])

	// useEffect(() => {
	// 	fetchDataResumeLine()
	// }, [])

	const fetchDataResumeLine = async (category_id, promotion_id) => {
		const session_token = localStorage.getItem('session')

		if (session_token) {
			console.log('context', category_id, promotion_id)
			return fetch(
				config.apiUrl +
					`/traditional_catalog/line_summary/${promotion_id}/${category_id}`,
				{
					//mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + session_token,
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Credentials': true,
					},
				}
			)
				.then((response) => {
					return response.json()
				})
				.then((response) => {
					setData(response)
					return response
				})
		}
		return {}
	}

	return (
		<DataResumeLineContext.Provider value={{ data, fetchDataResumeLine }}>
			{children}
		</DataResumeLineContext.Provider>
	)
}
