import React, { useState } from "react";
import { Navbar } from "./Navbar";
import searchb from "../assets/img/searchb.svg";
import dropdownblue from "../assets/img/dropdownblue.svg";
import { CatálogoDropdown } from "./CatálogoDropdown";
import "../assets/css/BusquedadePromociones.css";
import { TableOne, TableTwo } from "../utils/BusquedaTables";
import { Link } from "react-router-dom";
export const BusquedadePromociones = () => {
  const [Tab, setTab] = useState("first");
  const [InputContainer, setInputContainer] = useState(false);
  const [LineaVal, setLineaVal] = useState(false);

  return (
    <div className="Home BusquedadePromocionesWrapper">
      <Navbar no_need_search />
      <Link className="button_back_wrapper">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_2739_29951"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2739_29951)">
            <path
              d="M7.97505 19.45C7.60838 19.45 7.29605 19.3207 7.03805 19.062C6.77938 18.804 6.65005 18.4917 6.65005 18.125C6.65005 17.7583 6.77938 17.446 7.03805 17.188C7.29605 16.9293 7.60838 16.8 7.97505 16.8H14.2C15.1834 16.8 16.0377 16.4957 16.763 15.887C17.4877 15.279 17.85 14.5 17.85 13.55C17.85 12.6 17.4877 11.8207 16.763 11.212C16.0377 10.604 15.1834 10.3 14.2 10.3H8.50005L9.92505 11.725C10.175 11.975 10.296 12.2833 10.288 12.65C10.2794 13.0167 10.15 13.325 9.90005 13.575C9.65005 13.825 9.35005 13.946 9.00005 13.938C8.65005 13.9293 8.35005 13.8 8.10005 13.55L4.42505 9.9C4.29172 9.76667 4.19605 9.62067 4.13805 9.462C4.07938 9.304 4.05005 9.14167 4.05005 8.975C4.05005 8.80833 4.07938 8.646 4.13805 8.488C4.19605 8.32933 4.29172 8.18333 4.42505 8.05L8.10005 4.375C8.35005 4.125 8.65405 4 9.01205 4C9.37072 4 9.67505 4.125 9.92505 4.375C10.1917 4.625 10.3207 4.93333 10.312 5.3C10.304 5.66667 10.175 5.975 9.92505 6.225L8.50005 7.65H14.2C15.9167 7.65 17.396 8.21667 18.638 9.35C19.8794 10.4833 20.5 11.8833 20.5 13.55C20.5 15.2167 19.8794 16.6167 18.638 17.75C17.396 18.8833 15.9167 19.45 14.2 19.45H7.97505Z"
              fill="#87D7D6"
            />
          </g>
        </svg>
        Volver al Inicio
      </Link>
      <div className="maincontentarea_BusquedadePromociones">
        <div className="top_busqueda_area">
          <img src={searchb} alt="" />
          <h1>Busqueda de Promociones</h1>
        </div>
        <div className="tabs_area">
          <button
            className={`${Tab == "first" && "active"}`}
            onClick={(e) => {
              setTab("first");
            }}
          >
            Canal Moderno
          </button>
          <button
            className={`${Tab == "second" && "active"}`}
            onClick={(e) => {
              setTab("second");
            }}
          >
            Canal Tradicional
          </button>
        </div>
        <form className={`input_wrapper_area ${InputContainer && "hide"}`}>
          <div className="input_wrapper">
            <label htmlFor="">Nombre</label>
            <input type="text" placeholder="Cecinas" />
          </div>
          <div className="input_wrapper">
            <label htmlFor="">Modalidad</label>
            <CatálogoDropdown
              head="No"
              list={["Todas", "Todas", "Todas", "Todas"]}
            />
          </div>
          <div className="input_wrapper">
            <label htmlFor="">Cadena</label>
            <CatálogoDropdown
              head="No"
              list={["Todas", "Todas", "Todas", "Todas"]}
            />
          </div>
          <div className="input_wrapper">
            <label htmlFor="">Fase</label>
            <CatálogoDropdown
              head="No"
              list={["Todas", "Todas", "Todas", "Todas"]}
            />
          </div>

          <div className="input_wrapper">
            <label htmlFor="">Fecha</label>
            <CatálogoDropdown
              head="No"
              list={["Todas", "Todas", "Todas", "Todas"]}
            />
          </div>
          <div className="input_wrapper">
            <label htmlFor="">Línea</label>
            <CatálogoDropdown
              head="No"
              setVal={setLineaVal}
              list={["Todas", "Todas", "Todas", "Todas"]}
            />
          </div>
          <div
            className={`input_wrapper not_clickable ${LineaVal && "clickable"}`}
          >
            <label htmlFor="">Familia</label>
            <CatálogoDropdown
              head="No"
              list={["Todas", "Todas", "Todas", "Todas"]}
            />
          </div>

          <div
            className={`input_wrapper  not_clickable ${
              LineaVal && "clickable"
            }`}
          >
            <label htmlFor="">Marca</label>
            <CatálogoDropdown
              head="No"
              list={["Todas", "Todas", "Todas", "Todas"]}
            />
          </div>

          <div
            className={`input_wrapper not_clickable ${LineaVal && "clickable"}`}
          >
            <label htmlFor="">Producto</label>
            <CatálogoDropdown
              head="No"
              list={["Todas", "Todas", "Todas", "Todas"]}
            />
          </div>

          <div className="buttons_wrapper">
            <button>Limpiar Filtros</button>
            <button>Buscar</button>
            <img
              src={dropdownblue}
              alt=""
              onClick={(e) => {
                setInputContainer(!InputContainer);
              }}
            />
          </div>
        </form>
        {Tab == "first" ? (
          <div className="table_container">
            <h1>9 Resultados</h1>
            <table>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Modalidad</th>
                  <th>Cadena</th>
                  <th>Fase</th>
                  <th>Estado</th>
                  <th>Fecha</th>
                  <th>Línea</th>
                  <th>Familia</th>
                  <th>Marca</th>
                  <th>Producto</th>
                  <th>Ult. Modif.</th>
                </tr>
              </thead>
              <tbody>
                {TableOne.map((EachRow, key) => (
                  <tr key={key}>
                    <td>{EachRow.first}</td>
                    <td>{EachRow.second}</td>
                    <td>{EachRow.third}</td>
                    <td>
                      <button>{EachRow.fourth}</button>
                    </td>
                    <td>
                      <button
                        className={`${
                          EachRow.fifth == "Descartada" && "dead"
                        } ${EachRow.fifth == "Rechazada" && "red"} ${
                          EachRow.fifth == "Cance. Cte." && "red"
                        }
                            
                            ${EachRow.fifth == "En Revisión" && "warning"}
                            ${EachRow.fifth == "Condicional" && "warning"}
                          
                            `}
                      >
                        {EachRow.fifth}
                      </button>
                    </td>
                    <td>{EachRow.sixth}</td>
                    <td>{EachRow.seven}</td>
                    <td>{EachRow.eight}</td>
                    <td>{EachRow.nine}</td>
                    <td>{EachRow.ten}</td>
                    <td>{EachRow.end}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="table_container">
            <h1>10 Resultados</h1>
            <table>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Modalidad</th>
                  <th>Cadena</th>
                  <th>Fase</th>
                  <th>Estado</th>
                  <th>Fecha</th>
                  <th>Línea</th>
                  <th>Familia</th>
                  <th>Marca</th>
                  <th>Producto</th>
                  <th>Ult. Modif.</th>
                </tr>
              </thead>
              <tbody>
                {TableTwo.map((EachRow, key) => (
                  <tr key={key}>
                    <td>{EachRow.first}</td>
                    <td>{EachRow.second}</td>
                    <td width={100}>{EachRow.third}</td>
                    <td>
                      <button>{EachRow.fourth}</button>
                    </td>
                    <td>
                      <button
                        className={`${
                          EachRow.fifth == "Descartada" && "dead"
                        } ${EachRow.fifth == "Rechazada" && "red"} ${
                          EachRow.fifth == "Cance. Cte." && "red"
                        }
                            
                            ${EachRow.fifth == "En Revisión" && "warning"}
                            ${EachRow.fifth == "Condicional" && "warning"}
                          
                            `}
                      >
                        {EachRow.fifth}
                      </button>
                    </td>
                    <td>{EachRow.sixth}</td>
                    <td>{EachRow.seven}</td>
                    <td>{EachRow.eight}</td>
                    <td>{EachRow.nine}</td>
                    <td>{EachRow.ten}</td>
                    <td>{EachRow.end}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
