import React, { useState, useEffect, useContext } from 'react'
import { Navbar } from './Navbar'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { CombiarPopupTradicional } from './CombiarPopupTradicional'
import { RenombrarPopup } from './RenombrarPopup'
import { TopNavigatorFour } from './TopNavigatorFour'
import { ResumenLineaTable } from './ResumenLineaTable'
import { ResumenLineaFooter } from './ResumenLineaFooter'
import '../assets/css/CataLogoResumenTradicional.css'
import { config } from '../config'
import { Loader } from './Loader'
import { useModal } from '../hooks/useModal'
import { usePrintPDF } from '../hooks/usePrintPdf'
import Modal from './modal'
import {
	catalogoTradicionalJson,
	catalogoTradicionalFiltradoJson,
} from './hopeJson'
import { MoveCataLog } from './MoveCataLog'
import { exportToExcel } from 'react-json-to-excel'

function getPromotions() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

function catalogoLineResume(promotion_id) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrl + `/traditional_catalog/line_summary/all/${promotion_id}`,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				console.log(response)
				return response
			})
	}
	//there are no session token stored
	return {}
}

function catalogoLineResumeChild(promotion_id, category_id) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrl +
				`/traditional_catalog/line_summary/${category_id}/${promotion_id}`,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				console.log(response)
				return response
			})
	}
	//there are no session token stored
	return {}
}

function getDataCreate() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/traditional_promotion/data_create `, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

export const ResumenLinea = () => {
	const [active, setActive] = useState(false)
	const [combiar, setcombiar] = useState(false)
	const [Renombrar, setRenombrar] = useState(false)
	//CatalogoTradicional/:id_promocion/:nombre_promocion/:oficina/:linea/:fecha
	const { id_promocion, nombre_promocion, oficina, linea, fecha } = useParams()
	const [loader, setloader] = useState(true)
	//Se recibe todo desde el servicio padre
	const [data, setData] = useState(null) //useState(catalogoTradicionalJson);
	const [dataFamilies, setDataFamilies] = useState(null) //useState(catalogoTradicionalFiltradoJson);
	//const [idCategoria, setIdCategoria] = useState(null);
	const [CatalogMove, setCatalogMove] = useState(false)
	const [promotion, setPromotion] = useState(null)
	const { closeModal, isOpen, openModal, modalRef } = useModal()
	const { componentRef, handlePrint } = usePrintPDF(`${new Date()}`)
	const [dataEdit, setDataEdit] = useState({
		cadena_details: {
			name_promotion: nombre_promocion,
			promotion_id: id_promocion,
		},
	})



	useEffect(() => {
		//setData({catalog_categories: catalog_categories, promotion_id: promotion_id})
		/*//No se utiliza ya que el ID es el nombre str de la categoria y no el id de la linea
    getDataCreate().then(result =>{
      let id_categoria_ = 0;
      result.categories.map((c) => {
        if(c.linea == linea){
          id_categoria_ = c.id_linea;
        }
      });
      setIdCategoria(id_categoria_);
     });
     */

		catalogoLineResume(id_promocion).then((result) => {
			setData(result)
		})

		catalogoLineResumeChild(id_promocion, linea).then((result) => {
			setDataFamilies(result)
			setloader(false)
		})

		getPromotions().then((result) => {
			const promotion_filter = result.filter(function (element) {
				return element.id == id_promocion
			})
			console.log('*****************')
			console.log(promotion_filter[0])
			//alert(promotion_filter[0].id)
			setPromotion(promotion_filter[0])
		})
	}, [])
	//Con el servicio Padre, se debe tomar la linea y consultar al servicio web para traer todas las familias.

	console.log('Catalogo tradicional--------------')
	console.log(data)
	return (
		<div className='CataLogoResumen ResumenLinea' ref={componentRef}>
			{loader && <Loader message={'Cargando...'}></Loader>}
			{isOpen && (
				<Modal
					title='Exportar Vista'
					isOpen={isOpen}
					closeModal={closeModal}
					modalRef={modalRef}
					handlePrint={handlePrint}
					handleCsv={() => exportToExcel(data.data_rows, 'downloadfilename')}
				/>
			)}
			<TopNavigatorFour
				nombre_promocion={nombre_promocion}
				fecha={fecha}
				linea={linea}
			/>
			<Navbar no_need_search />
			{combiar && (
				<CombiarPopupTradicional
					setcombiar={setcombiar}
					promotion={promotion}
				/>
			)}
			{Renombrar && (
				<RenombrarPopup
					setRenombrar={setRenombrar}
					promotion={promotion}
					data={dataEdit}
					setData={setDataEdit}
				/>
			)}
			{CatalogMove && promotion && (
				<MoveCataLog setActive={setCatalogMove} promotion={promotion} />
			)}
			<div className='top_area top_area_rev'>
				<div className='left_side' style={{ flex: 'none' }}>
					<Link
						to={`/Seguimiento/catalogo_tradicional/${id_promocion}`}
						state={{ traditional: true }}
					>
						Seguimiento Catálogo
					</Link>
					<Link className='active'>Resumen Línea</Link>
					{dataFamilies &&
						dataFamilies.data_rows.map((EachRow) => {
							return (
								<Link
									to={`/CatalogoTradicionalFamiliaProducto/${id_promocion}/${nombre_promocion}/${oficina}/${linea}/${fecha}/${EachRow.category}`}
								>
									{EachRow.category}
								</Link>
							)
						})}
				</div>
			</div>
			<div className='link_area'>
				<p>{nombre_promocion}</p>
				<p>/</p>
				<p>Resumen Línea</p>
			</div>

			<div className='option_table' style={{ marginTop: 0 }}>
				<div>
					<Link to='/CataLogoResumen' className='active'>
						Todas
					</Link>
					{data &&
						data.data_rows.map(
							(EachRow) =>
								EachRow?.category === linea && (
									<Link
										to={`/CatalogoTradicionalFamilia/${id_promocion}/${nombre_promocion}/${oficina}/${fecha}/${EachRow.category}`}
									>
										{EachRow.category}
									</Link>
								)
						)}
					{/* <Link
						to={`/CatalogoTradicionalFamiliaProducto/${id_promocion}/${nombre_promocion}/${oficina}/${linea}/${fecha}/${dataFamilies[0]?.category}`}
					>
						{dataFamilies[0]?.category}
					</Link> */}
				</div>
			</div>

			{/* <CataLogoResumenTable /> */}
			{data && (
				<ResumenLineaTable
					data={data}
					vista={'segunda'}
					id_promocion={id_promocion}
					nombre_promocion={nombre_promocion}
					oficina={oficina}
					fecha={fecha}
					linea_={linea}
					nivel={'Línea'}
				/>
			)}

			{data && (
				<ResumenLineaFooter
					setcombiar={setcombiar}
					setRenombrar={setRenombrar}
					data={data}
					oficina={oficina}
					promotion_id={id_promocion}
					guardar={false}
					setCatalogMove={setCatalogMove}
					setData={setData}
					setloader={setloader}
					openModalExport={openModal}
				/>
			)}
		</div>
	)
}
