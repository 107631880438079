import React, { useEffect, useState } from 'react'
import { func, bool, string, node } from 'prop-types'
import styles from './modal.module.css'
import closeIcon from '../../assets/img/Cerrar.svg'
import CustomDropdown from '../customDropdown'
// import XLSX from 'xlsx';

const Modal = ({
	title = '',
	closeModal,
	isOpen,
	modalRef,
	children,
	handlePrint,
	handleCsv,
}) => {
	const [selected, setSelected] = useState('Seleccione formato')
	useEffect(() => {
		document.addEventListener('click', closeModalRef, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const closeModalRef = (e) => {
		if (!modalRef.current.contains(e.target)) {
			closeModal()
		}
	}
	/*
	const handleExportToXLS = () => {
		const table = document.getElementById('miTabla');
		const ws = XLSX.utils.table_to_sheet(table);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, 'tabla.xlsx');
	  };
	*/
	  const handleExportToCSV = () => {
		const table = document.getElementById('tablaDescargaFinal');
		const rows = Array.from(table.querySelectorAll('tr'));
		const csvData = rows
		  .map((row) => {
			const cells = Array.from(row.querySelectorAll('td,th'));
			return cells.map((cell) => cell.textContent).join(';');
		  })
		  .join('\n');
	
		const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'tabla.csv');
		document.body.appendChild(link);
		link.click();
	  };

	return (
		<>
			{isOpen && (
				<div className={styles.container_modal}>
					<div className={styles.content} ref={modalRef}>
						<div className={styles.header}>
							<div onClick={closeModal} className={styles.btn_close}>
								<img src={closeIcon} alt='' />
							</div>
							<h1 className={styles.header__title}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<mask
										id='mask0_2565_95419'
										maskUnits='userSpaceOnUse'
										x='0'
										y='0'
										width='24'
										height='24'
									>
										<rect width='24' height='24' fill='#D9D9D9' />
									</mask>
									<g mask='url(#mask0_2565_95419)'>
										<path
											d='M4.95 22.85C4.16667 22.85 3.5 22.571 2.95 22.013C2.4 21.4544 2.125 20.7917 2.125 20.025V5.90002C2.125 5.11669 2.4 4.45002 2.95 3.90002C3.5 3.35002 4.16667 3.07502 4.95 3.07502H5.2V2.50002C5.2 2.13336 5.32933 1.81669 5.588 1.55002C5.846 1.28336 6.15833 1.15002 6.525 1.15002C6.90833 1.15002 7.22933 1.28336 7.488 1.55002C7.746 1.81669 7.875 2.13336 7.875 2.50002V3.07502H16.125V2.50002C16.125 2.13336 16.2543 1.81669 16.513 1.55002C16.771 1.28336 17.0917 1.15002 17.475 1.15002C17.8417 1.15002 18.1543 1.28336 18.413 1.55002C18.671 1.81669 18.8 2.13336 18.8 2.50002V3.07502H19.05C19.8333 3.07502 20.5 3.35002 21.05 3.90002C21.6 4.45002 21.875 5.11669 21.875 5.90002V12.675H19.05V9.57502H4.95V20.025H12V22.85H4.95ZM4.95 7.02502H19.05V5.90002H4.95V7.02502ZM22.825 18.125L20.125 15.425L20.85 14.7C21.1167 14.4334 21.4457 14.3 21.837 14.3C22.229 14.3 22.5583 14.4334 22.825 14.7L23.55 15.425C23.8167 15.7084 23.95 16.046 23.95 16.438C23.95 16.8294 23.8167 17.15 23.55 17.4L22.825 18.125ZM14 23.575V22.15C14 21.95 14.0333 21.771 14.1 21.613C14.1667 21.4544 14.2667 21.3 14.4 21.15L19.425 16.125L22.125 18.825L17.1 23.85C16.9667 24 16.8167 24.1044 16.65 24.163C16.4833 24.221 16.3083 24.25 16.125 24.25H14.7C14.5 24.25 14.3333 24.1874 14.2 24.062C14.0667 23.9374 14 23.775 14 23.575Z'
											fill='url(#paint0_linear_2565_95419)'
										/>
									</g>
									<defs>
										<linearGradient
											id='paint0_linear_2565_95419'
											x1='3.0098'
											y1='1.15003'
											x2='28.844'
											y2='2.68393'
											gradientUnits='userSpaceOnUse'
										>
											<stop stop-color='#FFAC23' />
											<stop offset='1' stop-color='#FF5334' />
										</linearGradient>
									</defs>
								</svg>

								<span>Exportar Vista</span>
							</h1>
						</div>
						<div>
							<p className={styles.subtitle}>
								Selecciona el Formato en el que deseas exportar la vista
							</p>
						</div>
						<div>
							<span className={styles.text_info}>Formato</span>
							<CustomDropdown select={selected} setSelected={setSelected} />
						</div>
						{/* <div className={styles.body}>{children}</div> */}

						<div className={styles.btnContainer}>
							<button className={styles.btnCancelar} onClick={closeModal}>
								Cancelar
							</button>
							<button
								className={styles.btnPrimary}
								onClick={() => {
									if (selected === 'PDF') {
										setTimeout(() => {
											handlePrint()
										}, 500)
										closeModal()
									}
									if (selected === 'Csv') {
										closeModal()
										// handleCsv()
										handleExportToCSV()
									}
								}}
							>
								Aceptar
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default Modal

Modal.propTypes = {
	closeModal: func,
	isOpen: bool,
	title: string,
	children: node.isRequired,
}
