import React, {useState, useEffect} from 'react'
import '../../assets/css/newPFOffer/products-table.css'
import Cerrar from '../../assets/img/Cerrar.svg'
import {Link} from 'react-router-dom'
import {CatálogoDropdown} from '../CatálogoDropdown'
import filteruptable from '../../assets/img/filter-up-table.svg'
import {TableProductsPure} from './TableProductsPure'
import {config} from '../../config'
import { Loader } from '../Loader'
import { SwitchButtonOffer } from './SwitchButtonOffer'

// Acá pedir lista de datos de tabla productos
function getListProducts(idOferta) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrlOferta + `/product/listall/`+idOferta, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

export const ProductsTable = ({setProductsTableState, idOferta, setActualizar, setOffers}) => {
	const [filterShow, setfilterShow] = useState(true)
	const [loading, setLoading] = useState(true)
	const [products, setProducts] = useState(null)
	const [estrategia, setEstrategia] = useState(null)
	const [linea, setLinea] = useState(null)
	const [familia, setFamilia] = useState(null)
	const [catalogo, setCatalogo] = useState(null)
	const [oferta, setOferta] = useState(null)
	const [palabra, setPalabra] = useState(null)
	const [key, setKey] = useState(0); // Clave para resetear el componente

	// DropDowns estrategias, lineas y familias.
	const [dropdownEstrategia, setDropdownEstrategia] = useState(null)
	const [dropdownLinea, setDropdownLinea] = useState(null)
	const [dropdownFamilia, setDropdownFamilia] = useState(null)
	const [dropdownFamiliaBackUp, setDropdownFamiliaBackUp] = useState(null)
	const [active, setActive] = useState(false)	
	const [productsBackUp, setProductsBackUp] = useState(null)

	useEffect(() => {
		getListProducts(idOferta).then((response) => {
			console.log("response:::: list products::: ")
			console.log(response)

			
			// Utilizamos Sets para evitar elementos duplicados
			const estrategiasSet = new Set();
			const lineasSet = new Set();
			const familiasSet = new Set();
			
			response.forEach((item) => {
				estrategiasSet.add(item.strategy_name);
				lineasSet.add(item.category_name);
				familiasSet.add(item.subcategory_name);
			});
			
			// Convertimos los Sets a Arrays
			const arrEstrategias = Array.from(estrategiasSet);
			const arrLineas = Array.from(lineasSet);
			const arrFamilias = Array.from(familiasSet);
			arrEstrategias.unshift("Todos");
			arrLineas.unshift("Todos");
			arrFamilias.unshift("Todos");
			setDropdownEstrategia(arrEstrategias);
			setDropdownLinea(arrLineas);
			setDropdownFamilia(arrFamilias);
			setDropdownFamiliaBackUp(arrFamilias);
			setProducts(response)
			setProductsBackUp(response)
			setLoading(false)
		})
	}, [])

	useEffect(() => {
		if(dropdownLinea && linea !== "Todos" && products && dropdownFamiliaBackUp && linea){
			const familiasSet = new Set();
			products.forEach((item) => {
				console.log(item);
				if(linea === item.category_name){
					familiasSet.add(item.subcategory_name);
				}
			});
			const arrFamilias = Array.from(familiasSet);
			arrFamilias.unshift("Todos");
			setDropdownFamilia(arrFamilias);
		}else{
			if(products && dropdownFamiliaBackUp){
				setDropdownFamilia(dropdownFamiliaBackUp);
			}
		}
	}, [linea, dropdownLinea, dropdownFamiliaBackUp])

	useEffect(() => {
		if(active){
			console.log("Mostrando solo los productos activos")
			const parentTable = document.getElementById("listadoProductos");// e.target.closest('table')
			const allInputs = parentTable.querySelectorAll(
							'tbody .container input'
						)
			const productsSelected = [];
			allInputs.forEach((element) => {
				if(element.checked){
					productsSelected.push(element.value);
				}
			})
			const newProducts = products.filter((item) => {
				return productsSelected.includes(item.offer_product_id.toString());
			});
			setProducts(newProducts);
		}else{
			console.log("Mostrando todos los productos")
			setProducts(productsBackUp);
		}
	}, [active]);
	
	const addProduct = async (dataProducstSelected) => {
		//Guardar INFO
		setLoading(true);
		const session_token = localStorage.getItem('session');
		const response = await fetch(config.apiUrlOferta + `/offer/products/update/`+idOferta, {
		  //mode: 'no-cors',
			headers: {
			  'Content-Type': 'application/json',
			  'Authorization': 'Bearer ' + session_token,
			  "Access-Control-Allow-Origin" : "*", 
			  "Access-Control-Allow-Credentials" : true
			},
			method: "POST",
			body: dataProducstSelected
		  });
		  const responseJSON = await response.json();
		  console.log("RESPUESTA SERVICIO ADD PRODUCTS");
		  console.log(responseJSON);
		  // window.location.reload();
		  // Actualizar tabla principal luego de haber agregado un producto
		  console.log("Actualizando productos...");
		  // alert("Actualizando productos......")
		  setOffers(responseJSON.offer_data);
		  setActualizar(true);
		  setProductsTableState(false);
		  setLoading(false);
		}

	useEffect(() => {
		setKey(prevKey => prevKey + 1);
		setFamilia(null);
	}, [linea])

	return (
		<div className='ProductsTable' style={{width: "1200px"}}>
			{loading && <Loader message={'Cargando...'}></Loader>}
			<div className='top-product-table-area'>
				<button
					onClick={(e) => {
						setProductsTableState(false)
					}}
				>
					Lista de Productos
					<svg
						width='15'
						height='16'
						viewBox='0 0 15 16'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_1_6199'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='15'
							height='16'
						>
							<rect y='0.5' width='15' height='15' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_1_6199)'>
							<path
								d='M3.60032 9.20065C3.28749 9.20065 3.026 9.09277 2.81586 8.87702C2.60529 8.66127 2.5 8.40237 2.5 8.10032C2.5 7.78749 2.60529 7.52578 2.81586 7.31521C3.026 7.10507 3.28749 7 3.60032 7H11.3997C11.7125 7 11.9742 7.10507 12.1848 7.31521C12.3949 7.52578 12.5 7.78749 12.5 8.10032C12.5 8.40237 12.3949 8.66127 12.1848 8.87702C11.9742 9.09277 11.7125 9.20065 11.3997 9.20065H3.60032Z'
								fill='#36A7F5'
							/>
						</g>
					</svg>
				</button>
				{ // <Link disabled>Carga Masiva</Link>
					}

				<div
					className='right-area'
					onClick={(e) => {
						setProductsTableState(false)
					}}
				>
					<img src={Cerrar} alt='' />
				</div>
			</div>
			{filterShow && (
				<>
					<div className='input-grid-filter'>
						<div className='input_wrapper'>
							<label htmlFor=''>Estrategia</label>
							<CatálogoDropdown
								head='Seleccionar'
								list={dropdownEstrategia}
								setContenedor={setEstrategia}
								element_id={'estrategia_str'}
							/>
							<input type='hidden' id='estrategia_str' />
						</div>
						<div className='input_wrapper'>
							<label htmlFor=''>Línea</label>
							<CatálogoDropdown
								head='Seleccionar'
								list={dropdownLinea}
								setContenedor={setLinea}	
								element_id={'linea_str'}
							/>
							<input type='hidden' id='linea_str' />
						</div>

						<div className='input_wrapper'>
							<label htmlFor=''>Familia</label>
							<CatálogoDropdown
								key = {key}
								head='Seleccionar'
								list={dropdownFamilia}
								setContenedor={setFamilia}
								element_id={'familia_str'}
							/>
							<input type='hidden' id='familia_str' />
						</div>

						<div className='input_wrapper'>
							<label htmlFor=''>Catálogo</label>
							<CatálogoDropdown
								head='Seleccionar'
								list={[
									'Todos', 'Si', 'No'
								]}
								setContenedor={setCatalogo}
								element_id={'catalogo_str'}
							/>
							<input type='hidden' id='catalogo_str' />
						</div>

						<div className='input_wrapper'>
							<label htmlFor=''>Oferta</label>
							<CatálogoDropdown
								head='Seleccionar'
								list={['Todos', 'Si', 'No']}
								setContenedor={setOferta}
								element_id={'oferta_str'}
							/>
							<input type='hidden' id='oferta_str' />
						</div>
					</div>

					<div className='input_wrapper_collection'>
						<input
							type='text'
							placeholder='Buscar Producto, código, familia, subfamilia...'
							maxLength={25}
							onBlur={(e) => {
								if (e.target.value !== '') {
									e.target.classList.remove('error_oferto')
									e.target.classList.add('success_oferto')
								} else {
									e.target.classList.add('error_oferto')
									e.target.classList.remove('success_oferto')
								}
							}}
							onChange={(e) => {
								setPalabra(e.target.value)
								}
							}
						/>
						<div className='switch-wrapper'>
							<h1>Seleccionados &nbsp;</h1>
							<SwitchButtonOffer setActive={setActive} active={active} />
						</div>
						<button
							className='filter-btn'
							onClick={(e) => {
								setEstrategia(null)
								setLinea(null)
								setFamilia(null)
								setCatalogo(null)
								setOferta(null)
								setPalabra(null)
								setfilterShow(false)
							}}
						>
							Limpiar Filtros
						</button>

						<img
							src={filteruptable}
							alt=''
							onClick={(e) => {
								setfilterShow(false)
							}}
							className='arrowUpFilter'
						/>
					</div>
				</>
			)}
			{!filterShow && (
				<div className='input_wrapper_collection input_wrapper_collection_close'>
					<button
						className='filter-btn'
						onClick={(e) => {
							setfilterShow(true)
						}}
					>
						Desplegar Filtros
					</button>

					<img
						src={filteruptable}
						onClick={(e) => {
							setfilterShow(true)
						}}
						alt=''
						className='arrowDownFilter'
					/>
				</div>
			)}
			<div className='table-products-pure-wrapper'>
				{products && <TableProductsPure idOferta = {idOferta}
					products={products}
					catalogo={catalogo}
					oferta={oferta}
					palabra={palabra}
					estrategia={estrategia}
					linea={linea}
					familia={familia}
					active={active}
				/>}
			</div>

			<div className='footer-table-products'>
				<button 
				style={{cursor: 'pointer'}}
					onClick={(e) => {
						setEstrategia(null)
						setLinea(null)
						setFamilia(null)
						setCatalogo(null)
						setOferta(null)
						setPalabra(null)
						setfilterShow(false)
						// wait 1 second
						setTimeout(() => {
							console.log("esperando...")
							const parentTable = document.getElementById("listadoProductos");// e.target.closest('table')
							const allInputs = parentTable.querySelectorAll(
								'tbody .container input'
							)
							const productsSelected = [];
							allInputs.forEach((element) => {
								if(element.checked && element.getAttribute('data-custom') === "1"){
									productsSelected.push(element.value);
								}
							})

							const result = 
								productsSelected.map(item => {
									return {
										"offer_product_id": parseInt(item)
									};
								})
							;

							// Llamar a función para agregar productos
							console.log("Productos seleeccionadios: ");
							console.log(result);
							addProduct(JSON.stringify(result));
							// Después de agregar los productos, cerrar la ventana y poner un load para actualizar tabla principal
						}, 1000);
						

					}}
				>Agregar Productos</button>
			</div>
		</div>
	)
}
