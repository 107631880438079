import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Flecha from "../assets/img/Flecha.svg";
import Off from "../assets/img/Off.svg";
import orange_down from "../assets/img/orange_down.svg";
import { config } from "../config";
import { Navigate } from "react-router-dom";

function catalogoLineResume() {
  const session_token = localStorage.getItem("session");

  //if there is a session token stored
  if (session_token) {
    return fetch(config.apiUrl + `/catalog/line_resume`, {
      //mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + session_token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((response) => {
        //console.log(response.ok)
        return response.json();
      })
      .then((response) => {
        //console.log(response)
        return response;
      });
  }

  //there are no session token stored
  return {};
}


export const CataLogoResumenTable = (props, promotion_id) => {

  const [data, setData] = useState(null);
  console.log(props);
  useEffect(() => {
    catalogoLineResume().then((result) => {
      setData(result);
      // if (result)
      //   setData(result)
      // else
      //   window.location.replace("/");
    });
  }, []);

  console.log(data);

  return (
    <div className="CataLogoResumenTable">
      <Table>
        <thead>
          <tr>
            <th className="fimila" rowSpan={2}>
              Familia
            </th>
            <th colSpan={3}>
              <div className="display_flex green">
                <img src={Flecha} alt="" className="mr-5" />
                <p>Valor</p>
              </div>
            </th>
            <th colSpan={4}>
              <div className="display_flex green">
                <img src={Flecha} alt="" className="mr-5" />
                <p>Cumplimiento Meta</p>
              </div>
            </th>
            <th colSpan={4}>
              <div className="display_flex green">
                <img src={Flecha} alt="" className="mr-5" />
                <p>Precio</p>
              </div>
            </th>
            <th colSpan={3}>
              <div className="display_flex green">
                <img src={Flecha} alt="" className="mr-5" />
                <p>Resultado MM</p>
              </div>
            </th>

            <th colSpan={2}>
              <div className="display_flex blue">
                <p>Beneficio MM</p>
              </div>
            </th>

            <th colSpan={2}>
              <div className="display_flex blue">
                <p>Res. Campaña</p>
              </div>
            </th>
          </tr>

          <tr>
            <td>
              <div className="display_flex gray">
                <p>Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Opt.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Prop.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>Meta.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Opt.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Prop.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Opt.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>3M.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Prop.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Opt.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray">
                <p>Prop.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>Opt. - Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Prop. - Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>N° Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Imp</p>
                <img src={Off} alt="" />
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.catalog_categories.map((value, index) => {
              // if the channel is modern
              if (value.channel === "15")
                return value.categories.map((cat) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="display_flex gray">
                          <a href={"/catalogo/categoria/"+cat.category+"/"+data.promotion_id}>{cat.category}</a>
                        </div>
                      </td>
                      <td>
                        <div className="display_flex  gray">
                          <p>{cat.valor["Act"]} Ton.</p>
                        </div>
                      </td>
                      <td>
                        <div className="display_flex  center_justify gray">
                          <p>{cat.valor["Opt"]} Ton.</p>
                          <img src={Off} alt="" />
                        </div>
                      </td>
                      <td>
                        {cat.valor["Prop"] >= 0 ? (
                          <div className="display_flex green">
                            <img src={Flecha} alt="" className="mr-5" />
                            <p>{cat.valor["Prop"]} Ton.</p>
                          </div>
                        ) : (
                          <div className="display_flex orange">
                            <img src={orange_down} alt="" className="mr-5" />
                            <p>{cat.valor["Prop"]} Ton.</p>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="display_flex  gray">
                          <p>{cat.cumplimiento_meta["Meta"]} Ton.</p>
                        </div>
                      </td>
                      <td>
                        <div className="display_flex  center_justify gray">
                          <p>{cat.cumplimiento_meta["Act"]}%</p>
                        </div>
                      </td>
                      <td>
                        <div className="display_flex  center_justify gray">
                          <p>{cat.cumplimiento_meta["Opt"]}%</p>
                        </div>
                      </td>

                      <td>
                        {cat.cumplimiento_meta["Prop"] >= 0 ? (
                          <div className="display_flex green">
                            <img src={Flecha} alt="" className="mr-5" />
                            <p>{cat.cumplimiento_meta["Prop"]}%</p>
                          </div>
                        ) : (
                          <div className="display_flex orange">
                            <img src={orange_down} alt="" className="mr-5" />
                            <p>{cat.cumplimiento_meta["Prop"]}%</p>
                          </div>
                        )}
                      </td>

                      <td>
                        <div className="display_flex  center_justify gray">
                          <p>${cat.precio["Act"]}</p>
                        </div>
                      </td>
                      <td>
                        <div className="display_flex  center_justify gray">
                          <p>${cat.precio["Opt"]}</p>
                        </div>
                      </td>

                      <td>
                        {cat.precio["3M"] >= 0 ? (
                          <div className="display_flex green">
                            <img src={Flecha} alt="" className="mr-5" />
                            <p>{cat.precio["3M"]}%</p>
                          </div>
                        ) : (
                          <div className="display_flex orange">
                            <img src={orange_down} alt="" className="mr-5" />
                            <p>{cat.precio["3M"]}%</p>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="display_flex  gray">
                          <p>${cat.precio["Prop"]}</p>
                        </div>
                      </td>

                      <td>
                        <div className="display_flex  gray">
                          <p>${cat.resultado_mm["Act"]}</p>
                        </div>
                      </td>

                      <td>
                        <div className="display_flex  center_justify gray">
                          <p>${cat.resultado_mm["Opt"]}</p>
                        </div>
                      </td>

                      <td>
                        {cat.resultado_mm["Prop"] >= 0 ? (
                          <div className="display_flex green">
                            <img src={Flecha} alt="" className="mr-5" />
                            <p>${cat.resultado_mm["Prop"]}</p>
                          </div>
                        ) : (
                          <div className="display_flex orange">
                            <img src={orange_down} alt="" className="mr-5" />
                            <p>${cat.resultado_mm["Prop"]}</p>
                          </div>
                        )}
                      </td>

                      <td>
                        {cat.beneficio["Opt-Act"] >= 0 ? (
                          <div className="display_flex green">
                            <img src={Flecha} alt="" className="mr-5" />
                            <p>${cat.beneficio["Opt-Act"]}</p>
                          </div>
                        ) : (
                          <div className="display_flex orange">
                            <img src={orange_down} alt="" className="mr-5" />
                            <p>-${cat.beneficio["Opt-Act"]}</p>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="display_flex  gray">
                          <p>${cat.beneficio["Opt-Act"]}</p>
                        </div>
                      </td>

                      <td>
                        {cat.res_campaña["N Act"] >= 0 ? (
                          <div className="display_flex green">
                            <img src={Flecha} alt="" className="mr-5" />
                            <p>${cat.res_campaña["N Act"]}</p>
                          </div>
                        ) : (
                          <div className="display_flex orange">
                            <img src={orange_down} alt="" className="mr-5" />
                            <p>${cat.res_campaña["N Act"]}</p>
                          </div>
                        )}
                      </td>
                      <td>
                        {cat.res_campaña["Imp"] >= 0 ? (
                          <div className="display_flex green">
                            <img src={Flecha} alt="" className="mr-5" />
                            <p>{cat.res_campaña["Imp"]}%</p>
                          </div>
                        ) : (
                          <div className="display_flex orange">
                            <img src={orange_down} alt="" className="mr-5" />
                            <p>{cat.res_campaña["Imp"]}%</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                });
            })}
        </tbody>
      </Table>
    </div>
  );
};
