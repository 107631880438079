import PromoApi from '../utils/api/apiPromoboosterPF'
import { envVariables } from '../utils/envVariables'
import { config } from '../config'

export const updateStateMoves = async (promotion_id, new_state, comment = null) => {
	const token = localStorage.getItem('session')

	return PromoApi.post(
		`${config.apiUrl}/promotion/update_phase`,
		{
			promotional_state_id: new_state,
			promotion_id: promotion_id,
			comment: comment
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	)
}
