import React from "react";
import Codigo from "../assets/img/Codigo.svg";
import AvatarPop from "../assets/img/AvatarPop.svg";
import Actividad from "../assets/img/Actividad.svg";
import Jumbo from "../assets/img/Jumbo.svg";
import Resumen from "../assets/img/Icono Resumen.svg";
import Cerrar from "../assets/img/Cerrar.svg";
import Avatargirl from "../assets/img/Avatargirl.svg";

export const JumboPopOld = ({ setActive }) => {
  return (
    <div
      className="popupWrapper OfertaWrapper TottusPop"
      id="popupoldjumbo"
      onClick={(e) => {
        if (e.target.id == "popupoldjumbo") {
          setActive(false);
        }
      }}
    >
      <div className="popup">
        <div className="close_icon" onClick={(e) => setActive(false)}>
          <img src={Cerrar} alt="" />
        </div>
        <div className="left_side" style={{ gridColumn: "span 2" }}>
          <h1>
            <img src={Codigo} alt="" />
            X6000
          </h1>
          <div
            className="badge_long badge_long_catalogo"
            style={{
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr",
              width: "100%",
              padding: 0,
              boxShadow: "none",
              border: 0,
            }}
          >
            <div className="box_inner">
              <h1>Línea</h1>
              <p>Cecinas</p>
            </div>
            <div className="box_inner">
              <h1>Familia</h1>
              <p>Vienesas</p>
            </div>
            <div className="box_inner">
              <h1>Sub Familia</h1>
              <p>-</p>
            </div>

            <div className="box_inner">
              <h1>Marca</h1>
              <p>PF</p>
            </div>
            <div className="box_inner">
              <h1>Peso Caja</h1>
              <p>1Kg</p>
            </div>
            <div className="box_inner">
              <h1>Peso Unidad</h1>
              <p>250g</p>
            </div>
            <div className="box_inner">
              <h1>Unidades</h1>
              <p>4</p>
            </div>
            <div className="box_inner">
              <h1>Vol. Act.</h1>
              <p>14.050</p>
            </div>
            <div className="box_inner">
              <h1>Formato Venta</h1>
              <p>UK</p>
            </div>
          </div>
          <h1 style={{ marginTop: 30 }}>
            <img src={Resumen} alt="" />
            Detalles Optimización
          </h1>
          <div
            className="badge_long badge_long_catalogo"
            style={{ gridTemplateColumns: "repeat(10,1fr)", width: "100%" }}
          >
            <div
              className="box_inner"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ fontWeight: 700 }}>MAPE</p>
            </div>
            <div className="box_inner">
              <h1>Log-Log</h1>
              <p>40,5%</p>
            </div>
            <div className="box_inner">
              <h1>Elast</h1>
              <p>-1.5</p>
            </div>
            <div className="box_inner">
              <h1>Log</h1>
              <p>50,5%</p>
            </div>

            <div className="box_inner">
              <h1>Lin</h1>
              <p>60,5%</p>
            </div>
            <div className="box_inner">
              <h1>Log-Log</h1>
              <p>80,0%</p>
            </div>
            <div className="box_inner">
              <h1>Precio Act.</h1>
              <p>$4.050</p>
            </div>
            <div className="box_inner">
              <h1>Precio Opt.</h1>
              <p>$4.250</p>
            </div>
            <div className="box_inner">
              <h1>Vol. Act.</h1>
              <p>14.050</p>
            </div>
            <div className="box_inner">
              <h1>Vol. Opt.</h1>
              <p>12.660</p>
            </div>
          </div>

          <h1 style={{ marginTop: 20 }}>
            <img src={Actividad} alt="" />
            Actividad
          </h1>

          <div className="profile_box_wrapper">
            <div className="profile_box">
              <img src={Avatargirl} alt="" />
              <div className="presentation">
                <input type="text" placeholder="Escribe un comentario..." />
              </div>
            </div>

            <div className="profile_box responder">
              <img src={Avatargirl} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>María M.</h1>
                  <p>hace 10 mínutos</p>
                </div>
                <input type="text" placeholder="Saquemos el Jamón Ahumado" />
                <button>Responder</button>
              </div>
            </div>

            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>Juan Martínez</h1>
                  <p>ha movido esta promoción al estado</p>
                  <span>EN EDICIÓN</span>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>Pedro Perez</h1>
                  <p>ha movido esta promoción al estado</p>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>María M.</h1>
                  <p>ha movido esta promoción al estado</p>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
