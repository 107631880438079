import React, { useState, useEffect, useCallback } from 'react'
import Table from 'react-bootstrap/Table'
import Flecha from '../assets/img/Flecha_green.svg'
import Off from '../assets/img/Off.svg'
import orange_down from '../assets/img/orange_down.svg'
import { ToolTipCustomList } from './ToolTipCustomList'
import { forcedCeroUM, forcedCeroPercent } from '../config'
import { sortData } from '../utils/sortData'

export const CataLogoResumenTableCopy = (data, promotion_id) => {
	const [sortKey, setSortKey] = useState()
	const [sortOrder, setSortOrder] = useState('asc')

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const sortedData = useCallback(
		sortData({
			tableData: data.data.data_rows,
			sortKey,
			reverse: sortOrder === 'desc',
		}),
		[data, sortKey, sortOrder]
	)

	const changeSort = (key) => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		setSortKey(key)
	}

	//volumen promedio
	const total_rows = data.data.data_rows.length

	const avarageCompare = (firstColumn, secondColumn) => {
		let sum_first_column = 0
		data.data.data_rows.map((row) => {
			sum_first_column =
				sum_first_column +
				parseFloat(
					row[firstColumn]
						.split(' ')[0]
						.split('%')[0]
						.replace('.', '')
						.replace(',', '.')
				)
		})
		let avg_first_column = sum_first_column / total_rows

		let sum_second_column = 0
		data.data.data_rows.map((row) => {
			sum_second_column =
				sum_second_column +
				parseFloat(
					row[secondColumn]
						.split(' ')[0]
						.split('%')[0]
						.replace('.', '')
						.replace(',', '.')
				)
		})
		let avg_second_column = sum_second_column / total_rows

		return avg_first_column >= avg_second_column
	}

	const [MouseDownState, setMouseDownState] = useState(null)
	return (
		<div className='CataLogoResumenTable CataLogoResumenTableNew'>
			<Table id="tablaDescargaFinal">
				<thead style={{ height: `40 !important` }}>
					<tr>
						<th className='fimila border-right-gray' rowSpan={2}>
							Línea
						</th>
						<th colSpan={3} className='border-right-gray'>
							{avarageCompare('vol_prop', 'vol_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Volumen</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Volumen</p>
								</div>
							)}
						</th>
						<th colSpan={4} className='border-right-gray'>
							{avarageCompare('meta_prop', 'meta_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Cumplimiento Meta</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Cumplimiento Meta</p>
								</div>
							)}
						</th>
						<th colSpan={3} className='border-right-gray'>
							{avarageCompare('precio_prop', 'precio_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Precio</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Precio</p>
								</div>
							)}
						</th>
						<th colSpan={3} className='border-right-gray'>
							{avarageCompare('res_mm_prop', 'res_mm_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Resultado MM</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Resultado MM</p>
								</div>
							)}
						</th>

						<th colSpan={2} className='border-right-gray'>
							<div className='display_flex blue'>
								<p>Beneficio MM</p>
							</div>
						</th>

						<th colSpan={2}>
							<div className='display_flex blue'>
								<p>Res. Campaña</p>
							</div>
						</th>
					</tr>

					<tr>
            <td style={{display: "none"}}></td>
						<td>
							<div className='display_flex gray'>
								<p>Act.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('vol_act')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Opt.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('vol_opt')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Prop.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('vol_prop')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>Meta.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('meta')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Act.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('meta_act')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Opt.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('meta_opt')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Prop.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('meta_prop')}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Act.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('precio_act')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Opt.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('precio_opt')}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Prop.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('precio_prop')}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Act.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('res_mm_act')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Opt.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('res_mm_opt')}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Prop.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('res_mm_prop')}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Opt. - Act.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('ben_opt_act')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Prop. - Act.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									onClick={() => changeSort('ben_prop_act')}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex gray' onClick={(e) => {changeSort('n_act')}}>
								<p>N° Act.</p>
								<img
									style={{ cursor: 'pointer' }}
									src={Off}
									alt=''
									
								/>
							</div>
						</td>
						<td>
							<div
								className='display_flex  gray'
								onClick={() => changeSort('imp')}
							>
								<p>Imp</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					{data &&
						sortedData.map((cat, index) => {
							// if the channel is modern
							return (
								<tr>
									<td className='border-right-gray'>
										<div className='display_flex gray'>
											<a
												href={
													'/catalogo/categoria/' +
													cat.category +
													'/' +
													data.data.promotion_id
												}
											>
												{cat.category}
											</a>
										</div>
									</td>
									<td>
										<div className='display_flex  gray'>
											<p>{cat.vol_act}</p>
										</div>
									</td>
									<td>
										<div className='display_flex  center_justify gray'>
											<p>{cat.vol_opt}</p>
										</div>
									</td>
									<td className='border-right-gray'>
										{parseFloat(
											cat.vol_prop
												.split(' ')[0]
												.replace('.', '')
												.replace(',', '.')
										) >=
										parseFloat(
											cat.vol_act
												.split(' ')[0]
												.replace('.', '')
												.replace(',', '.')
										) ? (
											<div className='display_flex green'>
												<img src={Flecha} alt='' className='mr-5' />
												<p>{cat.vol_prop}</p>
											</div>
										) : (
											<div className='display_flex orange'>
												<img src={orange_down} alt='' className='mr-5' />
												<p>{cat.vol_prop}</p>
											</div>
										)}
									</td>
									<td>
										<div className='display_flex  gray'>
											<p>{cat.meta}</p>
										</div>
									</td>
									<td>
										<div className='display_flex  center_justify gray'>
											<p>{forcedCeroPercent(cat.meta_act)}</p>
										</div>
									</td>
									<td>
										<div className='display_flex  center_justify gray'>
											<p>{forcedCeroPercent(cat.meta_opt)}</p>
										</div>
									</td>

									<td className='border-right-gray'>
										{parseFloat(
											cat.meta_prop
												.split('%')[0]
												.replace('.', '')
												.replace(',', '.')
										) >=
										parseFloat(
											cat.meta_act
												.split('%')[0]
												.replace('.', '')
												.replace(',', '.')
										) ? (
											<div className='display_flex green'>
												<img src={Flecha} alt='' className='mr-5' />
												<p>{forcedCeroPercent(cat.meta_prop)}</p>
											</div>
										) : (
											<div className='display_flex orange'>
												<img src={orange_down} alt='' className='mr-5' />
												<p>{forcedCeroPercent(cat.meta_prop)}</p>
											</div>
										)}
									</td>
									<td>
										<div className='display_flex  gray'>
											<p>${cat.precio_act}</p>
										</div>
									</td>
									<td>
										<div className='display_flex  center_justify gray'>
											<p>${cat.precio_opt}</p>
										</div>
									</td>

									<td className='border-right-gray'>
										<div className='display_flex green'>
											{parseFloat(
												cat.precio_prop.replace('.', '').replace(',', '.')
											) >=
											parseFloat(
												cat.precio_act.replace('.', '').replace(',', '.')
											) ? (
												<div className='display_flex green'>
													<img src={Flecha} alt='' className='mr-5' />
													<p>${cat.precio_prop}</p>
												</div>
											) : (
												<div className='display_flex orange'>
													<img src={orange_down} alt='' className='mr-5' />
													<p>${cat.precio_prop}</p>
												</div>
											)}
										</div>
									</td>
									<td>
										<div className='display_flex  gray'>
											<p>${cat.res_mm_act}</p>
										</div>
									</td>

									<td>
										<div className='display_flex  center_justify gray'>
											<p>${cat.res_mm_opt}</p>
										</div>
									</td>

									<td className='border-right-gray'>
										{parseFloat(
											cat.res_mm_prop.replace('.', '').replace(',', '.')
										) >=
										parseFloat(
											cat.res_mm_act.replace('.', '').replace(',', '.')
										) ? (
											<div className='display_flex green'>
												<img src={Flecha} alt='' className='mr-5' />
												<p>${cat.res_mm_prop}</p>
											</div>
										) : (
											<div className='display_flex orange'>
												<img src={orange_down} alt='' className='mr-5' />
												<p>${cat.res_mm_prop}</p>
											</div>
										)}
									</td>
									<td>
										{parseFloat(
											cat.res_mm_opt.replace('.', '').replace(',', '.')
										) >=
										parseFloat(
											cat.res_mm_act.replace('.', '').replace(',', '.')
										) ? (
											<div className='display_flex green'>
												<img src={Flecha} alt='' className='mr-5' />
												<p>${cat.ben_opt_act}</p>
											</div>
										) : (
											<div className='display_flex orange'>
												<img src={orange_down} alt='' className='mr-5' />
												<p>${cat.ben_opt_act}</p>
											</div>
										)}
									</td>
									<td className='border-right-gray'>
										{parseFloat(
											cat.res_mm_prop.replace('.', '').replace(',', '.')
										) >=
										parseFloat(
											cat.res_mm_act.replace('.', '').replace(',', '.')
										) ? (
											<div className='display_flex green'>
												<img src={Flecha} alt='' className='mr-5' />
												<p>${cat.ben_prop_act}</p>
											</div>
										) : (
											<div className='display_flex orange'>
												<img src={orange_down} alt='' className='mr-5' />
												<p>${cat.ben_prop_act}</p>
											</div>
										)}
									</td>

									<td>
										<div className='display_flex gray'>
											<p>{cat.n_act}</p>
										</div>
									</td>
									<td>
										<div
											className='display_flex gray'
											onMouseUp={(e) => {
                        e.target.querySelector('.ToolTipCustom').classList.remove('show')
          
                        clearInterval(MouseDownState)
                      }}
											onMouseDown={(e) => {
												if (e.button != 0) {
													return false
												}
												let MouseDownTimeOut = setTimeout(() => {
													e.target
														.querySelector('.ToolTipCustom')
														.classList.add('show')
												}, [500])

												setMouseDownState(MouseDownTimeOut)
											}}
										>
											<ToolTipCustomList
                        list={[cat.tooltips_n_act.map(item => item.fecha.split(" ")[0]+":"+item.pct_activos+"% ")]}
											/>
											<p>{forcedCeroPercent(cat.imp)}</p>
										</div>

									
									</td>
									<td>
										<div
											className='display_flex green'
											
											onMouseDown={(e) => {
												if (e.button != 0) {
													return false
												}
												let MouseDownTimeOut = setTimeout(() => {
													e.target
														.querySelector('.ToolTipCustom')
														.classList.add('show')
												}, [500])

												setMouseDownState(MouseDownTimeOut)
											}}
										></div>
									</td>
								</tr>
							)
						})}
			
				</tbody>
			</Table>
		</div>
	)
}
