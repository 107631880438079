import React from "react";
import Table from "react-bootstrap/Table";
import Flecha from "../assets/img/Flecha.svg";
import Off from "../assets/img/Off.svg";
import orange_down from "../assets/img/orange_down.svg";
import Recomendacion from "../assets/img/Recomendacion.svg";
import tag from "../assets/img/tag.svg";
import Si from "../assets/img/Si.svg";
import dropdown_active from "../assets/img/dropdown_active.svg";
import filter_new from "../assets/img/filter_new.svg";
import dropdown from "../assets/img/dropdown.svg";
import dropdown_simple from "../assets/img/dropdown_simple.svg";

import No from "../assets/img/No.svg";
export const VienesasActivationTable = () => {
  return (
    <div className="CataLogoResumenTable VienesasTable">
      <Table>
        <thead>
          <tr>
            <th className="fimila border_head_gray" rowSpan={2} colSpan={3}>
              <div className="dropdown" style={{ width: "95%" }}>
                <div className="head">
                  <img src={filter_new} alt="" />
                  <p>Todas</p>
                  <img src={dropdown} alt="" />
                </div>
              </div>
            </th>
            <th colSpan={3} className="border_head_gray">
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>Volumen</p>
              </div>
            </th>
            <th colSpan={3} className="border_head_gray">
              <div className="display_flex green">
                <img src={Flecha} alt="" className="mr-5" />
                <p>Precio</p>
              </div>
            </th>
            <th colSpan={3} className="border_head_gray">
              <div className="display_flex green">
                <img src={Flecha} alt="" className="mr-5" />
                <p>Resultado MM</p>
              </div>
            </th>

            <th colSpan={2} className="border_head_gray">
              <div className="display_flex blue">
                <p>Beneficio</p>
              </div>
            </th>

            <th>
              <div className="display_flex blue">
                <p>Mg.Cad.</p>
              </div>
            </th>
          </tr>

          <tr className="bottom_row_head">
            <th>
              <div className="head_box">
                <h1>Act.</h1>
                <p>63,0 Ton.</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Opt.</h1>
                <p>62,5 Ton.</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop.</h1>
                <p>62,4 Ton.</p>
              </div>
            </th>

            <th>
              <div className="head_box">
                <h1>Act.</h1>
                <p>$1.782</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Opt</h1>
                <p>$1.766</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop.</h1>
                <p>$1.822</p>
              </div>
            </th>

            <th>
              <div className="head_box">
                <h1>Act.</h1>
                <p className="orange">$-5,2</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Opt</h1>
                <p className="orange">$-6,0</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop.</h1>
                <p className="orange">$-2,5</p>
              </div>
            </th>

            <th>
              <div className="head_box">
                <h1>Prop. - Act.</h1>
                <p>$2.698.969</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop. - Opt.</h1>
                <p>$3.494.070</p>
              </div>
            </th>
            <th>
              <div
                className="head_box wrap_input"
                style={{ transform: "translateX(5px)" }}
              >
                <p>%</p>
                <p>24,0</p>
              </div>
            </th>
          </tr>
        </thead>
      </Table>
      <Table>
        <thead style={{ background: "#E8E8E8", height: 30 }}>
          <tr>
            <td>
              <div className="display_flex gray">
                <p>Rec.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Código</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Descripción</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>Marca</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Vol. Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Vol. Opt.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Vol. Prop.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>Var. Vol.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Precio Ba.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Precio Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Precio Opt.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>Precio Prop.</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray">
                <p>Var. $ Opt/Act.</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>Var. $ Pr./Bas</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>RO % Prop.</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>Mg. Cad.</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>PVP Sug.</p>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="activate low_tall">
            <td colSpan={18} className="dropdown_active_table">
              <div className="display_flex" style={{ justifyContent: "left" }}>
                <h1 style={{ marginLeft: 10 }}>Productos con Activación</h1>
                <img src={dropdown_active} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate">
            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">PF</a>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.460</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex green">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>$</p>
                <p>1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>%</p>
                <p>0,0</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>4,9%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray wrap_input">
                <p>%</p>
                <p>24,0</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>$2.766</p>
              </div>
            </td>
          </tr>

          <tr className="activate">
            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">PF</a>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.460</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex green">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>$</p>
                <p>1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>%</p>
                <p>0,0</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>4,9%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray wrap_input">
                <p>%</p>
                <p>24,0</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>$2.766</p>
              </div>
            </td>
          </tr>

          <tr className="activate">
            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">PF</a>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.460</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex green">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>$</p>
                <p>1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>%</p>
                <p>0,0</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>4,9%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray wrap_input">
                <p>%</p>
                <p>24,0</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>$2.766</p>
              </div>
            </td>
          </tr>

          <tr className="low_tall">
            <td colSpan={18} className="dropdown_not_active_table">
              <div className="display_flex" style={{ justifyContent: "left" }}>
                <h1 style={{ marginLeft: 10 }}>Productos Sin Activación</h1>
                <img src={dropdown_simple} alt="" />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">PF</a>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.460</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex green">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>$</p>
                <p>1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>%</p>
                <p>0,0</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>4,9%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray wrap_input">
                <p>%</p>
                <p>24,0</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>$2.766</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">PF</a>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.460</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex green">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>$</p>
                <p>1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>%</p>
                <p>0,0</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>4,9%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray wrap_input">
                <p>%</p>
                <p>24,0</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>$2.766</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">PF</a>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.460</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex green">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>$</p>
                <p>1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>%</p>
                <p>0,0</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>4,9%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray wrap_input">
                <p>%</p>
                <p>24,0</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>$2.766</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">PF</a>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.460</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex green">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>$</p>
                <p>1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>%</p>
                <p>0,0</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>4,9%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray wrap_input">
                <p>%</p>
                <p>24,0</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>$2.766</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">PF</a>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>23,7 Ton.</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.460</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex green">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>$</p>
                <p>1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray wrap_input">
                <p>%</p>
                <p>0,0</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>-0,0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex orange">
                <img src={orange_down} alt="" className="mr-5" />
                <p>4,9%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray wrap_input">
                <p>%</p>
                <p>24,0</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>$2.766</p>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
