import React, { useState, useEffect } from "react";
import { Navbar } from "./Navbar";
import "../assets/css/CataLogoResumen.css";
import arrow_downward from "../assets/img/arrow_downward.svg";
import { CataLogoResumenFooter } from "./CataLogoResumenFooter";
import { CataLogoResumenTable } from "./CataLogoResumenTable";
import Table from "react-bootstrap/Table";
import { Link , useParams, useNavigate } from "react-router-dom";
import { TopNavigator } from "./TopNavigator";
import { config } from "../config";
import { TableHeader } from "./TableHeader";
import { CataLogoResumenTableCopy } from "./CataLogoResumenTableCopy";
import { CombiarPopup } from "./CombiarPopup";
import { RenombrarPopup } from "./RenombrarPopup";
import {MoveCataLog} from "./MoveCataLog";
import {Loader} from "./Loader";
import { useModal } from "../hooks/useModal";
import { usePrintPDF } from "../hooks/usePrintPdf";
import Modal from "./modal";
import { exportToExcel } from "react-json-to-excel";

function getPromotions(){
  const session_token = localStorage.getItem('session');
  //console.log(session_token);
  //if there is a session token stored
  if (session_token){
      return fetch(config.apiUrl + `/promotion/listall`, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        //console.log(response)
        return response;
      });
    

  }

  //there are no session token stored
  return {

  }

}

function catalogoLineResume(promotion_id){
  const session_token = localStorage.getItem('session');
  
  //if there is a session token stored
  if (session_token){
      return fetch(config.apiUrl + `/catalog/category_data/${promotion_id}`, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        console.log(response)
        return response;
      });
  }
  //there are no session token stored
  return {

  }

}

export const CataLogoResumen = () => {
  const [active, setActive] = useState(false);
  const [combiar, setcombiar] = useState(false);
  const [Renombrar, setRenombrar] = useState(false);
  let navigate = useNavigate();
  const [data, setData] = useState(null);
  const[promotion, setPromotion] = useState(null);
  const [categories, setCategories] = useState(null);
  const [resumeFooter, setResumeFooter] = useState(null);
  const { id_promocion } = useParams();
  const [CatalogMove, setCatalogMove] = useState(false);
  const [loader, setloader] = useState(true);

  const { closeModal, isOpen, openModal, modalRef } = useModal()
	const { componentRef, handlePrint } = usePrintPDF(`${new Date()}`)

  useEffect(() => {
    //setData({catalog_categories: catalog_categories, promotion_id: promotion_id})
    
    catalogoLineResume(id_promocion).then(result =>{
        //setData(result)
        if (result){
          
          console.log(result)
          result.promotion_id = id_promocion
          console.log("result promotion:::::")
          console.log(result)
          let categories_json = []
          result.data_rows.map((EachRow)=>{
            console.log(EachRow);
            categories_json.push({
              category: EachRow.category
            })
          });
          
          setData(result)
          //setCategories(categories_json)
        }
        else
          console.log("error")  
          //window.location.replace("/");
          setloader(false);
    });
    
    //catalogoLineResumeAsync(id_promocion);

    getPromotions().then(result =>{
        const promotion_filter = result.filter(function(element){
          return element.id == id_promocion;
        });
        console.log("*****************")
        console.log(promotion_filter[0])
        //alert(promotion_filter[0].id)
        setPromotion(promotion_filter[0])    
     });

     
  }, []);

  return (
    <div className="CataLogoResumen" ref={componentRef}>
      {loader && <Loader message={"Cargando..."}></Loader>}

      {isOpen && (
				<Modal
					title='Exportar Vista'
					isOpen={isOpen}
					closeModal={closeModal}
					modalRef={modalRef}
          handlePrint={handlePrint}
          handleCsv={() => exportToExcel(data.data_rows, 'downloadfilename')}
				/>
			)}
      

      {CatalogMove && promotion && <MoveCataLog setActive={setCatalogMove} promotion={promotion}/>}

      {!promotion ? '' : <TopNavigator data={promotion} dataPromotion={data}/>}
      
      <Navbar no_need_search />
      {(combiar && promotion) && <CombiarPopup setcombiar={setcombiar} promotion={promotion}/>}
      {Renombrar && <RenombrarPopup setRenombrar={setRenombrar} 
      data={data} setData={setData}/>}

      <div className="top_area top_area_rev">
      
        <div className="left_side" style={{ flex: "none" }}>
          {promotion && <Link to={"/Seguimiento/"+promotion.id}>Seguimiento Catálogo</Link>}
          
          {promotion && <Link to={"/CataLogoResumen/"+promotion.id} className="active">
            Resumen Línea
          </Link>}
        </div>
        <div className="right_side">
          <img src={arrow_downward} alt="" />
          <p>Selecciona una Línea para ver sus Familias</p>
        </div>
      </div>
      <div className="link_area">
      {promotion &&<p onClick={(e) => {
                navigate("/CataLogoResumen/"+promotion.id);
              }} style={{cursor: "pointer"}}>{promotion.name_promotion}
        </p>
      }
      <p>/</p>
        {promotion && <p onClick={(e) => {
                navigate("/ResumenLinea/"+promotion.id);
              }} style={{cursor: "pointer"}}> Resumen Línea</p>
            }
      </div>
{
    !data ? '' : <TableHeader data={data} active_todas="active"/>
}

      {
        data && <CataLogoResumenTableCopy data={data}/>
      }

      {data &&
      <CataLogoResumenFooter
        setcombiar={setcombiar}
        setRenombrar={setRenombrar}
        data={data}
        guardar={false}
        setCatalogMove={setCatalogMove}
        openModalExport={openModal}
        id_promocion={id_promocion}
      />}
    </div>
  );
}

