import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import Flecha from '../assets/img/Flecha_green.svg'
import Off from '../assets/img/Off.svg'
import orange_down from '../assets/img/orange_down.svg'
import { ToolTipCustomList } from './ToolTipCustomList'
import { ToolTipCustom } from './ToolTipCustom'
import { forcedCeroUM, forcedCeroPercent, formatMoney } from '../config'


export const ResumenLineaTableTr = ({
	line,
	vista,
	id_promocion,
	nombre_promocion,
	oficina,
	fecha,
	linea_,
}) => {
	const [MouseDownState, setMouseDownState] = useState(null)

	let listToolTipActives = ''
	let arrayListToolTipActives = []
	line.tooltips_n_act_res.map((item) =>{
		listToolTipActives += item.fecha.split(" ")[0]+": "+item.activos+" ,"
		arrayListToolTipActives.push(item.fecha.split(" ")[0]+": "+item.activos)
	})
		
	let listToolTipPercent = ''
	let arrayToolTipPercent = []
	line.tooltips_n_act_res.map((item) =>{
		listToolTipPercent += item.fecha.split(" ")[0]+": "+item.pct_activos+" , "
		arrayToolTipPercent.push(item.fecha.split(" ")[0]+": "+item.pct_activos)
	})

	let url_base = ''
	if (vista == 'primera') {
		url_base =
			'/CatalogoTradicional/' +
			id_promocion +
			'/' +
			nombre_promocion +
			'/' +
			oficina +
			'/' +
			fecha +
			'/' //falta linea y fecha que se concatenan al final
	} else if (vista == 'segunda') {
		url_base =
			'/CatalogoTradicionalFamilia/' +
			id_promocion +
			'/' +
			nombre_promocion +
			'/' +
			oficina +
			'/' +
			fecha +
			'/' // lo mismo de arriba
	} else {
		url_base =
			'/CatalogoTradicionalFamiliaProducto/' +
			id_promocion +
			'/' +
			nombre_promocion +
			'/' +
			oficina +
			'/' +
			linea_ +
			'/' +
			fecha +
			'/'
	}
	return (
		<tr>
			<td className='border-right-gray'>
				<div className='display_flex gray'>
					{/* TODO ver como armar los links */}
					{/* <Link
						to={`${linea_ === line?.category ? url_base + line.category : '#'}`}
					>
						{line.category}
					</Link> */}
					<Link to={url_base + line.category}>{line.category}</Link>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{line?.vol_act}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  center_justify gray'>
					<p>{line.vol_opt}</p>
				</div>
			</td>
			<td>
				{parseFloat(
					line.vol_prop.split(' ')[0].replace('.', '').replace(',', '.')
				) >=
				parseFloat(
					line.vol_act.split(' ')[0].replace('.', '').replace(',', '.')
				) ? (
					<div className='display_flex green'>
						<img src={Flecha} alt='' className='mr-5' />
						<p>{line.vol_prop}</p>
					</div>
				) : (
					<div className='display_flex orange'>
						<img src={orange_down} alt='' className='mr-5' />
						<p>{line.vol_prop}</p>
					</div>
				)}
			</td>
			<td className='border-right-gray'>
				<div className='display_flex  gray'>
					<p>{line.vol_prop_plus_oi}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{line?.meta}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  center_justify gray'>
					<p>{line?.meta_act}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  center_justify gray'>
					<p>{line?.meta_opt}</p>
				</div>
			</td>
			<td>
				{parseFloat(
					line.meta_prop.split(' ')[0].replace('.', '').replace(',', '.')
				) >=
				parseFloat(
					line.meta_act.split(' ')[0].replace('.', '').replace(',', '.')
				) ? (
					<div className='display_flex green'>
						<img src={Flecha} alt='' className='mr-5' />
						<p>{forcedCeroUM(line?.meta_prop)}%</p>
					</div>
				) : (
					<div className='display_flex orange'>
						<img src={orange_down} alt='' className='mr-5' />
						<p>{forcedCeroUM(line?.meta_prop)}%</p>
					</div>
				)}
			</td>
			<td className='border-right-gray'>
				<div className='display_flex  center_justify gray'>
					<p> {line?.meta_prop_plus_oi}%</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>${line.precio_act}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  center_justify gray'>
					<p>${line.precio_opt}</p>
				</div>
			</td>
			<td className='border-right-gray'>
				{parseFloat(
					line.precio_prop.split(' ')[0].replace('.', '').replace(',', '.')
				) >=
				parseFloat(
					line.precio_act.split(' ')[0].replace('.', '').replace(',', '.')
				) ? (
					<div className='display_flex green'>
						<img src={Flecha} alt='' className='mr-5' />
						<p>{formatMoney(line.precio_prop)}</p>
					</div>
				) : (
					<div className='display_flex orange'>
						<img src={orange_down} alt='' className='mr-5' />
						<p>{formatMoney(line.precio_prop)}</p>
					</div>
				)}
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{formatMoney(line.margen_act)}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  center_justify gray'>
					<p>{formatMoney(line.margen_opt)}</p>
				</div>
			</td>
			<td className='border-right-gray'>
				{parseFloat(
					String(line.margen_prop)
						.split(' ')[0]
						.replace('.', '')
						.replace(',', '.')
				) >=
				parseFloat(
					String(line.margen_act)
						.split(' ')[0]
						.replace('.', '')
						.replace(',', '.')
				) ? (
					<div className='display_flex green'>
						<img src={Flecha} alt='' className='mr-5' />
						<p>{formatMoney(line.margen_prop)}</p>
					</div>
				) : (
					<div className='display_flex orange'>
						<img src={orange_down} alt='' className='mr-5' />
						<p>{formatMoney(line.margen_prop)}</p>
					</div>
				)}
			</td>
			<td>
				<div className='display_flex gray'>
					<p>${(line.res_mm_act)}</p>
				</div>
			</td>

			<td>
				<div className='display_flex gray'>
					<p> ${(line.res_mm_opt)}</p>
				</div>
			</td>
			<td className='border-right-gray'>
				<div className='display_flex gray'>
					<p>${(line.res_mm_prop)}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'
				onMouseUp={(e) => {
					e.target.querySelector('.ToolTipCustom').classList.remove('show')
	  
					clearInterval(MouseDownState)
				  }}
										onMouseDown={(e) => {
											if (e.button != 0) {
												return false
											}
											let MouseDownTimeOut = setTimeout(() => {
												e.target
													.querySelector('.ToolTipCustom')
													.classList.add('show')
											}, [500])

											setMouseDownState(MouseDownTimeOut)
										}}
				style={{cursor:"pointer"}}
				>
					<ToolTipCustomList
						list={arrayListToolTipActives}
						// value={listToolTipActives}
						// width={80}
						/>
					<p>{line.n_act}</p>
				</div>
			</td>

			<td className='border-right-gray'>
				{parseFloat(
					line.imp.split(' ')[0].replace('.', '').replace(',', '.')
				) >= 0 ? (
					<div className='display_flex green'
					style={{cursor:"pointer"}}
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')
		  
						clearInterval(MouseDownState)
					  }}
						onMouseDown={(e) => {
												if (e.button != 0) {
													return false
												}
												let MouseDownTimeOut = setTimeout(() => {
													e.target
														.querySelector('.ToolTipCustom')
														.classList.add('show')
												}, [500])
	
												setMouseDownState(MouseDownTimeOut)
											}}
					>
						<ToolTipCustomList
							list={arrayToolTipPercent}
							// value={listToolTipPercent}
							// width={90}
							/>
						<img src={Flecha} alt='' className='mr-5' style={{cursor: "text"}}/>
						<p style={{cursor: "text"}}>{forcedCeroUM(line.imp)}%</p>
					</div>
				) : (
					<div className='display_flex orange'
					style={{cursor:"pointer", zIndex: 9}}
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')
		  
						clearInterval(MouseDownState)
					  }}
											onMouseDown={(e) => {
												if (e.button != 0) {
													return false
												}
												let MouseDownTimeOut = setTimeout(() => {
													e.target
														.querySelector('.ToolTipCustom')
														.classList.add('show')
												}, [500])
	
												setMouseDownState(MouseDownTimeOut)
											}}
					>
						<ToolTipCustom
							value={listToolTipPercent}
							width={100}
							/>
						<img src={orange_down} alt='' className='mr-5' />
						<p style={{zIndex: -1}}>{forcedCeroUM(line.imp)}%</p>
					</div>
				)}
			</td>

			<td>
				<div className='display_flex gray'>
				
					<p>{line.oi_n_act}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray' >
					<p>{line.oi_imp}</p>
				</div>
			</td>
		</tr>
	)
}
