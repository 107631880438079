import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'
import { Navbar } from './Navbar'
import '../assets/css/Seguimiento.css'
import { CataLogoResumenFooter } from './CataLogoResumenFooter'
import { SeguimientoTable } from './SeguimientoTable'
import { TopNavigator } from './TopNavigator'
import { config } from '../config'
import { Loader } from './Loader'
import { CombiarPopup } from './CombiarPopup'
import { RenombrarPopup } from './RenombrarPopup'
import { MoveCataLog } from './MoveCataLog'
import {
	getTraditionalTrackig,
	getModernTrackig,
} from '../services/tracking.service'
import segMock from '../mocks/seguimiento.json'
import { SeguimientoTableLineaNew } from './seguimientoTradicional/seguimientoTableLineaNew'
import { TopNavigatorFour } from './TopNavigatorFour'
import { DataResumeLineContext } from '../context/linksContext'

function getSeguimiento(promotion_id) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/catalog/seguimiento_linea/${promotion_id}`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

const getPromotions = async () => {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

function catalogoLineResume(promotion_id) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/catalog/category_data/${promotion_id}`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				console.log(response)
				return response
			})
	}
	//there are no session token stored
	return {}
}

export const Seguimiento = () => {
	const [combiar, setcombiar] = useState(false)
	const [Renombrar, setRenombrar] = useState(false)
	const [data, setData] = useState(null)
	const [promotion, setPromotion] = useState(null)
	const { id_promocion } = useParams()
	const [CatalogMove, setCatalogMove] = useState(false)
	const [loader, setloader] = useState(false)
	const [hopeJson, setHopeJson] = useState(null)
	let navigate = useNavigate()
	const location = useLocation()
	console.log('Seguimiento location', location)
	const [traditional, setTraditional] = useState(false)
	const { fetchDataResumeLine } = useContext(DataResumeLineContext)

	useEffect(() => {
		//setData({catalog_categories: catalog_categories, promotion_id: promotion_id})
		setTraditional(location?.state?.traditional)
		if(location.pathname.includes('tradicional')){
			catalogoLineResume(id_promocion).then((result) => {
				//setData(result)
				if (result) {
					console.log(result)
					result.promotion_id = id_promocion
					console.log('result promotion:::::')
					console.log(result)
					let categories_json = []
					result.data_rows.map((EachRow) => {
						console.log(EachRow)
						categories_json.push({
							category: EachRow.category,
						})
					})

					setData(result)
					console.log('catita', result)
					//setCategories(categories_json)
				} else console.log('error')
				//window.location.replace("/");
			})
		}
		//catalogoLineResumeAsync(id_promocion);
		getPromotions()
			.then((result) => {
				const promotion_filter = result.filter(function (element) {
					return element.id == id_promocion
				})
				console.log('*****************')
				console.log(promotion_filter[0])
				//alert(promotion_filter[0].id)
				setPromotion(promotion_filter[0])
			})
			.catch((err) => console.log(err))

		//  getSeguimiento(id_promocion).then(result =>{
		//   console.log("API ROW SEGUIMIENTO-------------------");
		//   console.log(result);
		//   setHopeJson(result);
		//   setloader(false);

		//  });
	}, [])

	useEffect(() => {
		;(async () => {
			if (location.pathname.includes('tradicional')) {
				const response = await getTraditionalTrackig(id_promocion)
				if (response) {
					const { data } = response
					setHopeJson(data)
				}
			} else {
				const response = await getModernTrackig(id_promocion)
				if (response) {
					const { data } = response
					setHopeJson(data)
				}
			}
		})()
	}, [])

	const distinctSubcategory = new Set()

	hopeJson?.data_rows.forEach((row) => {
		distinctSubcategory.add(row?.subcategory)
	})

	const distinctSubcategoriesArray = Array.from(distinctSubcategory)

	return (
		<div className='CataLogoResumen'>
			{/* {!promotion ? '' : <TopNavigator data={promotion} />} */}
			{/* {promotion && (
				<TopNavigatorFour
					nombre_promocion={promotion?.name_promotion}
					fecha={promotion?.month_promotion_str}
					linea={promotion?.linea}
				/>
			)} */}

			{location.pathname.includes('tradicional')
				? promotion && (
						<TopNavigatorFour
							nombre_promocion={promotion?.name_promotion}
							fecha={promotion?.month_promotion_str}
							linea={promotion?.linea}
						/>
				  )
				: promotion && <TopNavigator data={promotion} />}

			{loader && <Loader message={'Cargando...'}></Loader>}

			{CatalogMove && promotion && (
				<MoveCataLog setActive={setCatalogMove} promotion={promotion} />
			)}

			{combiar && promotion && (
				<CombiarPopup setcombiar={setcombiar} promotion={promotion} />
			)}
			{Renombrar && (
				<RenombrarPopup
					setRenombrar={setRenombrar}
					data={data}
					setData={setData}
				/>
			)}

			<Navbar no_need_search />

			<div
				className={`${
					location.pathname.includes('tradicional')
						? 'option_table option_table_upper'
						: 'top_area top_area_rev'
				}`}
				style={{ marginBottom: '1rem' }}
			>
				<div className='left_side' style={{ flex: 'none' }}>
					{promotion && <Link className='active'>Seguimiento Catálogo</Link>}

					{promotion && (
						<Link
							//CatalogoTradicional/322/Demo%20040723/Nacional/Julio%202023/CECINAS%20PF
							to={
								location.pathname.includes('tradicional')
									? `/CatalogoTradicional/${promotion?.id}/${promotion?.name_promotion}/Nacional/${promotion?.month_promotion_str}${promotion?.year_promotion_str}/${promotion?.linea}`
									: `/CataLogoResumen/${promotion.id}`
							}
						>
							Resumen Línea
						</Link>
					)}
					{hopeJson &&
						location.pathname.includes('tradicional') &&
						distinctSubcategoriesArray?.map((EachRow) => {
							return (
								<Link
									to={`/CatalogoTradicionalFamiliaProducto/${promotion?.id}/${promotion?.name_promotion}/Nacional/${promotion?.linea}/${promotion?.month_promotion_str}${promotion?.year_promotion_str}/${EachRow}`}
								>
									{EachRow}
								</Link>
							)
						})}
				</div>
				{!location.pathname.includes('tradicional') && (
					<div className='right_side'>
						<p>Selecciona Resumen Línea para volver a ver las Líneas</p>
					</div>
				)}
			</div>
			<div className='link_area'>
				{promotion && (
					<p
						onClick={(e) => {
							navigate(-1)
						}}
						style={{ cursor: 'pointer' }}
					>
						{promotion.name_promotion}
					</p>
				)}
				<p>/</p>
				<p>Seguimiento Catálogo</p>
			</div>
			
			{location.pathname.includes('tradicional')
				? hopeJson && <SeguimientoTableLineaNew hopeJson={hopeJson} />
				: hopeJson && (
						<SeguimientoTable hopeJson={hopeJson} traditional={traditional} />
				  )}

			{location.pathname.includes('tradicional') && (
				<CataLogoResumenFooter
					setcombiar={setcombiar}
					setRenombrar={setRenombrar}
					data={hopeJson}
					guardar={false}
					setCatalogMove={setCatalogMove}
					traditional={location.pathname.includes('tradicional')}
					offices={promotion?.offices}
					id_promocion={id_promocion}
				/>
			)}
			{data && (
				<CataLogoResumenFooter
					setcombiar={setcombiar}
					setRenombrar={setRenombrar}
					data={data}
					guardar={false}
					setCatalogMove={setCatalogMove}
					id_promocion={id_promocion}
				/>
			)}
		</div>
	)
}
