import {React, useState} from "react";
import moment from 'moment'
import 'moment/locale/es'

import { CatálogoDropdown } from "./CatálogoDropdown";
import { CatálogoDropdownDate } from "./CatálogoDropdownDate";
import { config } from "../config";

import bookmark from "../assets/img/bookmark.svg";
import edit from "../assets/img/edit.svg";
import calender from "../assets/img/calender.svg";
import shop from "../assets/img/shop.svg";
import Jumbo from "../assets/img/Jumbo.svg";
import Cerrar from "../assets/img/Cerrar.svg";
import error_explanation from "../assets/img/error_explanation.svg";
import tick_explanation from "../assets/img/tick_explanation.svg";

function returnMonth(mes){
  switch(mes){
    case 'Jan':
      mes = '01';
      break;
    case 'Feb':
        mes = '02';
        break;
    case 'Mar':
        mes = '03';
        break;
    case 'Apr':
        mes = '04';
        break;
    case 'May':
        mes = '05';
        break;
    case 'Jun':
        mes = '06';
        break;
    case 'Jul':
        mes = '07';
        break;
    case 'Aug':
        mes = '08';
        break;
    case 'Sep':
        mes = '09';
        break;
    case 'Oct':
        mes = '10';
        break;
    case 'Nov':
        mes = '11';
        break;
    case 'Dec':
        mes = '12';
        break;
  }
  return mes;
}

function getDateFormat(date_str){
  let date_array = date_str.split("/");
  date_array[1] = returnMonth(date_array[1]);
  return date_array[2]+"-"+date_array[1]+"-"+date_array[0];
}

function getListMonthsYear(initsellin, endsellin, initsellout, endsellout){
  const months_str = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio','Julio', 'Agosto'
  ,'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  
  let init_sellin_date = getDateFormat(initsellin);
  let end_sellin_date = getDateFormat(endsellin);
  let init_sellout_date = getDateFormat(initsellout);
  let end_sellout_date = getDateFormat(endsellout);
  
  if(document.getElementById('init_sellin_date')){
    if(document.getElementById('init_sellin_date').value != '' && document.getElementById('end_sellin_date').value != ''
      && document.getElementById('init_sellout_date').value != '' && document.getElementById('end_sellout_date').value != ''){
        init_sellin_date = document.getElementById('init_sellin_date').value;
        end_sellin_date = document.getElementById('end_sellin_date').value;
        init_sellout_date = document.getElementById('init_sellout_date').value;
        end_sellout_date = document.getElementById('end_sellout_date').value;
    }
  }
  let four_dates = [init_sellin_date, end_sellin_date, init_sellout_date, end_sellout_date];
  const sortedDates = four_dates.sort((a, b) => a - b);
  const list_dates = dateRange(sortedDates[0], sortedDates[sortedDates.length - 1]);
  let final_dates = [];
  list_dates.map((date) => {
    final_dates.push(months_str[parseInt(date.split("-")[1])]+"-"+date.split("-")[0]);
  });
  console.log("Filas Finalesss!!###:::  ")
  console.log(final_dates);
  return final_dates;
}

function dateRange(startDate, endDate) {
  var start      = startDate.split('-');
  var end        = endDate.split('-');
  var startYear  = parseInt(start[0]);
  var endYear    = parseInt(end[0]);
  var dates      = [];

  for(var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1])-1 : 0;
    for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
      var month = j+1;
      var displayMonth = month < 10 ? '0'+month : month;
      dates.push([i, displayMonth, '01'].join('-'));
    }
  }
  return dates;
}

export const CombiarPopup = ({ setcombiar, promotion }) => {
  const [dateList, setDateList] = useState(getListMonthsYear(
    promotion.start_sellin, promotion.end_sellin, promotion.start_sellout, promotion.end_sellout
  ));
  const [mesTexto, setMesTexto] = useState(promotion.month_promotion_str+" "+promotion.year_promotion_str);
  const [startSellin, setStartSellin] = useState(promotion.start_sellin);
  const [endSellin, setEndSellin] = useState(promotion.end_sellin);
  const [startSellout, setStartSellout] = useState(promotion.start_sellout);
  const [endSellout, setEndSellout] = useState(promotion.end_sellout);
  const [startSellinFormat, setStartSellinFormat] = useState(getDateFormat(promotion.start_sellin));
  const [endSellinFormat, setEndSellinFormat] = useState(getDateFormat(promotion.end_sellin));
  const [startSelloutFormat, setStartSelloutFormat] = useState(getDateFormat(promotion.start_sellout));
  const [endSelloutFormat, setEndSelloutFormat] = useState(getDateFormat(promotion.end_sellout));
  moment.locale('es')

  const updateDatePromotion = async () => {
    const start_sellin = document.getElementById('init_sellin_date').value;
    const end_sellin = document.getElementById('end_sellin_date').value;
    const start_sellout = document.getElementById('init_sellout_date').value;
    const end_sellout = document.getElementById('end_sellout_date').value;
    const session_token = localStorage.getItem('session');
    const response = await fetch(config.apiUrl + `/promotion/update`, {
      //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        },
        method: "POST",
        body: JSON.stringify(
          [
            {
                id: promotion.id,
                start_sellin: start_sellin,
                end_sellin: end_sellin,
                start_sellout: start_sellout,
                end_sellout: end_sellout,
                month_promotion_str: mesTexto.split("-")[0],
                year_promotion_str: mesTexto.split("-")[1],
            }
          ]  
        )
      });
      const responseJSON = await response.json();
      console.log(responseJSON);
      window.location.reload();
    }

  return (
		<div className='popupWrapper OfertaWrapper CombiarPopup'>
			<div className='popup'>
				<div className='close_icon' onClick={(e) => setcombiar(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<h1>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_2565_95419'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='24'
							height='24'
						>
							<rect width='24' height='24' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_2565_95419)'>
							<path
								d='M4.95 22.85C4.16667 22.85 3.5 22.571 2.95 22.013C2.4 21.4544 2.125 20.7917 2.125 20.025V5.90002C2.125 5.11669 2.4 4.45002 2.95 3.90002C3.5 3.35002 4.16667 3.07502 4.95 3.07502H5.2V2.50002C5.2 2.13336 5.32933 1.81669 5.588 1.55002C5.846 1.28336 6.15833 1.15002 6.525 1.15002C6.90833 1.15002 7.22933 1.28336 7.488 1.55002C7.746 1.81669 7.875 2.13336 7.875 2.50002V3.07502H16.125V2.50002C16.125 2.13336 16.2543 1.81669 16.513 1.55002C16.771 1.28336 17.0917 1.15002 17.475 1.15002C17.8417 1.15002 18.1543 1.28336 18.413 1.55002C18.671 1.81669 18.8 2.13336 18.8 2.50002V3.07502H19.05C19.8333 3.07502 20.5 3.35002 21.05 3.90002C21.6 4.45002 21.875 5.11669 21.875 5.90002V12.675H19.05V9.57502H4.95V20.025H12V22.85H4.95ZM4.95 7.02502H19.05V5.90002H4.95V7.02502ZM22.825 18.125L20.125 15.425L20.85 14.7C21.1167 14.4334 21.4457 14.3 21.837 14.3C22.229 14.3 22.5583 14.4334 22.825 14.7L23.55 15.425C23.8167 15.7084 23.95 16.046 23.95 16.438C23.95 16.8294 23.8167 17.15 23.55 17.4L22.825 18.125ZM14 23.575V22.15C14 21.95 14.0333 21.771 14.1 21.613C14.1667 21.4544 14.2667 21.3 14.4 21.15L19.425 16.125L22.125 18.825L17.1 23.85C16.9667 24 16.8167 24.1044 16.65 24.163C16.4833 24.221 16.3083 24.25 16.125 24.25H14.7C14.5 24.25 14.3333 24.1874 14.2 24.062C14.0667 23.9374 14 23.775 14 23.575Z'
								fill='url(#paint0_linear_2565_95419)'
							/>
						</g>
						<defs>
							<linearGradient
								id='paint0_linear_2565_95419'
								x1='3.0098'
								y1='1.15003'
								x2='28.844'
								y2='2.68393'
								gradientUnits='userSpaceOnUse'
							>
								<stop stop-color='#FFAC23' />
								<stop offset='1' stop-color='#FF5334' />
							</linearGradient>
						</defs>
					</svg>

					<span>Cambiar Fechas</span>
				</h1>
				<p>Define nuevas fechas para el Catálogo</p>

				<div style={{ marginTop: 20 }}>
					<div className='input_container'>
						<div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Sell In</label>
							<CatálogoDropdownDate
								head='Selecciona una fecha'
								setDateList={setDateList}
								setInit={promotion.start_sellin}
								setEnd={promotion.end_sellin}
								element_id='init_sellin_date'
								setStartHtml={setStartSellin}
								setEndHtml={setEndSellin}
								setValue1={setStartSellinFormat}
								setValue2={setEndSellinFormat}
							/>
							<input
								type='hidden'
								id='init_sellin_date'
								defaultValue={startSellinFormat}
							/>
							<input
								type='hidden'
								id='end_sellin_date'
								defaultValue={endSellinFormat}
							/>
						</div>

						<div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Sell Out</label>
							<CatálogoDropdownDate
								head='Selecciona una fecha'
								setDateList={setDateList}
								setInit={promotion.start_sellout}
								setEnd={promotion.end_sellout}
								element_id='init_sellout_date'
								setStartHtml={setStartSellout}
								setEndHtml={setEndSellout}
								setValue1={setStartSelloutFormat}
								setValue2={setEndSelloutFormat}
							/>
							<input
								type='hidden'
								id='init_sellout_date'
								defaultValue={startSelloutFormat}
							/>
							<input
								type='hidden'
								id='end_sellout_date'
								defaultValue={endSelloutFormat}
							/>
						</div>

						<div className='input_wrapper'>
							<label htmlFor=''>Mes</label>
							<CatálogoDropdown
								head='Selecciona un mes'
								list={dateList}
								element_id='month_date'
								setContenedor={setMesTexto}
								defaultValue={mesTexto}
							/>
							<input type='hidden' id='month_date' />
						</div>
					</div>
				</div>

				<div className='count_area'>
					<div className='box'>
						<h1>Sell-In</h1>
						<p>
							{moment(startSellin).format('DD/MMM/YYYY').replace(/\./g, '')} al{' '}
							<br />{' '}
							{moment(endSellin).format('DD/MMM/YYYY').replace(/\./g, '')}
						</p>
					</div>
					<div className='box'>
						<h1>Sell-Out</h1>
						<p>
							{moment(startSellout).format('DD/MMM/YYYY').replace(/\./g, '')} al{' '}
							<br />{' '}
							{moment(endSellout).format('DD/MMM/YYYY').replace(/\./g, '')}
						</p>
					</div>
					<div className='box'>
						<h1>Mes</h1>
						<p>{mesTexto}</p>
					</div>
				</div>

				<div className='bottom_buttton'>
					<button onClick={(e) => setcombiar(false)}>Cancelar</button>
					<button onClick={(e) => updateDatePromotion()}>Aceptar</button>
				</div>
			</div>
		</div>
	)
};
