import React, { useState, useEffect, useCallback } from 'react'
import Table from 'react-bootstrap/Table'
import Flecha from '../assets/img/Flecha_green.svg'
import Off from '../assets/img/Off.svg'
import orange_down from '../assets/img/orange_down.svg'
import filter_new from '../assets/img/filter_new.svg'
import dropdown from '../assets/img/dropdown.svg'
import forward_arrow from '../assets/img/forward_arrow.svg'
import { PromotionalVariable } from './PromotionalVariable'
import { VienesasRowGray } from './VienesasRowGray'
import { formatNumberES, redondearDecena, formatMoney, config } from '../config'
import '../assets/css/tablaProducto.css'
import { sortData, parseNumber, formatNumber } from '../utils/sortData'
import { sub } from 'date-fns'

export const VienesasTableNew = ({
	setCodigoPop,
	data,
	subcategory,
	setData,
	category,
	dataInit,
	setDataProduct,
	setloader,
	setChangeInput
}) => {
	const [subfamilyFilter, setSubfamilyFilter] = useState('Todas')
	const [brandFilter, setBrandFilter] = useState('Todas')
	const [SecondDropdown, setSecondDropdown] = useState(false)
	const [FirstDropdown, setFirstDropdown] = useState(false)
	const [PromotionalVariableState, setPromotionalVariable] = useState(false)
	const [dataPromotionals, setDataPromotionals] = useState(null)
	const [sortKey, setSortKey] = useState()
	const [sortOrder, setSortOrder] = useState('asc')
	const [brand, setBrand] = useState('')
	const [subcategories, setSubcategories] = useState('')
	const [reverse, setReverse] = useState(false) // false is asc, true is desc

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const sortedData = useCallback(
		sortData({
			tableData: data.data_rows,
			sortKey,
			reverse: reverse,
		}),
		[data, sortKey, reverse]
	)

	useEffect(() => {
		console.log("sortKey actualizado...")
		console.log(sortKey)
	}, [sortKey])
/*
	const filterTable = useCallback(() => {
		/*
		if (subfamilyFilter === 'Todas' && brandFilter === 'Todas') {
			return sortedData
		}
		if (brandFilter === 'Todas') {
			return sortedData
		}
		return sortedData.filter((item) => item.brand === brandFilter)
		*//*
		return sortedData
	}, [sortedData]
	// [subfamilyFilter, brandFilter, sortedData]
	)
	*/

	const changeSort = (key) => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		setSortKey(key)
		setReverse(!reverse)
	}

	const runChangePVPAsync = async (dataJSON) => {
		console.log("DATA CHANGE UPDATE PVP GENERAL!!!!!!::::::::::::::::");
		console.log(dataJSON);
		const session_token = localStorage.getItem('session')
		const response = await fetch(config.apiUrl + `/catalog/update_pvp`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
			method: 'POST',
			body: JSON.stringify(dataJSON),
		})
		let responseJSON = await response.json()
		responseJSON = JSON.stringify(responseJSON);
  		responseJSON = JSON.parse(responseJSON);
		/*
		responseJSON.data_rows.map((EachRow) => {
			
			
			document.getElementById('pvp_sug_' + EachRow.id).innerHTML =
				'$' + EachRow.pvp_sug_formated//formatNumberES(new_pvp_sug)
			
			
		})*/
		console.log("DATA CHANGE UPDATE PVP!!!!!!::::::::::::::::");
		console.log(responseJSON);
		setData({...responseJSON})
		setloader(false)
		//document.getElementById('mensaje_simulador').style.display = 'none'
	}

	const changeMg = (event) => {
		if (event.key === 'Enter') {
			setloader(true)
			document.getElementById('mg_input_general').value = parseFloat(document.getElementById('mg_input_general').value).toFixed(1);
			const newData = { ...data };
			newData.data_rows.map((EachRow) => {
				if (document.getElementById('mg_input_general').value < 0) {
					alert('No deben haber valores negativos')
					document.getElementById('mg_input_general').value = 0.0
					return false
				}
				try{
					let mg_cad_ = parseFloat(
						document.getElementById('mg_input_general').value
					).toFixed(1)
					EachRow.mg_cad = parseFloat(
						document.getElementById('mg_input_general').value
					).toFixed(1)
					document.getElementById('mg_input_row_' + EachRow.id).value =
						parseFloat(EachRow.mg_cad).toFixed(1)
					let precio_prop = parseFloat(
						document
							.getElementById('precio_prop_' + EachRow.id)
							.value.replace('.', '')
							.replace(',', '.')
					)
					/*let new_pvp_sug = parseFloat(
						(precio_prop / (1 - mg_cad_ / 100)) * 1.19
					).toFixed(0)
					document.getElementById('pvp_sug_' + EachRow.id).innerHTML =
						'$' + formatNumberES(redondearDecena(new_pvp_sug))
					EachRow.pvp_sug = new_pvp_sug*/
					EachRow.ever_simulated = 1
					EachRow.prop_price_modified = 1
				}catch(e){
					console.log("campo no encontrado "+e);
				}
			})
			setData(newData)
			runChangePVPAsync(newData);
			//document.getElementById("mg_input_general").value = parseFloat(document.getElementById("mg_input_general").value).toFixed(1);
		}
	}

	const handleOptionChangeBrand = (event) => {
		setBrandFilter(event.target.value)
		setSecondDropdown(false)
	}

	const handleOptionChangeSubfamily = (event) => {
		setSubfamilyFilter(event.target.value)
		setFirstDropdown(false)
	}

	useEffect(() => {
		const brands = data?.data_rows?.map((row) => row.brand)
		setBrand([...new Set(brands)])

		const subfamilyAux = data?.data_rows?.map((row) => row.subfamily)
		setSubcategories([...new Set(subfamilyAux)])
	}, [])

	/* //Esto sirve para separar en activado y no activado separado por una línea
  let rows_activate = dataInit.data_rows.filter(function(element){
    return ((1 - (element.precio_prop/element.precio_ba)) * 100) > 0;
  });

  let rows_not_activate = dataInit.data_rows.filter(function(element){
    return ((1 - (element.precio_prop/element.precio_ba)) * 100) <= 0;
  });
  */

	const avarageCompare = (firstColumn, secondColumn) => {
		const total_rows = data.data_rows.length
		let sum_first_column = 0
		data.data_rows.map((row) => {
			sum_first_column =
				sum_first_column +
				parseFloat(
					String(row[firstColumn])
						.split(' ')[0]
						.split('%')[0]
						.replace('.', '')
						.replace(',', '.')
				)
		})
		let avg_first_column = sum_first_column / total_rows

		let sum_second_column = 0
		data.data_rows.map((row) => {
			sum_second_column =
				sum_second_column +
				parseFloat(
					String(row[secondColumn])
						.split(' ')[0]
						.split('%')[0]
						.replace('.', '')
						.replace(',', '.')
				)
		})
		let avg_second_column = sum_second_column / total_rows
		//alert(firstColumn+" & "+secondColumn+" : "+avg_first_column +">="+ avg_second_column);
		return avg_first_column >= avg_second_column
	}
	return (
		<div className='CataLogoResumenTable VienesasTable'>
			{PromotionalVariableState && (
				<PromotionalVariable
					setPromotionalVariable={setPromotionalVariable}
					data={data}
					setData={setData}
					dataPromotionals={dataPromotionals}
				/>
			)}

			<Table id="tablaDescarga">
				<thead>
					<tr>
						<th className='fimila fimiladropdown ' rowSpan={2}>
							<div className='dropdown dropdown_new'>
								<div
									className='head'
									onClick={(e) => {
										setFirstDropdown(!FirstDropdown)
										setSecondDropdown(false)
									}}
									// style={{ backgroundColor: '#EFEFEF' }}
								>
									<img src={filter_new} alt='' />
									<p>{subfamilyFilter}</p>
									<img src={dropdown} alt='' />
								</div>
								 {FirstDropdown && (
									<ul>
										<li>
											<input
												type='radio'
												name='TodasSubfamilia'
												id='TodasSubfamilia'
												value={'Todas'}
												checked={subfamilyFilter === 'Todas'}
												onChange={handleOptionChangeSubfamily}
											/>
											<label htmlFor='TodasSubfamilia'></label>
											<p>Todas</p>
										</li>
									{subcategories.map((subcategoryFF) => (
										<li>
											<input
												type='radio'
												name={subcategoryFF}
												id={subcategoryFF}
												value={subcategoryFF}
												checked={subfamilyFilter === subcategoryFF}
												onChange={handleOptionChangeSubfamily}
											/>
											<label htmlFor={subcategoryFF}></label>
											<p>{subcategoryFF}</p>
										</li>
									))}
									</ul>
								)} 
							</div>
						</th>
						<th className='fimila border_head_gray fimiladropdown' rowSpan={2}>
							<div
								className='dropdown dropdown_new'
								style={{ paddingLeft: 5, paddingRight: 5 }}
							>
								<div
									className='head'
									onClick={(e) => {
										setSecondDropdown(!SecondDropdown)
										setFirstDropdown(false)
									}}
									// style={{ backgroundColor: '#EFEFEF' }}
								>
									<img src={filter_new} alt='' />
									<p>{brandFilter}</p>
									<img src={dropdown} alt='' />
								</div>
								 {SecondDropdown && (
									<ul>
										<li>
											<input
												type='radio'
												name='second_filter'
												id='Todas'
												value={'Todas'}
												checked={brandFilter === 'Todas'}
												onChange={handleOptionChangeBrand}
											/>
											<label htmlFor='Todas'></label>
											<p>Todas</p>
										</li>
										{brand.map((brand) => (
											<li>
												<input
													type='radio'
													name={brand}
													id={brand}
													value={brand}
													checked={brandFilter === brand}
													onChange={handleOptionChangeBrand}
												/>
												<label htmlFor={brand}></label>
												<p>{brand}</p>
											</li>
										))}
									</ul>
								)} 
							</div>
						</th>
						{/*
            <th className="fimila border_head_gray" rowSpan={2}>
              <div className="dropdown">
                <div className="head">
                  <img src={filter_new} alt="" />
                  <p>Todas</p>
                  <img src={dropdown} alt="" />
                </div>
              </div>
            </th> */}
						<th colSpan={3} className='border_head_gray'>
							{avarageCompare('vol_prop', 'vol_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Volumen</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Volumen</p>
								</div>
							)}
						</th>
						<th colSpan={3} className='border_head_gray'>
							{avarageCompare('precio_prop', 'precio_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Precio</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Precio</p>
								</div>
							)}
						</th>
						<th colSpan={3} className='border_head_gray'>
							{avarageCompare('res_mm_prop', 'res_mm_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Resultado MM</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Resultado MM</p>
								</div>
							)}
						</th>

						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex blue'>
								<p>Beneficio</p>
							</div>
						</th>

						<th>
							<div className='display_flex blue'>
								<p>Mg.Cad.</p>
							</div>
						</th>
					</tr>

					<tr className='bottom_row_head'>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p>{data.data_header.vol_act}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt.</h1>
								<p>{data.data_header.vol_opt}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop.</h1>
								<p>{data.data_header.vol_prop}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p>{formatMoney(data.data_header.precio_act)}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt</h1>

								<p>{formatMoney(data.data_header.precio_opt)}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop.</h1>
								<p>{formatMoney(data.data_header.precio_prop)}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p>${(data.data_header.ro_act_mm)}</p>
								{/*
                <p className="orange">$-5,2</p>
                */}
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt</h1>
								<p>${(data.data_header.ro_opt_mm)}</p>
								{/*
                <p className="orange">$-6,0</p>
                */}
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop.</h1>
								<p>${(data.data_header.ro_prop_mm)}</p>
								{/*
                <p className="orange">$-2,5</p>
                */}
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop. - Act.</h1>
								<p>{formatMoney(data.data_header.ben_prop_act)}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Prop. - Opt.</h1>
								<p>{formatMoney(data.data_header.ben_prop_opt)}</p>
							</div>
						</th>
						<th></th>
						<th style={{ width: '60px' }}>
							<div
								className='head_box wrap_input'
								style={{ transform: 'translateX(5px)' }}
							>
								<input
									type='number'
									id='mg_input_general'
									onKeyDown={changeMg}
									onChange={(e) => {
										e.preventDefault()
										setChangeInput(true)
									}}
								/>
								<p>%</p>
							</div>
						</th>
					</tr>
				</thead>
			</Table>
			<div style={{}}>
				<Table id="tablaDescargaFinal"//className="table_product_final"
				>
					<thead style={{ background: '#E8E8E8', height: 30 }}>
						<tr>
							<td>
								<div className='display_flex gray'>
									<p>Rec.</p>
									<img
										
										src={Off}
										alt=''
										onClick={() => changeSort('recommended')}
										style={{ cursor: 'pointer' }}
									/>
								</div>
							</td>
							<td>
								<div className='display_flex  gray'>
									<p>Código</p>
									{/* <img src={Off} alt="" /> */}
								</div>
							</td>
							<td>
								<div className='display_flex  center_justify gray'>
									<p>Descripción</p>
								</div>
							</td>
							<td>
								<div className='display_flex gray'>
									<p>SubFamilia</p>
								</div>
							</td>
							<td>
								<div className='display_flex gray'>
									<p>Marca</p>
								</div>
							</td>
							<td>
								<div className='display_flex  gray'>
									<p>Vol. Act.</p>
									<img
										style={{ cursor: 'pointer' }}
										src={Off}
										alt=''
										onClick={() => changeSort('__vol_act')}
									/>
								</div>
							</td>
							<td>
								<div className='display_flex  center_justify gray'>
									<p>Vol. Opt.</p>
									<img
										style={{ cursor: 'pointer' }}
										src={Off}
										alt=''
										onClick={() => changeSort('__vol_opt')}
									/>
								</div>
							</td>
							<td>
								<div className='display_flex  center_justify gray'>
									<p>Vol. Prop.</p>
								</div>
							</td>

							<td>
								<div className='display_flex gray'>
									<p>Var. Vol.</p>
								</div>
							</td>
							<td>
								<div className='display_flex  gray'>
									<p>Pre Ba.</p>
								</div>
							</td>
							<td>
								<div className='display_flex  center_justify gray'>
									<p>Pre Act.</p>
									<img
										style={{ cursor: 'pointer' }}
										src={Off}
										alt=''
										onClick={() => changeSort('precio_act')}
									/>
								</div>
							</td>
							<td>
								<div className='display_flex  center_justify gray'>
									<p>Pre Opt.</p>
									<img
										style={{ cursor: 'pointer' }}
										src={Off}
										alt=''
										onClick={() => changeSort('precio_opt')}
									/>
								</div>
							</td>

							<td>
								<div className='display_flex gray'>
									<p>Pre Prop.</p>
								</div>
							</td>
							<td>
								<div className='display_flex  gray'>
									<p>%</p>
								</div>
							</td>

							<td>
								<div className='display_flex  center_justify gray'>
									<p>Var. $ Opt/Act.</p>
								</div>
							</td>

							<td>
								<div className='display_flex gray'>
									<p>Var. $ Pr./Act.</p>
								</div>
							</td>
							<td>
								<div className='display_flex  gray'>
									<p>RO % Prop.</p>
								</div>
							</td>

							<td>
								<div className='display_flex gray'>
									<p>Mg. Cad.</p>
								</div>
							</td>
							<td>
								<div className='display_flex  gray'>
									<p>PVP Sug.</p>
								</div>
							</td>
							<td>
								<div className='display_flex  gray'>
									<p>Var.Pro</p>
								</div>
							</td>
						</tr>
					</thead>
					<tbody
						className='img_saperate_wrapper '
						style={{ position: 'relative !important' }}
					>
						{ // (data || sortKey) &&
						sortedData &&
						sortedData.map((EachRowProduct) => {
							// data.data_rows.map((EachRowProduct) => {
								if(brandFilter !== 'Todas'){
									if(EachRowProduct.brand !== brandFilter){
										return null
									}
								}
								if(subfamilyFilter !== 'Todas'){
									if(EachRowProduct.subfamily !== subfamilyFilter){
										return null
									}
								}
								return <VienesasRowGray
									key={EachRowProduct.id} 
									setCodigoPop={setCodigoPop}
									setPromotionalVariable={setPromotionalVariable}
									product={EachRowProduct}
									subcategory={subcategory}
									data={data}
									setData={setData}
									class_css={'VienesasGray'}
									category={category}
									setDataPromotionals={setDataPromotionals}
									setDataProduct={setDataProduct}
									setChangeInput={setChangeInput}
								/>
							})}
					</tbody>
				</Table>
			</div>
		</div>
	)
}
