import React, { useState, useEffect } from "react";
import { Navbar } from "../Navbar";
import "../../assets/css/ResumenDeCataloge.css";
import arrow_left from "../../assets/img/arrow_left.svg";
import { CataLogoResumenFooter } from "../CataLogoResumenFooter";
import { Link, useParams } from "react-router-dom";
import { NombreTable } from "./NombreTable";
import Icono from "../../assets/img/Icono.svg";
import reset from "../../assets/img/reset.svg";
import time from "../../assets/img/time.svg";
import AvatarPop from "../../assets/img/AvatarPop.svg";
import Avatargirl from "../../assets/img/Avatargirl.svg";
import { Usuario } from "../Usuario";
import visibility from "../../assets/img/visibility.svg";
import Table from "react-bootstrap/Table";
import { TopNavigator } from "../TopNavigator";
import { CombiarPopup } from "../CombiarPopup";
import { RenombrarPopup } from "../RenombrarPopup";
import {MoveCataLog} from "../MoveCataLog";
import {Loader} from "../Loader";
import { config } from "../../config";
import {OfertaFooter} from './OfertaFooter'

function getComments(promotion_id){
  const session_token = localStorage.getItem('session');
  //console.log(session_token);
  //if there is a session token stored
  if (session_token){
      return fetch(config.apiUrlOferta + `/offer/activity/`+promotion_id, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        //console.log(response)
        return response;
      });
  }
  return {
    //return empty json
  }

}

function getListOffer(id_oferta) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrlOferta + `/offer/products/`+id_oferta, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

function getPromotions(){
  const session_token = localStorage.getItem('session');
  //console.log(session_token);
  //if there is a session token stored
  if (session_token){
      return fetch(config.apiUrlOferta + `/offer/listall`, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        //console.log(response)
        return response;
      });
    

  }

  //there are no session token stored
  return {

  }

}

export const ResumenDeCatalogeOferta = () => {
  const [TextareaFocus, setTextareaFocus] = useState(false);
  const [disableCheck, setdisableCheck] = useState(true);
  const[promotion, setPromotion] = useState(null);
  const [loader, setloader] = useState(true);
  const { id_promocion} = useParams();
  const [data, setData] = useState(null);
  const [CatalogMove, setCatalogMove] = useState(false);
  const [combiar, setcombiar] = useState(false);
  const [Renombrar, setRenombrar] = useState(false);
  const [dataComments, setDataComments] = useState(false);
  const [flag, setFlag] = useState(false);
  const [nombreCliente, setNombreCliente] = useState("");
  const [nombrePromocion, setNombrePromocion] = useState("");
	const [offers, setOffers] = useState(null)
  const [actualizar, setActualizar] = useState(false)
  const [users, setUsers] = useState(null)

  const getUniqueUsers = (comments) => {
    // Filtrar comentarios que contienen "@"
    const filteredComments = comments.filter(comment => comment.comment_text.includes("@"));
    
    // Ordenar comentarios por id_comment en orden descendente
    const sortedComments = filteredComments.sort((a, b) => b.id_comment - a.id_comment);
    
    // Extraer usuarios únicos
    const uniqueUsers = [];
    const seenUsers = new Set();
    
    sortedComments.forEach(comment => {
      const fullName = `${comment.name_user} ${comment.last_name_user}`;
      if (!seenUsers.has(fullName)) {
        seenUsers.add(fullName);
        uniqueUsers.push(fullName);
      }
    });
  
    return uniqueUsers;
  }

  const CallbackOffer = async (promotion_id) => {
		const comment = document.getElementById('input_comment').value
		//alert(comment)

		const session_token = localStorage.getItem('session')
		if (session_token) {
			const response = await fetch(config.apiUrlOferta + `/comment/create`, {
				//mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify(
					{
						promotion_id: promotion_id,
						comment_text: comment					},
				),
			})
			const responseJSON = await response.json()
			document.getElementById('input_comment').value = ''
			// setDataComments(dataComments.concat(responseJSON))
			document.getElementById('input_comment').disabled = false
			setFlag(true)
		}
	}

  useEffect(() => {
		getListOffer(id_promocion).then((response) => {
			console.log("response:::: list products::: ")
			console.log(response)
			setOffers(response)
      setPromotion(response)
			// setProducts(response)
			setloader(false)
		})
		setActualizar(false)
	}, [actualizar])

  useEffect(() => {
    getComments(id_promocion).then(result =>{
      console.log("##########comentarios:::: ");
      console.log(result);
      setDataComments(result); 
      setUsers(getUniqueUsers(result));
      setFlag(false);  
    });
  }, [flag]);

  const getStrMonth3 = (month_str) => {
    const montsh_obj = {
      "Jan": "01",
      "Feb": "02",
      "Mar": "03",
      "Apr": "04",
      "May": "05",
      "Jun": "06",
      "Jul": "07",
      "Aug": "08",
      "Sep": "09",
      "Oct": "10",
      "Nov": "11",
      "Dec": "12"
    };
    return montsh_obj[month_str];
  }



  const onEnter = (event, promotion_id) => {if (event.key === 'Enter') {
    document.getElementById("input_comment").disabled = true;
    CallbackOffer(promotion_id);
  }};

  return (
    <div className="CataLogoResumen ResumenDeCatalogeNew">
      {(combiar && promotion) && <CombiarPopup setcombiar={setcombiar} promotion={promotion}/>}
      {Renombrar && <RenombrarPopup setRenombrar={setRenombrar} 
      data={data} setData={setData}/>}
      {CatalogMove && promotion && <MoveCataLog setActive={setCatalogMove} promotion={promotion}/>}
      { /* !promotion ? '' : <TopNavigator data={promotion} dataPromotion={data}/> */ }
      
      {loader && <Loader message={"Cargando..."}/>}
      <Navbar no_need_search />
      <div className="top_area">
        <div className="left_side">
          {id_promocion &&
          <>
          <Link  className='active' >Resumen Oferta</Link>
					<Link to={'/SeguimientoOferta/'+id_promocion}>Seguimiento Oferta</Link>
					<Link to={'/IngresoProduct/'+id_promocion} >
						Ingreso Productos
					</Link>
          </>
          }
        </div>
     
      </div>
      <div className="resumen_box_shadow_wrapper">
        {promotion && <NombreTable data={data} 
        promotion={promotion}/>
        }

        <div className="grid_area_resumen_de_cataloge">
          <div className="chat_area">
            <div className="head">
              <img src={Icono} alt="" />
              <p>Usuarios</p>
            </div>
            {users && users.map((user) => (
              <Usuario user={user}/>
            ))}
            
            {/*S
            <Usuario />
            <Usuario />
            <Usuario />
            <Usuario />
            */}
          </div>

          <div className="chat_area profile_Area_resumen">
            <div className="head">
              <img src={reset} alt="" />
              <p>Actividad</p>
            </div>

            <div className="profile_box_wrapper profile_box_wrapperNew">
              <div className="profile_box" style={{ alignItems: "flex-start" }}>
                <img src={Avatargirl} alt="" />
                <div className="presentation" style={{ position: "relative" }}>
                  <textarea
                    cols="30"
                    rows="10"
                    className={`${TextareaFocus && "messageType"}`}
                    placeholder="Escribe un comentario..."
                    onChange={(e) => {
                      if (e.target.value != "") {
                        setdisableCheck(false);
                      } else {
                        setdisableCheck(true);
                      }
                    }}
                    onFocus={(e) => {
                      setTextareaFocus(true);
                    }}
                    onBlur={(e) => {
                      setTextareaFocus(false);
                    }}
                    onKeyPress={e => onEnter(e, id_promocion)} 
                    id="input_comment" autocomplete="off"
                  ></textarea>
                  {/*<button className="comment" disabled={disableCheck} 
                    onClick={(e) => {Callback()}}>
                    Comentar
                  </button>*/}
                </div>
              </div>

              <div id="section_comments" className="commentsMax" style={{height: "100vh"}}>
      
          {!dataComments ? 'Cargando Comentarios ...' : dataComments.sort((a,b) => {return b.id_comment - a.id_comment}).map((EachRow) => (
              EachRow.comment_text.includes("@") ? (
              <div className="profile_box" 
              id={"box_comment_"+EachRow.id_comment}
              style={{marginBottom: "10px"}}>
                  <img src={AvatarPop} alt="" />
                  <div className="presentation">
                    <div className="top_area">
                      <h1>{EachRow.name_user} {EachRow.last_name_user}</h1>
                      <p>{EachRow.comment_text} <span>{EachRow.promotional_state.state_phase_str}</span></p>
                               
                    </div>
                    <p >
                    {EachRow.date_created}
                {/*<span
                        onClick={(e) => {
                          deleteComment(EachRow.id_comment)
                        }}
                        style={{marginRight:"0px"}}
                      >Eliminar</span>
                      */}
                      </p>
                    
                  </div>
                  
              </div>
              ) :
              <div className="profile_box responder">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>{EachRow.name_user} {EachRow.last_name_user}</h1>
                  <p>
                  {EachRow.date_created}
                  </p>
                </div>
                <input type="text" placeholder={EachRow.comment_text} value={EachRow.comment_text}/>
              </div>
            </div>
            
          ))}
              </div>
            </div>
          </div>

          <div className="chat_area table_third_resumen">
            <div className="head">
             { /*} <img src={time} alt="" />
              <p>Versiones</p> */ }
            </div>
          {/*
            <Table>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Hora</th>
                  <th>Fase</th>
                  <th>Estado</th>
                  <th>Usuario</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          */}
          </div>
        </div>
      </div>
      { offers && (
			<OfertaFooter 
				idOferta = {id_promocion}
				offers = {offers}
				setActualizar = {setActualizar}
				sinSimular = {null}
				setExecuteSimulation = {null}
			/>
			) }
    </div>
  );
};
