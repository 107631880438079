import React, { useState, useEffect, useRef } from 'react'
import Recomendacion from '../assets/img/Recomendacion.svg'
import orange_down from '../assets/img/orange_down.svg'
import No from '../assets/img/No.svg'
import tag from '../assets/img/tag.svg'
import Si from '../assets/img/Si.svg'
import recomendacion_alza from '../assets/img/recomendacion_alza.svg'
import { ToolTipCustom } from './ToolTipCustom'
import { ToolTipCustomList } from './ToolTipCustomList'
import {
	config,
	formatNumberES,
	validateNumber,
	redondearDecena,
} from '../config'
import { Loader } from './Loader'
import Flecha from '../assets/img/Flecha_green.svg'
import '../assets/css/modernoSimulacion.css'
import { Tooltip } from '@mui/material'
import '../assets/css/tooltip.css'
import { set } from 'date-fns'

function capitalize(string) {
	return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

const CapitalizeFirstLetter = (str) => {
	const arr = str.toLowerCase().split(' ')
	for (var i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
	}
	const str2 = arr.join(' ')
	return str2
}

export const runSimulatorAsyncAnyPlace = async (
	dataJSON,
	setData,
	setloader
) => {
	const session_token = localStorage.getItem('session')
	const response = await fetch(config.apiUrl + `/simulator/simulate`, {
		//mode: 'no-cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + session_token,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': true,
		},
		method: 'POST',
		body: JSON.stringify(dataJSON),
	})
	const responseJSON = await response.json()
	responseJSON.cadena_summary.ben_opt = formatNumberES(
		responseJSON.cadena_summary.ben_opt,
		1
	)
	responseJSON.cadena_summary.ben_prop = formatNumberES(
		responseJSON.cadena_summary.ben_prop,
		1
	)
	responseJSON.cadena_summary.res_op_prop = formatNumberES(
		responseJSON.cadena_summary.res_op_prop
	)

	responseJSON.data_rows.map((EachRow) => {
		let descuento_calculado = parseFloat(
			(1 - EachRow.precio_prop / EachRow.precio_ba) * 100
		).toFixed(1)
		/*
		let new_pvp_sug = parseFloat(
			(EachRow.precio_prop / (1 - EachRow.mg_cad / 100)) * 1.19
		).toFixed(0) */
		
		/* document.getElementById('precio_prop_' + EachRow.id).defaultValue =
			formatNumberES(EachRow.precio_prop)
		document.getElementById('precio_prop_' + EachRow.id).value = formatNumberES(
			EachRow.precio_prop
		)
		document.getElementById('pvp_sug_' + EachRow.id).innerHTML =
			'$' + formatNumberES(new_pvp_sug)
		document.getElementById('desc_price_' + EachRow.id).value =
			descuento_calculado
			*/
		//Crear campos formated
		EachRow.precio_prop_formated = formatNumberES(EachRow.precio_prop)
		// EachRow.pvp_sug_formated = formatNumberES(new_pvp_sug)
		// EachRow.pvp_sug = new_pvp_sug
		EachRow.descuento_calculado = descuento_calculado
		
		if (descuento_calculado > 0) {
			try{
				document
				.getElementById('row_tr_' + EachRow.id)
				.classList.add('VienesasRowActive')
			}catch(e){
				console.log("Validación de fila no existe:: "+e);
			}
			
		}
	})
	const elementos = document.querySelectorAll('.onEditS')
	elementos.forEach((elemento) => {
		elemento.classList.remove('onEditS')
	})
	setData(responseJSON)
	setloader(false)
	document.getElementById('mensaje_simulador').style.display = 'none'
}

export const getSimulationByPriceAnyPlace = (
	data,
	category,
	subcategory,
	setData,
	setloader
) => {
	setloader(true)
	try {
		delete data['data_promotion']
	} catch (error) {
		console.log('Aún no existe data promotion')
	}

	data = {
		...data,
		data_promotion: {
			id: data.cadena_details.promotion_id,
			category: category,
			subcategory: subcategory,
		},
	}
	//Cambiar lógica, si es que el prop_price_modified = 1 entonces se ejecuta lo siguiente
	//Se debe recorrer todo el arreglo.

	data.cadena_summary.ben_opt = parseFloat(
		data.cadena_summary.ben_opt.replace('.', '').replace(',', '.')
	)
	data.cadena_summary.ben_prop = parseFloat(
		data.cadena_summary.ben_prop.replace('.', '').replace(',', '.')
	)
	data.cadena_summary.res_op_prop = parseFloat(
		data.cadena_summary.res_op_prop.replace('.', '').replace(',', '.')
	)
	data.data_rows.map((EachRow) => {
		if (EachRow.prop_price_modified === 1) {
			try{
				let precio_prop = parseFloat(
				
					document
						.getElementById('precio_prop_' + EachRow.id)
						.value.replace('.', '')
						.replace(',', '.')
					
					// preProp.replace('.', '').replace(',', '.')
				)
				let mg_cad = parseFloat(
					// mgCadInput
					document.getElementById('mg_input_row_' + EachRow.id).value
				)
				/*
				let new_pvp_sug = parseFloat(
					(precio_prop / (1 - mg_cad / 100)) * 1.19
				).toFixed(0) */
				EachRow.precio_prop = precio_prop
				EachRow.descuento_calculado = parseFloat(
					1 - precio_prop / EachRow.precio_ba
				).toFixed(1)
				// EachRow.pvp_sug = new_pvp_sug
				EachRow.prop_price_modified = 1
				EachRow.on_edit = 0
				EachRow.mg_cad = parseFloat(mg_cad).toFixed(1)
				EachRow.mg_cad_formated = formatNumberES(mg_cad)
				if(EachRow.descuento_calculado > 0){
					document
					.getElementById('row_tr_' + EachRow.id)
					.classList.add('VienesasRowActive')
				}else{
					document
					.getElementById('row_tr_' + EachRow.id)
					.classList.remove('VienesasRowActive')
				}
				
			}catch(e){
				console.log("error capturado simulacion, columna no existe:: "+e);
			}
			
		}
	})
	runSimulatorAsyncAnyPlace(data, setData, setloader)
}

export const VienesasRowGray = ({
	setCodigoPop,
	setPromotionalVariable,
	product,
	subcategory,
	data,
	setData,
	class_css,
	category,
	setDataPromotionals,
	setDataProduct,
	setChangeInput
}) => {
	const [MouseDownState, setMouseDownState] = useState(null)
	const [loader, setloader] = useState(false)
	const [loaderPVP, setloaderPVP] = useState(false)
	// const [mgCad, setMgCad] = useState('')
	const [preProp, setPreProp] = useState('')
	const [descInput, setDescInput] = useState('')
	const [preBa, setPreBa] = useState('')
	const [mgCadInput, setMgCadInput] = useState('')
	const [pvpInput, setPvpInput] = useState('')
	const [estadoActivo, setEstadoActivo] = useState(false)
	const inputRef = React.useRef()

	const prevDescInput = useRef(""); // Usamos useRef en lugar de useState


	useEffect(() => {
		const descuento_calculado = parseFloat(descInput).toFixed(1)
		// alert(descInput)
		if(descuento_calculado > 0){
			setEstadoActivo(true)
			document.getElementById('row_tr_' + product.id).classList.add('VienesasRowActive')
		}else{
			// alert(product.code_product+" actualizado")
			setEstadoActivo(false)
			document.getElementById('row_tr_' + product.id).classList.remove('VienesasRowActive')
		}
	}, [descInput])

	useEffect(() => {
		const precioPropuesto = parseInt(preProp.replace(".","").replace(",","."))
		// alert(precioPropuesto+" < "+product.precio_ba) 
		if(precioPropuesto < product.precio_ba){
			setEstadoActivo(true)
			document.getElementById('row_tr_' + product.id).classList.add('VienesasRowActive')
		}else{
			setEstadoActivo(false)
			document.getElementById('row_tr_' + product.id).classList.remove('VienesasRowActive')
		}
	}, [preProp])

	const handleMgCadChange = (event) => {
		//setMgCad(parseFloat(event.target.value).toFixed(1))
		console.log("pass");
	}

	const handleKeyDownMgCad = (event) => {
		if (event.key === 'Enter') {
			if (
				mgCadInput < 0
				// document.getElementById('mg_input_row_' + product.id).value < 0
			) {
				alert('No deben haber valores negativos')
				// document.getElementById('mg_input_row_' + product.id).value = 0
				// return false
			}
			changeMgRow(product.id)
			//handleMgCadChange(event)
			event.target.value = parseFloat(event.target.value).toFixed(1)
		}
	}

	let total_promotional_values = 0
	let total_promotional_vars = 5

	if (product.promotional_values.mechanic.length > 0) {
		total_promotional_values += 1
	}
	if (product.promotional_values.activation.length > 0) {
		total_promotional_values += 1
	}
	if (product.promotional_values.adherence.length > 0) {
		total_promotional_values += 1
	}
	if (product.promotional_values.exhibition.length > 0) {
		total_promotional_values += 1
	}
	if (product.promotional_values.aditional_inv.length > 0) {
		total_promotional_values += 1
	}
	const runSimulatorAsync = async (dataJSON) => {
		const session_token = localStorage.getItem('session')
		const response = await fetch(config.apiUrl + `/simulator/simulate`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
			method: 'POST',
			body: JSON.stringify(dataJSON),
		})
		const responseJSON = await response.json()
		responseJSON.cadena_summary.ben_opt = formatNumberES(
			responseJSON.cadena_summary.ben_opt,
			1
		)
		responseJSON.cadena_summary.ben_prop = formatNumberES(
			responseJSON.cadena_summary.ben_prop,
			1
		)
		responseJSON.cadena_summary.res_op_prop = formatNumberES(
			responseJSON.cadena_summary.res_op_prop
		)

		responseJSON.data_rows.map((EachRow) => {
			let descuento_calculado = parseFloat(
				(1 - EachRow.precio_prop / EachRow.precio_ba) * 100
			).toFixed(1)
			/*
			let new_pvp_sug = parseFloat(
				(EachRow.precio_prop / (1 - EachRow.mg_cad / 100)) * 1.19
			).toFixed(0)
			*/
			// chequear
			/*document.getElementById('precio_prop_' + EachRow.id).defaultValue =
				formatNumberES(EachRow.precio_prop)
			document.getElementById('precio_prop_' + EachRow.id).value =
				formatNumberES(EachRow.precio_prop)
			document.getElementById('pvp_sug_' + EachRow.id).innerHTML =
				'$' + formatNumberES(new_pvp_sug)
			document.getElementById('desc_price_' + EachRow.id).value =
				descuento_calculado
			*/

			//Crear campos formated
			EachRow.precio_prop_formated = formatNumberES(EachRow.precio_prop)
			// EachRow.pvp_sug_formated = formatNumberES(new_pvp_sug)
			// EachRow.pvp_sug = new_pvp_sug
			EachRow.descuento_calculado = descuento_calculado

			// setPreProp(EachRow.precio_prop_formated)
			// setDescInput(descuento_calculado)

			if (descuento_calculado > 0) {
				try{
					document
					.getElementById('row_tr_' + EachRow.id)
					.classList.add('VienesasRowActive')
				}catch(e){
					console.log("Validación de fila no existe:: "+e);
				}
				
			}
		})
		const elementos = document.querySelectorAll('.onEditS')
		elementos.forEach((elemento) => {
			elemento.classList.remove('onEditS')
		})
		setData(responseJSON)
		setloader(false)
		document.getElementById('mensaje_simulador').style.display = 'none'
	}

	const runChangePVPAsync = async (dataJSON) => {
		console.log("DATA CHANGE UPDATE PVP!!!!!!::::::::::::::::");
		console.log(dataJSON);
		const session_token = localStorage.getItem('session')
		const response = await fetch(config.apiUrl + `/catalog/update_pvp`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
			method: 'POST',
			body: JSON.stringify(dataJSON),
		})
		let responseJSON = await response.json()
		responseJSON = JSON.stringify(responseJSON);
  		responseJSON = JSON.parse(responseJSON);
		

		responseJSON.data_rows.map((EachRow) => {
			
			if(EachRow.id === product.id){
				setPvpInput(EachRow.pvp_sug_formated)
				setloader(false)
			}
			/*
			document.getElementById('pvp_sug_' + EachRow.id).innerHTML =
				'$' + EachRow.pvp_sug_formated//formatNumberES(new_pvp_sug)
			*/
			
			
		})
		/*const elementos = document.querySelectorAll('.onEditS')
		elementos.forEach((elemento) => {
			elemento.classList.remove('onEditS')
		})*/
		console.log("DATA CHANGE UPDATE PVP!!!!!!::::::::::::::::");
		console.log(responseJSON);
		setData({...responseJSON})
		setloaderPVP(false)
		//document.getElementById('mensaje_simulador').style.display = 'none'
	}

	const changeMgRow = (id) => {
		setloaderPVP(true)
		let mg_cad = parseFloat(
			// document.getElementById('mg_input_row_' + id).value
			mgCadInput	
		).toFixed(1)
		let precio_prop = parseFloat(
			/*
			document
				.getElementById('precio_prop_' + id)
				.value.replace('.', '')
				.replace(',', '.')
			*/
			preProp.replace('.', '').replace(',', '.')
		)
		/*let new_pvp_sug = parseFloat(
			(precio_prop / (1 - mg_cad / 100)) * 1.19
		).toFixed(0)*/
		/*document.getElementById('pvp_sug_' + id).innerHTML =
			'$' + formatNumberES(redondearDecena(new_pvp_sug))*/
		const newData = { ...data }
		newData.data_rows.map((EachRow) => {
			if (EachRow.id === id) {
				console.log("Validacion------------Cambiar margen");
				console.log(EachRow.mg_cad)
				EachRow.mg_cad = parseFloat(mg_cad).toFixed(1)
				console.log(EachRow.mg_cad)
				//EachRow.pvp_sug = new_pvp_sug
				EachRow.ever_simulated = 1
				EachRow.prop_price_modified=1
			}
		})
		setData({...newData})
		runChangePVPAsync(newData);
		console.log('CHANGE MARGEN DATA')
		console.log(data)
	}

	const getSimulationByPrice = (id) => {
		try {
			delete data['data_promotion']
		} catch (error) {
			console.log('Aún no existe data promotion')
		}
		//let data_backup = data;
		//console.log("backup::::")
		//console.log(data_backup)
		data = {
			...data,
			data_promotion: {
				id: data.cadena_details.promotion_id,
				category: category,
				subcategory: subcategory,
			},
		}
		//Cambiar lógica, si es que el prop_price_modified = 1 entonces se ejecuta lo siguiente
		//Se debe recorrer todo el arreglo.

		data.cadena_summary.ben_opt = parseFloat(
			data.cadena_summary.ben_opt.replace('.', '').replace(',', '.')
		)
		data.cadena_summary.ben_prop = parseFloat(
			data.cadena_summary.ben_prop.replace('.', '').replace(',', '.')
		)
		data.cadena_summary.res_op_prop = parseFloat(
			data.cadena_summary.res_op_prop.replace('.', '').replace(',', '.')
		)
		data.data_rows.map((EachRow) => {
			if (EachRow.prop_price_modified === 1) {
				try{

				
					let precio_prop = parseFloat(
						
						document
							.getElementById('precio_prop_' + EachRow.id)
							.value.replace('.', '')
							.replace(',', '.')
						
						// preProp.replace('.', '').replace(',', '.')
					)
					let mg_cad = parseFloat(
						// mgCadInput
						document.getElementById('mg_input_row_' + EachRow.id).value
					)
					/*
					let new_pvp_sug = parseFloat(
						(precio_prop / (1 - mg_cad / 100)) * 1.19
					).toFixed(0) */
					EachRow.precio_prop = precio_prop
					EachRow.descuento_calculado = parseFloat(
						1 - precio_prop / EachRow.precio_ba
					).toFixed(1)
					// EachRow.pvp_sug = new_pvp_sug
					EachRow.prop_price_modified = 1
					EachRow.on_edit = 0
					EachRow.mg_cad = mg_cad
					EachRow.mg_cad_formated = formatNumberES(mg_cad)
					if(EachRow.descuento_calculado > 0){
						document
						.getElementById('row_tr_' + EachRow.id)
						.classList.add('VienesasRowActive')
					}else{
						document
						.getElementById('row_tr_' + EachRow.id)
						.classList.remove('VienesasRowActive')
					}
				}catch(e){
					console.log("error capturado simulacion, columna no existe:: "+e);
				}
			}
		})
		runSimulatorAsync(data)
	}

	const calcDescuento = () => {
		//Sin terminar
		let data_backup = {...data}
		data_backup.data_rows.map((EachRow) => {
			if (EachRow.prop_price_modified === 1 && EachRow.code_product === product.code_product) {
				let price_prop = parseFloat(
					/*
					document
						.getElementById('precio_prop_' + EachRow.id)
						.value.replace('.', '')
						.replace(',', '.')
					*/
					preProp.replace('.', '').replace(',', '.')
				)
				let descuento_calculado = parseFloat(
					(1 - price_prop / preBa) * 100
				).toFixed(1)
				/* document.getElementById('desc_price_' + EachRow.id).value =
					descuento_calculado
				document.getElementById('precio_prop_' + EachRow.id).value =
					formatNumberES(price_prop)
				*/
				EachRow.precio_prop_formated = formatNumberES(price_prop)
				EachRow.precio_prop = price_prop
				EachRow.descuento_calculado = descuento_calculado
				// nuevo
				setPreProp(formatNumberES(price_prop))
				setDescInput(descuento_calculado)
				if (descuento_calculado > 0) {
					document
						.getElementById('row_tr_' + EachRow.id)
						.classList.add('VienesasRowActive')
				}else{
					document
						.getElementById('row_tr_' + EachRow.id)
						.classList.remove('VienesasRowActive')
				}
			}
		})
		setData(data_backup)
	}

	const calcPrecioProp = () => {
		//SIn terminar
		let data_backup = {...data}
		data_backup.data_rows.map((EachRow) => {
			if (EachRow.prop_price_modified === 1 && EachRow.code_product === product.code_product) {
				let descuento_calculado = parseFloat(
					// document.getElementById('desc_price_' + EachRow.id).value
					descInput
				).toFixed(1)
				let precio_prop = parseFloat(
					parseFloat(preBa) * (1 - descuento_calculado / 100)
				).toFixed(0)
				//alert(precio_prop);
				//Crear campos formated
				EachRow.precio_prop_formated = formatNumberES(precio_prop)
				EachRow.pre_prop = precio_prop
				//document.getElementById("precio_prop_"+EachRow.id).defaultValue = formatNumberES(precio_prop);
				/*document.getElementById('precio_prop_' + EachRow.id).value =
					formatNumberES(precio_prop)
				document.getElementById('desc_price_' + EachRow.id).value =
					parseFloat(descuento_calculado).toFixed(1)
				*/
				setPreProp(formatNumberES(precio_prop))
				setDescInput(descuento_calculado)
				// setDescInput(descuento_calculado)
				if (descuento_calculado > 0) {
					document
						.getElementById('row_tr_' + EachRow.id)
						.classList.add('VienesasRowActive')
				}else{
					document
						.getElementById('row_tr_' + EachRow.id)
						.classList.remove('VienesasRowActive')
				}
			}
		})
		setData(data_backup)
	}

	const onEnter = (event, product_id, onBlur = null) => {
		if (event.key === 'Enter' && event.ctrlKey) {
			setloader(true)
			//document.getElementById("toolTipEnterInputPrice_"+product_id).classList.remove("show");
			document.getElementById('save_validator_' + product_id).value = 0
			document
				.getElementById('row_tr_' + product_id)
				.classList.remove('onEditS')
			getSimulationByPrice(product_id)
		}

		if ((event.key === 'Enter' && !event.ctrlKey) || onBlur) {
			calcDescuento()
		}
	} //getSimulationByPrice(product.id)

	const onEnterDesc = (event, product_id, product_precio_ba, onBlur = null) => {
		if (event.key === 'Enter' && event.ctrlKey) {
			setloader(true)
			//document.getElementById("toolTipEnterInputDesc_"+product.id).classList.remove("show");
			document.getElementById('save_validator_' + product_id).value = 0
			document
				.getElementById('row_tr_' + product_id)
				.classList.remove('onEditS')
			let descuento =
				1 -
				// parseFloat(document.getElementById('desc_price_' + product_id).value) 
				descInput
				/
					100
			let precio_prop = parseFloat(
				parseFloat(product_precio_ba) * descuento
			).toFixed(0)
			// document.getElementById('precio_prop_' + product_id).value = precio_prop
			setPreProp(formatNumberES(precio_prop))
			getSimulationByPrice(product_id)
			document
				.getElementById('row_tr_' + product_id)
				.classList.remove('onEditS')
			
		}
		if ((event.key === 'Enter' && !event.ctrlKey) || onBlur) {
			calcPrecioProp()
		}
	} //getSimulationByPrice(product.id)

	let class_css_edit = ''
	if (product.on_edit == 1) {
		class_css_edit = '' //"onEditS";
	}
	if (
		parseFloat((1 - 
		parseInt(product.precio_prop_formated.replace(".","").replace(",",".")) 
		/ 
		product.precio_ba) * 100).toFixed(1) >
		0
	) {
		class_css = 'VienesasRowActive'
	}

	useEffect(() => { // carga inicial
		console.log('product:: ', inputRef.current.value)
		setPreProp(product.precio_prop_formated)
		
		setDescInput(parseFloat(
				(1 - (
					// product.precio_prop 
					parseInt(product.precio_prop_formated.replace(".","").replace(",",".")) 
					/ product.precio_ba)) * 100
			).toFixed(1))
		const descuento_calculado = (1 - (product.precio_prop_formated / product.precio_ba)) * 100
		console.log("valor actual "+product.code_product+":::::::::::::: "+product.precio_prop_formated+" yyy "+product.precio_prop);
		
		// setDescInput(product.descuento_calculado)
		setPreBa(product.precio_ba)
		setMgCadInput(product.mg_cad)
		setPvpInput(product.pvp_sug_formated)
	}, [product])

	useEffect(() => {}, [descInput, mgCadInput, preProp]);

	return (
		<tr
			className={class_css + ' active ' + class_css_edit}
			// className={estadoActivo ? 'VienesasRowActive' : 'VienesasRowGray'}
			id={'row_tr_' + product.id}
		>
			<td>
				{loader && <Loader message={'Simulando Catálogo'}></Loader>}
				{loaderPVP && <Loader message={'Cargando Información'}></Loader>}
				<div className='display_flex gray cursor_row_active '
						onMouseUp={(e) => {
							e.target.querySelector('.ToolTipCustom').classList.remove('show')

							clearInterval(MouseDownState)
						}}
						onMouseDown={(e) => {
							if (e.button != 0) {
								return false
							}
							let MouseDownTimeOut = setTimeout(() => {
								e.target.querySelector('.ToolTipCustom').classList.add('show')
							}, [50])

							setMouseDownState(MouseDownTimeOut)
						}}
					>
						<ToolTipCustomList
						// value={`Precio unidad: $${product?.tooltips?.base_price[0]?.pxu_base} Precio venta: $${product?.tooltips?.base_price[0]?.pv_base}`} 
						list={[
							product.recommended === 0 ? "Sin recomendación" :
							product.recommended === 1 ? "Alza de Precio" :
							product.recommended === 2 ? "Promoción" : "Sin registro"
						]} 
						width={'100px'}
						/>
					<input
						type='hidden'
						id={'prop_price_modified_' + product.id}
						defaultValue={product.prop_price_modified}
					/>
					{/*model, si es 1 es el check y si es 0 es la x*/}
					{product.model ? <img src={Si} alt='' /> : <img src={No} alt='' />}
					{/* si es 0 entonces ciruclo, */}
					{product.recommended === 0 && <img src={Recomendacion} alt='' />}
					{product.recommended === 1 && <img src={recomendacion_alza} alt='' />}
					{product.recommended === 2 && <img src={tag} alt='' />}
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<a
						href='#'
						onClick={(e) => {
							e.preventDefault()
							setDataProduct(product)
							setCodigoPop(true)
						}}
					>
						{product.code_product}
					</a>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p style={{ width: '80px', textAlign: 'center' }}>
						{CapitalizeFirstLetter(product.description)}
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p style={{ width: '40px', textAlign: 'center' }}>
						{
							//subcategory
							product.subfamily
						}
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p style={{ textAlign: 'center' }}>{product.brand}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{product.vol_act}</p>
				</div>
			</td>
			<td>
				<div className='display_flex   green'>
					<p>{product.vol_opt}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{product.vol_prop}</p>
				</div>
			</td>
			<td>
				{parseFloat(
					String(product.vol_prop)
						.split(' ')[0]
						.replace('.', '')
						.replace(',', '.')
				).toFixed(2) -
					parseFloat(
						String(product.vol_act)
							.split(' ')[0]
							.replace('.', '')
							.replace(',', '.')
					).toFixed(2) >
					0 && (
					<div className='display_flex green'>
						<img src={Flecha} alt='' className='mr-5' />
					</div>
				)}
				{parseFloat(
					String(product.vol_prop)
						.split(' ')[0]
						.replace('.', '')
						.replace(',', '.')
				).toFixed(2) -
					parseFloat(
						String(product.vol_act)
							.split(' ')[0]
							.replace('.', '')
							.replace(',', '.')
					).toFixed(2) <
					0 && (
					<div className='display_flex orange'>
						<img src={orange_down} alt='' className='mr-5' />
					</div>
				)}
				{parseFloat(
					String(product.vol_prop)
						.split(' ')[0]
						.replace('.', '')
						.replace(',', '.')
				).toFixed(2) -
					parseFloat(
						String(product.vol_act)
							.split(' ')[0]
							.replace('.', '')
							.replace(',', '.')
					).toFixed(2) ==
					0 && (
					<div className='display_flex gray'>
						<p>-</p>
					</div>
				)}
			</td>

			<td>
				<Tooltip 
					// title={`Precio unidad: $${product?.tooltips?.base_price[0]?.pxu_base} Precio venta: $${product?.tooltips?.base_price[0]?.pv_base}`}
				>
					<div
						className='display_flex gray cursor_row_active '
						onMouseUp={(e) => {
							e.target.querySelector('.ToolTipCustom').classList.remove('show')

							clearInterval(MouseDownState)
						}}
						onMouseDown={(e) => {
							if (e.button != 0) {
								return false
							}
							let MouseDownTimeOut = setTimeout(() => {
								e.target.querySelector('.ToolTipCustom').classList.add('show')
							}, [50])

							setMouseDownState(MouseDownTimeOut)
						}}
					>
						<ToolTipCustomList
						// value={`Precio unidad: $${product?.tooltips?.base_price[0]?.pxu_base} Precio venta: $${product?.tooltips?.base_price[0]?.pv_base}`} 
						list={[`Precio unidad: $${product?.tooltips?.base_price[0]?.pxu_base}`, `Precio venta: $${product?.tooltips?.base_price[0]?.pv_base}`]} 
						width={'130px'}
						/>
						<p style={{ pointerEvents: 'none', cursor: 'pointer' }}>
							${product.precio_ba_formated}
						</p>
					</div>
				</Tooltip>
			</td>
			<td>
				<Tooltip
					// title={`Precio unidad: $${product?.tooltips?.act_price[0]?.pxu_act} Precio venta: $${product?.tooltips?.act_price[0]?.pv_act}`}
				>
					<div
						className='display_flex gray cursor_row_active'
						onMouseUp={(e) => {
							e.target.querySelector('.ToolTipCustom').classList.remove('show')

							clearInterval(MouseDownState)
						}}
						onMouseDown={(e) => {
							if (e.button != 0) {
								return false
							}
							let MouseDownTimeOut = setTimeout(() => {
								e.target.querySelector('.ToolTipCustom').classList.add('show')
							}, [50])

							setMouseDownState(MouseDownTimeOut)
						}}
					>
						<ToolTipCustomList 
						//value={`Precio unidad: $${product?.tooltips?.act_price[0]?.pxu_act} Precio venta: $${product?.tooltips?.act_price[0]?.pv_act}`} 
						list={[`Precio unidad: $${product?.tooltips?.act_price[0]?.pxu_act}`,`Precio venta: $${product?.tooltips?.act_price[0]?.pv_act}`]} 
						width={'130px'}
						/>
						<p style={{ pointerEvents: 'none', cursor: 'pointer' }}>
							${product.precio_act_formated}
						</p>
					</div>
				</Tooltip>
			</td>
			<td>
				<Tooltip
					// title={`Precio unidad: $${product?.tooltips?.opt_price[0]?.pxu_opt} Precio venta: $${product?.tooltips?.opt_price[0]?.pv_opt}`}
				>
					<div
						className='display_flex gray cursor_row_active'
						onMouseUp={(e) => {
							e.target.querySelector('.ToolTipCustom').classList.remove('show')

							clearInterval(MouseDownState)
						}}
						onMouseDown={(e) => {
							if (e.button != 0) {
								return false
							}
							let MouseDownTimeOut = setTimeout(() => {
								e.target.querySelector('.ToolTipCustom').classList.add('show')
							}, [50])

							setMouseDownState(MouseDownTimeOut)
						}}
					>
						<ToolTipCustomList 
						// value={`Precio unidad: $${product?.tooltips?.opt_price[0]?.pxu_opt} Precio venta: $${product?.tooltips?.opt_price[0]?.pv_opt}`}
						list={[`Precio unidad: $${product?.tooltips?.opt_price[0]?.pxu_opt}`, `Precio venta: $${product?.tooltips?.opt_price[0]?.pv_opt}`]}
						width={'130px'}
						/>

						<p style={{ pointerEvents: 'none', cursor: 'pointer' }}>
							${product.precio_opt_formated}
						</p>
					</div>
				</Tooltip>
			</td>
			<td>
				<Tooltip
					// title={`Precio unidad: $${product?.tooltips?.opt_price[0]?.pxu_opt} Precio venta: $${product?.tooltips?.opt_price[0]?.pv_opt}`}
				>
					<div
						className='display_flex  center_justify gray wrap_input'
						style={{ fontSize: '11px' }}
					>
						<input
							type='hidden'
							defaultValue={0}
							id={'save_validator_' + product.id}
						/>
						{ /*
						<ToolTipCustom 
						value={`Precio unidad: $${product?.tooltips?.opt_price[0]?.pxu_opt} Precio venta: $${product?.tooltips?.opt_price[0]?.pv_opt}`}
						// value="Recuerda_presionar_Enter_para_aplicar_el_precio " 
						id_input ={"toolTipEnterInputPrice_"+product.id}
							width="300px" heigth="74px"/>
					*/ }
						$
						<input
							ref={inputRef}
							type='text'
							value={preProp}
							id={'precio_prop_' + product.id}
							
							onChange={(e) => {
								e.stopPropagation();
								setPreProp(e.target.value)
								setChangeInput(true)
							}}
							onKeyPress={(e) => {
								//e.target.value = e.target.value.replace(/\D/g, '');
								validateNumber(e)
								onEnter(e, product.id)
							}}
							onBlur={(e) => {
								//e.target.value = e.target.value.replace(/\D/g, '');
								validateNumber(e)
								onEnter(e, product.id, true)
							}}
							onKeyUp={(e) => {
								//document.getElementById("toolTipEnterInputPrice_"+product.id).classList.add("show");
								if(product.precio_ba < (parseInt(String(e.target.value).replace('.', '').replace(',', '.')))){
									alert("El precio propuesto no puede ser mayor al precio base")
									e.target.value = product.precio_ba_formated
									setPreProp(product.precio_ba_formated)
									setDescInput(0.0)
									/*
									setPreProp(formatNumberES(
										product.precio_ba * (1 - (parseFloat(descInput) / 100))
										))
									return false
									*/
								}
								document.getElementById(
									'save_validator_' + product.id
								).value = 1
								document
									.getElementById('row_tr_' + product.id)
									.classList.add('onEditS')
								//document.getElementById("row_tr_"+product.id).classList.add("VienesasRowGray");
								if(product.precio_ba === (parseInt(String(e.target.value).replace('.', '').replace(',', '.')))){
									document
									.getElementById('row_tr_' + product.id)
									.classList.remove('VienesasRowActive')
								}else{
									document
									.getElementById('row_tr_' + product.id)
									.classList.add('VienesasRowActive')
								}
								
								product.on_edit = 1
								product.prop_price_modified = 1
								const newData = { ...data }
								newData.data_rows.map((EachRow) => {
									if (EachRow.id == product.id) {
										EachRow.on_edit = 1
										EachRow.prop_price_modified = 1
										EachRow.ever_simulated = 1
										document.getElementById('mensaje_simulador').style.display =
											'block'
									}
								})
								setData(newData)
								console.log(
									'data en el onkeyup del precio propuesto############'
								)
								console.log(data)
							}}
						/>
					</div>
				</Tooltip>
			</td>
			<td>
				<div
					className='display_flex  center_justify gray wrap_input font'
					style={{ fontSize: '11px' }}
				>
					<input
						type='number'
						value={descInput}
						onChange={(e) => {
							e.stopPropagation();
							prevDescInput.current = descInput; // Guarda el valor anterior antes de cambiarlo
							setDescInput(e.target.value);
							setChangeInput(true);
							
							}
						}
						id={'desc_price_' + product.id}
						onKeyPress={(e) => {
							onEnterDesc(e, product.id, product.precio_ba)
						}}
						onBlur={(e) => {
							onEnterDesc(e, product.id, product.precio_ba, true)
						}}
						onKeyUp={(e) => {
							if (
								descInput < 0
							) {
								alert('No deben haber valores negativos')
								// document.getElementById('desc_price_' + product.id).value = 0.0
								// setDescInput(prevDescInput.current); // Restablece al valor anterior
								setDescInput(parseFloat(
									(1 - (
										// product.precio_prop 
										parseInt(preProp.replace(".","").replace(",",".")) 
										/ preBa)) * 100
								).toFixed(1))
								// calcPrecioProp()
								return false
							}
							//document.getElementById("toolTipEnterInputPrice_"+product.id).classList.add("show");
							document.getElementById('save_validator_' + product.id).value = 1
							document
								.getElementById('row_tr_' + product.id)
								.classList.add('onEditS')
							//document.getElementById("row_tr_"+product.id).classList.add("VienesasRowGray");
							if(descInput <= 0){
								document
									.getElementById('row_tr_' + product.id)
									.classList.remove('VienesasRowActive')
							}
							product.on_edit = 1
							product.prop_price_modified = 1
							const newData = { ...data }
							newData.data_rows.map((EachRow) => {
								if (EachRow.id == product.id) {
									EachRow.on_edit = 1
									EachRow.prop_price_modified = 1
									EachRow.ever_simulated = 1
									document.getElementById('mensaje_simulador').style.display =
										'block'
								}
							})
							setData(newData)
						}}
					/>
					%
				</div>
			</td>
			<td>
				{parseFloat(product.var_opt_act.split('%')[0].replace(',', '.')) >
					0 && (
					<div className='display_flex green'>
						<img src={Flecha} alt='' className='mr-5' />
						<p>{product.var_opt_act}</p>
					</div>
				)}
				{parseFloat(product.var_opt_act.split('%')[0].replace(',', '.')) <
					0 && (
					<div className='display_flex orange'>
						<img src={orange_down} alt='' className='mr-5' />
						<p>{product.var_opt_act}</p>
					</div>
				)}
				{parseFloat(product.var_opt_act.split('%')[0].replace(',', '.')) ==
					0 && (
					<div className='display_flex gray'>
						<p>{product.var_opt_act}</p>
					</div>
				)}
			</td>
			<td>
				{parseFloat(
					String(product.var_pr_curr).split('%')[0].replace(',', '.')
				) > 0 && (
					<div className='display_flex green'>
						<img src={Flecha} alt='' className='mr-5' />
						<p>{product.var_pr_curr.split('%')[0]}%</p>
					</div>
				)}
				{parseFloat(
					String(product.var_pr_curr).split('%')[0].replace(',', '.')
				) < 0 && (
					<div className='display_flex orange'>
						<img src={orange_down} alt='' className='mr-5' />
						<p>{product.var_pr_curr.split('%')[0]}%</p>
					</div>
				)}
				{parseFloat(
					String(product.var_pr_curr).split('%')[0].replace(',', '.')
				) == 0 && (
					<div className='display_flex gray'>
						<p>{product.var_pr_curr.split('%')[0]}%</p>
					</div>
				)}
			</td>
			<td>
				<Tooltip
					// title={`RO $/Kg ${product?.prop_tooltips?.ro_price_kg}, RO $ ${product?.prop_tooltips?.ro_price}, Precio Crítico $${product?.prop_tooltips?.critical_price}`}
				>
					<div
						className={`display_flex
            ${
							parseFloat(
								String(product.ro_percent).split('%')[0].replace(',', '.')
							) == 0 && 'gray '
						}
            ${
							parseFloat(
								String(product.ro_percent).split('%')[0].replace(',', '.')
							) > 0 && 'green'
						}
            ${
							parseFloat(
								String(product.ro_percent).split('%')[0].replace(',', '.')
							) < 0 && 'orange'
						}
              cursor_row_active `}
						onMouseUp={(e) => {
							e.target.querySelector('.ToolTipCustom').classList.remove('show')

							clearInterval(MouseDownState)
						}}
						onMouseDown={(e) => {
							if (e.button != 0) {
								return false
							}
							let MouseDownTimeOut = setTimeout(() => {
								e.target.querySelector('.ToolTipCustom').classList.add('show')
							}, [50])

							setMouseDownState(MouseDownTimeOut)
						}}
					>
						<ToolTipCustomList 
						// value={`RO $/Kg ${product?.prop_tooltips?.ro_price_kg}, RO $ ${product?.prop_tooltips?.ro_price}, Precio Crítico $${product?.prop_tooltips?.critical_price}`}
						list={[`RO $/Kg ${product?.prop_tooltips?.ro_price_kg}`, `RO $ ${product?.prop_tooltips?.ro_price}`, `Precio Crítico $${product?.prop_tooltips?.critical_price}`]}
						width={'130px'}
						/>
						{parseFloat(
							String(product.ro_percent).split('%')[0].replace(',', '.')
						) < 0 && <img src={orange_down} alt='' className='mr-5' />}
						{parseFloat(
							String(product.ro_percent).split('%')[0].replace(',', '.')
						) > 0 && <img src={Flecha} alt='' className='mr-5' />}
						<p>{product.ro_percent}</p>
					</div>
				</Tooltip>
			</td>
			<td>
				<div
					className='display_flex gray wrap_input'
					style={{ fontSize: '11px' }}
					title='Presione Enter para actualizar'
				>
					<input
						type='number'
						id={'mg_input_row_' + product.id}
						value={mgCadInput}
						onChange={(e) => {
							e.stopPropagation();
							setMgCadInput(e.target.value)
							setChangeInput(true)
							}
						}
						// onChange={(e) => {
						// 	if (
						// 		document.getElementById('mg_input_row_' + product.id).value < 0
						// 	) {
						// 		alert('No deben haber valores negativos')
						// 		document.getElementById('mg_input_row_' + product.id).value = 0
						// 		return false
						// 	}
						// 	console.log(product.id)
						// 	changeMgRow(product.id)
						// }}
						// onChange={}
						onKeyDown={handleKeyDownMgCad}
					/>
					%
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p id={'pvp_sug_' + product.id}>$
					{	
						pvpInput
						// product.pvp_sug_formated
					}
					</p>
				</div>
			</td>
			<td>
				<div
					className='display_flex editable_icon  gray'
					onClick={(e) => {
						setDataPromotionals({
							promotional_values: product.promotional_values,
							product_id: product.id,
							product_code: product.code_product,
						})
						setPromotionalVariable(true)
					}}
				>
					<p id={'var_promo_' + product.id}>
						{total_promotional_values}/{total_promotional_vars}
					</p>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 24 24'
						fill='currentColor'
						className='w-6 h-6'
					>
						<path d='M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z' />
					</svg>
				</div>
			</td>
		</tr>
	)
}
