import React from 'react'
import Table from 'react-bootstrap/Table'
import filter_new from '../../assets/img/filter_new.svg'
import dropdown from '../../assets/img/dropdown.svg'
import forward_arrow from '../../assets/img/forward_arrow.svg'
import Off from '../../assets/img/Off.svg'
import tag from '../../assets/img/tag.svg'
import tick from '../../assets/img/tick.svg'
import close_sm from '../../assets/img/close_sm.svg'
import Recomendacion from '../../assets/img/Recomendacion.svg'
import recomendacion_alza from '../../assets/img/recomendacion_alza.svg'
import No from '../../assets/img/No.svg'
import Si from '../../assets/img/Si.svg'

export const SeguimientoTableTr = ({
	class_css,
	hopeJson,
	codeProduct,
	traditional,
}) => {

	const product = traditional
		? hopeJson?.data_rows.find(
				(product) => product.product_code === codeProduct
		  )
		: hopeJson?.data_rows.find(
				(product) => product.product_code.includes(codeProduct)
		  )

	console.log('product', product)

	// try {
	// 	dataRowinitial = hopeJson.data_rows.initial.filter(function (element) {
	// 		return element.code_product == codeProduct
	// 	})[0]

	// 	dataRowcollaborative = hopeJson.data_rows.collaborative.filter(function (
	// 		element
	// 	) {
	// 		return element.code_product == codeProduct
	// 	})[0]

	// 	dataRownegotiation = hopeJson.data_rows.negotiation.filter(function (
	// 		element
	// 	) {
	// 		return element.code_product == codeProduct
	// 	})[0]

	// 	dataRowexecution = hopeJson.data_rows.execution.filter(function (element) {
	// 		return element.code_product == codeProduct
	// 	})[0]

	// 	console.log('Table TR PRODUCTO-----------')
	// 	console.log(dataRowinitial)
	// } catch (e) {
	// 	console.log('Se salta.... error')
	// 	return <></>
	// }

	const CapitalizeFirstLetter = (str) => {
		const arr = String(str).toLowerCase().split(' ')
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
		}
		const str2 = arr.join(' ')
		return str2
	}

	return (
		<tr className={'activate ' + class_css}>
			<td>
				<div className='display_flex gray'>
					<p>{product?.subfamily}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{product?.short_brand}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					{product?.recommendation === 0 && <img src={Recomendacion} alt='' />}
					{product?.recommendation === 1 && (
						<img src={recomendacion_alza} alt='' />
					)}
					{product?.recommendation === 2 && <img src={tag} alt='' />}
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<a href='#'>{product?.product_code}</a>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{CapitalizeFirstLetter(product?.product_description)}</p>
				</div>
			</td>

			<td>
				<div className='display_flex gray'>
					<p>
						{
							`$${product?.base_price}`}
					</p>
				</div>
			</td>

			
			<td>
				<div className='display_flex   green'>
					<p>${`${product?.opt_price}`}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{product?.initial?.strat_price}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{product?.initial?.discount}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{product?.collaborative?.strat_price}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{product?.collaborative.discount}</p>
					{product?.collaborative.icon === 'check' && <img src={tick} alt='' />}
					{product?.collaborative.icon === 'x' && <img src={close_sm} alt='' />}
				</div>
			</td>

			<td>
				<div className='display_flex gray'>
					<p>{product?.negotiation?.strat_price}</p>
				</div>
			</td>

			<td>
				<div className='display_flex gray'>
					<p>{product?.negotiation?.discount}</p>
					{product?.negotiation.icon === 'check' && <img src={tick} alt='' />}
					{product?.negotiation.icon === 'x' && <img src={close_sm} alt='' />}
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{product?.execution?.strat_price}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{product?.execution?.discount}</p>
					{product?.execution.icon === 'check' && <img src={tick} alt='' />}
					{product?.execution.icon === 'x' && <img src={close_sm} alt='' />}
				</div>
			</td>
		</tr>
		// <tr className={'activate ' + class_css}>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<p>-</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<p>{dataRowinitial.brand}</p>
		// 		</div>
		// 	</td>

		// <td>
		// 	<div className='display_flex gray'>
		// 		<img src={Si} alt='' />
		// 		<img src={tag} alt='' />
		// 	</div>
		// </td>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<a href='#'>{dataRowinitial.code_product}</a>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex  gray'>
		// 			<p>{dataRowinitial.current_description_product}</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<p>${dataRowinitial.base_price}</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<p>${dataRowinitial.current_price}</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex   green'>
		// 			<p>${dataRowinitial.current_optimization_price}</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<p>${dataRowinitial.proposed_price}</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<p>{dataRowinitial.discount}%</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<p>${dataRowcollaborative.proposed_price}</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex gray'>
		// 			<p>{dataRowcollaborative.discount}%</p> <img src={tick} alt='' />
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex  gray'>
		// 			<p>${dataRownegotiation.proposed_price}</p>
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex  gray'>
		// 			<p>{dataRownegotiation.discount}%</p> <img src={tick} alt='' />
		// 		</div>
		// 	</td>
		// 	<td>
		// 		<div className='display_flex  gray'>
		// 			<p>${dataRowexecution.proposed_price}</p>
		// 		</div>
		// 	</td>

		// 	<td>
		// 		<div className='display_flex  gray'>
		// 			<p>{dataRowexecution.discount}%</p>
		// 			<img src={tick} alt='' />
		// 		</div>
		// 	</td>
		// </tr>
	)
}
