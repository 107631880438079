import React from "react";
//import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

export const TableHeader = (data, active_todas, selected) => {
  let array_categories_unique = [];
  console.log("Active Table Header Todas::::::")
  console.log(data)
  console.log("Active Table TODAS!!!!::::: ")
  console.log(data.selected)
  return (
    <div className="option_table" style={{ marginTop: 0 }}>
        <table>
          <tbody>
            <tr>
            
              <td className={data.active_todas}>
                <Link to={"/CataLogoResumen/"+data.data.promotion_id}>Todas</Link>
              </td>
            
              {data.data.data_rows.map((Row) => {
                    if(!array_categories_unique.includes(Row.category)){
                      array_categories_unique.push(Row.category)
                      return (
                          <td className={data.selected == Row.category ? 'active' : ''}>
                            <Link 
                            //to={"/catalogo/categoria/"+Row.category+"/"+data.data.promotion_id}
                            onClick={() => {window.location.href="/catalogo/categoria/"+Row.category+"/"+data.data.promotion_id}}
                            >{Row.category}</Link>
                          </td>
                        )
                    }
              })}
              
              
             
            </tr>
          </tbody>
        </table>
      </div>
  );
};
