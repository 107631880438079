import React, { useState } from "react";
import { Navbar } from "./Navbar";
import "../assets/css/CataLogoResumen.css";

import { CataLogoResumenFooter } from "./CataLogoResumenFooter";

import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Flecha_green from "../assets/img/Flecha_green.svg";
import Flecha_orange from "../assets/img/Flecha_orange.svg";

import { VienesasActivationTable } from "./VienesasActivationTable";
import { SaveCatalog } from "./SaveCatalog";
import { CatalogSaved } from "./CatalogSaved";
import { CatalogMoved } from "./CatalogMoved";
import { CatalogDelete } from "./CatalogDelete";
import { ExportPop } from "./ExportPop";
import { PromVariable } from "./PromVariable";
import { MoveCataLog } from "./MoveCataLog";
import { JumboPopOld } from "./JumboPopOld";
import { TopNavigator } from "./TopNavigator";
export const VienesasActivation = () => {
  const [Catalogactive, SetCatalog] = useState(true);
  const [CatalogSavedActive, SetCatalogSaved] = useState(false);
  const [CatalogMovedActive, SetCatalogMoved] = useState(false);
  const [CatalogDeleteActive, SetCatalogDelete] = useState(false);
  const [CatalogExportActive, SetCatalogExport] = useState(false);
  const [PromVariableActive, SetPromVariable] = useState(false);
  const [CatalogMoveActive, SetCatalogMove] = useState(false);
  const [CatalogJumboActive, SetCatalogJumbo] = useState(false);
  return (
    <div className="CataLogoResumen">
      {Catalogactive && (
        <SaveCatalog setActive={SetCatalog} setSaved={SetCatalogSaved} />
      )}
      {CatalogSavedActive && <CatalogSaved setActive={SetCatalogSaved} />}
      {CatalogMovedActive && <CatalogMoved setActive={SetCatalogMoved} />}
      {CatalogDeleteActive && <CatalogDelete setActive={SetCatalogDelete} />}
      {CatalogExportActive && <ExportPop setActive={SetCatalogExport} />}
      {PromVariableActive && <PromVariable setActive={SetPromVariable} />}
      {CatalogMoveActive && <MoveCataLog setActive={SetCatalogMove} />}
      {CatalogJumboActive && <JumboPopOld setActive={SetCatalogJumbo} />}

      <Navbar no_need_search />
      <TopNavigator />
      <div className="top_area top_area_differ">
        <div className="left_side">
          <Link to="/Seguimiento">Seguimiento Catálogo</Link>
          <Link to="/CataLogoResumen">Resumen Línea</Link>
        </div>
        <div className="option_table">
          <Table>
            <tbody>
              <tr>
                <td>
                  <Link to="/Vienesas" className="active">
                    Vienesas
                  </Link>
                </td>
                <td>
                  <Link to="/">Jamones</Link>
                </td>
                <td>
                  <Link to="/">Mortadelas</Link>
                </td>
                <td>
                  <Link to="/">Longanizas</Link>
                </td>
                <td>
                  <Link to="/">Pates</Link>
                </td>
                <td>
                  <Link to="/">Madurados</Link>
                </td>
                <td>
                  <Link to="/">Arrollados</Link>
                </td>
                <td>
                  <Link to="/SeguimientoLinea">Seguimiento Línea</Link>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="extralinewrapper d-flex">
        <div className="link_area">
          <p>Jumbo Abril 2022</p>
          <p>/</p>
          <p>Cecinas</p>
          <p>/</p>
          <p>Vienesas</p>
        </div>
        <nav>
          <li>
            <div className="box">
              <h1>Prod. c/Mod.</h1>
              <p className="green">
                <img src={Flecha_green} alt="" />
                5/17 (42,1%)
              </p>
            </div>
          </li>

          <li>
            <div className="box">
              <h1>N° Act. / Imp. Vol.</h1>
              <p className="orange">
                <img src={Flecha_orange} alt="" />8 (25,6%)
              </p>
            </div>
          </li>

          <li>
            <div className="box">
              <h1>Var $.</h1>
              <p className="orange">
                <img src={Flecha_orange} alt="" />
                7,2%
              </p>
            </div>
          </li>

          <li>
            <div className="box">
              <h1>Var Vol.</h1>
              <p className="green">
                <img src={Flecha_green} alt="" />
                -12,3%
              </p>
            </div>
          </li>
        </nav>

        <div className="nav_wrapper_box">
          <div className="inner_box">
            <h1>ACTUAL</h1>
            <nav>
              <li>
                <div className="box">
                  <h1>Volumen</h1>
                  <p>64,70%</p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Meta</h1>
                  <p>64,70%</p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Cumpl</h1>
                  <p className="orange">
                    <img src={Flecha_orange} alt="" />
                    95,3%
                  </p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Var Vol.</h1>
                  <p className="green">
                    <img src={Flecha_green} alt="" />
                    -12,3%
                  </p>
                </div>
              </li>
            </nav>
          </div>

          <div className="inner_box orangec">
            <h1>ÓPTIMO</h1>
            <nav>
              <li>
                <div className="box">
                  <h1>Volumen</h1>
                  <p>64,70%</p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Meta</h1>
                  <p>64,70%</p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Cumpl</h1>
                  <p className="orange">
                    <img src={Flecha_orange} alt="" />
                    95,3%
                  </p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Var Vol.</h1>
                  <p className="green">
                    <img src={Flecha_green} alt="" />
                    -12,3%
                  </p>
                </div>
              </li>
            </nav>
          </div>

          <div className="inner_box green">
            <h1>PROPUESTO</h1>
            <nav>
              <li>
                <div className="box">
                  <h1>Volumen</h1>
                  <p>64,70%</p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Meta</h1>
                  <p>64,70%</p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Cumpl</h1>
                  <p className="orange">
                    <img src={Flecha_orange} alt="" />
                    95,3%
                  </p>
                </div>
              </li>

              <li>
                <div className="box">
                  <h1>Var Vol.</h1>
                  <p className="green">
                    <img src={Flecha_green} alt="" />
                    -12,3%
                  </p>
                </div>
              </li>
            </nav>
          </div>
        </div>
      </div>

      <VienesasActivationTable />

      <CataLogoResumenFooter SetCatalogMove={SetCatalogMove} />
    </div>
  );
};
