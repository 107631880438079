import React from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import forwardArrow from '../../assets/img/forwardArrow.svg'
import greenarrow from '../../assets/img/newPFOffer/green-arrow.svg'
export const ProductTableLinea = ({
	setProductTableFamiliaState,
	ProductTableFamiliaState,
	setProductTableFamiliaInnerState,
	setProductTableInnerState,
	familia,
	data
}) => {
	console.log("DATA GROUPED ROWS::::: ")
	console.log(data)
	return (
		<>
			<tr
				className={`${ProductTableFamiliaState && 'rotate_img'}`}
				onClick={(e) => {
					setProductTableFamiliaState(!ProductTableFamiliaState)
					setProductTableFamiliaInnerState(false)
					setProductTableInnerState(false)
				}}
			>
				<td>
					<div className='display_flex familiap  center_justify gray blue_bg special_p'>
						<p >{familia}</p>
					</div>
				</td>
				<td colSpan={5} className='border-gray-custom-blue'>
					<div
						className='display_flex  center_justify gray blue_bg special_p'
						style={{justifyContent: 'flex-start', paddingLeft: 25}}
					>
						<img src={forwardArrow} alt='' />
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p> {data.brand.curr_vol}</p>
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p> {data.brand.opt_vol}</p>
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>{data.brand.strat_vol}</p>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex  '>
						{ /*<img src={greenarrow} alt='' style={{transform: `rotate(0)`}} /> */ }
						<p>-</p>
					</div>
				</td>
				<td colSpan={7} className='border-gray-custom-blue'></td>
				<td>
					<div className='display_flex  gray'>
						
						<p>{data.brand.strat_ro_pct}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray '>
						<p>{data.brand.incr_ro}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray'>
						<p>-</p>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex  gray'>
						<p>{data.brand.elasticity}</p>
					</div>
				</td>
				<td colSpan={3}></td>
			</tr>
		</>
	)
}
