import React from "react";
import bookmark from "../assets/img/bookmark.svg";
import AvatarPop from "../assets/img/AvatarPop.svg";
import Actividad from "../assets/img/Actividad.svg";
import Tottus from "../assets/img/Tottus.svg";
import Resumen from "../assets/img/Icono Resumen.svg";
import Cerrar from "../assets/img/Cerrar.svg";
import Avatargirl from "../assets/img/Avatargirl.svg";

export const TottusPop = ({ setActive }) => {
  return (
    <div className="popupWrapper OfertaWrapper TottusPop">
      <div className="popup">
        <div className="close_icon" onClick={(e) => setActive(false)}>
          <img src={Cerrar} alt="" />
        </div>
        <div className="left_side">
          <h1>
            <img src={bookmark} alt="" />
            Tottus Julio
          </h1>
          <div className="first_left">
            <div className="left_box">
              <h1>Fase</h1>
              <p>PROPUESTA COLABORATIVA</p>
            </div>
            <div className="left_box">
              <h1>Estado</h1>
              <p>EN REVISIÓN</p>
            </div>
            <p>
              Esta promoción no puede ser modificada porque está siendo revisada
              por el Administrador.
            </p>
          </div>
          <h1 style={{ marginTop: 30 }}>
            <img src={Resumen} alt="" />
            Resumen
          </h1>
          <div className="badge_long badge_long_catalogo">
            <div className="box_inner">
              <h1>Canal</h1>
              <p>Moderno</p>
            </div>

            <div className="box_inner">
              <h1>Cadena</h1>
              <img src={Tottus} alt="" />
            </div>
            <div className="box_inner">
              <h1>Modalidad</h1>
              <p>Catálogo</p>
            </div>
            <div className="box_inner">
              <h1>Actv</h1>
              <p>84,0%</p>
            </div>

            <div className="box_inner">
              <h1>Mes</h1>
              <p>Julio</p>
            </div>
            <div className="box_inner">
              <h1>Sell-In</h1>
              <p>
                01/01/2022 <br />
                01/02/2022
              </p>
            </div>
            <div className="box_inner">
              <h1>Sell-Out</h1>
              <p>
                01/01/2022 <br />
                01/02/2022
              </p>
            </div>
          </div>

          <h1 style={{ marginTop: 20 }}>
            <img src={Actividad} alt="" />
            Actividad
          </h1>

          <div className="profile_box_wrapper">
            <div className="profile_box">
              <img src={Avatargirl} alt="" />
              <div className="presentation">
                <input type="text" placeholder="Escribe un comentario..." />
              </div>
            </div>

            <div className="profile_box responder">
              <img src={Avatargirl} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>María M.</h1>
                  <p>hace 10 mínutos</p>
                </div>
                <input type="text" placeholder="Saquemos el Jamón Ahumado" />
                <button>Responder</button>
              </div>
            </div>

            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>Juan Martínez</h1>
                  <p>ha movido esta promoción al estado</p>
                  <span>EN EDICIÓN</span>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>Pedro Perez</h1>
                  <p>ha movido esta promoción al estado</p>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>María M.</h1>
                  <p>ha movido esta promoción al estado</p>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right_side">
          <div className="buttons_right_side">
            <h1>Promoción</h1>
            <button>Editar</button>
            <button className="active">Resumen</button>
            <button>Versiones</button>
          </div>
          <div className="buttons_right_side">
            <h1>Estado</h1>
            <button>Mover</button>
          </div>
          <div className="buttons_right_side">
            <h1>Acciones</h1>
            <button className="active">Duplicar</button>
            <button>Eliminar</button>
          </div>
        </div>
      </div>
    </div>
  );
};
