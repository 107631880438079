import React, { useEffect, useState } from 'react';

export const Ifra = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <a href="/home">Cerrar Demo Avances</a>
      <iframe
        src="https://promobooster-new-2.netlify.app"
        width="100%"
        height={screenHeight} // Utiliza la altura calculada de la pantalla
        style={{ border: 'none' }}
        title="Oferta Tradicional"
      ></iframe>
    </>
  );
};