import jumbo from './/assets/img/Jumbo.svg'
import mayorista from './/assets/img/Mayorista.png'
import unimarc from './/assets/img/Unimarc.png'
import lider from './/assets/img/Lider.png'
import santa from './/assets/img/Santa Isabel.svg'
import tottus from './/assets/img/Tottus.svg'
import central_mayorista from './/assets/img/central_mayorista.png'
import acuenta from './/assets/img/Acuenta.png'
import ekono from './/assets/img/Ekono.png'
// https://pfpromobooster-production.pricemaker.io/



export const config = {
	//piUrl: "http://172.17.0.2:5000"
	// apiUrl: "http://ec2-54-201-36-9.us-west-2.compute.amazonaws.com:5000"
	// apiUrl: "https://api-pf-services.pricemaker.io",
	// apiUrl: 'http://35.230.86.55:5000',
	apiUrl: "https://api-pf-services.pricemaker.io",
	// apiUrl: "https://api1-pb-pf-base-mfpelzjkxq-tl.a.run.app"
	// apiUrlOferta: "https://pfpb-ms-gateway-jkskhfnhta-uc.a.run.app/gtw/moffer",
	apiUrlOferta: "https://pbpf-modern-offer-service.pricemaker.io",
}



export const getImgCustomer = (img, width_size) => {
	let imagen_cadena = null
	switch (img) {
		case 'SANTA ISABEL':
			imagen_cadena = <img src={{ santa }.santa} width={width_size + 'px'} />
			break
		case 'JUMBO':
			imagen_cadena = <img src={{ jumbo }.jumbo} width={width_size + 'px'} />
			break
		case 'UNIMARC':
			imagen_cadena = (
				<img src={{ unimarc }.unimarc} width={width_size + 'px'} />
			)
			break
		case 'LIDER':
			imagen_cadena = <img src={{ lider }.lider} width={width_size + 'px'} />
			break
		case 'MAYORISTA 10':
			imagen_cadena = (
				<img src={{ mayorista }.mayorista} width={width_size + 'px'} />
			)
			break
		case 'TOTTUS S.A.':
			imagen_cadena = <img src={{ tottus }.tottus} width={width_size + 'px'} />
			break
		case 'ACUENTA':
			imagen_cadena = (
				<img src={{ acuenta }.acuenta} width={width_size + 'px'} />
			)
			break
		case 'EKONO':
			imagen_cadena = <img src={{ ekono }.ekono} width={width_size + 'px'} />
			break
		case 'LIDER MAYORISTA':
			imagen_cadena = (
				<img
					src={{ central_mayorista }.central_mayorista}
					width={width_size + 'px'}
				/>
			)
			break
		default:
			imagen_cadena = img.toLowerCase()
			break
	}
	return imagen_cadena
}

export const formatNumberES = (n, d = 0) => {
	console.log('EJECUNTADO FOMATEOOOO!!!')
	try {
		n = new Intl.NumberFormat('es-ES').format(
			parseFloat(n.replace('.', '').replace(',', '.')).toFixed(d)
		)
		console.log('final fomateo ' + n)
	} catch (error) {
		n = new Intl.NumberFormat('es-ES').format(parseFloat(n).toFixed(d))
		console.log('final fomateo ' + n)
	}

	if (String(n).replace('.', '').length == 4) {
		n = n.charAt(0) + '.' + n.substr(1, 3)
		console.log('NNN formateo excepcional ' + n)
	}

	if (d > 0) {
		// Obtenemos la cantidad de decimales que tiene el numero
		const decimals = n.indexOf(',') > -1 ? n.length - 1 - n.indexOf(',') : 0

		// añadimos los ceros necesios al numero
		n = decimals == 0 ? n + ',' + '0'.repeat(d) : n + '0'.repeat(d - decimals)
	}

	return n
}

export const validateNumber = (evt) => {
	var theEvent = evt || window.event

	// Handle paste
	if (theEvent.type === 'paste') {
		key = window.event.clipboardData.getData('text/plain')
	} else {
		// Handle key press
		var key = theEvent.keyCode || theEvent.which
		key = String.fromCharCode(key)
	}
	var regex = /[0-9]|\./
	if (!regex.test(key)) {
		theEvent.returnValue = false
		if (theEvent.preventDefault) theEvent.preventDefault()
	}
}

export const numberFormatInput = (e) => {
	//Formato americano separado por ,
	if (e.target.value.trim() == '' || e.target.value.trim() == '-') {
		return
	}

	// Obtenemos un array con el numero y los decimales si hay
	let contenido = e.target.value.replace(/[^0-9\.]/g, '').split('.')

	// añadimos los separadores de miles al primer numero del array
	contenido[0] = contenido[0].length
		? new Intl.NumberFormat('en-US').format(parseInt(contenido[0]))
		: '0'
	// Juntamos el numero con los decimales si hay decimales
	let resultado =
		contenido.length > 1 ? contenido.slice(0, 2).joffsdin('.') : contenido[0]
	e.target.value = e.target.value[0] == '-' ? '-' + resultado : resultado
}

export const forcedCeroUM = (string_value) => {
	//cero forzado Unidad de Medida, valores que vienen con espacio
	let number = string_value.split(' ')[0].replace(',', '.')
	let um = '' //string_value.split(" ")[1];
	let total_string = string_value.split(' ')
	for (let i = 1; i < total_string.length; i++) {
		//parte del 1 ya que el 0 es el número y el resto es el texto
		um += total_string[i] + ' '
	}
	return String(parseFloat(number).toFixed(1)).replace('.', ',') + ' ' + um
}

export const forcedCeroPercent = (string_value) => {
	let number = string_value.split('%')[0].replace(',', '.')
	return String(parseFloat(number).toFixed(1)).replace('.', ',') + '%'
}

export const redondearDecena = (num) => {
	return Math.round(num / 10) * 10
}

export const formatMoney = (str) => {
	let new_str = '$' + String(str).split(',')[0]
	new_str = new_str.replace('$-', '-$')

	if (new_str.indexOf('-') > -1) {
		return <p style={{ color: '#FF5334' }}> {new_str} </p>
	} else {
		return <p> {new_str} </p>
	}
}


function getPromotions() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			// mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				if (!response.ok) {
					if (response.status === 401) {
						// Verificar si ya estamos en la página "/login" antes de redirigir.
						if (window.location.pathname !== '/' && window.location.pathname !== '/ResetPassword'
						&& window.location.pathname !== '/ResetPasswordFound' && window.location.pathname !== '/EmailSent'
						&& window.location.pathname !== '/PasswordChangeSuccess'
						&& window.location.pathname !== '/user/password-reset' 
						) {
							console.log("ERROR 401 NO LOGUEADO");
							window.location.href = '/';
						}
					} else {
						throw new Error('Solicitud fallida con código de estado: ' + response.status);
					}
				}else{
					if(window.location.pathname === '/'){
						window.location.href = '/home';
					}
				}
				
				return response.json();
			})
			.catch(error => {
				console.log(error);
				// Manejo de errores de red u otros errores no relacionados con el estado HTTP.
			});
		
	}else{
		console.log("ERROR 401 NO LOGUEADO")
		// window.location.href = '/'
		if (window.location.pathname !== '/' && window.location.pathname !== '/ResetPassword'
		&& window.location.pathname !== '/ResetPasswordFound' && window.location.pathname !== '/EmailSent'
		&& window.location.pathname !== '/PasswordChangeSuccess'
		&& window.location.pathname !== '/user/password-reset' 
		) {
			console.log("ERROR 401 NO LOGUEADO");
			window.location.href = '/';
		}
	}
}

//Validación login
getPromotions();