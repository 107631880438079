import React, { useState, useEffect } from "react";
import bookmark from "../assets/img/bookmark.svg";
import edit from "../assets/img/edit.svg";
import calender from "../assets/img/calender.svg";
import shop from "../assets/img/shop.svg";
import {useNavigate} from 'react-router-dom';
import Cerrar from "../assets/img/Cerrar.svg";
import error_explanation from "../assets/img/error_explanation.svg";
import tick_explanation from "../assets/img/tick_explanation.svg";
import { CatálogoDropdown } from "./CatálogoDropdown";
import { CatálogoDropdownDate } from "./CatálogoDropdownDate";
import { config } from "../config";
import spin from "../assets/img/spin.png";


export const Loader = ({message = null}) => {
  let final_message = 'Cargando...';
  if(message){
    final_message = message
  }
  return (
    <div className="ConfiguracióndelPerfilWrapper" style={{backgroundColor:"transparent"}}>
      
        <div className="loader_wrappper" >
          <img src={spin} alt="" />
          <p>{final_message}</p>
        </div>
     
      </div>
  );
};
