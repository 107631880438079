import { useEffect, useRef, useState } from 'react'
import filter from '..//assets/img/filter.svg'
import {
	Filter,
	FilterColumn1,
	FilterColumn2,
	FilterColumn3,
	FilterColumn4,
} from '../utils/Filter'
import { set } from 'date-fns'

function reviewCheck(collection, id_input) {
	for (let i = 0; i < collection.length; i++) {
		if (parseInt(collection[i].id) === parseInt(id_input)) {
			return true
		}
	}
	return false
}

export const FilterArea = ({
	point,
	collection,
	setCollection,
	dataPromotion,
	setDataPromotions,
	dataAll,
}) => {
	const [active, setActive] = useState(false)
	const divRef = useRef(null);
	const scrollAmount = 100; // Ajusta esto según la cantidad que quieres desplazar

	const handleHorizontalScroll = (direction) => {
		if (divRef.current) {
		divRef.current.scrollLeft += direction === 'right' ? scrollAmount : -scrollAmount;
		}
	};
	let FilterFinal = Filter
	switch (point) {
		case 'one':
			FilterFinal = FilterColumn1
			break
		case 'two':
			FilterFinal = FilterColumn2
			break
		case 'three':
			FilterFinal = FilterColumn3
			break
		case 'four':
			FilterFinal = FilterColumn4
			break
		default:
			break
	}

	const refFilter = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeFilter, true)
		// setCollection([{ id: '1', name: 'Todos' }])
		// setCollection(FilterFinal)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		console.log("Actualizando collection!!!")
	}, [collection]);

	const closeFilter = (e) => {
		if (!refFilter.current.contains(e.target)) {
			setActive(false)
		}
	}

	const selectElement = (EachFilter) => {

		let FilterArea = collection.filter((EachCollection) => {
			if (EachCollection.id === EachFilter.id) {
				return true
			}
			return false
		}) 
		if (FilterArea.length > 0 && EachFilter.name !== 'Todos') {
			let FilterAreaInner = collection.filter((EachCollection) => {
				if (EachCollection.id != EachFilter.id && EachCollection.name !== 'Todos') {
					return true
				}
				return false
			})
			setCollection(FilterAreaInner)
			applyFilter(FilterAreaInner)
		} else {
			// alert(FilterFinal.length+"---"+ collection.length)
			if(EachFilter.name === 'Todos'){
				setCollection([{ id: EachFilter.id, name: EachFilter.name }])
				applyFilter([])
				reviewCheck(FilterFinal, EachFilter.id)
			}else if(FilterFinal.length - 3 === collection.length){
				// alert("todos")
				setCollection([...collection,
						{ id: EachFilter.id, name: EachFilter.name },
						{ id: '1', name: 'Todos' }])
				applyFilter([...collection, { id: EachFilter.id, name: EachFilter.name }, { id: '1', name: 'Todos' }])
				reviewCheck(FilterFinal, EachFilter.id)	
				reviewCheck(FilterFinal, "1")	
			}else{
				setCollection(
						[
							...collection,
							{ id: EachFilter.id, name: EachFilter.name },
						].filter((t) => t.name !== 'Todos')
				  )
				applyFilter([...collection, { id: EachFilter.id, name: EachFilter.name }])
				reviewCheck(collection, EachFilter.id)
			}

			
		}
	}

	const applyFilter = (collection_) => {
		console.log(collection_)
		if (collection_.length > 0) {
			let array_filter_names = []
			for (let i = 0; i < collection_.length; i++) {
				array_filter_names.push(collection_[i].name)
			}
			if (array_filter_names.indexOf('Todos') > -1) {
				setDataPromotions(dataAll)
				return true
			}
			console.log(array_filter_names)
			dataPromotion = dataAll.filter((element) => {
				return (
					array_filter_names.indexOf(
						element.promotional_state.state_phase_str
					) > -1
				)
			})
			//console.log(dataPromotion)
			setDataPromotions(dataPromotion)
		} else {
			setDataPromotions(dataAll)
		}
	}

	return (
		<div className='input' ref={refFilter}>
			<img src={filter} alt='' onClick={(e) => {
							setActive(!active)
						}}
				style={{ cursor: 'pointer' }}
						/>
			{collection.length > 0 ? (
				<>
					<div
						ref={divRef}
						className='input_filter_div'
						onClick={(e) => {
							setActive(!active)
						}}
					>
						{collection.map((EachCollection) => (
							<span>{EachCollection.name}</span>
						))}
					</div>
					<svg 
						onClick={() => handleHorizontalScroll('right')} 
						style={{cursor: 'pointer', position: 'absolute', right: '0', top: '0', margin: '0.5rem 0.5rem 0 0'}}
						width='15'
						height='15'
						viewBox='0 0 15 15'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect
							width='18'
							height='18'
							transform='translate(0 17) rotate(-90)'
							fill='white'
						/>
						<path
							d='M7.35355 3.64645C7.15829 3.45118 6.84171 3.45118 6.64645 3.64645C6.45118 3.84171 6.45118 4.15829 6.64645 4.35355L7.35355 3.64645ZM11 8L11.3536 8.35355C11.5488 8.15829 11.5488 7.84171 11.3536 7.64645L11 8ZM6.64645 11.6464C6.45118 11.8417 6.45118 12.1583 6.64645 12.3536C6.84171 12.5488 7.15829 12.5488 7.35355 12.3536L6.64645 11.6464ZM6.64645 4.35355L10.6464 8.35355L11.3536 7.64645L7.35355 3.64645L6.64645 4.35355ZM10.6464 7.64645L6.64645 11.6464L7.35355 12.3536L11.3536 8.35355L10.6464 7.64645Z'
							fill='#E8E8E8'
						/>
					</svg>
				</>
			) : (
				<input
					type='text'
					placeholder='Filtrar'
					readOnly
					style={{ cursor: 'pointer' }}
					onClick={(e) => {
						setActive(!active)
					}}
				/>
			)}

			{active && (
				<ul className='filter_list'>
					{FilterFinal.map((EachFilter) => (
						<>
							{EachFilter.id === '10' ? (
								<li style={{ padding: '6px 0.8rem' }}>{EachFilter.name}</li>
							) : (
								<li>
									<input
										type='checkbox'
										name='filter_1'
										id={`${point}_${EachFilter.id}`}
										onClick={(e) => {
											selectElement(EachFilter)
											//applyFilter()
										}}
										checked={
									(EachFilter.name === "Todos" && collection.length === 0 )? 
										true :
										reviewCheck(collection, EachFilter.id)}
										defaultChecked={true}
									/>
									<label htmlFor={`${point}_${EachFilter.id}`}>
										<div className='checbox_wrapper'>
											<span className='ball'></span>
										</div>
										<p>{EachFilter.name}</p>
									</label>
								</li>
							)}
						</>
					))}
				</ul>
			)}
		</div>
	)
}
