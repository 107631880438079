import React, { useState } from 'react'

import tick from '../assets/img/tick.svg'
import { SwitchButton } from './SwitchButton'
import { VienesasTableCategoreActiveRow } from './VienesasTableCategoreActiveRow'
export const VienesasTableCategoreActive = ({
	ActiveInner,
	current,
	offices,
	code_product,
	data,
	setData,
	setDataProduct,
	setCodigoPop,
	productDescription,
	productBrand,
  product
}) => {
	return (
		<>
			{offices.map((EachRow) => {
				console.log('office', EachRow)
				return (
					<VienesasTableCategoreActiveRow
						firstCol={EachRow.office_name}
						office={EachRow}
						code_product={code_product}
						data={data}
						setData={setData}
						setDataProduct={setDataProduct}
            product={product}
						setCodigoPop={setCodigoPop}
						productDescription={productDescription}
						productBrand={productBrand}
					/>
				)
			})}
		</>
	)
}
