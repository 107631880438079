import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Navbar } from './Navbar'
import '../assets/css/CataLogoResumenTradicional.css'
import Flecha_green from '../assets/img/Flecha_green.svg'
import Flecha_orange from '../assets/img/Flecha_orange.svg'
import Table from 'react-bootstrap/Table'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { CodigoPopNew } from './CodigoPopNew'
import { ToolTipCustom } from './ToolTipCustom'
import { ToolTipCustomList } from './ToolTipCustomList'
import { TopNavigatorFour } from './TopNavigatorFour'
import { ResumenLineaFooter } from './ResumenLineaFooter'
import { VienesasTableCategore } from './VienesasTableCategore'
import { config } from '../config'
import { Loader } from './Loader'
import {
	catalogoTradicionalProductoFiltradoJson,
	catalogoTradicionalFiltradoJson,
} from './hopeJson'
import { MoveCataLog } from './MoveCataLog'
import { CombiarPopupTradicional } from './CombiarPopupTradicional'
import { RenombrarPopup } from './RenombrarPopup'
import { useModal } from '../hooks/useModal'
import { usePrintPDF } from '../hooks/usePrintPdf'
import Modal from './modal'
import { exportToExcel } from 'react-json-to-excel'
import { DataResumeLineContext } from '../context/linksContext'

function getPromotions() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				return response.json()
			})
			.then((response) => {
				return response
			})
	}

	//there are no session token stored
	return {}
}

function catalogoLineResume(promotion_id, category, subcategory) {
	const session_token = localStorage.getItem('session')
	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrl +
				`/traditional_catalog/line_summary/products_view/${promotion_id}/${category}/${subcategory}/all/all`,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				return response.json()
			})
			.then((response) => {
				return response
			})
	}
	//there are no session token stored
	return {}
}

function catalogoLineResumeChild(id_promocion, linea, familia) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrl +
				`/traditional_catalog/line_summary/products_view/${id_promocion}/${linea}/${familia}/all/all`,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				return response.json()
			})
			.then((response) => {
				return response
			})
			.catch((err) => console.error('err :::', err))
	}
	//there are no session token stored
	return {}
}

export const VienesasNewCategore = () => {
	const [MouseDownState, setMouseDownState] = useState(null)
	const [CodigoPopState, setCodigoPop] = useState(false)
	const { id_promocion, nombre_promocion, oficina, linea, fecha, familia } =
		useParams()
	const navigate = useNavigate()

	//Servicio Web
	const [data, setData] = useState(null) //useState(catalogoTradicionalProductoFiltradoJson);
	const [dataFamilies, setDataFamilies] = useState(null) //useState(catalogoTradicionalFiltradoJson);
	const [dataProduct, setDataProduct] = useState(null)
	const [CatalogMove, setCatalogMove] = useState(false)
	const [promotion, setPromotion] = useState(null)
	const [combiar, setcombiar] = useState(false)
	const [Renombrar, setRenombrar] = useState(false)
	const { closeModal, isOpen, openModal, modalRef } = useModal()
	const { componentRef, handlePrint } = usePrintPDF(`${new Date()}`)
	const [dataEdit, setDataEdit] = useState({
		cadena_details: {
			name_promotion: nombre_promocion,
			promotion_id: id_promocion,
		},
	})

	const [loader, setloader] = useState(true)
	const { data: dateResumeLineFamily, fetchDataResumeLine } = useContext(
		DataResumeLineContext
	)

	// Salir sin guardar
	useEffect(() => {
		window.addEventListener('beforeunload', function (event) {
			let countChanges = 0;
			if(data){
				data.data_rows.map((row) => {
					if(row.prop_price_modified == 1){
						countChanges++;
					}
				});
				console.log("ALERTAAAAAA!!!!!!!!!");
				if(countChanges > 0){
					console.log("ALERTA TRIPLE!!!!!!!")
					event.returnValue = 'Estás a punto de abandonar este sitio. ¿Estás seguro?';
				}else{
					console.log("NO CONTABILIZADO!!!!!!!!")
				}
				
			}else{
				console.log("no capturado alaerta;");
			}
								
		});
	
		return () => {
		  window.removeEventListener('beforeunload', function (event) {
			let countChanges = 0;
			if(data){
				console.log("ALERTAAAAAA!!!!!!!!!");
				event.returnValue = 'Estás a punto de abandonar este sitio. ¿Estás seguro?';	
			}else{
				console.log("no capturado alaerta;");
			}
			
			
		  });
		};
	  }, [data]);
	// Fin salir sin guardar

	useEffect(() => {
		catalogoLineResumeChild(
			id_promocion,
			linea.replace('%20', ' '),
			familia
		).then((result) => {
			//setData(result)
			setDataFamilies(result)
			//setloader(false);
		})

		catalogoLineResume(
			id_promocion,
			linea.replace('%20', ' '),
			familia.replace('%20', ' ')
		).then((result) => {
			//setData(result)
			setData(result)
			setloader(false)
		})

		getPromotions().then((result) => {
			const promotion_filter = result.filter(function (element) {
				return element.id == id_promocion
			})
			console.log('*****************')
			console.log(promotion_filter[0])
			//alert(promotion_filter[0].id)
			setPromotion(promotion_filter[0])
		})

		fetchDataResumeLine(id_promocion, linea.replace('%20', ' ')).then(
			(result) => {
				console.log(result)
			}
		)
	}, [])

	return (
		<div className='CataLogoResumen' ref={componentRef}>
			{loader && <Loader message={'Cargando...'}></Loader>}
			{isOpen && (
				<Modal
					title='Exportar Vista'
					isOpen={isOpen}
					closeModal={closeModal}
					modalRef={modalRef}
					handlePrint={handlePrint}
					handleCsv={() => exportToExcel(data.data_rows, 'downloadfilename')}
				/>
			)}
			{combiar && (
				<CombiarPopupTradicional
					setcombiar={setcombiar}
					promotion={promotion}
				/>
			)}
			{Renombrar && (
				<RenombrarPopup
					setRenombrar={setRenombrar}
					promotion={promotion}
					data={dataEdit}
					setData={setDataEdit}
				/>
			)}
			{CatalogMove && promotion && (
				<MoveCataLog setActive={setCatalogMove} promotion={promotion} 
				data={data}
				tipo_catalogo={"tradicional"}/>
			)}
			{CodigoPopState && (
				<CodigoPopNew
					setCodigoPop={setCodigoPop}
					dataProduct={dataProduct}
					data={data}
					categoria={linea}
					subcategoria={familia}
					id_promocion={id_promocion}
					promotion={data}
					tradicional={true}
				/>
			)}

			<TopNavigatorFour
				nombre_promocion={nombre_promocion}
				fecha={fecha}
				linea={linea}
			/>
			{data && <Navbar no_need_search data={data}/>}
			<div className='top_area top_area_differ'>
				<div className='option_table option_table_upper'>
					<div>
					<Link
						to={`/Seguimiento/catalogo_tradicional/${id_promocion}`}
						state={{ traditional: true }}
					>
						Seguimiento Catálogo
					</Link>
						<Link to='/CataLogoResumen' onClick={() => navigate(-1)}>
							Resumen Línea
						</Link>
						{dateResumeLineFamily &&
							dateResumeLineFamily?.data_rows?.map((EachRow) => {
								return (
									<Link
										className={familia === EachRow.category && 'active'}
										onClick={(e) => {
											window.location.href = `/CatalogoTradicionalFamiliaProducto/${id_promocion}/${nombre_promocion}/${oficina}/${linea}/${fecha}/${EachRow.category}`
										}}
									>
										{EachRow.category}
									</Link>
								)
							})}
					</div>
				</div>
			</div>
			<div
				className='extralinewrapper extralinewrapperNew d-flex'
				style={{ marginBottom: 10 }}
			>
				<div className='link_area' style={{ flex: 1 }}>
					<p>{nombre_promocion}</p>
					<p>/</p>
					<p>{linea}</p>
					<p>/</p>
					<p>{familia}</p>
				</div>
				<nav>
					<li>
						<div
							className='box'
							onMouseUp={(e) => {
								e.target
									.querySelector('.ToolTipCustom')
									.classList.remove('show')

								clearInterval(MouseDownState)
							}}
							onMouseDown={(e) => {
								if (e.button != 0) {
									return false
								}
								let MouseDownTimeOut = setTimeout(() => {
									e.target.querySelector('.ToolTipCustom').classList.add('show')
								}, [500])

								setMouseDownState(MouseDownTimeOut)
							}}
						>
							{//<ToolTipCustom value='$1650' />
								}
							{data &&
								<ToolTipCustomList 
                        list={data.indicators.tooltips.prods_con_modelo.map(item => item.fecha.split(" ")[0]+":"+String(item.pct_impacto_volumen).replace(" ","")+" | "+item.prods_con_modelo.replace(" ",""))}
											/>
							}
							{data &&
							parseFloat(
								data.indicators.prod_c_mod.split('(')[0].split(',')[0]
							) > 0 ? (
								<>
									<h1>Prod. c/Mod.</h1>
									<p className='green'>
										<img src={Flecha_green} alt='' />
										{data && data.indicators.prod_c_mod}
									</p>
								</>
							) : (
								<>
									<h1 className='orange'>Prod. c/Mod.</h1>
									<p className='orange'>
										<img src={Flecha_orange} alt='' />
										{data && data.indicators.prod_c_mod}
									</p>
								</>
							)}
						</div>
					</li>

					<li>
						<div
							className='box'
							onMouseUp={(e) => {
								e.target
									.querySelector('.ToolTipCustom')
									.classList.remove('show')

								clearInterval(MouseDownState)
							}}
							onMouseDown={(e) => {
								if (e.button != 0) {
									return false
								}
								let MouseDownTimeOut = setTimeout(() => {
									e.target.querySelector('.ToolTipCustom').classList.add('show')
								}, [500])

								setMouseDownState(MouseDownTimeOut)
							}}
						>
							{data &&
								<ToolTipCustomList 
                        list={data.indicators.tooltips.prods_activos.map(item => item.fecha.split(" ")[0]+":"+String(item.pct_impacto_volumen).replace(" ","")+" | "+String(item.prods_activos).replace(" ",""))}
											/>
							}

							{data &&
							parseFloat(
								data.indicators.n_act_imp_vol.split('(')[0].split(',')[0]
							) > 0 ? (
								<>
									<h1>N° Act. / Imp. Vol.</h1>
									<p className='green'>
										<img src={Flecha_green} alt='' />
										{data && data.indicators.n_act_imp_vol}
									</p>
								</>
							) : (
								<>
									<h1 className='orange'>N° Act. / Imp. Vol.</h1>
									<p className='orange'>
										<img src={Flecha_orange} alt='' />
										{data && data.indicators.n_act_imp_vol}
									</p>
								</>
							)}
						</div>
					</li>

					<li>
						<div
							className='box'
							onMouseUp={(e) => {
								e.target
									.querySelector('.ToolTipCustom')
									.classList.remove('show')

								clearInterval(MouseDownState)
							}}
							onMouseDown={(e) => {
								if (e.button != 0) {
									return false
								}
								let MouseDownTimeOut = setTimeout(() => {
									e.target.querySelector('.ToolTipCustom').classList.add('show')
								}, [500])

								setMouseDownState(MouseDownTimeOut)
							}}
						>
							{data &&
								<ToolTipCustomList 
                        list={data.indicators.tooltips.variacion_precio.map(item => item.fecha.split(" ")[0]+":"+String(item.pct_var_precio).replace(" ","")+" ")}
											/>
							}

							{data &&
							parseFloat(
								data.indicators.var.replace('%', '').replace(',', '')
							) > 0 ? (
								<>
									<h1>Var $.</h1>
									<p className='green'>
										<img src={Flecha_green} alt='' />
										{data && data.indicators.var}
									</p>
								</>
							) : (
								<>
									<h1 className='orange'>Var $.</h1>
									<p className='orange'>
										<img src={Flecha_orange} alt='' />
										{data && data.indicators.var}
									</p>
								</>
							)}
						</div>
					</li>

					<li>
						<div
							className='box'
							onMouseUp={(e) => {
								e.target
									.querySelector('.ToolTipCustom')
									.classList.remove('show')

								clearInterval(MouseDownState)
							}}
							onMouseDown={(e) => {
								if (e.button != 0) {
									return false
								}
								let MouseDownTimeOut = setTimeout(() => {
									e.target.querySelector('.ToolTipCustom').classList.add('show')
								}, [500])

								setMouseDownState(MouseDownTimeOut)
							}}
						>
							{data &&
								<ToolTipCustomList 
                        list={data.indicators.tooltips.variacion_volumen.map(item => item.fecha.split(" ")[0]+":"+String(item.pct_var_vol).replace(" ","")+" ")}
											/>
							}

							{data &&
							parseFloat(
								data.indicators.var_vol.replace('%', '').replace(',', '')
							) > 0 ? (
								<>
									<h1>Var Vol.</h1>
									<p className='green'>
										<img src={Flecha_green} alt='' />
										{data && data.indicators.var_vol}
									</p>
								</>
							) : (
								<>
									<h1 className='orange'>Var Vol.</h1>
									<p className='orange'>
										<img src={Flecha_orange} alt='' />
										{data && data.indicators.var_vol}
									</p>
								</>
							)}
						</div>
					</li>
				</nav>

				<div className='breadcrumb'>
					<h1>META</h1>
					<span>Volumen</span>
					<p>{data && data.meta.meta[0].replace(' ', '')}</p>
				</div>

				<div className='nav_wrapper_box'>
					<div className='inner_box'>
						<h1>ACTUAL</h1>
						<nav>
							<li>
								<div className='box'>
									<h1>Volumen</h1>
									<p>{data && data.meta.meta_act[0]}</p>
								</div>
							</li>

							<li>
								<div className='box'>
									{data &&
									parseFloat(
										data.meta.meta_act[1].replace('%', '').replace(',', '.')
									) > 0 ? (
										<>
											<h1>Cumpl</h1>
											<p className='green'>
												<img src={Flecha_green} alt='' />
												{data && data.meta.meta_act[1]}
											</p>
										</>
									) : (
										<>
											<h1 className='orange'>Cumpl</h1>
											<p className='orange'>
												<img src={Flecha_orange} alt='' />
												{data && data.meta.meta_act[1]}
											</p>
										</>
									)}
								</div>
							</li>
						</nav>
					</div>

					<div className='inner_box orangec'>
						<h1>ÓPTIMO</h1>
						<nav>
							<li>
								<div className='box'>
									<h1>Volumen</h1>
									<p>{data && data.meta.meta_opt[0]}</p>
								</div>
							</li>

							<li>
								<div className='box'>
									{data &&
									parseFloat(
										data.meta.meta_opt[1].replace('%', '').replace(',', '.')
									) > 0 ? (
										<>
											<h1>Cumpl</h1>
											<p className='green'>
												<img src={Flecha_green} alt='' />
												{data && data.meta.meta_opt[1]}
											</p>
										</>
									) : (
										<>
											<h1 className='orange'>Cumpl</h1>
											<p className='orange'>
												<img src={Flecha_orange} alt='' />
												{data && data.meta.meta_opt[1]}
											</p>
										</>
									)}
								</div>
							</li>
						</nav>
					</div>

					<div className='inner_box green'>
						<h1>PROPUESTO</h1>
						<nav>
							<li>
								<div className='box'>
									<h1>Volumen</h1>
									<p>{data && data.meta.meta_prop[0]}</p>
								</div>
							</li>

							<li>
								<div className='box'>
									{data &&
									parseFloat(
										data.meta.meta_prop[1].replace('%', '').replace(',', '.')
									) > 0 ? (
										<>
											<h1>Cumpl</h1>
											<p className='green'>
												<img src={Flecha_green} alt='' />
												{data && data.meta.meta_prop[1]}
											</p>
										</>
									) : (
										<>
											<h1 className='orange'>Cumpl</h1>
											<p className='orange'>
												<img src={Flecha_orange} alt='' />
												{data && data.meta.meta_prop[1]}
											</p>
										</>
									)}
								</div>
							</li>
						</nav>
					</div>
				</div>
			</div>

			{data && (
				<VienesasTableCategore
					setCodigoPop={setCodigoPop}
					data={data}
					setData={setData}
					setDataProduct={setDataProduct}
				/>
			)}

			{data && (
				<ResumenLineaFooter
					data={data}
					oficina={oficina}
					promotion_id={id_promocion}
					guardar={true}
					setCatalogMove={setCatalogMove}
					setcombiar={setcombiar}
					setRenombrar={setRenombrar}
					setData={setData}
					setloader={setloader}
					openModalExport={openModal}
					category={familia}
					subcategory={linea}
				/>
			)}
		</div>
	)
}
