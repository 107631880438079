import React, { useState } from "react";
import { config } from "../config";

import logotwo from "..//assets/img/logo_secondary.svg";
import mainLogo from "../assets/img/PFAlimentos.png"

import Icono from "..//assets/img/Icono.svg";
import paasword from "..//assets/img/paasword.svg";
import line_bg from "..//assets/img/line_bg.svg";
import "../assets/css/login.css";
import { Link, useNavigate } from "react-router-dom";

export const Login = () => {
  let navigate = useNavigate();
  let [LoginActive, setLoginActive] = useState(false);
  let [passActive, setpassActive] = useState(false);
  let [PassError, setPassError] = useState(false);
  let [passwordState, setPasswordState] = useState("");
  let [emailState, setEmailState] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch(config.apiUrl + "/user/login", {
      //mode: 'no-cors',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: emailState,
        password: passwordState,
      }),
    });
    const data = await response.json();

    if (response.ok) {
      console.log("usuario loggeado");
      console.log(data);
      localStorage.setItem("session", data.token);
      localStorage.setItem("rol_and_permissions", data.rol_and_permissions);
      navigate("/home");
      //login(response.data, location.state?.from)
    } else {
      setPassError(true);
      console.log("contraseña o email icorrectos");
    }
  };

  return (
    <div className="Login">
      <img src={line_bg} alt="" />
      <form action="">
        <div className="logo_wrapper">
          <img src={mainLogo} width={100} alt="" />
        </div>
        <h1>
          Bienvenido a <span>PROMO</span>BOOSTER
        </h1>
        <div
          className={`input_wrapper ${LoginActive && "input_active"}`}
          onFocus={(e) => {
            setLoginActive(true);
          }}
          onBlur={(e) => {
            setLoginActive(false);
          }}
        >
          <img src={Icono} alt="" />
          <input
            type="text"
            placeholder="Ingresa tu Correo"
            onChange={(e) => {
              setEmailState(e.target.value);
            }}
          />
        </div>
        <div
          className={`input_wrapper ${passActive && "input_active"} ${
            PassError && "passerror"
          }`}
          onFocus={(e) => {
            setpassActive(true);
          }}
          onBlur={(e) => {
            setpassActive(false);
          }}
        >
          <img src={paasword} alt="" />
          <input
            type="password"
            placeholder="Ingresa tu Contraseña"
            value={passwordState}
            onChange={(e) => {
              setPasswordState(e.target.value);
            }}
          />
        </div>
        {PassError && (
          <p className="error_code">El usuario/contrasena es invalido</p>
        )}

        <div className="button_wrapper button_wrapper_new">
          <button
            // onClick={(e) => {
            //   e.preventDefault();
            //   if (passwordState == pass) {
            //     navigate("/home");
            //   } else {
            //     setPassError(true);
            //   }
            // }}
            onClick={handleLogin}
          >
            Iniciar Sesión
          </button>

          <Link to="/ResetPassword">Olvidé mi contraseña</Link>
        </div>
      </form>
      {/* <img src={logoone} className="logo_one" alt="" /> */}
    </div>
  );
};
