export const sortData = ({ tableData, sortKey, reverse }) => {
	if (!sortKey) return tableData

	const sortedData = [...tableData].sort((a, b) => {
		let firstOption,
			secondOption = 0
		if (typeof a[sortKey] === 'number') {
			firstOption = a[sortKey]
		} else {
			firstOption = parseNumber(a[sortKey])
		}

		if (typeof b[sortKey] === 'number') {
			secondOption = b[sortKey]
		} else {
			secondOption = parseNumber(b[sortKey])
		}
		if (reverse) {
			return firstOption < secondOption ? 1 : -1
		}
		return firstOption > secondOption ? 1 : -1
	})
	console.log("ORDENANDO DESDE SORTDATA "+sortKey+" "+reverse)
	return sortedData
}

export const formatNumber = (stringNumber) => {
	const numberString = stringNumber.replace(',', '.')
	const number = parseFloat(numberString)

	if (isNaN(number)) {
		return NaN
	}

	return number
}

export const parseNumber = (str) => {
	const cleanedStr = String(str).replace(/[^\d,-]/g, '')

	const dotRemoved = cleanedStr.replace('.', '')

	const decimalStr = dotRemoved.replace(',', '.')

	const number = parseFloat(decimalStr)

	return number
}
