import { React, useState, useEffect, useCallback, useMemo } from 'react'
import Table from 'react-bootstrap/Table'
import filter_new from '../../assets/img/filter_new.svg'
import dropdown from '../../assets/img/dropdown.svg'
import Off from '../../assets/img/Off.svg'
import { SeguimientoTableTr } from './SeguimientoTableTr'
import { SeguimientoTableTrFamilia } from './SeguimientoTableTrFamilia'
import { SeguimientoTableTrLinea } from './SeguimientoTableTrLinea'
import '../../assets/css/Seguimiento.css'
import { sortData } from '../../utils/sortData'
//import {hopeJson} from "./hopeJson";
// import hopeJson from '../mocks/seguimiento.json'

//SeguimientoTableTr
export const SeguimientoTable = ({ hopeJson, traditional }) => {
	const [filterDropdown, setFilterDropdown] = useState(false)
	const [subfamilyFilter, setSubfamilyFilter] = useState('Todas')
	const [subfamilies, setSubFamilies] = useState([])
	const [sortKey, setSortKey] = useState()
	const [sortOrder, setSortOrder] = useState('asc')

	const handleOptionChangeSubfamily = (event) => {
		setSubfamilyFilter(event.target.value)
	}

	const changeSort = (key) => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		setSortKey(key)
	}

	const sortedData = useMemo(() => {
        if (hopeJson && hopeJson.data_rows) {
            return sortData({
                tableData: hopeJson.data_rows,
                sortKey,
                reverse: sortOrder === 'desc',
            });
        }
        return [];
    }, [hopeJson, sortKey, sortOrder]);

    // ... tus otras funciones ...

    // Función de filtrado ajustada
    const filterTable = useMemo(() => {
        if (subfamilyFilter === 'Todas') {
            return sortedData;
        }
        return sortedData.filter((item) => item.subfamily === subfamilyFilter);
    }, [sortedData, subfamilyFilter]);

    const filtereds = filterTable;
	let array_all_lines = []
	let array_all_families = []
	let array_all_products = []
	filtereds.map((item) => {
		array_all_lines.push(item.family)
		array_all_families.push(item.family + '--' + item.subfamily)
		array_all_products.push({
        "family": item.family,
        "subfamily": item.subfamily,
        "product_code": item.product_code
      });
		array_all_products.push(
			item.family + '--' + item.subfamily + '--' + item.product_code
		)
	})
	let uniqueLines = [...new Set(array_all_lines)]
	let uniqueFamilies = [...new Set(array_all_families)]
	let uniqueProducts = [...new Set(array_all_products)]

	useEffect(() => {
		const subfamiliesAux = hopeJson?.data_rows?.map((row) => row.subfamily)
		setSubFamilies([...new Set(subfamiliesAux)])
	}, [hopeJson?.data_rows])

	return (
		<div className='SeguimientoTable SeguimientoTableLinea SeguimientoTableLineaNew VienesasTable'>
			<Table id="tablaDescargaFinal">
				<thead>
					<tr>
						<th colSpan={8} className='fimila fimiladropdown'>
							<div className='dropdown dropdown_new'>
								<div
									className='head'
									onClick={() => setFilterDropdown(!filterDropdown)}
								>
									<img src={filter_new} alt='' />
									<p>Todas</p>
									<img src={dropdown} alt='' />
								</div>
								{filterDropdown && (
									<ul style={{ width: '228px' }}>
										<li>
											<input
												type='radio'
												name='TodasSubfamilia'
												id='TodasSubfamilia'
												value={'Todas'}
												checked={subfamilyFilter === 'Todas'}
												style={{ display: 'none' }}
												onChange={handleOptionChangeSubfamily}
											/>
											<label htmlFor='TodasSubfamilia'></label>
											<p>Todas</p>
										</li>
										{subfamilies.map((subcategory) => (
											<li>
												<input
													type='radio'
													name={subcategory}
													id={subcategory}
													value={subcategory}
													checked={subfamilyFilter === subcategory}
													onChange={handleOptionChangeSubfamily}
												/>
												<label htmlFor={subcategory}></label>
												<p>{subcategory}</p>
											</li>
										))}
									</ul>
								)}
							</div>
						</th>

						<td colSpan={2}>
							<p>Prop. Inicial</p>
							<span className='propspan'></span>
						</td>
						<td colSpan={2} >
							<p>Prop. Colaborativa</p>
							<div className='percentage green_percentage'
							style={{"--percentageWidth": hopeJson?.header?.collaborative.split(" ")[0].replace(",",".")}}
							>
								<span className='percentage_inner'></span>
								<p>{hopeJson?.header?.collaborative}</p>
							</div>
						</td>
						<td colSpan={2}>
							<p>Negociación</p>
							<div className='percentage yellow_percentage'
							style={{"--percentageWidth": hopeJson?.header?.negotiation.split(" ")[0].replace(",",".")}}
							>
								<span className='percentage_inner'></span>
								<p>{hopeJson?.header?.negotiation}</p>
							</div>
						</td>
						<td colSpan={2}>
							<p>Ejecución</p>
							<div className='percentage red_percentage'
							style={{"--percentageWidth": hopeJson?.header?.execution.split(" ")[0].replace(",",".")}}
							>
								<span className='percentage_inner'></span>
								<p>{hopeJson?.header?.execution}</p>
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr className='special_body_row'>
						<td>
							<div className='display_flex  gray'>
								<p>SubFamilia</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Marca</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Rec</p>
								<img src={Off} alt='' 
								onClick={() => changeSort('recommendation')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Código</p>
								<img src={Off} alt='' 
								onClick={() => changeSort('product_code')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Descripción.</p>
							</div>
						</td>
						{traditional ? (
							<td>
								<div className='display_flex  center_justify gray'>
									<p>Oficina</p>
									<img src={Off} alt='' />
								</div>
							</td>
						) : (
							<td>
								<div className='display_flex  center_justify gray'>
									<p>Pre. Ba .</p>
									<img src={Off} alt='' 
									onClick={() => changeSort('base_price')}
									/>
								</div>
							</td>
						)}

						

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Pre. Opt.</p>
								<img src={Off} alt='' 
								onClick={() => changeSort('opt_price')}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
					</tr>
					{uniqueLines.map((item) => {
						return (
							<>
								<SeguimientoTableTrLinea
									lineStr={item
										.replace(' ', '')
										.replace('.', '')
										.replace('-', '')}
									lineNormal={item}
									// traditional={traditional}
								/>
								{uniqueFamilies.map((item_) => {
									if (item_.split('--')[0] == item)
										return (
											<>
												<SeguimientoTableTrFamilia
													lineStr={item_
														.split('--')[0]
														.replace(' ', '')
														.replace('.', '')
														.replace('-', '')}
													familyNormal={item_.split('--')[1]}
													familyStr={item_
														.split('--')[1]
														.replace(' ', '')
														.replace('.', '')
														.replace('-', '')}
													// traditional={traditional}
												/>
												{uniqueProducts.map((item__) => {
													console.log("itm productos::: ")
													console.log(item_)
													console.log(item__)
													console.log(item_.split('--')[2] +" == "+item__.product_code)
													if (item_.split('--')[2] == item__.product_code)
														return (
															<>
																<SeguimientoTableTr
																	class_css={item_
																		.split('--')[1]
																		.replace(' ', '')
																		.replace('.', '')
																		.replace('-', '')
																		.replace(/\s/g, '')}
																	hopeJson={hopeJson}
																	codeProduct={item__.split('--')[2]}
																	// traditional={traditional}
																/>
															</>
														)
												})}
											</>
										)
								})}
							</>
						)
					})}

					<SeguimientoTableTr
						class_css={'familia1'}
						traditional={traditional}
					/>
				</tbody>
			</Table>
		</div>
	)
}
