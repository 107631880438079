import React from 'react'
import forward_arrow from '../assets/img/forward_arrow.svg'

export const SeguimientoTableTrFamilia = ({
	familyStr,
	familyNormal,
	lineStr,
}) => {
	const idCustom = familyStr.replace(/\s/g, '')
	return (
		<tr
			id={idCustom}
			className={lineStr + ' subcategory'}
			onClick={(e) => {
				if (
					document
						.querySelector('#' + idCustom)
						.classList.contains('rotate_img')
				) {
					document.querySelector('#' + idCustom).classList.remove('rotate_img')
				} else {
					document.querySelector('#' + idCustom).classList.add('rotate_img')
				}
				document.querySelectorAll('.' + idCustom).forEach((Each) => {
					if (Each.classList.contains('active')) {
						Each.classList.remove('active')
					} else {
						Each.classList.add('active')
					}
				})
			}}
		>
			<td>
				<div className='display_flex familiap  center_justify gray blue_bg special_p'>
					<p>{familyNormal}</p>
				</div>
			</td>
			<td>
				<div
					className='display_flex  center_justify gray blue_bg special_p'
					style={{ justifyContent: 'flex-start', paddingLeft: 25 }}
				>
					<img src={forward_arrow} alt='' />
				</div>
			</td>
		</tr>
	)
}
