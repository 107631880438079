import PromoApi from '../utils/api/apiPromoboosterPF'
import { envVariables } from '../utils/envVariables'
import { config } from '../config'


export const getListPromotionById = async (id) => {
	const token = localStorage.getItem('session')

	return PromoApi.get(`${config.apiUrl}/promotion/list/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
}
