import React from "react";
import Table from "react-bootstrap/Table";
import moment from 'moment'
import 'moment/locale/es'

import Jumbo from "../assets/img/promorec.svg";
import {config, getImgCustomer} from "../config"

export const NombreTable = ({
  data,
  nombrePromocion,
  nombreCliente,
  promotion
}) => {
  moment.locale('es')
  return (
		<div className='NombreTable NombreTableNew'>
			<Table>
				<tbody>
					<tr>
						<td>
							<p>Nombre</p>
							<h1>{
							//data.cadena_details.name_promotion
							nombrePromocion
							}</h1>
						</td>
						<td>
							<p>Cadena</p>
							{getImgCustomer(
								//data.cadena_details.name_customer
								nombreCliente
								, 
								100)}
						</td>
						<td>
							<p>Fecha</p>
							<h1>
								{promotion.month_promotion_str} {promotion.year_promotion_str}
							</h1>
						</td>
						<td>
							<p>Fase</p>
							<h1 className='badge_blue'>
								{promotion.promotional_state.phase_str.toUpperCase()}
							</h1>
						</td>
						<td>
							<p>Estado</p>
							<h1 className='badge_blue'>
								{promotion.promotional_state.state_phase_str.toUpperCase()}
							</h1>
						</td>
					</tr>
				</tbody>
			</Table>

			<Table>
				<tbody>
					<td>
						<p>Sell-In</p>

						<p>
							In.{' '}
							<b>
								{moment(promotion.start_sellin)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')}
							</b>{' '}
							<br />
							Te.{' '}
							<b>
								{moment(promotion.end_sellin)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')}
							</b>
						</p>
					</td>
					<td>
						<p>Sell-Out</p>

						<p>
							In.{' '}
							<b>
								{moment(promotion.start_sellout)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')}
							</b>{' '}
							<br />
							Te.{' '}
							<b>
								{moment(promotion.end_sellout)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')}
							</b>
						</p>
					</td>
					<td>
						<p>Número SKU</p>

						<p>
							<b>-</b> (-)
						</p>
					</td>

					<td>
						<p>Número Act.</p>

						<p>
							<b>-</b> (-)
						</p>
					</td>

					<td>
						<p>Volumen Act.</p>

						<p>
							<b>-</b> (-)
						</p>
					</td>
				</tbody>
			</Table>
		</div>
	)
};
