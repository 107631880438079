import React from "react";
//import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

export const TableHeaderCecinas = (data, active_todas, selected) => {
  console.log("Active Table Header Header catagoria Todas::::::")
  console.log(data)
  return (
    <div className="option_table option_table_upper" style={{ marginTop: 0 }}>
        <table>
          <tbody>
            <tr>
            
            <td><Link to={"/Seguimiento/"+data.data.promotion_id}>Seguimiento Catálogo</Link></td>
            <td><Link to={"/catalogo/categoria/"+data.data.category+"/"+data.data.promotion_id} className={data.active_todas}>Resumen Línea</Link></td>
              {data.data.data_rows.map((EachRow) => {
                    //if(!array_categories_unique.includes(EachRow.subcategory) && EachRow.subcategory != 'TOTAL'){
                      //array_categories_unique.push(EachRow.category)
                      return (
                          <td >
                            <Link className={data.selected == EachRow.subcategory ? 'active' : ''}
                            //to={"/catalogo/subcategoria/"+EachRow.subcategory+"/"+data.data.promotion_id+"/"+data.data.category}
                            onClick={() => {window.location.href="/catalogo/subcategoria/"+EachRow.subcategory+"/"+data.data.promotion_id+"/"+data.data.category}}>{EachRow.subcategory}</Link>
                          </td>
                        )
                    //}
              })}
              
              
             
            </tr>
          </tbody>
        </table>
      </div>
  );
};
