import React, { useState, useEffect, useCallback } from "react";
import Table from "react-bootstrap/Table";
import Flecha from "../assets/img/Flecha.svg";
import Off from "../assets/img/Off.svg";
import orange_down from "../assets/img/orange_down.svg";
import filter_new from "../assets/img/filter_new.svg";
import dropdown from "../assets/img/dropdown.svg";
import forward_arrow from "../assets/img/forward_arrow.svg";
import { PromotionalVariable } from "./PromotionalVariable";
import { VienesasRowGrayNew } from "./VienesasRowGrayNew";
import { VienesasRowActiveNew } from "./VienesasRowActiveNew";
import { VienesasTableCategoreActive } from "./VienesasTableCategoreActive";
import { formatNumberES, config, formatMoney } from '../config'
import { sortData, parseNumber, formatNumber } from '../utils/sortData'
import { set } from "date-fns";

export const VienesasTableCategore = ({ setCodigoPop, data, setData, setDataProduct }) => {
  const [subfamilyFilter, setSubfamilyFilter] = useState('Todas')
	const [brandFilter, setBrandFilter] = useState('Todas')
  const [FirstFilter, setFirstFilter] = useState("Todas");
  const [SecondFilter, setSecondFilter] = useState("Marca");
  const [SecondDropdown, setSecondDropdown] = useState(false);
  const [FirstDropdown, setFirstDropdown] = useState(false);
  const [PromotionalVariableState, setPromotionalVariable] = useState(false);
  const [ActiveInner, setActiveInner] = useState(null);
  const [NonActiveInner, setNonActiveInner] = useState(null);
  const [dataPromotionals, setDataPromotionals] = useState(null);
  const [brand, setBrand] = useState('')
	const [subcategories, setSubcategories] = useState('')
  const [sortKey, setSortKey] = useState()
	const [sortOrder, setSortOrder] = useState('asc')
  const [reverse, setReverse] = useState(false)

  const sortedData = useCallback(
		sortData({
			tableData: data.data_rows,
			sortKey,
			reverse: reverse,
		}),
		[data, sortKey, reverse]
	)

	useEffect(() => {
		console.log("sortKey actualizado...")
		console.log(sortKey)
	}, [sortKey])
/*
  const filterTable = useCallback(() => {
		if (subfamilyFilter === 'Todas' && brandFilter === 'Todas') {
			return sortedData
		}
		if (brandFilter === 'Todas') {
			return sortedData
		}
		return sortedData.filter((item) => item.brand === brandFilter)
	}, [subfamilyFilter, brandFilter, sortedData])
*/
  const handleOptionChangeBrand = (event) => {
		setBrandFilter(event.target.value)
    setSecondFilter(event.target.value)
    setSecondDropdown(false)

	}

  const handleOptionChangeSubfamily = (event) => {
		setSubfamilyFilter(event.target.value)
    setFirstFilter(event.target.value)
    setFirstDropdown(false)
	}

  useEffect(() => {
		const brands = data?.data_rows?.map((row) => row.brand)
		setBrand([...new Set(brands)])

		const subfamilyAux = data?.data_rows?.map((row) => row.subfamily)
		setSubcategories([...new Set(subfamilyAux)])
	}, [])

  const HandleResizeWork = () => {
    let WindowHeight = window.innerHeight;
    let HalfHeight = WindowHeight - 300;
    let table_wrapper_overflow = document.querySelector(
      ".table_wrapper_overflow_category"
    );
    table_wrapper_overflow.style.height = `${HalfHeight}px`;
  };
  useEffect(() => {
    window.addEventListener("resize", HandleResizeWork);
    HandleResizeWork();
  }, []);

  const runChangePVPAsync = async (dataJSON) => {
		const session_token = localStorage.getItem('session')
		const response = await fetch(config.apiUrl + `/traditional_catalog/update_pvp`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
			method: 'POST',
			body: JSON.stringify(dataJSON),
		})
		let responseJSON = await response.json()
    responseJSON = JSON.stringify(responseJSON);
    responseJSON = JSON.parse(responseJSON);
		responseJSON.cadena_summary.ben_opt = formatNumberES(
			responseJSON.cadena_summary.ben_opt,
			1
		)
		responseJSON.cadena_summary.ben_prop = formatNumberES(
			responseJSON.cadena_summary.ben_prop,
			1
		)
		responseJSON.cadena_summary.res_op_prop = formatNumberES(
			responseJSON.cadena_summary.res_op_prop
		)
    
    console.log("DATA CHANGE UPDATE PVP GENERAL!!!!!!::::::::::::::::");
    console.log(responseJSON);
    /*
		responseJSON.data_rows.map((EachRow) => {
			
      console.log("PVP SUG::::::::::::::::");
      console.log(EachRow.pvp_sug);
      //document.getElementById('father_pvp_sug_' + EachRow.code_product).innerHTML = EachRow.pvp_sug;
			
		})
    */
		/*const elementos = document.querySelectorAll('.onEditS')
		elementos.forEach((elemento) => {
			elemento.classList.remove('onEditS')
		})*/
		console.log("DATA CHANGE UPDATE PVP!!!!!!::::::::::::::::");
		console.log(responseJSON);
		setData({...responseJSON})
		//setloader(false)
		//document.getElementById('mensaje_simulador').style.display = 'none'
	}

  const changeSort = (key) => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		setSortKey(key)
		setReverse(!reverse)
	}

  const updatePVPgeneral = () => {
    let mg_pvp_general = String(document.getElementById("mg_pvp_general").value).replace(",",".")//.replace(" ","").replace(".","").replace(",",".");
    mg_pvp_general = parseFloat(mg_pvp_general)//.toFixed(2);//parseFloat(1 + (parseFloat(mg_pvp_general)/100)).toFixed(2);
    console.log("Margen General!!!----------------");
    console.log(mg_pvp_general);
    const newData = { ...data };
    newData.data_rows.map((EachRow) => {
      let price_prop = EachRow.price_prop.replace("$","").replace(" ","").replace(".","").replace(",",".");
      price_prop = parseFloat(price_prop);
      //EachRow.pvp_sug = parseFloat(price_prop/(1-(mg_pvp_general/100))*1.19).toFixed(0)
      //EachRow.__pvp_sug = parseFloat(price_prop/(1-(mg_pvp_general/100))*1.19).toFixed(0)
      //console.log(price_prop/(1-(mg_pvp_general/100))*1.19);
      EachRow.offices_data_rows.map((office) => {
        let price_prop_ = office.price_prop.replace("$","").replace(" ","").replace(".","").replace(",",".");
        price_prop_ = parseFloat(price_prop_);
        //office.pvp_sug = parseFloat(price_prop/(1-(mg_pvp_general/100))*1.19).toFixed(0);
        //office.__pvp_sug = parseFloat(price_prop_/(1-(mg_pvp_general/100))*1.19).toFixed(0);
        office.prop_price_modified = 1;
        
      });
    });
    document.getElementById("mg_pvp_general").value = mg_pvp_general;
    setData({ ...newData, mg_pvp: mg_pvp_general / 100 });
    runChangePVPAsync({ ...newData, mg_pvp: mg_pvp_general / 100 });
  }

  const avarageCompare = (firstColumn, secondColumn) => {
    const total_rows = data.data_rows.length;
    let sum_first_column = 0;
    data.data_rows.map((row) => {
      sum_first_column  = sum_first_column  + 
      parseFloat(String(row[firstColumn]).split(" ")[0].split("%")[0].replace(".","").replace(",","."));
    });
    let avg_first_column = sum_first_column / total_rows;

    let sum_second_column = 0;
    data.data_rows.map((row) => {
      sum_second_column  = sum_second_column  + 
      parseFloat(String(row[secondColumn]).split(" ")[0].split("%")[0].replace(".","").replace(",","."));
    });
    let avg_second_column = sum_second_column / total_rows;
    //alert(firstColumn+" & "+secondColumn+" : "+avg_first_column +">="+ avg_second_column);
    return avg_first_column >= avg_second_column;
  }

  return (
    <div className="CataLogoResumenTable VienesasTable">
      {PromotionalVariableState && (
        <PromotionalVariable setPromotionalVariable={setPromotionalVariable} data={data} setData={setData}
        dataPromotionals={dataPromotionals}/>
      )}

      <Table>
        <thead>
          <tr>
            <th
              className="fimila fimiladropdown "
              style={{ width: "13%" }}
              rowSpan={2}
            >
              <div className="dropdown dropdown_new" style={{maxWidth: '250px'}}>
                <div
                  className="head"
                  onClick={(e) => {
                    setFirstDropdown(!FirstDropdown);
                    setSecondDropdown(false);
                  }}
                >
                  <img src={filter_new} alt="" />
                  <p>{FirstFilter}</p>
                  <img src={dropdown} alt="" />
                </div>
                {FirstDropdown && (
                  <ul>
                    <li>
                    <input
												type='radio'
												name='TodasSubfamilia'
												id='TodasSubfamilia'
												value={'Todas'}
												checked={subfamilyFilter === 'Todas'}
												onChange={handleOptionChangeSubfamily}
											/>
											<label htmlFor='TodasSubfamilia'></label>
											<p>Todas</p>
                    </li>
                    {subcategories.map((subcategoryFF) => (
										<li>
											<input
												type='radio'
												name={subcategoryFF}
												id={subcategoryFF}
												value={subcategoryFF}
												checked={subfamilyFilter === subcategoryFF}
												onChange={handleOptionChangeSubfamily}
											/>
											<label htmlFor={subcategoryFF}></label>
											<p>{subcategoryFF}</p>
										</li>
									))}
                  </ul>
                )}
              </div>
            </th>
            <th
              className="fimila border_head_gray fimiladropdown"
              style={{ width: "13%" }}
              rowSpan={2}
            >
              <div
                className="dropdown dropdown_new"
                style={{ paddingLeft: 5, paddingRight: 5, maxWidth: '250px' }}
              >
                <div
                  className="head"
                  onClick={(e) => {
                    setSecondDropdown(!SecondDropdown);
                    setFirstDropdown(false);
                  }}
                >
                  <img src={filter_new} alt="" />
                  <p>{SecondFilter}</p>
                  <img src={dropdown} alt="" />
                </div>
                {SecondDropdown && (
                  <ul>
                    <li>
                    <input
												type='radio'
												name='second_filter'
												id='Todas'
												value={'Todas'}
												checked={brandFilter === 'Todas'}
												onChange={handleOptionChangeBrand}
											/>
											<label htmlFor='Todas'></label>
											<p>Todas</p>
                    </li>
                    {brand.map((brand) => (
											<li>
												<input
													type='radio'
													name={brand}
													id={brand}
													value={brand}
													checked={brandFilter === brand}
													onChange={handleOptionChangeBrand}
												/>
												<label htmlFor={brand}></label>
												<p>{brand}</p>
											</li>
										))}
                  </ul>
                )}
              </div>
            </th>
            {/*
            <th className="fimila border_head_gray" rowSpan={2}>
              <div className="dropdown">
                <div className="head">
                  <img src={filter_new} alt="" />
                  <p>Todas</p>
                  <img src={dropdown} alt="" />
                </div>
              </div>
            </th> */}
            <th
              colSpan={3}
              style={{ width: "14%" }}
              className="border_head_gray"
            >
              {avarageCompare("vol_prop", "vol_act") ? 
                <div className="display_flex green">
                  <img src={Flecha} alt="" className="mr-5" />
                  <p>Volumen</p>
                </div> 
              :
                <div className="display_flex orange">
                  <img src={orange_down} alt="" className="mr-5" />
                  <p>Volumen</p>
                </div>
              }
            </th>
            <th colSpan={3} className="border_head_gray">
            {parseFloat(data.data_header.price_prop.replace(".","").replace(",","."))
            >
            parseFloat(data.data_header.price_act.replace(".","").replace(",","."))
            ? 
                <div className="display_flex green">
                  <img src={Flecha} alt="" className="mr-5" />
                  <p>Precio</p>
                </div> 
              :
                <div className="display_flex orange">
                  <img src={orange_down} alt="" className="mr-5" />
                  <p>Precio</p>
                </div>
              }
            </th>
            <th colSpan={3} className="border_head_gray">
            {parseFloat(data.data_header.mg_prop.replace(".","").replace(",","."))
            >
            parseFloat(data.data_header.mg_act.replace(".","").replace(",","."))
            ? 
                <div className="display_flex green">
                  <img src={Flecha} alt="" className="mr-5" />
                  <p>Margen Bruto</p>
                </div> 
              :
                <div className="display_flex orange">
                  <img src={orange_down} alt="" className="mr-5" />
                  <p>Margen Bruto</p>
                </div>
              }
            </th>
            <th colSpan={3} className="border_head_gray">
            {parseFloat(data.data_header.res_mm_prop.replace(".","").replace(",","."))
            >
            parseFloat(data.data_header.res_mm_act.replace(".","").replace(",","."))
            ? 
                <div className="display_flex green">
                  <img src={Flecha} alt="" className="mr-5" />
                  <p>Resultado MM</p>
                </div> 
              :
                <div className="display_flex orange">
                  <img src={orange_down} alt="" className="mr-5" />
                  <p>Resultado MM</p>
                </div>
              }
            </th>

            <th
              colSpan={3}
              style={{ width: "12%" }}
              className="border_head_gray"
            >
              <div className="display_flex blue">
                <p>Beneficio</p>
              </div>
            </th>

            <th style={{ width: 62 }}>
              <div className="display_flex blue">
                <p>Mg. PVP</p>
              </div>
            </th>
          </tr>

          <tr className="bottom_row_head">
            <th>
              <div className="head_box">
                <h1>Act.</h1>
                <p>{data.data_header.vol_act}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Opt.</h1>
                <p>{data.data_header.vol_opt}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop.</h1>
                <p>{data.data_header.vol_prop}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Act.</h1>
                <p>${data.data_header.price_act}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Opt</h1>

                <p>${data.data_header.price_opt}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop.</h1>
                <p>${data.data_header.price_prop}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Act.</h1>
                <p>${data.data_header.mg_act}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Opt</h1>

                <p>${data.data_header.mg_opt}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop.</h1>
                <p>${data.data_header.mg_prop}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Act.</h1>
                <p >${data.data_header.res_mm_act}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Opt</h1>
                <p >${data.data_header.res_mm_opt}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop.</h1>
                <p >${data.data_header.res_mm_prop}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop. - Act.</h1>
                <p>${data.data_header.ben_prop_act}</p>
              </div>
            </th>
            <th>
              <div className="head_box">
                <h1>Prop. - Opt.</h1>
                <p>${data.data_header.ben_prop_opt}</p>
              </div>
            </th>
            <th></th>
            <th>
              <div
                className="head_box wrap_input"
                style={{
                  transform: "translateX(3px)",
                  width: "46px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontSize: '11px'
                }}
              >
                <input type="number" id="mg_pvp_general" defaultValue="30.0" 
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      // Aquí puedes llamar a la función que deseas activar cuando se presiona Enter
                      updatePVPgeneral();
                    }
                }}/>%
              </div>
            </th>
          </tr>
        </thead>
      </Table>
      <div className="table_wrapper_overflow_category">
        <Table className="table_body_category">
          <thead style={{ background: "#E8E8E8", height: 30 }}>
            <tr>
              <td>
                <div className="display_flex gray">
                  <p>Rec.</p>
                  <img src={Off} 
                  alt=''
                  onClick={() => changeSort('recommendation')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  gray">
                  <p>Código</p>
                  {/* <img src={Off} alt="" /> */}
                </div>
              </td>
              <td>
                <div className="display_flex  center_justify gray">
                  <p>Descripción</p>
                </div>
              </td>
              <td>
                <div className="display_flex gray">
                  <p>SubFamilia</p>
                </div>
              </td>
              <td>
                <div className="display_flex gray">
                  <p>Marca</p>
                </div>
              </td>
              <td>
                <div className="display_flex gray">
                  <p>Ofic.</p>
                </div>
              </td>
              <td>
                <div className="display_flex gray">
                  <p> Actv.</p>
                </div>
              </td>

              <td>
                <div className="display_flex  gray">
                  <p>Vol. Act.</p>
                  <img src={Off} 
                  alt=''
                  onClick={() => changeSort('__vol_act')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  center_justify gray">
                  <p>Vol. Opt.</p>
                  <img src={Off} 
                  alt=''
                  onClick={() => changeSort('__vol_opt')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  center_justify gray">
                  <p>Vol. Prop.</p>
                  <img src={Off} 
                  alt=''
                  onClick={() => changeSort('__vol_prop')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>

              <td>
                <div className="display_flex gray">
                  <p>V. Prop.+OI</p>
                  <img src={Off} 
                  alt=''
                  onClick={() => changeSort('__vol_prop_oi')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex gray">
                  <p>$ Act.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('__price_act')}
                  style={{ cursor: 'pointer', zIndex: 1000 }}
                  />
                </div>
              </td>

              <td>
                <div className="display_flex  gray">
                  <p>$ Opt.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('__price_opt')}
                  style={{ cursor: 'pointer' , zIndex: 1000}}
                  />
                </div>
              </td>

              <td>
                <div className="display_flex gray">
                  <p>Prop.</p>
                </div>
              </td>

              <td>
                <div className="display_flex  center_justify gray">
                  <p>$ Opt/Act.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('opt_act')}
                  style={{ cursor: 'pointer' , zIndex: 1000}}
                  />
                </div>
              </td>

              <td>
                <div className="display_flex gray">
                  <p>Mg. Act.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('mg_act')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  gray">
                  <p>Mg. Opt.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('mg_opt')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>

              <td>
                <div className="display_flex gray">
                  <p>Mg. Prop.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('mg_prop')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  gray">
                  <p>RO Prop.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('ro_perc_prop')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  gray">
                  <p>Uplift</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('uplift')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  gray">
                  <p>RO Incr.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('ro_incr')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  gray">
                  <p>PVP Sug.</p>
                  <img src={Off} alt="" 
                  onClick={() => changeSort('pvp_sug')}
                  style={{ cursor: 'pointer' }}
                  />
                </div>
              </td>
              <td>
                <div className="display_flex  gray">
                  <p> Var. Pro.</p>
                </div>
              </td>
              <td>
                <div className="display_flex  gray">
                  <p> OI</p>
                </div>
              </td>
            </tr>
          </thead>
          <tbody className="img_saperate_wrapper">
            {/*}
            <tr
              style={{ height: 20 }}
              id="active_row_button_table"
              onClick={(e) => {
                if (
                  document
                    .querySelector("#active_row_button_table")
                    .classList.contains("rotate_img")
                ) {
                  document
                    .querySelector("#active_row_button_table")
                    .classList.remove("rotate_img");
                } else {
                  document
                    .querySelector("#active_row_button_table")
                    .classList.add("rotate_img");
                }
                document
                  .querySelectorAll(".VienesasRowActive")
                  .forEach((Each) => {
                    if (Each.classList.contains("active")) {
                      Each.classList.remove("active");
                    } else {
                      Each.classList.add("active");
                    }
                  });
              }}
            >
            
              <td colSpan={24}>
                <div
                  className="display_flex familiap  gray blue_bg special_p"
                  style={{ justifyContent: "flex-start", paddingLeft: 20 }}
                >
                  <p>Productos con Activación</p>
                  <div
                    className="display_flex  center_justify gray blue_bg special_p"
                    style={{ justifyContent: "flex-start", paddingLeft: 25 }}
                  >
                    <img src={forward_arrow} alt="" />
                  </div>
                </div>
              </td>
            </tr>
            */}
          {/*
            <VienesasRowActiveNew
              setCodigoPop={setCodigoPop}
              setPromotionalVariable={setPromotionalVariable}
              name="V2000"
              value="first"
              setActiveInner={setActiveInner}
              ActiveInner={ActiveInner}
            />

            {ActiveInner == "first" && (
              <VienesasTableCategoreActive
                ActiveInner={ActiveInner}
                current="first"
              />
            )}
            <VienesasRowActiveNew
              setCodigoPop={setCodigoPop}
              setPromotionalVariable={setPromotionalVariable}
              name="CVR1"
              value="second"
              setActiveInner={setActiveInner}
              ActiveInner={ActiveInner}
            />
            {ActiveInner == "second" && (
              <VienesasTableCategoreActive
                ActiveInner={ActiveInner}
                current="second"
              />
            )}
            */}
          {/*
            <tr
              style={{ height: 20 }}
              id="not_active_row_button_table"
              onClick={(e) => {
                if (
                  document
                    .querySelector("#active_row_button_table")
                    .classList.contains("rotate_img")
                ) {
                  document
                    .querySelector("#active_row_button_table")
                    .classList.remove("rotate_img");
                } else {
                  document
                    .querySelector("#active_row_button_table")
                    .classList.add("rotate_img");
                }
                document
                  .querySelectorAll(".VienesasRowGray")
                  .forEach((Each) => {
                    if (Each.classList.contains("active")) {
                      Each.classList.remove("active");
                    } else {
                      Each.classList.add("active");
                    }
                  });
              }}
            >
              <td colSpan={24}>
                <div
                  className="display_flex familiap   gray blue_bg special_p"
                  style={{ justifyContent: "flex-start", paddingLeft: 20 }}
                >
                  <p>Productos sin Activación</p>
                  <div
                    className="display_flex  center_justify gray blue_bg special_p"
                    style={{ justifyContent: "flex-start", paddingLeft: 25 }}
                  >
                    <img src={forward_arrow} alt="" />
                  </div>
                </div>
              </td>
              {/* <td colSpan={19}></td> 
            </tr>
            */}

          {
            /*data.data_rows
            .sort((a, b) => {
              let firstOption,
                secondOption = 0
              if (typeof a[sortKey] === 'number') {
                firstOption = a[sortKey]
              } else {
                firstOption = parseNumber(a[sortKey])
              }
          
              if (typeof b[sortKey] === 'number') {
                secondOption = b[sortKey]
              } else {
                secondOption = parseNumber(b[sortKey])
              }
              if (reverse) {
                return firstOption < secondOption ? 1 : -1
              }
              return firstOption > secondOption ? 1 : -1
            })*/
            sortedData &&
							// filterTable()
            sortedData
            .map((EachRow) => {
              if(brandFilter !== 'Todas'){
                if(EachRow.brand !== brandFilter){
                  return null
                }
              }
              if(subfamilyFilter !== 'Todas'){
                if(EachRow.subfamily !== subfamilyFilter){
                  return null
                }
              }
              return <><VienesasRowGrayNew
              key={EachRow.code_product}
              setCodigoPop={setCodigoPop}
              name={EachRow.code_product}
              setPromotionalVariable={setPromotionalVariable}
              value={EachRow.code_product.replace(" ","").replace(".","").replace("-","").replace(",","")}
              setActiveInner={setNonActiveInner}
              ActiveInner={NonActiveInner}
              line={EachRow}
              data={data}
              setData={setData}
              setDataPromotionals={setDataPromotionals}
              setDataProduct={setDataProduct}
              product={EachRow}
              />
              {NonActiveInner == EachRow.code_product.replace(" ","").replace(".","").replace("-","").replace(",","") && (
                <VienesasTableCategoreActive
                  ActiveInner={ActiveInner}
                  current={EachRow.code_product.replace(" ","").replace(".","").replace("-","").replace(",","")}
                  offices={EachRow.offices_data_rows}
                  code_product={EachRow.code_product}
                  data={data}
                  setData={setData}
                  setDataProduct={setDataProduct}
                  product={EachRow}
                  setCodigoPop={setCodigoPop}
                  productDescription={EachRow.description}
                  productBrand={EachRow.brand}
                />
              )}
              </>
            })
          }
            

            
          
            
            
          </tbody>
        </Table>
      </div>
    </div>
  );
};
