import React, { useState, useEffect, useRef } from 'react'
import AvatarPop from '../assets/img/AvatarPop.svg'
import Actividad from '../assets/img/Actividad.svg'
import Resumen from '../assets/img/Icono Resumen.svg'
import Cerrar from '../assets/img/Cerrar.svg'
import Avatargirl from '../assets/img/Avatargirl.svg'
import { Loader } from './Loader'
import { config, formatNumberES } from '../config'
import TableauReport from 'tableau-react'

function getComments(promotion_id) {
	const session_token = localStorage.getItem('session')
	if (session_token) {
		return fetch(
			config.apiUrl + `/comment_pb/activity/` + promotion_id,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				return response.json()
			})
			.then((response) => {
				return response
			})
	}
	return {
		//return empty json
	}
}

export const CodigoPopNew = ({
	setCodigoPop,
	dataProduct,
	data,
	categoria,
	subcategoria,
	id_promocion,
	promotion,
	tradicional = false,
}) => {
	console.log('Data producto vista producto ----------')
	console.log(dataProduct)
	console.log('Data general vista producto')
	console.log(data)
	const [TextareaFocus, setTextareaFocus] = useState(false)
	const [disableCheck, setdisableCheck] = useState(true)
	const [dataView, setDataView] = useState(null)
	const [dataComments, setDataComments] = useState(null)
	const [tokenTableau, setTokenTableau] = useState()
	const [loader, setLoader] = useState(false)
	const [flag, setFlag] = useState(false)
	let url_ = ''
	let parameters_ = {}

	const refCatalogModern = useRef(null)

	function getViewProduct(
		code_product,
		customer,
		type = null,
		promotion_id = null
	) {
		const session_token = localStorage.getItem('session')
		//if there is a session token stored
		setLoader(true)
		if (session_token) {
			let url_base = ``
			if (type == 'tradicional') {
				if (dataProduct.office_code != null) {
					url_base = `/traditional_catalog/product_view/${promotion_id}/${code_product}/${customer}`
				} else {
					url_base = `/traditional_catalog/product_view/${promotion_id}/${code_product}`
				}
				console.log('url', config.apiUrl + url_base)
			} else {
				url_base = `/catalog/product_view/${code_product}/${customer}`
			}
			return fetch(config.apiUrl + url_base, {
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			})
				.then((response) => {
					if (response.ok) {
						return response.json()
					}
				})
				.then((response) => {
					setLoader(false)
					return response
				})
				.catch((error) => {
					setLoader(false)
					console.log('err::: ', error)
				})
		}
	}

	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatalogModern.current.contains(e.target)) {
			setCodigoPop(false)
		}
	}

	if (tradicional) {
		url_ = 'https://tableauserver-azure.pricemaker.io/t/PF/views/'
		let complete_code = dataProduct.code_product
		if (dataProduct.office_code != null) {
			//Cuando es un producto por una oficina.
			complete_code = dataProduct.code_product + '-' + dataProduct.office_code
			parameters_ = {
				'Id G': [complete_code], //dataProduct.code_product
			}
			url_ += 'basetest_trad/titi3'
		} else {
			//Cuando es un producto en general
			let array_str = "";
			dataProduct.offices_data_rows.forEach((element) => {
				array_str += element.office_code+'-'+element.office_name + ',';
			});
			array_str = array_str.slice(0, -1);
			parameters_ = {
				'Codigo Producto': [complete_code], //dataProduct.code_product
				'Oficinas': [array_str],
			}
			url_ += 'basetest_trad2/dash'
		}
	} else {
		url_ =
			'https://tableauserver-azure.pricemaker.io/t/PF/views/basetest/titi2'
		parameters_ = {
			ID1: [dataProduct.code_product + '-' + data.cadena_details.name_customer],
		}
	}

	const fetchApi = async (tradicional = false) => {
		let url = 'https://optimusprice.pricemaker.io/api/getChartTrust'
		//https://tableauserver-azure.pricemaker.io/t/Soprole/views/basetest/titi2
		let dtarget_site = 'PF'
		let project = 'basetest'
		let page = 'titi2'
		if (tradicional) {
			dtarget_site = 'PF'
			project = 'basetest_trad'
			page = 'titi3'
		}
		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				token: window.sessionStorage.getItem('token') + '-2chzRY3F3I9@',
				tabs_permission: 2,
				dtarget_site: dtarget_site,
				project: project,
				page: page,
			}),
		})

		console.log(response.status)
		const responseJSON = await response.json()
		setTokenTableau(responseJSON.token_server)
	}

	useEffect(() => {
		fetchApi(tradicional)
		if (tradicional) {
			console.log('Producto!!!--------- Vista tradicional')
			console.log(dataProduct)
			getViewProduct(
				dataProduct?.code_product,
				dataProduct?.office_code,
				'tradicional',
				id_promocion
			)
				.then((result) => {
					setDataView(result)
				})
				.catch((err) => {
					console.err(err)
				})
		} else {
			getViewProduct(
				dataProduct.code_product,
				data.cadena_details.name_customer
			)
				.then((result) => {
					setDataView(result)
				})
				.catch((err) => {
					console.err(err)
				})
		}

		
	}, [])

	useEffect(() => {
		getComments(id_promocion).then((result) => {
			setDataComments(result)
			setFlag(false)
		})
		
	}, [flag])

	const Callback = async (promotion_id) => {
		const comment = document.getElementById('input_comment').value
		const session_token = localStorage.getItem('session')
		if (session_token) {
			const response = await fetch(config.apiUrl + `/comment_pb/create`, {
				//mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify([
					{
						id_promotion: promotion_id,
						comment_text: comment,
						send_notification: 1,
					},
				]),
			})
			const responseJSON = await response.json()
			document.getElementById('input_comment').value = ''
			// setDataComments(dataComments.concat(responseJSON))
			document.getElementById('input_comment').disabled = false
			setFlag(true)
		}
	}

	const onEnter = (event, promotion_id) => {
		if (event.key === 'Enter') {
			document.getElementById('input_comment').disabled = true
			Callback(promotion_id)
		}
	}

	const getStrMonth3 = (month_str) => {
		const montsh_obj = {
			Jan: '01',
			Feb: '02',
			Mar: '03',
			Apr: '04',
			May: '05',
			Jun: '06',
			Jul: '07',
			Aug: '08',
			Sep: '09',
			Oct: '10',
			Nov: '11',
			Dec: '12',
		}
		return montsh_obj[month_str]
	}

	// const arrAuxMapeName = [
	// 	dataView?.mape_winner_name,
	// 	...dataView.other_mape_names,
	// ]
	// const arrAuxMapeValues = [dataView?.mape, ...dataView?.other_mape_values]

	return (
		<div className='popupWrapper OfertaWrapper TottusPop CodigoPop'>
			{loader && <Loader message={'Cargando...'} />}
			{(!dataView?.other_mape_names || !dataView) && (
				<Loader message={'Cargando...'} />
			)}
			{!loader && (
				<div className='popup' ref={refCatalogModern}>
					<div
						className='close_icon'
						onClick={(e) => {
							setCodigoPop(false)
						}}
					>
						<img
							src={Cerrar}
							alt=''
							onClick={(e) => {
								setCodigoPop(false)
							}}
						/>
					</div>
					<div className='left_side'>
						<h1>
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<mask
									id='mask0_2505_117424'
									maskUnits='userSpaceOnUse'
									x='0'
									y='0'
									width='24'
									height='24'
								>
									<rect width='24' height='24' fill='#D9D9D9' />
								</mask>
								<g mask='url(#mask0_2505_117424)'>
									<path
										d='M2 6H3.83299V19H2V6ZM7.45825 6H8.35438V19H7.45825V6ZM4.72912 6H6.56212V19H4.72912V6ZM19.2709 6H22V19H19.2709V6ZM10.1874 6H11.9796V19H10.1874V6ZM16.5418 6H17.4379V19H16.5418V6ZM12.9165 6H15.6456V19H12.9165V6Z'
										fill='#AEB0B0'
									/>
								</g>
							</svg>
							{dataProduct.code_product}
							<p className='special_p'>{dataProduct.description}</p>
						</h1>

						<table>
							<tbody>
								<tr>
									<td>
										<p>Línea</p>
										<h1> {tradicional ? dataView?.linea : categoria}</h1>
									</td>
									<td>
										<p>Familia</p>
										<h1>{tradicional ? dataView?.familia : subcategoria}</h1>
									</td>
									<td>
										<p>Sub Familia</p>
										<h1>
											{tradicional
												? dataProduct.subfamily
												: dataProduct.subfamily}
										</h1>
									</td>
									<td className='border-gray' width='12%'>
										<p>Marca</p>
										<h1>{tradicional ? dataView?.marca : dataProduct.brand}</h1>
									</td>
									<td>
										<p>Peso Caja</p>
										<h1>{dataView && dataView?.peso_caja}Kg</h1>
									</td>
									<td>
										<p>Peso Unidad</p>
										<h1>{dataView && dataView?.peso_unidad} g</h1>
									</td>
									<td width='5%'>
										<p>Unidades</p>
										<h1>{dataView && dataView?.unidades}</h1>
									</td>
									<td>
										<p>Formato Venta</p>
										<h1>{dataView && dataView?.formato_venta}</h1>
									</td>
								</tr>
								<tr>
									<td>
										<p>
											Costo <br /> Direc.
										</p>
										<h1>{`$${dataView?.costo_directo}` || ''}</h1>
									</td>
									<td>
										<p>
											Cto. <br /> Proyec.
										</p>
										<h1>
											{tradicional
												? `$${dataView?.costo_proyeccion}`
												: `$${dataView?.costo_proyectado}` || ''}
										</h1>
									</td>
									{tradicional ? (
										<td>
											<p>
												Ticket <br /> Vol. UMP
											</p>
											<h1>{dataView?.ticket_vol_ump}</h1>
										</td>
									) : (
										<td>
											<p style={{ height: '30px' }}>Salas</p>
											<h1>{dataView?.salas}</h1>
										</td>
									)}
									{tradicional ? (
										<td
											style={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
												height: '60px',
											}}
										>
											<p>Cartera</p>
											<h1>{dataView?.cartera}</h1>
										</td>
									) : (
										<td width='12%'
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'space-between',
											height: '60px',
										}}
										>
											<p>Catalogación</p>
											<h1><br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{dataView?.catalogacion}</h1>
										</td>
									)}

									{tradicional ? (
										<td>
											<p>
												Frec. <br /> Compra
											</p>
											<h1>{dataView?.frec_compra}</h1>
										</td>
									) : (
										<td>
											<p>
												Crecimiento <br /> Interanual
											</p>
											<h1>{dataView?.crec_interanual}</h1>
										</td>
									)}
									{tradicional && (
										<td>
											<p>
												Crec. <br /> Interanual
											</p>
											<h1>{dataView?.crec_interanual}</h1>
										</td>
									)}
									{tradicional && (
										<td>
											<p>Vta. Prom.</p>
											<h1>{dataView?.vta_prom}</h1>
										</td>
									)}
									<td>
										<p>
											Días <br /> Inventario
										</p>
										<h1>{dataView?.dias_inventario}</h1>
									</td>
								</tr>
							</tbody>
						</table>

						<h1 style={{ marginTop: 30 }}>
							<img src={Resumen} alt='' />
							Detalles Optimización
						</h1>

						<div className='table_grid'>
							<table>
								<tbody>
									<tr>
										<td>MAPE</td>
										{dataView?.other_mape_names && dataView?.other_mape_values
											? [
													dataView?.mape_winner_name,
													...dataView?.other_mape_names,
											  ]?.map((name, index) => (
													<td>
														<p>{name}</p>
														<h1 style={{ fontWeight: 700 }}>
															{dataView?.other_mape_values &&
																[
																	dataView?.mape,
																	...dataView?.other_mape_values,
																][index]}
															%
														</h1>
													</td>
											  ))
											: ''}
									</tr>
								</tbody>
							</table>

							{!tradicional && (
								<table>
									<tbody>
										<tr>
											<td>
												<p>Precio Act.</p>
												<h1>
													${dataView && formatNumberES(dataView.precio_actual)}
												</h1>
											</td>
											<td>
												<p>Precio Opt.</p>
												<h1>
													$
													{dataView &&
														formatNumberES(dataView.precio_optimizado)}
												</h1>
											</td>
											<td>
												<p>Vol. Act.</p>
												<h1>
													{dataView && formatNumberES(dataView.volumen_actual)}
												</h1>
											</td>
											<td>
												<p>Vol. Opt.</p>
												<h1>
													{dataView &&
														formatNumberES(dataView.volumen_optimizado)}
												</h1>
											</td>
										</tr>
									</tbody>
								</table>
							)}
						</div>

						<h1 style={{ marginTop: 20 }}>
							<img src={Actividad} alt='' />
							Actividad
						</h1>

						<div className='profile_box_wrapper profile_box_wrapperNew'>
							<div className='profile_box' style={{ alignItems: 'flex-start' }}>
								<img src={Avatargirl} alt='' />
								<div className='presentation' style={{ position: 'relative' }}>
									<textarea
										cols='30'
										rows='10'
										className={`${TextareaFocus && 'messageType'}`}
										placeholder='Escribe un comentario...'
										onChange={(e) => {
											if (e.target.value != '') {
												setdisableCheck(false)
											} else {
												setdisableCheck(true)
											}
										}}
										onFocus={(e) => {
											setTextareaFocus(true)
										}}
										onBlur={(e) => {
											setTextareaFocus(false)
										}}
										onKeyPress={(e) => onEnter(e, id_promocion)}
										id='input_comment'
										autocomplete='off'
									></textarea>
									{/*<button className="comment" disabled={disableCheck} 
                    onClick={(e) => {Callback()}}>
                    Comentar
                  </button>*/}
								</div>
							</div>

							<div id='section_comments' className='commentsMax'>
							{!dataComments
								? 'Cargando Comentarios ...'
								: dataComments.sort((a,b) => {return b.id_comment - a.id_comment})
								.map((EachRow) =>
										EachRow.comment_text.includes('@') ? (
											<div
												className='profile_box'
												id={'box_comment_' + EachRow.id_comment}
												style={{ marginBottom: '10px' }}
											>
												<img src={AvatarPop} alt='' />
												<div className='presentation'>
													<div className='top_area'>
														<h1>
															{EachRow.name_user} {EachRow.last_name_user}
														</h1>
													</div>
													<div className='middle_area'>
														<p>
															{EachRow.comment_text}{' '}
															<span>
																{EachRow.promotional_state.state_phase_str}
															</span>
														</p>
													</div>
													<p>
														{EachRow.date_created}
														{
														/*
														String(EachRow.date_created).split(' ')[1] +
															'/' +
															getStrMonth3(
																String(EachRow.date_created).split(' ')[2]
															) +
															'/' +
															String(EachRow.date_created).split(' ')[3]}{' '}
														a las{' '}
														{String(EachRow.date_created)
															.split(' ')[4]
															.split(':')[0] +
															':' +
															String(EachRow.date_created)
																.split(' ')[4]
																.split(':')[1]}
														*/}
														{/*<span
                        onClick={(e) => {
                          deleteComment(EachRow.id_comment)
                        }}
                        style={{marginRight:"0px"}}
                      >Eliminar</span>
                      */}
													</p>
												</div>
											</div>
										) : (
											<div className='profile_box responder'>
												<img src={AvatarPop} alt='' />
												<div className='presentation'>
													<div className='top_area'>
														<h1>
															{EachRow.name_user} {EachRow.last_name_user}
														</h1>
														<p>
														{EachRow.date_created}
														</p>
													</div>
													<input
														type='text'
														placeholder={EachRow.comment_text}
														value={EachRow.comment_text}
													/>
												</div>
											</div>
										)
								  )}
						</div>
						</div>
					</div>
					<div className='right_side'>
						<div className='gray_area' style={{ background: 'transparent' }}>
							{!tokenTableau ? (
								'Cargando Dashboard...'
							) : (
								<TableauReport
									url={url_}
									token={tokenTableau}
									options={{
										width: 600,
										height: 650,
									}}
									parameters={parameters_}
									query="?:jsdebug=n"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
