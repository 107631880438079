/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'

import tick from '../assets/img/tick.svg'
import { SwitchButton } from './SwitchButton'
import { formatNumberES } from '../config'
import { ToolTipCustom } from './ToolTipCustom'
import { ToolTipCustomList } from './ToolTipCustomList'


export const VienesasTableCategoreActiveRow = ({
	firstCol,
	office,
	code_product,
	data,
	setData,
	setDataProduct,
	setCodigoPop,
	productDescription,
	productBrand,
	product,
}) => {
	const [MouseDownState, setMouseDownState] = useState(null)

	let activate_final = false
	if (office.active_office == 1) {
		activate_final = true
	} else {
		activate_final = false
	}
	const [active, setActive] = useState(activate_final)

	useEffect(() => {
		console.log("Refresh...")
	}, [data])

	const activateOffice = (code_product, id_office, active_) => {
		 // Crear una nueva copia de data.data_rows
		 const newDataRows = data.data_rows.map((EachRow) => {
			if (EachRow.code_product === code_product) {
				// Crear una nueva copia de offices_data_rows
				const newOfficesDataRows = EachRow.offices_data_rows.map((office) => {
					if (office.id === id_office) {
						return {
							...office,
							active: active_ ? 1 : 0,
							active_office:  active_ ? 1 : 0,
							price_prop: active_ ? EachRow.price_prop : office.price_prop,
						};
					}
					return office;
				});
	
				return {
					...EachRow,
					ever_modified: 1,
					prop_price_modified: 1,
					offices_data_rows: newOfficesDataRows,
				};
			}
			return EachRow;
		});
	
		// Actualizar el estado con el nuevo arreglo
		setData({ ...data, data_rows: newDataRows });
	}

	const CapitalizeFirstLetter = (str) => {
		const arr = str.toLowerCase().split(' ')
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
		}
		const str2 = arr.join(' ')
		return str2
	}

	return (
		<tr
			className={`activate familia1 InnerActiveSqguimiento InnerActiveSqguimientoNew ${
				!active && 'InnerActiveSqguimientoFalse'
			} `}
			style={{ display: 'table-row', background: '#fff' }}
		>
			<td colSpan={5} className='inner_active'>
				<span></span>
			</td>
			<td>
				<div className='display_flex  gray'>
					<a
						href='#'
						onClick={(e) => {
							e.preventDefault()
							// setDataProduct({
							// 	code_product: code_product + '-' + office.office_id,
							// 	description: productDescription,
							// 	brand: productBrand,
							// })
							console.log('al set estado',{ ...product, office_code: office?.office_code })
							setDataProduct({ ...product, office_code: office?.office_code })
							setCodigoPop(true)
						}}
					>
						{CapitalizeFirstLetter(firstCol)}
					</a>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<SwitchButton
						setActive={setActive}
						active={active}
						data={data}
						setData={setData}
						code_product={code_product}
						id_office={office.id}
						activateOffice={activateOffice}
					/>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{office.vol_act}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  green'>
					<p>{office.vol_opt}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{office.vol_prop}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p>{office.vol_prop_oi}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray cursor_row_active' onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					>
						<ToolTipCustomList 
						//value={`$Unidad: $${office?.tltp_pxu_act} $Venta: $${office?.tltp_pv_act}`}
						list={[`$Unidad: $${office?.tltp_pxu_act}`, `$Venta: $${office?.tltp_pv_act}`]}
						width={'100px'}
						/>
					<p id={'child_price_act_' + code_product + '_' + office.id}>
						${office.price_act}
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray cursor_row_active'
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}>
					<ToolTipCustomList 
					// value={`$Unidad: $${office?.tltp_pxu_opt} $Venta: $${office?.tltp_pv_opt}`}
					list={[`$Unidad: $${office?.tltp_pxu_opt}`,`$Venta: $${office?.tltp_pv_opt}`]}

					width={'100px'} />
					<p>${office.price_opt}</p>
				</div>
			</td>
			<td>
				<div className='display_flex green cursor_row_active'
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}>
					<ToolTipCustomList 
					//value={`$Unidad: $${office?.tltp_pxu_act} $Venta: $${office?.tltp_pv_prop}`}
					list={[`$Unidad: $${office?.tltp_pxu_act}`, `$Venta: $${office?.tltp_pv_prop}`]}
					width={'100px'} />
					<p id={'child_price_prop_' + code_product + '_' + office.id}>
						${formatNumberES(office.price_prop)}
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p id={'child_price_percent_' + code_product + '_' + office.id}>
						{office.opt_act}%
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>${office.mg_act}</p>
				</div>
			</td>{' '}
			<td>
				<div className='display_flex  gray'>
					<p>${office.mg_opt}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>${office.mg_prop}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray cursor_row_active'
						onMouseUp={(e) => {
							e.target.querySelector('.ToolTipCustom').classList.remove('show')

							clearInterval(MouseDownState)
						}}
						onMouseDown={(e) => {
							if (e.button != 0) {
								return false
							}
							let MouseDownTimeOut = setTimeout(() => {
								e.target.querySelector('.ToolTipCustom').classList.add('show')
							}, [50])

							setMouseDownState(MouseDownTimeOut)
						}}
						>
							<ToolTipCustomList 
							// value={`RO $/UMP: ${office?.tltp_ro_price_kg}, RO $: ${office?.tltp_ro_price}, Crítico: $${office?.tltp_critical_price}`}
							list={[`RO $/UMP: ${office?.tltp_ro_price_kg}`, `RO $: ${office?.tltp_ro_price}, Crítico: $${office?.tltp_critical_price}`]}
							width={'100px'} />
					<p>{office.ro_perc_prop}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>{office.uplift}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray cursor_row_active'
					onMouseUp={(e) => {
						e.target.querySelector('.ToolTipCustom').classList.remove('show')

						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							e.target.querySelector('.ToolTipCustom').classList.add('show')
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					>
						<ToolTipCustom value={`RO. Incr. $${office?.tltp_ro_incre_perc}`}
						width={'130px'} />
					<p>
						{
							office.ro_incr
						}
						%
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray'>
					<p>${office.pvp_sug.split(",")[0]}</p>
				</div>
			</td>
			<td colSpan={2}></td>
		</tr>
	)
}
