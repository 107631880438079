import React from "react";
import Icono_cata from "../assets/img/Iconoexport.svg";
export const PromVariable = ({ setActive }) => {
  return (
    <div
      className="popupWrapper"
      id="popupCatalog"
      onClick={(e) => {
        if (e.target.id == "popupCatalog") {
          setActive(false);
        }
      }}
    >
      <div className="popup SaveCatalog ExportPop" style={{ height: 490 }}>
        <div className="topexport">
          <h1 style={{ marginLeft: 0 }}>Variables Promocionales</h1>
        </div>
        <p>
          Define las variables para <a href="#">X6000</a>
        </p>

        <div className="input_container">
          <label htmlFor="">Mecánica</label>
          <div className="input_wrapper">
            <input type="text" placeholder="No" />
            <div className="box">
              <h1>Mecánica</h1>
              <p>24,0%</p>
            </div>
          </div>
        </div>

        <div className="input_container">
          <label htmlFor="">Exhibición</label>
          <div className="input_wrapper">
            <input type="text" placeholder="No" />
            <div className="box">
              <h1>Exhibición</h1>
              <p>24,0%</p>
            </div>
          </div>
        </div>
        <div className="input_container">
          <label htmlFor="">Activación</label>
          <div className="input_wrapper">
            <input type="text" placeholder="No" />
            <div className="box">
              <h1>Activación</h1>
              <p>24,0%</p>
            </div>
          </div>
        </div>
        <div className="input_container">
          <label htmlFor="">Inv. Adicional</label>
          <div className="input_wrapper">
            <input type="text" placeholder="No" />
            <div className="box">
              <h1>Inv. Adicional</h1>
              <p>24,0%</p>
            </div>
          </div>
        </div>
        <div className="input_container">
          <label htmlFor="">Adherencia</label>
          <div className="input_wrapper">
            <input type="text" placeholder="No" />
            <div className="box">
              <h1>Adherencia</h1>
              <p>24,0%</p>
            </div>
          </div>
        </div>

        <div className="buttons_wrapper">
          <button>Cancelar</button>
          <button>Exportar</button>
        </div>
      </div>
    </div>
  );
};
