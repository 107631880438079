import React, { useCallback, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import Flecha from '../assets/img/Flecha_green.svg'
import Off from '../assets/img/Off.svg'
import orange_down from '../assets/img/orange_down.svg'
import { ToolTipCustomList } from './ToolTipCustomList'
import { ResumenLineaTableTr } from './ResumenLineaTableTr'
import { sortData } from '../utils/sortData'

export const ResumenLineaTable = ({
	data,
	vista,
	id_promocion,
	nombre_promocion,
	oficina,
	fecha,
	linea_,
	nivel
}) => {
	const [MouseDownState, setMouseDownState] = useState(null)

	const [sortKey, setSortKey] = useState()
	const [sortOrder, setSortOrder] = useState('asc')

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const sortedData = useCallback(
		sortData({
			tableData: data.data_rows,
			sortKey,
			reverse: sortOrder === 'desc',
		}),
		[data, sortKey, sortOrder]
	)

	const changeSort = (key) => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		setSortKey(key)
	}

	const avarageCompare = (firstColumn, secondColumn) => {
		const total_rows = data.data_rows.length
		let sum_first_column = 0
		data.data_rows.map((row) => {
			sum_first_column =
				sum_first_column +
				parseFloat(
					String(row[firstColumn])
						.split(' ')[0]
						.split('%')[0]
						.replace('.', '')
						.replace(',', '.')
				)
		})
		let avg_first_column = sum_first_column / total_rows

		let sum_second_column = 0
		data.data_rows.map((row) => {
			sum_second_column =
				sum_second_column +
				parseFloat(
					String(row[secondColumn])
						.split(' ')[0]
						.split('%')[0]
						.replace('.', '')
						.replace(',', '.')
				)
		})
		let avg_second_column = sum_second_column / total_rows
		//alert(firstColumn+" & "+secondColumn+" : "+avg_first_column +">="+ avg_second_column);
		return avg_first_column >= avg_second_column
	}

	return (
		<div className='CataLogoResumenTable CataLogoResumenTableNew ResumenLineaTable'>
			<Table>
				<thead style={{ height: `40 !important` }}>
					<tr>
						<th className='fimila border-right-gray' rowSpan={2}>
							{nivel}
						</th>
						<th colSpan={4} className='border-right-gray'>
							{avarageCompare('vol_prop', 'vol_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Volumen</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Volumen</p>
								</div>
							)}
						</th>
						<th colSpan={5} className='border-right-gray'>
							{avarageCompare('meta_prop', 'meta_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Cumplimiento Meta</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Cumplimiento Meta</p>
								</div>
							)}
						</th>
						<th colSpan={3} className='border-right-gray'>
							{avarageCompare('precio_prop', 'precio_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Precio</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Precio</p>
								</div>
							)}
						</th>
						<th colSpan={3} className='border-right-gray'>
							{avarageCompare('margen_prop', 'margen_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Margen Bruto</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Margen Bruto</p>
								</div>
							)}
						</th>
						<th colSpan={3} className='border-right-gray'>
							{avarageCompare('margen_prop', 'margen_act') ? (
								<div className='display_flex green'>
									<img src={Flecha} alt='' className='mr-5' />
									<p>Resultado MM</p>
								</div>
							) : (
								<div className='display_flex orange'>
									<img src={orange_down} alt='' className='mr-5' />
									<p>Resultado MM</p>
								</div>
							)}
						</th>

						<th colSpan={2} className='border-right-gray'>
							<div className='display_flex blue'>
								<p>Res. Campaña</p>
							</div>
						</th>

						<th colSpan={2}>
							<div className='display_flex blue'>
								<p>Ofertas Int.</p>
							</div>
						</th>
					</tr>

					<tr>
						<td>
							<div
								className='display_flex gray'
								onClick={() => changeSort('vol_act')}
							>
								<p>Act.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  gray'
								onClick={() => changeSort('vol_opt')}
							>
								<p>Opt.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  center_justify gray'
								onClick={() => changeSort('vol_prop')}
							>
								<p>Prop.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex  center_justify gray'
								onClick={() => changeSort('vol_prop')}
							>
								<p>Prop + OI</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex gray'
								onClick={() => changeSort('meta')}
							>
								<p>Meta.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  gray'
								onClick={() => changeSort('meta_act')}
							>
								<p>Act.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  center_justify gray'
								onClick={() => changeSort('meta_opt')}
							>
								<p>Opt.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  center_justify gray'
								onClick={() => changeSort('meta_prop')}
							>
								<p>Prop.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  center_justify gray'
								onClick={() => changeSort('meta_prop_plus_oi')}
							>
								<p> Prop + OI</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex gray'
								onClick={() => changeSort('precio_act')}
							>
								<p>Act.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  gray'
								onClick={() => changeSort('precio_opt')}
							>
								<p>Opt.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex  center_justify gray'
								onClick={() => changeSort('precio_prop')}
							>
								<p>Prop.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex gray'
								onClick={() => changeSort('margen_act')}
							>
								<p>Act.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  gray'
								onClick={() => changeSort('margen_opt')}
							>
								<p>Opt.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex  center_justify gray'
								onClick={() => changeSort('margen_prop')}
							>
								<p>Prop.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex gray'
								onClick={() => changeSort('res_mm_act')}
							>
								<p>Act.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  gray'
								onClick={() => changeSort('res_mm_opt')}
							>
								<p>Opt.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex  center_justify gray'
								onClick={() => changeSort('res_mm_prop')}
							>
								<p>Prop.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex gray'
								onClick={(e) => {changeSort('n_act')}}
								style={{zIndex: 1000}}
							>
								<p>N° Act.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  gray'
								onClick={() => changeSort('imp')}
								style={{zIndex: 1000}}
							>
								<p>Imp</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>

						<td>
							<div
								className='display_flex gray'
								onClick={() => changeSort('oi_n_act')}
							>
								<p>N° Act.</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
						<td>
							<div
								className='display_flex  gray'
								onClick={() => changeSort('oi_imp')}
							>
								<p>Imp. Kg</p>
								<img style={{ cursor: 'pointer' }} src={Off} alt='' />
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					{data &&
						sortedData.map((EachRow) => {
							return (
								<ResumenLineaTableTr
									line={EachRow}
									vista={vista}
									id_promocion={id_promocion}
									nombre_promocion={nombre_promocion}
									oficina={oficina}
									fecha={fecha}
									linea_={linea_}
								/>
							)
						})}
				</tbody>
			</Table>
		</div>
	)
}
