export let Filter = [
  {
    id: "10",
    name: "Estado",
  },
  {
    id: "1",
    name: "Todos",
  },
  {
    id: "2",
    name: "En Edición",
  },
  {
    id: "3",
    name: "Descartadas",
  },
  {
    id: "10",
    name: "Modalidad",
  },
  {
    id: "4",
    name: "Todas",
  },
  {
    id: "5",
    name: "Catálogo",
  },
  {
    id: "6",
    name: "Oferta",
  },
  {
    id: "10",
    name: "Canal",
  },
  {
    id: "7",
    name: "Todos",
  },
  {
    id: "8",
    name: "Moderno",
  },
  {
    id: "9",
    name: "Tradicional",
  },
];

export let FilterColumn1 = [
  {
    id: "10",
    name: "Estado",
  },
  {
    id: "1",
    name: "Todos",
  },
  {
    id: "2",
    name: "En edición",
  },
  {
    id: "3",
    name: "Descartada",
  },
];//Quitar esto y borrar comentario general para agregar otras opciones
/*
  {
    id: "10",
    name: "Modalidad",
  },
  {
    id: "11",
    name: "Todos",
  },
  {
    id: "4",
    name: "Catálogo",
  },
  {
    id: "5",
    name: "Oferta",
  },
  {
    id: "10",
    name: "Canal",
  },
  {
    id: "111",
    name: "Todos",
  },
  {
    id: "6",
    name: "Moderno",
  },
  {
    id: "7",
    name: "Tradicional",
  },
];
*/
export let FilterColumn2 = [
  {
    id: "10",
    name: "Estado",
  },
  {
    id: "1",
    name: "Todos",
  },
  {
    id: "2",
    name: "En edición",
  },
  {
    id: "3",
    name: "En revisión",
  },
  {
    id: "4",
    name: "Descartada",
  },
  {
    id: "5",
    name: "Condicional",
  },
  {
    id: "6",
    name: "Rechazada",
  },
];//Quitar esto y borrar comentario general para agregar otras opciones
/*
  {
    id: "10",
    name: "Modalidad",
  },
  {
    id: "11",
    name: "Todos",
  },
  {
    id: "12",
    name: "Catálogo",
  },
  {
    id: "13",
    name: "Oferta",
  },
  {
    id: "10",
    name: "Canal",
  },
  {
    id: "111",
    name: "Todos",
  },
  {
    id: "14",
    name: "Moderno",
  },
  {
    id: "15",
    name: "Tradicional",
  },
];
*/
export let FilterColumn3 = [
  {
    id: "10",
    name: "Estado",
  },
  {
    id: "1",
    name: "Todos",
  },
  {
    id: "2",
    name: "En negociación",
  },
  {
    id: "3",
    name: "Sin acuerdo",
  },
  {
    id: "4",
    name: "Pre-aprobada",
  },
];//Quitar esto y borrar comentario general para agregar otras opciones
/*
  {
    id: "10",
    name: "Modalidad",
  },
  {
    id: "11",
    name: "Todos",
  },
  {
    id: "12",
    name: "Catálogo",
  },
  {
    id: "13",
    name: "Oferta",
  },
  {
    id: "10",
    name: "Canal",
  },
  {
    id: "111",
    name: "Todos",
  },
  {
    id: "14",
    name: "Moderno",
  },
  {
    id: "15",
    name: "Tradicional",
  },
];
*/
export let FilterColumn4 = [
  {
    id: "10",
    name: "Estado",
  },
  {
    id: "1",
    name: "Todos",
  },
  {
    id: "2",
    name: "Confirmada",
  },
  {
    id: "3",
    name: "Actualizada",
  },
  {
    id: "4",
    name: "Cancelada Cliente",
  },
  {
    id: "5",
    name: "Cancelada PF",
  },
  {
    id: "6",
    name: "Ejecutada",
  },
];//Quitar esto y borrar comentario general para agregar otras opciones
/*
  {
    id: "10",
    name: "Modalidad",
  },
  {
    id: "11",
    name: "Todos",
  },
  {
    id: "12",
    name: "Catálogo",
  },
  {
    id: "13",
    name: "Oferta",
  },
  {
    id: "10",
    name: "Canal",
  },
  {
    id: "111",
    name: "Todos",
  },
  {
    id: "14",
    name: "Moderno",
  },
  {
    id: "15",
    name: "Tradicional",
  },
];
*/