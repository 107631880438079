import React, { useState, useEffect } from "react";
import { CatálogoDropdown } from "./CatálogoDropdown";
import Cerrar from "../assets/img/Cerrar.svg";
export const PromotionalVariable = ({ setPromotionalVariable, data, setData, dataPromotionals }) => {
  const [Mecanica, setMecanica] = useState("1xY");
  const [Exhibición, setExhibición] = useState("Isla");
  const [Activación, setActivación] = useState("Lista");
  const [Adicional, setAdicional] = useState("");
  const [Adherencia, setAdherencia] = useState("");
  
  //setAdherencia(dataPromotionals.promotional_values.adherence);
  //setAdicional(dataPromotionals.promotional_values.aditional_inv);
    /*
  if(EachRow.promotional_values.mechanic.length > 0){
                  total_change += 1;
                }
                if(EachRow.promotional_values.activation.length > 0){
                  total_change += 1;
                }
                if(EachRow.promotional_values.adherence.length > 0){
                  total_change += 1;
                }
                if(EachRow.promotional_values.exhibition.length > 0){
                  total_change += 1;
                }
                if(EachRow.promotional_values.aditional_inv.length > 0){
                  total_change += 1;
                }
  */
 let promotional_vars_all = ['mechanic', 'activation','adherence','exhibition','aditional_inv'];

 promotional_vars_all.map((key) => {
    try{
      if(dataPromotionals.promotional_values[key]){console.log("Ok");}
    }catch{
      dataPromotionals.promotional_values[key] = "";
    }
 });


  return (
    <div className="popupWrapper" id="popupCatalog" style={{ zIndex: 99999 }}>
      <div className="popup SaveCatalog ExportPop PromotionalVariable">
        <div className="close_icon">
          <img
            src={Cerrar}
            alt=""
            onClick={(e) => {
              setPromotionalVariable(false);
            }}
          />
        </div>
        <div className="topexport">
          <h1 style={{ marginLeft: 0 }}>Variables Promocionales</h1>
        </div>
        <p>
          Define las variables para <a href="#">{dataPromotionals.product_code}</a>
        </p>

        <div className="input_container">
          <label htmlFor="">Mecánica</label>
          <div className="input_wrapper">
            <CatálogoDropdown
              setVal={setMecanica}
              head={dataPromotionals.promotional_values.mechanic}
              list={["-","1xY", "2xY", "3xY", "4xY","5xY", "6xY", "7xY", "8xY", "9xY", "10xY", "11xY", "12xY"]}
              element_id="mecanica_input"
            />
            <input type="hidden" id="mecanica_input" defaultValue={dataPromotionals.promotional_values.mechanic}/>
            {/*
            <div className="box">
              <h1>Mecánica</h1>
              <p>{Mecanica}</p>
            </div>
            */}
          </div>
        </div>

        <div className="input_container">
          <label htmlFor="">Exhibición</label>
          <div className="input_wrapper">
            <CatálogoDropdown
              head={dataPromotionals.promotional_values.exhibition}
              list={["-","Isla", "Cabecera", "Cabecera + Isla"]}
              setVal={setExhibición}
              element_id="exhibicion_input"
            />
            <input type="hidden" id="exhibicion_input" defaultValue={dataPromotionals.promotional_values.exhibition}/>
            {/*
            <div className="box">
              <h1>Exhibición</h1>
              <p>{Exhibición}</p>
            </div>
            */}
          </div>
        </div>
        <div className="input_container">
          <label htmlFor="">Activación</label>
          <div className="input_wrapper">
            <CatálogoDropdown
              head={dataPromotionals.promotional_values.activation}
              list={["-","Lista/Catálogo","Lista","2x1", "Volante", "TRAFICO", "YAPA"]}
              setVal={setActivación}
              element_id="activacion_input"
            />
            <input type="hidden" id="activacion_input" defaultValue={dataPromotionals.promotional_values.activation}/>
            {/*
            <div className="box">
              <h1>Activación</h1>
              <p>{Activación}</p>
            </div>
            */}
          </div>
        </div>
        <div className="input_container">
          <label htmlFor="">$Inv. Adicional (CLP)</label>
          <div className="input_wrapper">
            
            <input
              id="inversion_adicional_input"
              type="number"
              placeholder="Ej: 100000"
              className={`${Adicional != "" && "active"}`}
              //value={Adicional}
              defaultValue={dataPromotionals.promotional_values.aditional_inv}
              onChange={(e) => {
                setAdicional(e.target.value);
              }}
            />
            {/*
            <div className="box">
              <h1>Inv. Adicional</h1>
              <p>${Adicional == "" ? "0" : Adicional}</p>
            </div>
            */}
          </div>
        </div>
        <div className="input_container">
          <label htmlFor="">Adherencia % (0 - 100)</label>
          <div className="input_wrapper">
            
            <input
              id="adherencia_input"
              type="number"
              placeholder="Ej: 24.3"
              className={`${Adherencia != "" && "active"}`}
              //value={Adherencia}
              defaultValue={dataPromotionals.promotional_values.adherence}
              onChange={(e) => {
                setAdherencia(e.target.value);
              }}
            />
            {/*
            <div className="box">
              <h1>Adherencia</h1>
              <p>{Adherencia == "" ? "00.0" : Adherencia}%</p>
            </div>
            */}
          </div>
        </div>

        <div className="buttons_wrapper">
          <button onClick={(e)=>{setPromotionalVariable(false);}}>Cancelar</button>
          <button onClick={(e) => {
            let total_change = 0;
            console.log("dataPromotionals############################");
            console.log(dataPromotionals);
            const newData = { ...data };
            newData.data_rows.map((EachRow) => {
              if((dataPromotionals.product_id === EachRow.id) || (dataPromotionals.product_id === EachRow.product_id)){
                //alert("id::: "+EachRow.id+" --- "+dataPromotionals.product_id);
                EachRow.promotional_values.mechanic = document.getElementById("mecanica_input").value;
                EachRow.promotional_values.activation = document.getElementById("activacion_input").value;
                EachRow.promotional_values.adherence = document.getElementById("adherencia_input").value;
                EachRow.promotional_values.exhibition = document.getElementById("exhibicion_input").value;
                EachRow.promotional_values.aditional_inv = document.getElementById("inversion_adicional_input").value;
                EachRow.ever_simulated = 1;
                if(EachRow.promotional_values.mechanic.length > 0){
                  total_change += 1;
                }
                if(EachRow.promotional_values.activation.length > 0){
                  total_change += 1;
                }
                if(EachRow.promotional_values.adherence.length > 0){
                  total_change += 1;
                }
                if(EachRow.promotional_values.exhibition.length > 0){
                  total_change += 1;
                }
                if(EachRow.promotional_values.aditional_inv.length > 0){
                  total_change += 1;
                }
              document.getElementById("var_promo_"+dataPromotionals.product_id).innerHTML = total_change+"/5";
              }
            });
            setData(newData)
            console.log("Data Variables Promocionales:::")
            console.log(data)
            setPromotionalVariable(false);
          }}>Aceptar</button>
        </div>
      </div>
    </div>
  );
};
