import React, { useState, useEffect, useRef } from 'react'
import '../../assets/css/newPFOffer/oferta.css'
import bookmark from '../../assets/img/bookmark.svg'
import edit from '../../assets/img/edit.svg'
import calender from '../../assets/img/calender.svg'
import shop from '../../assets/img/shop.svg'
import Jumbo from '../../assets/img/Jumbo.svg'
import Cerrar from '../../assets/img/Cerrar.svg'
import errorExplanation from '../../assets/img/error_explanation.svg'
// import warning from '../../assets/img/warning.png'
import { CatálogoDropdown } from '../CatálogoDropdown'
import {CatálogoDropdownDate} from '../CatálogoDropdownDate'
import { useNavigate } from 'react-router-dom'
import { config } from '../../config'
import { Loader } from '../Loader'
import tick_explanation from '../../assets/img/tick_explanation.svg'


function getListMonthsYear() {
	const months_str = [
		'',
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	]

	let init_sellin_date = '2020-01-01'
	let end_sellin_date = '2035-01-01'
	let init_sellout_date = '2020-01-01'
	let end_sellout_date = '2037-01-01'
	if (document.getElementById('init_sellin_date')) {
		if (
			document.getElementById('init_sellin_date').value != '' &&
			document.getElementById('end_sellin_date').value != '' &&
			document.getElementById('init_sellout_date').value != '' &&
			document.getElementById('end_sellout_date').value != ''
		) {
			init_sellin_date = document.getElementById('init_sellin_date').value
			end_sellin_date = document.getElementById('end_sellin_date').value
			init_sellout_date = document.getElementById('init_sellout_date').value
			end_sellout_date = document.getElementById('end_sellout_date').value
		}
	}
	let four_dates = [
		init_sellin_date,
		end_sellin_date,
		init_sellout_date,
		end_sellout_date,
	]
	const sortedDates = four_dates.sort((a, b) => a - b)
	const list_dates = dateRange(
		sortedDates[0],
		sortedDates[sortedDates.length - 1]
	)
	let final_dates = []
	list_dates.map((date) => {
		final_dates.push(
			months_str[parseInt(date.split('-')[1])] + '-' + date.split('-')[0]
		)
	})
	return final_dates
}

function dateRange(startDate, endDate) {
	var start = startDate.split('-')
	var end = endDate.split('-')
	var startYear = parseInt(start[0])
	var endYear = parseInt(end[0])
	var dates = []

	for (var i = startYear; i <= endYear; i++) {
		var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
		var startMon = i === startYear ? parseInt(start[1]) - 1 : 0
		for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
			var month = j + 1
			var displayMonth = month < 10 ? '0' + month : month
			dates.push([i, displayMonth, '01'].join('-'))
		}
	}
	return dates
}

function getCustomers() {
	const session_token = localStorage.getItem('session')
	if (session_token) {
		return fetch(config.apiUrl + `/customer/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}
	//there are no session token stored
	return {}
}

export const Oferta = ({setActive, setOfertExistenteState, dataPromotions, open, setActiveBack}) => {
	console.log("INFO EXISTENTE::::: ", dataPromotions);
	const [dataCustomers, setDataCustomers] = useState(null)
	const [dataCustomersList, setDataCustomersList] = useState(null)
	const navigate = useNavigate()
	const [dateList, setDateList] = useState(getListMonthsYear())
	const [mesTexto, setMesTexto] = useState('-')
	const [contenedorCadena, setContenedorCadena] = useState('-')
	const [nombreCatalogo, setNombreCatalogo] = useState('')
	const [alertInsert, setAlertInsert] = useState(null)
	const [loading, setLoading] = useState(false)
	const [activeModal, setActiveModal] = useState(open)
	const [activeExistente, setActiveExistente] = useState(false)

	useEffect(() => {
		//setData({promotions: window.catalog_categories})
		getCustomers().then((result) => {
			let list_customers = []
			result.forEach((element) => {
				console.log(element.name_customer)
				list_customers.push(element.name_customer)
			})
			setDataCustomersList(list_customers)
			setDataCustomers(result)
		})
	}, [])

	const refCatalogModern = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatalogModern.current.contains(e.target)) {
			setActive(false)
			setActiveExistente(false)
		}
	}

	const addPromotion = async () => {
		const name_promotion = nombreCatalogo //document.getElementById("name_promotion").value;
		const init_sellin_date = document.getElementById('init_sellin_date').value
		const end_sellin_date = document.getElementById('end_sellin_date').value
		const init_sellout_date = document.getElementById('init_sellout_date').value
		const end_sellout_date = document.getElementById('end_sellout_date').value
		const month_date = document.getElementById('month_date').value.split('-')[0]
		const year_date = document.getElementById('month_date').value.split('-')[1]
		const name_customer = document.getElementById('customer_name').value
		const id_customer = document.getElementById('customer_id').value
		const fields_to_validate = [
			'init_sellin_date',
			'end_sellin_date',
			'init_sellout_date',
			'end_sellout_date',
			'month_date',
			'customer_name',
			'customer_id',
			'customer_id',
		]
		let validator = true

		fields_to_validate.map((field) => {
			console.log(
				'Validando !!! ' + field + ' ' + document.getElementById(field).value
			)
			if (document.getElementById(field).value != '') {
				document.getElementById(field).classList.remove('error_oferto')
				document.getElementById(field).classList.add('success_oferto')
			} else {
				document.getElementById(field).classList.add('error_oferto')
				document.getElementById(field).classList.remove('success_oferto')
				validator = false
				return false
			}
		})
		if (!validator) {
			return false
		}
		const session_token = localStorage.getItem('session')

		try {
			setLoading(true)
			const response = await fetch(config.apiUrlOferta + `/offer/create`, {
				//mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify(
					{
						customer_id: id_customer,
						customer_name: name_customer.toUpperCase(),
						promotion_name: name_promotion,
						description: 'SIN DESCRIPCION',
						start_sellin: init_sellin_date,
						end_sellin: end_sellin_date,
						start_sellout: init_sellout_date,
						end_sellout: end_sellout_date,
						promotion_month_str: month_date,
						promotion_year_str: year_date,
						type_promotion_id: 3,
					},
				),
			})
			const responseJSON = await response.json()

			navigate('/IngresoProduct/' + responseJSON.promotion_id, {
				inserted_promotion_id: responseJSON.promotion_id,
				promotion_id: responseJSON.promotion_id,
			})
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
		{loading && <Loader message={'Cargando...'}></Loader>}
		{!loading && 
		<div className='popupWrapper OfertaWrapper OfertanewPF'>
			<div className='popup'>
				<div
					className='OfertaWrapper-bg'
					onClick={(e) => setActive(false)}
				></div>
				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<h1>
					<img src={bookmark} alt='' />
					<span>
						<span>PROMO</span>BOOSTER:
					</span>
					Oferta
				</h1>
				<div className='oferta_buttons'>
					<button className='active-btn'>Crear Oferta nueva</button>
					<button
						onClick={(e) => {
							setActive(false)
							setOfertExistenteState(true)
						}}
					>
						{ /* Crear a partir de Oferta existente */ }
					</button>
				</div>

				<div className='box_border'>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={edit} alt='' />
							Nombre de Oferta
						</label>
						<input
							id='name_promotion'
							type='text'
							placeholder='Nombre de la Oferta'
							maxLength={25}
							onBlur={(e) => {
								setNombreCatalogo(e.target.value)
								if (e.target.value != '') {
									e.target.classList.remove('error_oferto')
									e.target.classList.add('success_oferto')
								} else {
									e.target.classList.add('error_oferto')
									e.target.classList.remove('success_oferto')
								}
							}}
							style={{ color: '#252525' }}
						/>
						<img src={errorExplanation} alt='' />
						<img src={tick_explanation} alt='' />
					</div>
				</div>

				<div className='box_border' style={{marginTop: 17}}>
					<div className='input_wrapper fechas-wrapper'>
						<label htmlFor=''>
							<img src={calender} alt='' />
							Fechas
						</label>
					</div>

					<div className='input_container'>
						<div className='input_wrapper'>
							<label htmlFor=''>Sell-In</label>

							<CatálogoDropdownDate
								head='Selecciona una fecha'
								element_id='init_sellin_date'
								setDateList={setDateList}
								setAlertInsert={setAlertInsert}
							/>
							<input type='hidden' id='init_sellin_date' />
							<input type='hidden' id='end_sellin_date' />
						</div>

						<div className='input_wrapper'>
							<label htmlFor=''>Sell-Out</label>
							<CatálogoDropdownDate
								head='Selecciona una fecha'
								element_id='init_sellout_date'
								setDateList={setDateList}
								setAlertInsert={setAlertInsert}
							/>
							<input type='hidden' id='init_sellout_date' />
							<input type='hidden' id='end_sellout_date' />
						</div>

						<div className='input_wrapper' style={{marginTop: 30}}>
							<label htmlFor=''>Mes</label>
							<CatálogoDropdown
								head='Selecciona un mes'
								list={dateList}
								element_id='month_date'
								setContenedor={setMesTexto}
							/>
						</div>

						<input type='hidden' id='month_date' />
					</div>
				</div>

				<div className='box_border' style={{marginTop: 17}}>
					<div className='input_container'>
						<div className='input_wrapper'>
							<label htmlFor=''>
								<img src={shop} alt='' />
								Cadena
							</label>
							{!dataCustomersList ? (
								'Cargando cadenas...'
							) : (
								<CatálogoDropdown
									head='Selecciona una Cadena'
									list={dataCustomersList}
									element_id='customer_name'
									list_customers={dataCustomers}
									setContenedor={setContenedorCadena}
								/>
							)}
						</div>
						<input type='hidden' id='customer_name' />
						<input type='hidden' id='customer_id' />

						<div className='right_range'>
							<div className='left_side'>
								<p>ROI</p>
								<div className='value_wrapper'>
									<p>-</p>
									<p>-</p>
								</div>
							</div>
							<div className='right_side'>
								<p>Volumen</p>
								<div className='value_wrapper'>
									<p>-</p>
									<p>-</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='badge_long'>
					<div className='box_inner'>
						<h1>Canal</h1>
						<p>Moderno</p>
					</div>
					{ /*
					<div className='box_inner'>
						<h1>Sell-In</h1>
						<p>01/04/22 al 31/04/22</p>
					</div>
					<div className='box_inner'>
						<h1>Sell-Out</h1>
						<p>01/04/22 al 31/04/22</p>
					</div>
							*/}
					<div className='box_inner'>
						<h1>Fecha</h1>
						<p>
							<span>{mesTexto}</span>
						</p>
					</div>
					<div className='box_inner'>
						<h1>Cadena</h1>
						{contenedorCadena}
					</div>
				</div>
{ /*
				<p className='warning-message'>
					<img src={warning} alt='' />
					Ya existe una oferta para la fecha sell in indicada.
				</p>
							*/ }

				<div className='bottom_buttton'>
					<button
						onClick={() => {
							setActive(false)
							setActiveBack(true)
						}}
					>Volver atrás</button>
					<button
					id='btnContinuarModerno'
					disabled={loading}
					onClick={(e) => {
						if (!nombreCatalogo) {
							setAlertInsert('*Todos los campos son obligatorios')
							return false
						}
						const fields_to_validate = [
							'init_sellin_date',
							'end_sellin_date',
							'init_sellout_date',
							'end_sellout_date',
							'month_date',
							'customer_name',
							'customer_id',
							'customer_id',
						]
						let validator = true

						fields_to_validate.map((field) => {
							console.log(
								'Validando !!! ' +
									field +
									' ' +
									document.getElementById(field).value
							)
							if (document.getElementById(field).value != '') {
								document
									.getElementById(field)
									.classList.remove('error_oferto')
								document
									.getElementById(field)
									.classList.add('success_oferto')
							} else {
								document.getElementById(field).classList.add('error_oferto')
								document
									.getElementById(field)
									.classList.remove('success_oferto')
								validator = false
								setAlertInsert('*Todos los campos son obligatorios')
								return false
							}
						})
						if (!validator) {
							setAlertInsert('*Todos los campos son obligatorios')
							return false
						}
						if (nombreCatalogo && nombreCatalogo.length > 3) {
							setAlertInsert(null)
							addPromotion()
							e.preventDefault()
							e.stopPropagation()
						}
					}}
					>Continuar</button>
				</div>
			</div>
		</div>
		}
		</>
	)
}
