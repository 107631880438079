import React , { useState } from "react";
import Icono_cata from "../assets/img/Icono_cata.svg";
import { config } from "../config";
import {Loader} from "./Loader";

export const RestaurarPropuesta = ({ setActive, data, tipo_catalogo , id_promocion=null, category=null, 
subcategory= null}) => {
  const [loader, setloader] = useState(false);
  const restoreDataAsync = async (dataJSON) => {
    console.log(dataJSON)
    const session_token = localStorage.getItem('session');
    //traditional y catalog
    let url_final = ``;
    if (tipo_catalogo == "moderno") {
      url_final = `/catalog/backup/restore/origin/family/`+id_promocion+`/`+category+`/`+subcategory;
    }else{
      url_final = `/traditional_catalog/backup/restore/origin/family/`+id_promocion+`/`+category+`/`+subcategory;
    }
    const response = await fetch(`${config.apiUrl}`+url_final, {
      //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        },
        method: "GET",
      });
      const responseJSON = await response.json();
      console.log(responseJSON)
      setloader(false);
      alert("El catálogo ha sido restaurado, se debe actualizar la página para ver los cambios");
      window.location.reload();
  }

  return (
    <>
    {loader && <Loader message={"Restaurando..."}></Loader>}
    <div
      className="popupWrapper"
      id="popupCatalog"
      style={{ position: "fixed", zIndex: 9999 }}
      onClick={(e) => {
        if (e.target.id == "popupCatalog") {
          setActive(false);
        }
      }}
    >
      <div className="popup SaveCatalog" id={"popUpCatalogBack"}>
      <svg
									width="150"
									height="160"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 3L12 12M12 12L8.5 8.5M12 12L15.5 8.5M16.5 7C16.5 9.48528 14.4853 11.5 12 11.5C9.51472 11.5 7.5 9.48528 7.5 7M4.5 7C4.5 10.5899 7.41015 13.5 11 13.5C12.6569 13.5 14.1569 12.9696 15.364 12.0361M19.5 7C19.5 10.0376 17.0376 12.5 14 12.5C12.3431 12.5 10.8431 11.9696 9.63604 11.0361"
										stroke="#000"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
        <h1>¿Estás seguro que deseas restaurar esta familia?</h1>
        <p>Al ejecutar esta acción, toda la información actual se perderá</p>
        <div className="buttons_wrapper">
          <button
            onClick={(e) => {
              setActive(false);
            }}
          >
            Seguir Editando
          </button>
          <button
            onClick={(e) => {
              // setActive(false);
              setActive(false)
              setloader(true);
              restoreDataAsync(data)
              //Ejecutar servicio
              //
              // document.getElementById("popupCatalog").style.opacity = "100";
            }}
            id="btn_guardado_save"
          >
            Restaurar a Propuesta Original
          </button>
        </div>
      </div>
      
    </div>
    </>
  );
};
