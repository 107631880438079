import React from "react";

export const TopNavigatorFour = ({
  nombre_promocion,
  fecha,
  linea
}) => {
  return (
    <div className="TopNavigator TopNavigatorFour">
      <nav>
        <li>
          <h1>Catálogo</h1>
          <p>{nombre_promocion}</p>
        </li>
        <li>
          <h1>Fecha</h1>
          <p>{fecha}</p>
        </li>
        <li>
          <h1>Canal</h1>
          <p>Tradicional</p>
        </li>
        <li>
          <h1>Línea</h1>
          <p>{linea}</p>
        </li>
      </nav>
    </div>
  );
};
