import React from 'react'
import dots from '../assets/img/dots.svg'
import Avatar from "../assets/img/Avatar.svg";
export const Usuario = ({user}) => {
  console.log("data user::::::::::::::")
  console.log(user)
	return (
		<div className='Usuario'>
			<img
				src={Avatar}
				alt=''
			/>
			<div className='presentation'>
				<div className='left_side'>
					<h1>
						{user && user} { /* <p>hace 10 mínutos</p> */ }
					</h1>

					{ /*  <span>VISUALIZADOR</span> */ }
				</div>
				{ /*<img src={dots} alt='' /> */ }
			</div>
		</div>
	)
}
