import React from "react";
import Table from "react-bootstrap/Table";
import filter_new from "../assets/img/filter_new.svg";
import dropdown from "../assets/img/dropdown.svg";
import forward_arrow from "../assets/img/forward_arrow.svg";
import Off from "../assets/img/Off.svg";
import Valor from "../assets/img/Valor.svg";
import close_sm from "../assets/img/close_sm.svg";
import Recomendacion from "../assets/img/Recomendacion.svg";
import tag from "../assets/img/tag.svg";
import Si from "../assets/img/Si.svg";
import tick from "../assets/img/tick.svg";
import No from "../assets/img/No.svg";
export const SeguimientoTableLinea = () => {
  return (
    <div className="SeguimientoTable">
      <Table>
        <thead>
          <tr>
            <td colSpan={8}>
              <div className="dropdown">
                <div className="head">
                  <img src={filter_new} alt="" />
                  <p>Todas</p>
                  <img src={dropdown} alt="" />
                </div>
              </div>
            </td>

            <td colSpan={2}>
              <p>Prop. Inicial</p>
              <span className="propspan"></span>
            </td>
            <td colSpan={2}>
              <p>Prop. Colaborativa</p>
              <div className="percentage yellow_percentage">
                <span className="percentage_inner"></span>
                <p>100,0% (18/18)</p>
              </div>
            </td>
            <td colSpan={2}>
              <p>Negociación</p>
              <div className="percentage red_percentage">
                <span className="percentage_inner"></span>
                <p>75,0% (14/18)</p>
              </div>
            </td>
            <td colSpan={2}>
              <p>Ejecución</p>
              <div className="percentage green_percentage">
                <span className="percentage_inner"></span>
                <p>50,0% (9/18)</p>
              </div>
            </td>
          </tr>
        </thead>
        <tbody className="img_saperate_wrapper">
          <tr className="special_body_row">
            <td>
              <div className="display_flex  gray">
                <p>SubFamilia</p>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Marca</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Rec</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Código</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>Descripción.</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Pre. Ba .</p>
                <img src={Off} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray">
                <p>Pre. Act.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray">
                <p>Pre. Opt.</p>
                <img src={Off} alt="" />
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray blue_bg">
                <p>Pre. Prop.</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray blue_bg">
                <p>% Prop.</p>
              </div>
            </td>

            <td>
              <div className="display_flex  center_justify gray blue_bg">
                <p>Pre. Prop.</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray blue_bg">
                <p>% Prop.</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray blue_bg">
                <p>Pre. Prop.</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray blue_bg">
                <p>% Prop.</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray blue_bg">
                <p>Pre. Prop.</p>
              </div>
            </td>
            <td>
              <div className="display_flex  center_justify gray blue_bg">
                <p>% Prop.</p>
              </div>
            </td>
          </tr>
          <tr
            id="familia1"
            onClick={(e) => {
              if (
                document
                  .querySelector("#familia1")
                  .classList.contains("rotate_img")
              ) {
                document
                  .querySelector("#familia1")
                  .classList.remove("rotate_img");
              } else {
                document.querySelector("#familia1").classList.add("rotate_img");
              }
              document.querySelectorAll(".familia1").forEach((Each) => {
                if (Each.classList.contains("active")) {
                  Each.classList.remove("active");
                } else {
                  Each.classList.add("active");
                }
              });
            }}
          >
            <td>
              <div className="display_flex familiap  center_justify gray blue_bg special_p">
                <p>Familia</p>
              </div>
            </td>
            <td colSpan={15}>
              <div
                className="display_flex  center_justify gray blue_bg special_p"
                style={{ justifyContent: "flex-start", paddingLeft: 25 }}
              >
                <img src={forward_arrow} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia1">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia1">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia1">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia1">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={close_sm} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={close_sm} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia1">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={close_sm} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia1">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia1">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={close_sm} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={close_sm} alt="" />
              </div>
            </td>
          </tr>

          <tr
            id="familia2"
            onClick={(e) => {
              if (
                document
                  .querySelector("#familia2")
                  .classList.contains("rotate_img")
              ) {
                document
                  .querySelector("#familia2")
                  .classList.remove("rotate_img");
              } else {
                document.querySelector("#familia2").classList.add("rotate_img");
              }

              document.querySelectorAll(".familia2").forEach((Each) => {
                if (Each.classList.contains("active")) {
                  Each.classList.remove("active");
                } else {
                  Each.classList.add("active");
                }
              });
            }}
          >
            <td>
              <div className="display_flex familiap  center_justify gray blue_bg special_p">
                <p>Familia</p>
              </div>
            </td>
            <td colSpan={15}>
              <div
                className="display_flex  center_justify gray blue_bg special_p"
                style={{ justifyContent: "flex-start", paddingLeft: 25 }}
              >
                <img src={forward_arrow} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia2">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia2">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia2">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia2">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={close_sm} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={close_sm} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia2">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={close_sm} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia2">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={Si} alt="" />
                <img src={tag} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={tick} alt="" />
              </div>
            </td>
          </tr>
          <tr className="activate familia2">
            <td>
              <div className="display_flex gray">
                <p>-</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>PF</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <img src={No} alt="" />
                <img src={Recomendacion} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <a href="#">X6000</a>
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>
                  Salchichas Sureñas <br /> PF (6x1Kg)
                </p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex   green">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex gray">
                <p>1.0%</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>$1.766</p>
              </div>
            </td>
            <td>
              <div className="display_flex gray">
                <p>1.0%</p> <img src={tick} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p> <img src={close_sm} alt="" />
              </div>
            </td>
            <td>
              <div className="display_flex  gray">
                <p>$1.766</p>
              </div>
            </td>

            <td>
              <div className="display_flex  gray">
                <p>1.0%</p>
                <img src={close_sm} alt="" />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
