import React, { useState, useEffect, useRef } from 'react'
import { Loader } from './Loader'
import Retool from 'react-retool'
import { Navbar } from './Navbar'

export const ConfiguracionSystem = ({ }) => {

    const [retoolEmbedUrl, setRetoolEmbedUrl] = useState("");

    useEffect(() => {
        const userJwt = "gabrielaraya@pricemaker.io";
        const retoolAppName = "gestion-usuarios";
        const options = {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: 'Bearer retool_01hhf73b61d9kjy6hynf69ar21',
            },
            mode: 'cors',
            body: JSON.stringify({ userJwt, retoolAppName }),
        };
    
        fetch("https://pricemaker1.retool.com/p/gestion-usuarios", options)
            .then((res) => res.json())
            .then((data) => {
                setRetoolEmbedUrl(data.embedUrl);
            })
            .catch((error) => {
                setRetoolEmbedUrl("https://pricemaker1.retool.com/p/gestion-usuarios");
                console.error('Error:', error);
            });
    }, []);
    


	return (
        <>
        <Navbar />
        {!retoolEmbedUrl && <Loader />}
		{retoolEmbedUrl && (
              <Retool url={retoolEmbedUrl} 
              height="1000px"
              />
          )}
        </>
	)
}
