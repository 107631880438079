import React, { useEffect, useRef, useState } from "react";
import { config } from "../config";
import { Loader } from './Loader'
import { set } from "date-fns";

export const EliminarShow = ({ seteliminarShow, promotionId, data }) => {
	const [loading, setLoading] = useState(false)

  const deletePromotion = async (promotion_id) => {
    setLoading(true)
    
    const session_token = localStorage.getItem('session');
    if (session_token){
      
        const response = await fetch(config.apiUrl + `/promotion/delete/`+promotion_id, {
        //mode: 'no-cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      const responseJSON = await response.json();
      console.log("Eliminar promoción desde el home");
      console.log(responseJSON);
      setLoading(false)
      window.location.reload();

    }
  
  }

  const deletePromotionOffer = async (promotion_id) => {
    setLoading(true)
    const session_token = localStorage.getItem('session');
    if (session_token){
      
        const response = await fetch(config.apiUrlOferta + `/offer/update_phase`, {
        //mode: 'no-cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        },
        body: JSON.stringify({
            promotionalstate_id: 2,
            promotion_id: promotion_id,
            comment_text: null
        }),
      })
      const responseJSON = await response.json();
      console.log("Eliminar promoción desde el home");
      console.log(responseJSON);
      setLoading(false)
      window.location.reload();

    }
  
  }

  const refCatalogModal = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])
  
  const closeModal = (e) => {
		if (!refCatalogModal.current.contains(e.target)) {
			seteliminarShow(false)
		}
	}

  return (
    <div className="EliminarShow" ref={refCatalogModal}>
      {loading && <Loader message={'Cargando...'}></Loader>}
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2319_430958"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="26"
          height="25"
        >
          <rect x="0.5" width="25" height="25" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2319_430958)">
          <path
            d="M7.08862 22.4218C6.46362 22.4218 5.90807 22.1874 5.42196 21.7187C4.93585 21.2499 4.69279 20.6944 4.69279 20.052V5.677H4.40633C4.07647 5.677 3.80303 5.56415 3.58602 5.33846C3.369 5.11277 3.2605 4.83499 3.2605 4.50513C3.2605 4.1579 3.369 3.87145 3.58602 3.64575C3.80303 3.42006 4.07647 3.30721 4.40633 3.30721H9.14591C9.14591 2.95999 9.25876 2.66919 9.48446 2.43481C9.71015 2.20044 9.99661 2.08325 10.3438 2.08325H15.6563C16.0036 2.08325 16.2944 2.20044 16.5287 2.43481C16.7631 2.66919 16.8803 2.95999 16.8803 3.30721H21.5678C21.915 3.30721 22.2015 3.4244 22.4272 3.65877C22.6529 3.89315 22.7657 4.16659 22.7657 4.47909C22.7657 4.84367 22.6529 5.13447 22.4272 5.35148C22.2015 5.56849 21.915 5.677 21.5678 5.677H21.2813V20.052C21.2813 20.6944 21.047 21.2499 20.5782 21.7187C20.1095 22.1874 19.5452 22.4218 18.8855 22.4218H7.08862ZM7.08862 5.677V20.052H18.8855V5.677H7.08862ZM9.79696 17.0833C9.79696 17.3263 9.89244 17.5433 10.0834 17.7343C10.2744 17.9253 10.4914 18.0208 10.7345 18.0208C10.9949 18.0208 11.2249 17.9253 11.4246 17.7343C11.6242 17.5433 11.724 17.3263 11.724 17.0833V8.64575C11.724 8.38534 11.6199 8.1553 11.4115 7.95565C11.2032 7.75599 10.9775 7.65617 10.7345 7.65617C10.474 7.65617 10.2527 7.75599 10.0704 7.95565C9.8881 8.1553 9.79696 8.38534 9.79696 8.64575V17.0833ZM14.3022 17.0833C14.3022 17.3263 14.402 17.5433 14.6016 17.7343C14.8013 17.9253 15.0226 18.0208 15.2657 18.0208C15.5261 18.0208 15.7562 17.9253 15.9558 17.7343C16.1555 17.5433 16.2553 17.3263 16.2553 17.0833V8.64575C16.2553 8.38534 16.1555 8.1553 15.9558 7.95565C15.7562 7.75599 15.5261 7.65617 15.2657 7.65617C15.0053 7.65617 14.7796 7.75599 14.5886 7.95565C14.3977 8.1553 14.3022 8.38534 14.3022 8.64575V17.0833Z"
            fill="#FF5334"
          />
        </g>
      </svg>
      <h1>¿Estás seguro que deseas eliminar la promoción?</h1>
      <p>
        Si la eliminas, no aparecerá en las Promociones Activas y sólo podrás
        verlo desde el Historial de Promociones
      </p>
      <div className="btn_wrapper">
        <button
          onClick={(e) => {
            seteliminarShow(false);
          }}
        >
          Cancelar
        </button>
        <button
          onClick={(e) => {
            if(data.type_promotion.type_promotion === 'Catalogo'){
              deletePromotion(promotionId)
            }else{
              deletePromotionOffer(data.promotion_id)
            }
            
            //seteliminarShow(false);
          }}
        >
          Eliminar
        </button>
      </div>

      <svg
        className="close_icon_eliminar_show"
        onClick={(e) => {
          seteliminarShow(false);
        }}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_297_6366"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <rect width="12" height="12" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_297_6366)">
          <path
            d="M6.0251 6.92495L3.6376 9.31245C3.50426 9.44578 3.34593 9.51245 3.1626 9.51245C2.97926 9.51245 2.82093 9.44578 2.6876 9.31245C2.55426 9.17912 2.4876 9.02295 2.4876 8.84395C2.4876 8.66462 2.55426 8.50828 2.6876 8.37495L5.0876 5.97495L2.6751 3.57495C2.54176 3.44162 2.4751 3.28745 2.4751 3.11245C2.4751 2.93745 2.54176 2.78328 2.6751 2.64995C2.80843 2.51662 2.9646 2.44995 3.1436 2.44995C3.32293 2.44995 3.47926 2.51662 3.6126 2.64995L6.0001 5.02495L8.3626 2.66245C8.49593 2.52912 8.65426 2.46245 8.8376 2.46245C9.02093 2.46245 9.17926 2.52912 9.3126 2.66245C9.44593 2.79578 9.5126 2.95612 9.5126 3.14345C9.5126 3.33112 9.44593 3.49162 9.3126 3.62495L6.9376 6.01245L9.3251 8.39995C9.4501 8.52495 9.5126 8.67912 9.5126 8.86245C9.5126 9.04578 9.4501 9.19995 9.3251 9.32495C9.2001 9.44995 9.0481 9.51245 8.8691 9.51245C8.68976 9.51245 8.5376 9.44995 8.4126 9.32495L6.0251 6.92495Z"
            fill="#AEB0B0"
          />
        </g>
      </svg>
    </div>
  );
};
