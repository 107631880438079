import './App.css'
import { Login } from './components/Login'
import { Home } from './components/Home'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { CataLogoResumen } from './components/CataLogoResumen'
import { CecinasResumen } from './components/CecinasResumen'
import { Seguimiento } from './components/Seguimiento'
import { ResumenDeCataloge } from './components/ResumenDeCataloge'
import { Vienesas } from './components/Vienesas'
import { VienesasActivation } from './components/VienesasActivation'
import { SeguimientoLinea } from './components/SeguimientoLinea'
import { ResetPassword } from './components/ResetPassword'
import { EmailSent } from './components/EmailSent'
import { ResetPasswordFound } from './components/ResetPasswordFound'
import { ConfiguracionSystem } from './components/ConfiguracionSystem'
import { PasswordChangeSuccess } from './components/PasswordChangeSuccess'
import { BusquedadePromociones } from './components/BusquedadePromociones'
import { ConfiguracióndelPerfil } from './components/ConfiguracióndelPerfil'
import { ResumenLinea } from './components/ResumenLinea'
import { ResumenLineaFamilia } from './components/ResumenLineaFamilia'
import { VienesasNewCategore } from './components/VienesasNewCategore'
import { DataResumeLineProvider } from './context/linksContext'
import { IngresoProduct } from './components/newPFOffer/IngresoProduct'
import { Ifra } from './components/newPFOffer/Ifra'
import { ResumenDeCatalogeOferta } from './components/newPFOffer/ResumenDeCatalogeOferta'
import { SeguimientoOfertav2 } from './components/newPFOffer/SeguimientoOfertav2'
import { Dashboard } from './components/Dashboard'
import { SeguimientoTableLineaNew } from './components/seguimientoTradicional/seguimientoTableLineaNew'
import RedirectComponent from './components/RedirectComponent'; // Asegúrate de importar tu componente

function App() {
	return (
		<div className='App'>
			<DataResumeLineProvider>
				<BrowserRouter>
					<Routes>
						
						<Route path='/' element={<Login />} />
						<Route path='/ResetPassword' element={<ResetPassword />} />

						<Route path='/IngresoProduct/:id_oferta' element={<IngresoProduct />} />

						<Route path='/OfertaTradicional' element={<Ifra />} />
						<Route path='/ConfiguracionSystem' element={<ConfiguracionSystem />} />
						<Route
							path='/user/password-reset'
							element={<ResetPasswordFound />}
						/>
						<Route path='/EmailSent' element={<EmailSent />} />
						<Route
							path='/PasswordChangeSuccess'
							element={<PasswordChangeSuccess />}
						/>
						<Route path='/Home' element={<Home />} />
						{/* //Catalogo Tradicional */}
						<Route path='/seg' element={<SeguimientoTableLineaNew />} />

						<Route
							path='/CatalogoTradicional/:id_promocion/:nombre_promocion/:oficina/:fecha/:linea/'
							element={<ResumenLinea />}
						/>
						<Route path="/redirect/:id" element={<RedirectComponent />} />
						<Route
							path='/CatalogoTradicionalFamilia/:id_promocion/:nombre_promocion/:oficina/:fecha/:linea/'
							element={<ResumenLineaFamilia />}
						/>
						<Route
							path='/CatalogoTradicionalFamiliaProducto/:id_promocion/:nombre_promocion/:oficina/:linea/:fecha/:familia'
							element={<VienesasNewCategore />}
						/>
						<Route
							path='/catalogoTradicional/categoria/:categoria/:id_promocion'
							element={<CecinasResumen />}
						/>
						{
							//Catálogo Moderno
						}
						<Route
							path='/CataLogoResumen/:id_promocion'
							element={<CataLogoResumen />}
						/>
						{/* <Route path="/Cecinas" element={<CecinasResumen />} /> */}
						<Route
							path='/catalogo/categoria/:categoria/:id_promocion'
							element={<CecinasResumen />}
						/>
						{/* Moderno  TODO modificar path */}
						<Route
							path='/Seguimiento/:id_promocion'
							element={<Seguimiento />}
						/>
						<Route
							path='/SeguimientoOferta/:id_promocion'
							element={<SeguimientoOfertav2 />}
						/>
						{/* Tradicional */}
						<Route
							path='/Seguimiento/catalogo_tradicional/:id_promocion'
							element={<Seguimiento />}
						/>

						<Route
							path='/ResumenDeCatalogo/:id_promocion/:tipo_catalogo'
							element={<ResumenDeCataloge />}
						/>

						<Route
							path='/ResumenOferta/:id_promocion'
							element={<ResumenDeCatalogeOferta />}
						/>

						{/* <Route path="/Vienesas" element={<Vienesas />} /> */}
						<Route
							path='/catalogo/subcategoria/:subcategoria/:id_promocion/:categoria'
							element={<Vienesas />}
						/>
						{
							//Reportería tableau
						}
						<Route path='/dashboard/:pagina' element={<Dashboard />} />
						{
							//Módulos pendientes
						}
						<Route
							path='/VienesasActivation'
							element={<VienesasActivation />}
						/>
						<Route path='/SeguimientoLinea' element={<SeguimientoLinea />} />
						<Route
							path='/BusquedadePromociones'
							element={<BusquedadePromociones />}
						/>
						<Route
							path='/ConfiguraciondelPerfil'
							element={<ConfiguracióndelPerfil />}
						/>
					</Routes>
				</BrowserRouter>
			</DataResumeLineProvider>
		</div>
	)
}

export default App
