import React, { Children, useState, useMemo, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import filter_new from '../../../assets/img/filter_new.svg'
import dropdown from '../../../assets/img/dropdown.svg'
import forward_arrow from '../../../assets/img/forward_arrow.svg'
import Off from '../../../assets/img/Off.svg'
import close_sm from '../../../assets/img/close_sm.svg'
import Recomendacion from '../../../assets/img/Recomendacion.svg'
import recomendacion_alza from '../../../assets/img/recomendacion_alza.svg'
import tag from '../../../assets/img/tag.svg'
import Si from '../../../assets/img/Si.svg'
import tick from '../../../assets/img/tick.svg'
import No from '../../../assets/img/No.svg'
import { InnerActiveSqguimiento } from './InnerActiveSqguimiento'
import { CapitalizeFirstLetter } from '../../../utils/CapitalizeFirstLetter'
import Recomendacion2 from '../../../assets/img/Recomendacion2.svg'
import volumen from '../../../assets/img/Volumen.svg'
import volumenRentabilidad from '../../../assets/img/Volumen_Rentabilidad.svg'
import { sortData } from '../../../utils/sortData'

export const SeguimientoTableLineaNew = ({ hopeJson }) => {
	const [ActiveInner, setActiveInner] = useState('')
	const [filterDropdown, setFilterDropdown] = useState(false)
	const [subfamilyFilter, setSubfamilyFilter] = useState('Todas')
	const [subfamilies, setSubFamilies] = useState([])
	const [sortKey, setSortKey] = useState("opt_price")
	const [sortOrder, setSortOrder] = useState('asc')
	
	useEffect(() => {
		const subfamiliesAux = hopeJson?.data_rows?.map((row) => row.category)
		setSubFamilies([...new Set(subfamiliesAux)])
	}, [hopeJson?.data_rows])

	const changeSort = (key) => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		setSortKey(key)
	}

	const handleOptionChangeSubfamily = (event) => {
		setSubfamilyFilter(event.target.value)
	}

	const sortedData = useMemo(() => {
        if (hopeJson) {
            return sortData({
                tableData: hopeJson.data_rows,
                sortKey,
                reverse: sortOrder === 'desc',
            });
        }
        return [];
    }, [hopeJson, sortKey, sortOrder]);

    // Función de filtrado ajustada
    const filterTable = useMemo(() => {
        if (subfamilyFilter === 'Todas') {
            return sortedData;
        }
        return sortedData.filter((item) => item.category === subfamilyFilter);
    }, [sortedData, subfamilyFilter]);

    const filtereds = filterTable;

	console.log('traditional', filtereds)
	let array_all_lines = []
	let array_all_families = []
	let array_all_products = []
	if (filtereds) {
		filtereds.map((item) => {
			array_all_lines.push(item.category)
			array_all_families.push(item.category + '--' + item.subcategory)
			array_all_products.push(
				item.category + '--' + item.subcategory + '--' + item.product_code
			)
		})
	}
	console.log('traditional 2', filtereds)

	const distinctCategories = new Set()
	if (filtereds) {
		filtereds.forEach((row) => {
			console.log("Adding category:", row.category);
			distinctCategories.add(row.category)
	})
	}
	const distinctCategoriesArray = Array.from(distinctCategories)
	console.log("DATA FILTRADA Y ORDENADA::: "+distinctCategoriesArray)

	return (
		<div className='SeguimientoTable SeguimientoTableLinea SeguimientoTableLineaNew VienesasTable'>
			<Table>
				<thead>
					<tr>
						<th colSpan={8} className='fimila fimiladropdown'>
							<div className='dropdown dropdown_new'>
								<div className='head'
								onClick={() => setFilterDropdown(!filterDropdown)}
								>
									<img src={filter_new} alt='' />
									<p>Todas</p>
									<img src={dropdown} alt='' />
									
								</div>
								{filterDropdown && (
											<ul style={{ width: '228px' }}>
												<li>
													<input
														type='radio'
														name='TodasSubfamilia'
														id='TodasSubfamilia'
														value={'Todas'}
														checked={subfamilyFilter === 'Todas'}
														style={{ display: 'none' }}
														onChange={handleOptionChangeSubfamily}
													/>
													<label htmlFor='TodasSubfamilia'></label>
													<p>Todas</p>
												</li>
												{subfamilies.map((subcategory) => (
													<li>
														<input
															type='radio'
															name={subcategory}
															id={subcategory}
															value={subcategory}
															checked={subfamilyFilter === subcategory}
															onChange={handleOptionChangeSubfamily}
														/>
														<label htmlFor={subcategory}></label>
														<p>{subcategory}</p>
													</li>
												))}
											</ul>
										)}
							</div>
						</th>

						{/* <td colSpan={2}>
							<div className='dropdown'>
								<div className='head'>
									<img src={filter_new} alt='' />
									<p>Marca</p>
									<img src={dropdown} alt='' />
								</div>
							</div>
						</td>
						<td colSpan={2}>
							<div className='dropdown'>
								<div className='head'>
									<img src={filter_new} alt='' />
									<p>Recomendación</p>
									<img src={dropdown} alt='' />
								</div>
							</div>
						</td> */}
						<td colSpan={2}>
							<p>Prop. Inicial</p>
							<span className='propspan'></span>
						</td>
						<td colSpan={2}>
							<p>Prop. Colaborativa</p>
							<div className='percentage green_percentage'
							style={{"--percentageWidth": hopeJson?.header?.colaborativa.split(" ")[0].replace(",",".")}}
							>
								<span className='percentage_inner'></span>
								<p>{hopeJson?.header?.colaborativa}</p>
							</div>
						</td>
						<td colSpan={2}>
							<p>Negociación</p>
							<div className='percentage yellow_percentage'
							style={{"--percentageWidth": hopeJson?.header?.negociacion.split(" ")[0].replace(",",".")}}
							>
								<span className='percentage_inner'></span>
								<p>{hopeJson?.header?.negociacion}</p>
							</div>
						</td>
						<td colSpan={2}>
							<p>Ejecución</p>
							<div className='percentage red_percentage'
							style={{"--percentageWidth": hopeJson?.header?.ejecucion.split(" ")[0].replace(",",".")}}
							>
								<span className='percentage_inner'></span>
								<p>{hopeJson?.header?.ejecucion}</p>
							</div>
						</td>
					</tr>
				</thead>
				<tbody className=''>
					<tr className='special_body_row'>
						<td>
							<div className='display_flex  gray'>
								<p>Familia</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Subfamilia</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Rec</p>
								<img src={Off} alt='' 
								onClick={() => changeSort('recommendation')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Código</p>
								<img src={Off} alt='' 
								onClick={() => changeSort('product_code')}
								/>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Descripción.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Oficina</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Pre. Act.</p>
								<img src={Off} alt='' 
								onClick={() => changeSort('act_price')}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Pre. Opt.</p>
								<img src={Off} alt='' 
								onClick={(e) => {changeSort('opt_price')}}
								/>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Pre. Prop.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>% Prop.</p>
							</div>
						</td>
					</tr>
					{/* Familia */}

					{distinctCategoriesArray.map((item) => {
						return (
							<MainRow
								category={item}
							>
								{sortedData.map((row, index) => {
									return (
										<SecondaryRow
											ActiveInner={ActiveInner}
											setActiveInner={setActiveInner}
											current={index}
											product={row}
										/>
									)
								})}
							</MainRow>
						)
					})}
				</tbody>
			</Table>
		</div>
	)
}

export const MainRow = ({ category, children }) => {
	return (
		<>
			<tr
				id='familia1'
				onClick={(e) => {
					if (
						document.querySelector('#familia1').classList.contains('rotate_img')
					) {
						document.querySelector('#familia1').classList.remove('rotate_img')
					} else {
						document.querySelector('#familia1').classList.add('rotate_img')
					}
					document.querySelectorAll('.familia1').forEach((Each) => {
						if (Each.classList.contains('active')) {
							Each.classList.remove('active')
						} else {
							Each.classList.add('active')
						}
					})
				}}
			>
				<td>
					<div className='display_flex familiap  center_justify gray blue_bg special_p'>
						<p>{category}</p>
					</div>
				</td>
				<td colSpan={15}>
					<div
						className='display_flex  center_justify gray blue_bg special_p'
						style={{ justifyContent: 'flex-start', paddingLeft: 25 }}
					>
						<img src={forward_arrow} alt='' />
					</div>
				</td>
			</tr>
			{children}
		</>
	)
}

export const SecondaryRow = ({
	ActiveInner,
	setActiveInner,
	current,
	product,
}) => {
	return (
		<>
			<tr
				className={`activate familia1 `}
				id={`${ActiveInner === current && 'blue_area'}`}
			>
				<td>
					<div className='display_flex gray'>
						<p>{product?.subcategory}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{product?.subfamily}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						{product?.recommendation === 0 && (
							<img src={Recomendacion} alt='' />
						)}
						{product?.recommendation === 1 && (
							<img src={Recomendacion2} alt='' />
						)}
						{product?.recommendation === 2 && <img src={volumen} alt='' />}
						{product?.recommendation === 3 && <img src={volumenRentabilidad} alt='' />}
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<a href='#'>{product?.product_code}</a>
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>{CapitalizeFirstLetter(product?.product_description)}</p>
						<div
							onClick={(e) => {
								setActiveInner(current)
							}}
						>
							<svg
								width='16'
								height='18'
								viewBox='0 0 16 18'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<rect
									x='2'
									y='3'
									width='12'
									height='12'
									rx='6'
									fill='#C1E5FE'
								/>
								<path
									d='M7 5L11 9L7 13'
									stroke='white'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</div>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{`${product?.offices_data_rows?.length} Of.`}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>${`${product?.act_price}`}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   green'>
						<p>${`${product?.opt_price}`}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>${product?.inicial?.prop_price}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{product?.inicial?.discount}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>${product?.colaborativa?.prop_price}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{product?.colaborativa.discount}</p>
						{product?.colaborativa.icon === 'check' && (
							<img src={tick} alt='' />
						)}
						{product?.colaborativa.icon === 'x' && (
							<img src={close_sm} alt='' />
						)}
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>${product?.negociacion?.prop_price}</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>{product?.negociacion?.discount}</p>
						{product?.negociacion.icon === 'check' && <img src={tick} alt='' />}
						{product?.negociacion.icon === 'x' && <img src={close_sm} alt='' />}
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						<p>${product?.ejecucion?.prop_price}</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						<p>{product?.ejecucion?.discount}</p>
						{product?.ejecucion.icon === 'check' && <img src={tick} alt='' />}
						{product?.ejecucion.icon === 'x' && <img src={close_sm} alt='' />}
					</div>
				</td>
			</tr>
			{product?.offices_data_rows?.map((office) => (
				<InnerActiveSqguimiento
					ActiveInner={ActiveInner}
					current={current}
					office={office}
				/>
			))}
		</>
	)
}
