import React, { useState, useEffect } from "react";
import PromoBooster from "../assets/img/PromoBooster.svg";
import { Link, useNavigate } from "react-router-dom";
import line_bg from "..//assets/img/line_bg.svg";
import { config } from '../config'

export const ResetPassword = () => {
  let navigate = useNavigate();

  let [EmailState, setEmailState] = useState(null);
  let [EmailError, setEmail] = useState(null);
  const [ValidateBool, setValidateBool] = useState(false);

  const handleRecovery = (e) => {
    e.preventDefault();
    let responseBool = (HandleEmail(e, EmailState));
    if(responseBool){
      handleLogin(e, EmailState);
    }
  };

  const handleLogin = async (e, email_) => {
    e.preventDefault();
    const response = await fetch(config.apiUrl + `/user/restore/password/${email_}`, {
      //mode: 'no-cors',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const data = await response.json();

    if (response.ok) {
      console.log("usuario loggeado");
      console.log(data);
      navigate("/EmailSent");
      //login(response.data, location.state?.from)
    } else {
      console.log("contraseña o email icorrectos");
    }
  };
/*
  useEffect(() => {
    if (ValidateBool) {
      sendEmail(EmailState).then((response) => {
        console.log(response);
      });
      navigate("/EmailSent");
    }
  }, [ValidateBool]);*/

  const HandleEmail = (e, value) => {
    let email = /^([a-zA-Z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    console.log(value);
    console.log(email.test(value));
    if (email.test(value)) {
      setEmail(true);
      return true;
    } else {
      setEmail(false);
      return false;
    }
  };

  return (
    <div className="Login ResetPassword">
      <img src={line_bg} alt="" style={{ opacity: 0.4 }} />
      <form action="">
        <h1>Recupera tu Contraseña</h1>
        <p>Ingresa el correo electrónico con el que te registraste </p>
        <div
          className={`input_wrapper ${
            EmailError == true
              ? "input_active"
              : EmailError == false && "passerror"
          } `}
        >
          <input
            type="text"
            placeholder="user@pfalimentos.cl"
            value={EmailState}
            onChange={(e) => {
              setEmailState(e.target.value);
            }}
            onFocus={(e) => {
              setEmail(true);
            }}
            onBlur={(e) => {
              HandleEmail(e, e.target.value);
            }}
          />
        </div>
        {EmailError == false && (
          <p className="error_code">Debe ingresar un correo electrónico</p>
        )}
        <div className="button_wrapper button_wrapper_new">
          <button onClick={handleRecovery}>Aceptar</button>

          <Link to="/">Cancelar</Link>
        </div>
      </form>
      <img src={PromoBooster} className="logo_one" alt="" />
    </div>
  );
};
