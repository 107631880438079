import React from "react";
import { useNavigate } from 'react-router-dom'
import Icono_cata from "../assets/img/IconoColor.svg";

export const CatalogSaved = ({ setActive }) => {
  const navigate = useNavigate()
  return (
    <div
      className="popupWrapper"
      id="popupCatalog"
      style={{ position: "fixed", zIndex: 9999 }}
      onClick={(e) => {
        if (e.target.id == "popupCatalog") {
          setActive(false);
        }
      }}
    >
      <div className="popup SaveCatalog">
        <img src={Icono_cata} alt="" />
        <h1>Los cambios se han guardado exitosamente</h1>
        <p>
          Puedes seguir editandolo o volver al inicio de{" "}
          <span>PROMOBOOSTER</span>
        </p>
        <div className="buttons_wrapper">
          <button
            onClick={(e) => {
              setActive(false);
              navigate('/home')
            }}
          >
            Volver al Inicio
          </button>
          <button
            onClick={(e) => {
              setActive(false);
            }}
          >
            Seguir Editando
          </button>
        </div>
      </div>
    </div>
  );
};
