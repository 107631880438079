import React, { useState, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import error_explanation from '../assets/img/error_explanation.svg'
import { config, getImgCustomer } from '../config'

export const CatálogoDropdown = ({
	head,
	list,
	element_id,
	setVal = null,
	list_customers = null,
	setContenedor,
	defaultValue = null,
}) => {
	const [dropdown, setDropdown] = useState(false)
	const [dropdownValue, setdropdownValue] = useState('')
	const [response, setresponse] = useState(null)
	const [count, setCount] = useState(0)

	// useEffect(() => {
	// 	if (count == 2) {
	// 		if (dropdownValue == '') {
	// 			setresponse('error')
	// 		} else {
	// 			setresponse('success')
	// 		}
	// 	}

	// 	if (count < 2) {
	// 		setCount(count + 1)
	// 		setdropdownValue(defaultValue)
	// 	}
	// }, [dropdownValue, dropdown])

	const refCatalogModal = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])
	/*
	useEffect(() => {
		setdropdownValue('')
	}, [list])
	*/
	const closeModal = (e) => {
		
		if (!refCatalogModal.current.contains(e.target)) {
			setDropdown(false)
		}
		// Esto estaba deprecado antes.... cualquier cosa comentar...
		console.log("deprecado");
	}

	return (
		<div className='CatálogoDropdown' ref={refCatalogModal}>
			<div
				className={`head ${response} ${dropdown && 'success_open'}`}
				onClick={(e) => {
					setDropdown(!dropdown)
				}}
			>
				<p style={{ color: '#252525' }}>
					{dropdownValue == '' ? head : dropdownValue}
				</p>

				<img src={error_explanation} alt='' />

				<svg
					width='10'
					height='5'
					viewBox='0 0 10 5'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M9.35355 0.853554C9.54882 0.658291 9.54882 0.341709 9.35355 0.146447C9.15829 -0.0488152 8.84171 -0.0488153 8.64645 0.146447L9.35355 0.853554ZM5 4.5L4.64645 4.85355C4.84171 5.04882 5.15829 5.04882 5.35355 4.85355L5 4.5ZM1.35355 0.146446C1.15829 -0.0488159 0.84171 -0.048816 0.646448 0.146446C0.451185 0.341708 0.451185 0.658291 0.646448 0.853553L1.35355 0.146446ZM8.64645 0.146447L4.64645 4.14645L5.35355 4.85355L9.35355 0.853554L8.64645 0.146447ZM5.35355 4.14645L1.35355 0.146446L0.646448 0.853553L4.64645 4.85355L5.35355 4.14645Z'
						fill='#E8E8E8'
					/>
				</svg>
			</div>
			{dropdown && (
				<ul>
					{list.map((EachList) => (
						<li>
							<p
								onClick={(e) => {
									setDropdown(false);
									setdropdownValue(EachList)
									document.getElementById(element_id).value = EachList

									if (element_id == 'customer_name') {
										let getCustomerProps = list_customers.filter(function (
											element
										) {
											return element.name_customer == EachList
										})
										let imagen_cadena = getImgCustomer(
											EachList.toUpperCase(),
											100
										)

										//document.getElementById("contenedor_cadena").innerHTML = ReactDOMServer.renderToString(imagen_cadena);
										document.getElementById('customer_id').value =
											getCustomerProps[0].id
										console.log(getCustomerProps[0].id)
										setContenedor(imagen_cadena)
									}
									if (element_id == 'month_date') {
										//document.getElementById("mes_texto").innerHTML = ReactDOMServer.renderToString(EachList);
										setContenedor(ReactDOMServer.renderToString(EachList))
									}
									if (element_id == 'linea_texto'
									|| element_id == 'cadena_texto_buscar'
									|| element_id == 'month_date_buscar'
									|| element_id == 'estrategia_str'
									|| element_id == 'familia_str'
									|| element_id == 'linea_str'
									|| element_id == 'catalogo_str'
									|| element_id == 'oferta_str'
									) {
										setContenedor(EachList)
									}

									//setVal(EachList);
								}}
							>
								{EachList}
							</p>
						</li>
					))}
				</ul>
			)}
		</div>
	)
}
