import React from "react";

export const SwitchButton = ({ setActive, active, data, setData, code_product, id_office, activateOffice}) => {

  
  return (
    <div
      className={`SwitchButton ${active && "active"}`}
      onClick={(e) => {
        activateOffice(code_product, id_office, !active);
        setActive(!active);
      }}
    >
      <span className="SwitchButtonBall"></span>
    </div>
  );
};
