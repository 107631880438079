import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'
import { Navbar } from '../Navbar'
import '../../assets/css/Seguimiento.css'
import { SeguimientoTable } from './SeguimientoTable'
import { TopNavigator } from '../TopNavigator'
import { config } from '../../config'
import { Loader } from '../Loader'
// import { MoveCataLog } from './MoveCataLog'
import { TopNavigatorFour } from '../TopNavigatorFour'
import {OfertaFooter} from './OfertaFooter'

function getListOffer(id_oferta) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrlOferta + `/offer/products/`+id_oferta, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

function getSeguimiento(promotion_id) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrlOferta + `/offer/brand_tracking/${promotion_id}`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

const getPromotions = async () => {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

function catalogoLineResume(promotion_id) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/catalog/category_data/${promotion_id}`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				console.log(response)
				return response
			})
	}
	//there are no session token stored
	return {}
}

export const SeguimientoOfertav2 = () => {
	const [combiar, setcombiar] = useState(false)
	const [Renombrar, setRenombrar] = useState(false)
	const [data, setData] = useState(null)
	const [promotion, setPromotion] = useState(null)
	const { id_promocion } = useParams()
	const [CatalogMove, setCatalogMove] = useState(false)
	const [loader, setloader] = useState(true)
	const [hopeJson, setHopeJson] = useState(null)
	let navigate = useNavigate()
	const location = useLocation()
	console.log('Seguimiento location', location)
	const [traditional, setTraditional] = useState(false)
	const [offers, setOffers] = useState(null)
	const [actualizar, setActualizar] = useState(false)
	// const { fetchDataResumeLine } = useContext(DataResumeLineContext)
	useEffect(() => {
		getListOffer(id_promocion).then((response) => {
			console.log("response:::: list products::: ")
			console.log(response)
			setOffers(response)
			// setProducts(response)
			
		})
		setActualizar(false)
	}, [actualizar])

	useEffect(() => {
		//setData({catalog_categories: catalog_categories, promotion_id: promotion_id})
		setTraditional(location?.state?.traditional)
		if(location.pathname.includes('tradicional')){
			catalogoLineResume(id_promocion).then((result) => {
				//setData(result)
				if (result) {
					console.log(result)
					result.promotion_id = id_promocion
					console.log('result promotion:::::')
					console.log(result)
					let categories_json = []
					result.data_rows.map((EachRow) => {
						console.log(EachRow)
						categories_json.push({
							category: EachRow.category,
						})
					})

					setData(result)
					console.log('catita', result)
					//setCategories(categories_json)
				} else console.log('error')
				//window.location.replace("/");
			})
		}
		//catalogoLineResumeAsync(id_promocion);
		getPromotions()
			.then((result) => {
				const promotion_filter = result.filter(function (element) {
					return element.id == id_promocion
				})
				console.log('*****************')
				console.log(promotion_filter[0])
				//alert(promotion_filter[0].id)
				setPromotion(promotion_filter[0])
			})
			.catch((err) => console.log(err))

		getSeguimiento(id_promocion).then(result =>{
		console.log("API ROW SEGUIMIENTO-------------------");
		console.log(result);
		setHopeJson(result);
		setloader(false);

		});
	}, [])



	const distinctSubcategory = new Set()

	hopeJson?.data_rows.forEach((row) => {
		distinctSubcategory.add(row?.subcategory)
	})

	const distinctSubcategoriesArray = Array.from(distinctSubcategory)

	return (
		<div className='CataLogoResumen'>
			{/* {!promotion ? '' : <TopNavigator data={promotion} />} */}
			{/* {promotion && (
				<TopNavigatorFour
					nombre_promocion={promotion?.name_promotion}
					fecha={promotion?.month_promotion_str}
					linea={promotion?.linea}
				/>
			)} */}

			{location.pathname.includes('tradicional')
				? promotion && (
						<TopNavigatorFour
							nombre_promocion={promotion?.name_promotion}
							fecha={promotion?.month_promotion_str}
							linea={promotion?.linea}
						/>
				  )
				: promotion && <TopNavigator data={promotion} />}

			{loader && <Loader message={'Cargando...'}></Loader>}

			{/*CatalogMove && promotion && (
				<MoveCataLog setActive={setCatalogMove} promotion={promotion} />
			)*/}

			<Navbar no_need_search />

			<div className='top_area IngresoProductTopArea'>
				<div className='left_side'>
					<Link to={"/ResumenOferta/"+id_promocion}>Resumen Oferta</Link>
					<Link  className='active'>Seguimiento Oferta</Link>
					<Link to={'/IngresoProduct/'+id_promocion}>
						Ingreso Productos
					</Link>
				</div>
			</div>
			<div className='link_area'>
				{promotion && (
					<p
						onClick={(e) => {
							navigate(-1)
						}}
						style={{ cursor: 'pointer' }}
					>
						{promotion.name_promotion}
					</p>
				)}
				<p>/</p>
				<p>Seguimiento Catálogo</p>
			</div>
			
			{
				hopeJson &&
						<SeguimientoTable hopeJson={hopeJson} traditional={traditional} />
				  }

{ offers && (
			<OfertaFooter 
				idOferta = {id_promocion}
				offers = {offers}
				setActualizar = {null}
				sinSimular = {null}
				setExecuteSimulation = {null}
			/>
			) }
		</div>
	)
}
