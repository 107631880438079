import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

export const usePrintPDF = ({ titleDoc }) => {
	const componentRef = useRef(null)

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: titleDoc,
	})
	return { componentRef, handlePrint }
}
