import React, { useState, useEffect } from 'react'
import { exportToExcel } from 'react-json-to-excel';
import { Navbar } from './Navbar'
import '../assets/css/CataLogoResumen.css'
import { CataLogoResumenFooter } from './CataLogoResumenFooter'
import { SubcategoriesResumenTable } from './SubcategoriesResumenTable'
import Table from 'react-bootstrap/Table'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { config } from '../config'
import { TopNavigator } from './TopNavigator'
import { CataLogoResumenTableCopy } from './CataLogoResumenTableCopy'
import { CecinasResumenTable } from './CecinasResumenTable'
import { TableHeaderCecinas } from './TableHeaderCecinas'
import { TableHeader } from './TableHeader'
import { MoveCataLog } from './MoveCataLog'
import { Loader } from './Loader'
import { RenombrarPopup } from './RenombrarPopup'
import { CombiarPopup } from './CombiarPopup'
import Modal from './modal'
import { useModal } from '../hooks/useModal'
import { usePrintPDF } from '../hooks/usePrintPdf'

function getPromotions() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

function catalogoLineResume(promotion_id) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/catalog/category_data/${promotion_id}`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}
	//there are no session token stored
	return {}
}

function categoryResume(category_name, promotion_id) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrl +
				`/catalog/subcategory_data/${category_name}/${promotion_id}`,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

export const CecinasResumen = (props) => {
	const { categoria } = useParams()
	const [active, setActive] = useState(false)
	const [data, setData] = useState(null)
	const [dataSubCategories, setDataSubCategories] = useState(null)
	const [dataCategories, setDataCategories] = useState(null)
	const { id_promocion } = useParams()
	const [promotion, setPromotion] = useState(null)
	const [combiar, setcombiar] = useState(false)
	const [Renombrar, setRenombrar] = useState(false)
	const [CatalogMove, setCatalogMove] = useState(false)
	const navigate = useNavigate()
	const [loader, setloader] = useState(true)

	const { closeModal, isOpen, openModal, modalRef } = useModal()
	const { componentRef, handlePrint } = usePrintPDF(`${new Date()}`)

	//let urlElements = window.location.href.split('/')
	// let urlCategory = window.location.pathname.slice(1,)
	//console.log("urlElelement:", window.location.pathname.slice(1,))

	useEffect(() => {
		categoryResume(categoria, id_promocion).then((result) => {
			setData({
				catalog_categories: result,
				promotion_id: id_promocion,
				category: categoria,
			})
			result.promotion_id = id_promocion
			result.category = categoria
			setDataSubCategories(result)
		})

		getPromotions().then((result) => {
			const promotion_filter = result.filter(function (element) {
				return element.id == id_promocion
			})
			setPromotion(promotion_filter[0])
		})

		catalogoLineResume(id_promocion).then((result) => {
			result.promotion_id = id_promocion
			setDataCategories(result)
			setloader(false)
		})
	}, [])

	console.log('data::', data)

	return (
		<div className='CataLogoResumen' ref={componentRef}>
			{loader && <Loader message={'Cargando...'}></Loader>}
			{Renombrar && (
				<RenombrarPopup
					setRenombrar={setRenombrar}
					data={dataCategories}
					setData={setDataCategories}
				/>
			)}
			{CatalogMove && promotion && (
				<MoveCataLog setActive={setCatalogMove} promotion={promotion} />
			)}
			{combiar && promotion && (
				<CombiarPopup setcombiar={setcombiar} promotion={promotion} />
			)}

			{!promotion ? '' : <TopNavigator data={promotion} dataPromotion={dataCategories}/>}

			{/* {!promotion ? '' : <TopNavigator data={promotion} />} */}
			<Navbar no_need_search />

			{isOpen && (
				<Modal
					title='Exportar Vista'
					isOpen={isOpen}
					closeModal={closeModal}
					modalRef={modalRef}
          handlePrint={handlePrint}
          handleCsv={() => exportToExcel(dataSubCategories.data_rows, 'downloadfilename')}
				/>
			)}

			<div className='top_area top_area_differ'>
				{!dataSubCategories ? (
					''
				) : (
					<TableHeaderCecinas data={dataSubCategories} active_todas='active' />
				)}
			</div>
			<div className='link_area'>
				<p
					onClick={(e) => {
						navigate('/CatalogoResumen/' + promotion.id)
					}}
					style={{ cursor: 'pointer' }}
				>
					{!promotion ? '' : promotion.name_promotion}
				</p>
				<p>/</p>
				<p style={{ cursor: 'pointer', color: '#36a7f5' }}>{categoria}</p>
				<p>/</p>
				<p style={{ cursor: 'pointer', color: '#36a7f5' }}>Resumen Línea</p>
			</div>

			{!dataCategories ? (
				''
			) : (
				<TableHeader
					data={dataCategories}
					active_todas=''
					selected={categoria}
				/>
			)}

			{
				//  data && <SubcategoriesResumenTable data={data}/>
			}

			{/* <CataLogoResumenTable /> */}
			{/*<CecinasResumenTable />*/}
			{data && <CecinasResumenTable data={dataSubCategories} />}
			<CataLogoResumenFooter
				setcombiar={setcombiar}
				setRenombrar={setRenombrar}
				data={dataSubCategories}
				guardar={false}
				setCatalogMove={setCatalogMove}
				resumen_canal={<Link>{categoria}</Link>}
				openModalExport={openModal}
			/>
		</div>
	)
}
