export const TableOne = [
  {
    first: "Jumbo Abril 2022",
    second: "Catálogo",
    third: "Jumbo",
    fourth: "Prop. Inicial",
    fifth: "En Edición",
    sixth: "Abr. 2022",
    seven: "-",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },

  {
    first: "Unimarc Cecinas",
    second: "Oferta",
    third: "Jumbo",
    fourth: "Prop. Colab.",
    fifth: "Descartada",
    sixth: "Abr. 2022",
    seven: "Cecinas, Lácteos",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },

  {
    first: "Líder 12-21",
    second: "Catálogo",
    third: "Jumbo",
    fourth: "Prop. Colab.",
    fifth: "Rechazada",
    sixth: "Abr. 2022",
    seven: "-",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },

  {
    first: "Fiestas Patrias Unimarc",
    second: "Catálogo",
    third: "Jumbo",
    fourth: "Prop. Colab.",
    fifth: "En Revisión",
    sixth: "Abr. 2022",
    seven: "-",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "18Sept Lider",
    second: "Oferta",
    third: "Jumbo",
    fourth: "Negociación",
    fifth: "Condicional",
    sixth: "Abr. 2022",
    seven: "Platos Preparados",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "Santa Isabel Sept",
    second: "Oferta",
    third: "Jumbo",
    fourth: "Ejecución",
    fifth: "Confirmada",
    sixth: "Abr. 2022",
    seven: "Cecinas",
    eight: "Vienesas",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "Perecibles 22",
    second: "Catálogo",
    third: "Jumbo",
    fourth: "Ejecución",
    fifth: "Cance. Cte.",
    sixth: "Abr. 2022",
    seven: "Cecinas",
    eight: "Mortadelas",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "Unimarc Cecinas",
    second: "Oferta",
    third: "Jumbo",
    fourth: "Prop. Colab.",
    fifth: "Descartada",
    sixth: "Abr. 2022",
    seven: "Cecinas, Lácteos",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },

  {
    first: "Líder 12-21",
    second: "Catálogo",
    third: "Jumbo",
    fourth: "Prop. Colab.",
    fifth: "Rechazada",
    sixth: "Abr. 2022",
    seven: "-",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },

  {
    first: "Fiestas Patrias Unimarc",
    second: "Catálogo",
    third: "Jumbo",
    fourth: "Prop. Colab.",
    fifth: "En Revisión",
    sixth: "Abr. 2022",
    seven: "-",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
];
export const TableTwo = [
  {
    first: "Sur Cecinas",
    second: "Oferta",
    third: "Maule, Bío Bío, Ñuble, Araucanía, Los Ríos",
    fourth: "Prop. Inicial",
    fifth: "En Edición",
    sixth: "Ene. 2022",
    seven: "Cecinas",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },

  {
    first: "Líder 12-21",
    second: "Catálogo",
    third: "Valparaíso, Santiago",
    fourth: "Prop. Colab.",
    fifth: "En Revisión",
    sixth: "Sep. 2022",
    seven: "L   ácteos",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "18Sept Lider",
    second: "Oferta",
    third: "Maule, Bío Bío, Ñuble, Araucanía, Los Ríos",
    fourth: "Ejecución",
    fifth: "Confirmada",
    sixth: "Sep. 2022",
    seven: "L   ácteos",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "Sur Cecinas",
    second: "Oferta",
    third: "Maule, Bío Bío, Ñuble, Araucanía, Los Ríos",
    fourth: "Prop. Inicial",
    fifth: "En Edición",
    sixth: "Ene. 2022",
    seven: "Cecinas",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },

  {
    first: "Líder 12-21",
    second: "Catálogo",
    third: "Valparaíso, Santiago",
    fourth: "Prop. Colab.",
    fifth: "En Revisión",
    sixth: "Sep. 2022",
    seven: "L   ácteos",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "18Sept Lider",
    second: "Oferta",
    third: "Maule, Bío Bío, Ñuble, Araucanía, Los Ríos",
    fourth: "Ejecución",
    fifth: "Confirmada",
    sixth: "Sep. 2022",
    seven: "L   ácteos",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "Sur Cecinas",
    second: "Oferta",
    third: "Maule, Bío Bío, Ñuble, Araucanía, Los Ríos",
    fourth: "Prop. Inicial",
    fifth: "En Edición",
    sixth: "Ene. 2022",
    seven: "Cecinas",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },

  {
    first: "Líder 12-21",
    second: "Catálogo",
    third: "Valparaíso, Santiago",
    fourth: "Prop. Colab.",
    fifth: "En Revisión",
    sixth: "Sep. 2022",
    seven: "L   ácteos",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "18Sept Lider",
    second: "Oferta",
    third: "Maule, Bío Bío, Ñuble, Araucanía, Los Ríos",
    fourth: "Ejecución",
    fifth: "Confirmada",
    sixth: "Sep. 2022",
    seven: "L   ácteos",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
  {
    first: "Sur Cecinas",
    second: "Oferta",
    third: "Maule, Bío Bío, Ñuble, Araucanía, Los Ríos",
    fourth: "Prop. Inicial",
    fifth: "En Edición",
    sixth: "Ene. 2022",
    seven: "Cecinas",
    eight: "-",
    nine: "-",
    ten: "-",
    end: "31/05/2022",
  },
];
