import React, {useState, useEffect} from 'react'
import { Loader } from '../Loader'
// img
import file from '../../assets/img/newPFOffer/file.svg'
import Resumen from '../../assets/img/Icono Resumen.svg'
import Cerrar from '../../assets/img/Cerrar.svg'
import rent from '../../assets/img/newPFOffer/rent.svg'
import vol from '../../assets/img/newPFOffer/vol.svg'
import rent2 from '../../assets/img/newPFOffer/rent2.svg'
import o from '../../assets/img/newPFOffer/o.svg'
import c from '../../assets/img/newPFOffer/c.svg'
// css
import '../../assets/css/newPFOffer/modelo.css'
import TableauReport from 'tableau-react'
import {config} from '../../config'

function getViewProduct(id_offer, code_product, customer) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrlOferta + `/offer/product/`+code_product+`/`+customer+`/`+id_offer, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}


export const ModeloPopup = ({setModeloPopupState, product, setProduct, cadena}) => {
	console.log("vista producto: ")
	console.log(product)
	console.log("cadena::: ")
	console.log(cadena)
	const [tokenTableau, setTokenTableau] = useState()
	const [loading, setLoading] = useState(true)
	const [productView, setProductView] = useState(null)

    //PF/Promo/AnlisisROI
	let url_ = 'https://tableauserver-azure.pricemaker.io/t/PF/views/';

    url_ = url_+'basetest_oferta/dash';
	/*
            parameters_ = {
			ID1: [dataProduct.code_product + '-' + data.cadena_details.name_customer],
		}
    */
	const fetchApi = async (tradicional = false) => {
		let url = 'https://optimusprice.pricemaker.io/api/getChartTrust'
		//https://tableauserver-azure.pricemaker.io/t/Soprole/views/basetest/titi2
		let dtarget_site = 'PF'
		let project = 'basetest_oferta'
		let page = 'dash'
		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				token: window.sessionStorage.getItem('token') + '-2chzRY3F3I9@',
				tabs_permission: 2,
				dtarget_site: dtarget_site,
				project: project,
				page: page,
			}),
		})

		console.log(response.status)
		const responseJSON = await response.json()
		setTokenTableau(responseJSON.token_server)
	}

	let parameters_ = {
		ID: [product.product_code + '-' + cadena],
	}

    useEffect(() => {
		fetchApi()
    }, [])

	useEffect(() => {
		getViewProduct(product.promotion_id, product.product_code, cadena).then((response) => {
			console.log("response:::: list product view!! ::: ")
			console.log(response)
			// setProducts(response)
			setProductView(response)
			setLoading(false)
		})

	}, [])

	return (
		<>
		{loading && <Loader message={"Cargando... "}/>}
		{!loading && <div className='popupWrapper OfertaWrapper TottusPop CodigoPop'
		>
			
			<div
				className='ModeloPopupbg'
				onClick={(e) => {
					setModeloPopupState(false)
					setProduct(null)
				}}
			></div>
			<div className='popup ModeloPopup'>
				<div
					className='close_icon '
					onClick={(e) => {
						setModeloPopupState(false)
						setProduct(null)
					}}
				>
					<img src={Cerrar} alt='' />
				</div>
				<div className='left_side'>
					<h1>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<mask
								id='mask0_2505_117424'
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='24'
								height='24'
							>
								<rect width='24' height='24' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_2505_117424)'>
								<path
									d='M2 6H3.83299V19H2V6ZM7.45825 6H8.35438V19H7.45825V6ZM4.72912 6H6.56212V19H4.72912V6ZM19.2709 6H22V19H19.2709V6ZM10.1874 6H11.9796V19H10.1874V6ZM16.5418 6H17.4379V19H16.5418V6ZM12.9165 6H15.6456V19H12.9165V6Z'
									fill='#AEB0B0'
								/>
							</g>
						</svg>
						{product.product_code}
						<p className='special_p'>{product.product_description}</p>
					</h1>

					<table className='table1'>
						<tbody>
							<tr>
								<td>
									<p>Línea</p>
									<h1>{product.brand}</h1>
								</td>
								<td>
									<p>Familia</p>
									<h1>{product.family}</h1>
								</td>
								<td>
									<p>Sub Familia</p>
									<h1>{product.subfamily}</h1>
								</td>
								<td className='border-gray' width='12%'>
									<p>Marca</p>
									<h1>{product.short_brand}</h1>
								</td>
								<td>
									<p>Peso Caja</p>
									<h1>{productView.peso_caja}</h1>
								</td>
								<td>
									<p>Peso Unidad</p>
									<h1>{productView.peso_unidad}</h1>
								</td>
								<td width='5%'>
									<p>Unidades</p>
									<h1>{productView.unidades}</h1>
								</td>
								<td>
									<p>Formato Venta</p>
									<h1>{productView.formato_venta}</h1>
								</td>
							</tr>
						</tbody>
					</table>

					<table className='table2'>
						<tbody>
							<tr>
								<td>
									<p>Cto. Direc.</p>
									<h1>{productView.costo_directo}</h1>
								</td>
								<td>
									<p>Cto. Proyec.</p>
									<h1>{productView.costo_proyectado}</h1>
								</td>
								<td>
									<p>Salas</p>
									<h1>{productView.salas}</h1>
								</td>
								<td>
									<p>Catalogación</p>
									<h1>{productView.catalogacion}</h1>
								</td>
								<td>
									<p>Crec. Interanual</p>
									<h1>{productView.crec_interanual}</h1>
								</td>
								<td>
									<p>Días Inventario</p>
									<h1>{productView.dias_inventario}</h1>
								</td>
							</tr>
						</tbody>
					</table>

					<h1 style={{marginTop: 24}}>
						<img src={Resumen} alt='' />
						Detalles Optimización
					</h1>

					<div className='table_grid'>
						<table>
							<tbody>
								<tr>
									<td>MAPE</td>
									<td>
										<p>{productView.mape_winner_name}</p>
										<h1>{productView.mape}</h1>
									</td>
									<td className='border-gray' width='15%'>
										<p>Elast</p>
										<h1>{productView.elasticity}%</h1>
									</td>
									{
										productView.other_mape_names.length > 0 &&
										productView.other_mape_names.map((name, index) => {
											return (
												<td key={index}>
													<p>{name}</p>
													<h1>{productView.other_mape_values[index]}</h1>
												</td>
											)
										})
									}
								</tr>
							</tbody>
						</table>
						<table>
							<tbody>
								<tr>
									<td>
										<p>Precio Bas.</p>
										<h1>{productView.base_price}</h1>
									</td>
									<td>
										<p>Vol. Bas.</p>
										<h1>{productView.base_volume}</h1>
									</td>
									<td>
										<p>Precio Act.</p>
										<h1>{productView.act_price}</h1>
									</td>
									<td>
										<p>Vol. Act.</p>
										<h1>{productView.act_volume}</h1>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className='table_grid table_grid2'>
						<table>
							<tbody>
								<tr>
									<td className='border-gray'>
										<div className='flex'>
											<img src={rent} alt='' />
											<p>
												Rent. <br /> + Vol.
											</p>
										</div>
									</td>
									<td>
										<p>Precio</p>
										<h1>{productView.precio_est_1}</h1>
									</td>
									<td>
										<p>Vol.</p>
										<h1>{productView.volumen_est_1}</h1>
									</td>
								</tr>
							</tbody>
						</table>
						<table>
							<tbody>
								<tr>
									<td className='border-gray'>
										<div className='flex'>
											<img src={vol} alt='' />
											<p>Vol.</p>
										</div>
									</td>
									<td>
										<p>Precio</p>
										<h1>{productView.precio_est_3}</h1>
									</td>
									<td>
										<p>Vol.</p>
										<h1>{productView.volumen_est_3}</h1>
									</td>
								</tr>
							</tbody>
						</table>
						<table>
							<tbody>
								<tr>
									<td className='border-gray'>
										<div className='flex'>
											<img src={rent2} alt='' />
											<p>Rent.</p>
										</div>
									</td>
									
									<td>
										<p>Precio</p>
										<h1>{productView.precio_est_2}</h1>
									</td>
									<td>
										<p>Vol.</p>
										<h1>{productView.volumen_est_2}</h1>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<h1 style={{marginTop: 24}}>
						<img src={file} alt='' />
						Activaciones
					</h1>
					<div className='activate-table-wrapper'>
						<table className='activate-table'>
							<thead>
								<tr>
									<th>Tipo</th>
									<th>Nombre</th>
									<th>Sell In</th>
									<th>Sell Out</th>
									<th>Precio</th>
									<th>Dcto.</th>
									<th>Vol.</th>
									<th>PVP</th>
									<th>RO</th>
								</tr>
							</thead>
							<tbody>
							{product.product_rows.map(
								
								(row) => {
									let img_;
									if(row.type === "catalog") {
										img_ = c
									}else{
										img_ = o
									}
									return (<>
									<tr>
										<td>
											<img src={img_} alt='' />
										</td>
										<td>{row.promotion_name}</td>
										<td>
											{row.tooltip_sellin}
										</td>
										<td>
											{row.tooltip_sellout}
										</td>

										<td>{row.strat_price}</td>
										<td>{row.discount}</td>
										<td>{row.curr_vol}.</td>
										<td>{row.recommended_pvp}</td>
										<td>{row.strat_ro_pct}</td>
									</tr>
									</>)
								}
							)}
							</tbody>
						</table>
					</div>
				</div>
				<div className='right_side' style={{overflow: 'auto'}}>
					
					<div className='gray_area' style={{ background: 'transparent' , overflow: "auto"}}>

					{!tokenTableau ? (
								'Cargando Dashboard...'
							) : (
								<TableauReport
									url={url_}
									token={tokenTableau}
									options={{
										width: 680,
										height: "100vh",
									}}
									parameters={parameters_}
									query="?:jsdebug=n"
								/>
							)}
					</div>
				</div>
			</div>
		</div>}
		</>
	)
}
