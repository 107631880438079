import React, {useState, useEffect, useRef } from 'react'
import '../../assets/css/newPFOffer/oferta.css'
import bookmark from '../../assets/img/bookmark.svg'
import edit from '../../assets/img/edit.svg'
import calender from '../../assets/img/calender.svg'
import Jumbo from '../../assets/img/Jumbo.svg'
import Cerrar from '../../assets/img/Cerrar.svg'
import errorExplanation from '../../assets/img/error_explanation.svg'
import { CatálogoDropdown } from '../CatálogoDropdown'
import { CatálogoDropdownDateTradicional } from '../CatálogoDropdownDateTradicional'
import { config, getImgCustomer } from '../../config'
import { useNavigate } from 'react-router-dom'


function getListMonthsYear() {
	const months_str = [
		'',
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	]

	let init_sellin_date = '2020-01-01'
	let end_sellin_date = '2035-01-01'
	if (document.getElementById('init_sellin_date')) {
		if (
			document.getElementById('init_sellin_date').value != '' &&
			document.getElementById('end_sellin_date').value != ''
		) {
			init_sellin_date = document.getElementById('init_sellin_date').value
			end_sellin_date = document.getElementById('end_sellin_date').value
		}
	}
	let four_dates = [init_sellin_date, end_sellin_date]
	const sortedDates = four_dates.sort((a, b) => a - b)
	const list_dates = dateRange(
		sortedDates[0],
		sortedDates[sortedDates.length - 1]
	)
	let final_dates = []
	list_dates.map((date) => {
		final_dates.push(
			months_str[parseInt(date.split('-')[1])] + '-' + date.split('-')[0]
		)
	})
	console.log('Filas Finalesss:::  ')
	console.log(final_dates)
	return final_dates
}

function dateRange(startDate, endDate) {
	var start = startDate.split('-')
	var end = endDate.split('-')
	var startYear = parseInt(start[0])
	var endYear = parseInt(end[0])
	var dates = []

	for (var i = startYear; i <= endYear; i++) {
		var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
		var startMon = i === startYear ? parseInt(start[1]) - 1 : 0
		for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
			var month = j + 1
			var displayMonth = month < 10 ? '0' + month : month
			dates.push([i, displayMonth, '01'].join('-'))
		}
	}
	return dates
}


export const CrearCatalogoExistenteTrad = ({setActive, setAnterior, dataPromotions, setLoading, lines}) => {
    const [nombreCatalogoBuscar, setNombreCatalogoBuscar] = useState('');
    const [mesCatalogoBuscar, setMesCatalogoBuscar] = useState('');
    const [cadenaCatalogoBuscar, setCadenaCatalogoBuscar] = useState('');
    const [listaMeses, setListaMeses] = useState([]);
    const [listaCadenas, setListaCadenas] = useState([]);
    const [mesTextoBuscar, setMesTextoBuscar] = useState('');
    const [cadenaTextoBuscar, setCadenaTextoBuscar] = useState('');
    const [nombreCatalogo, setNombreCatalogo] = useState('');
    const [alertInsert, setAlertInsert] = useState(null);
    const [inicioSellin, setInicioSellin] = useState('-');
    const [terminoSellin, setTerminoSellin] = useState('-');
    const [inicioSellout, setInicioSellout] = useState('-');
    const [terminoSellout, setTerminoSellout] = useState('-');
    const [mesTexto, setMesTexto] = useState('');
    const [cadenaTexto, setCadenaTexto] = useState('');
    const [dateList, setDateList] = useState(getListMonthsYear())
    const [catalogoSelected, setCatalogoSelected] = useState(null)
    const [lineaTexto, setLineaTexto] = useState('')
    const navigate = useNavigate()
    const refCatalogModal = useRef(null)
	const refDropdownRegions = useRef(null)

	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatalogModal.current.contains(e.target)) {
			console.log('cat trad')
			setActive(false)
		}
	}

    const addPromotion = async () => {
		const name_promotion = nombreCatalogo //document.getElementById("name_promotion").value;
		const init_sellin_date = document.getElementById('init_sellin_date').value
		const end_sellin_date = document.getElementById('end_sellin_date').value
		const month_date = document.getElementById('month_date').value.split('-')[0]
		const year_date = document.getElementById('month_date').value.split('-')[1]
        const promotion_id = catalogoSelected.id
        const linea = lineaTexto

        if(linea === "" || linea === "Selecciona una Línea"
        || linea === "Todos" || linea === null || linea === undefined){
            setAlertInsert('*Todos los campos son obligatorios')
            alert("Debe seleccionar una linea");
            return false
        }

		const fields_to_validate = [
			'init_sellin_date',
			'end_sellin_date',
			'month_date'
		]
		let validator = true

		fields_to_validate.map((field) => {
			console.log(
				'Validando !!! ' + field + ' ' + document.getElementById(field).value
			)
			if (document.getElementById(field).value != '') {
				document.getElementById(field).classList.remove('error_oferto')
				document.getElementById(field).classList.add('success_oferto')
			} else {
				document.getElementById(field).classList.add('error_oferto')
				document.getElementById(field).classList.remove('success_oferto')
				validator = false
				return false
			}
		})
		if (!validator) {
			return false
		}
		const session_token = localStorage.getItem('session')

		try {
			setLoading(true)
			const response = await fetch(config.apiUrl + `/traditional_promotion/create_from/create`, {
				//mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify(
					{
						name_promotion: name_promotion,
						start_sellin: init_sellin_date,
						end_sellin: end_sellin_date,
						month_promotion_str: month_date,
						year_promotion_str: year_date,
						promotion_id: promotion_id,
                        category: linea
					},
				),
			})
			const responseJSON = await response.json()

			window.location.href = '/redirect/'+responseJSON.created_promotion_id;

		} catch (error) {
			console.log('Error al crear la promoción')
			console.log(error)
			alert('Error al crear la promoción, es posible que esté seleccionando el mismo periodo en que ya está creada, contacte con administrador indicando el catálogo que intenta replciar y la fecha...')
			setLoading(false)
		} finally {
			setLoading(false)
		}
	}

    useEffect(() => {

        const meses = ['Todos'];
        const cadenas = ['Todos'];
        dataPromotions.map((item, index) => {
            if(item.customer.name_customer !== 'Tradicional'){
                return;
            }
            if(!meses.includes(item.month_promotion_str+" "+item.year_promotion_str)){
                meses.push(item.month_promotion_str+" "+item.year_promotion_str);
            }
            if(!cadenas.includes(item.linea)){
                cadenas.push(item.linea);
            }
        })
        setListaMeses(meses);
        setListaCadenas(cadenas);
    }, [dataPromotions])

	return (
		<div className='popupWrapper OfertaWrapper OfertanewPF OfertanewPFExistente' 
        >
			<div className='popup' style={{height:"630px"}}>
				<div
					className='OfertaWrapper-bg'
					onClick={(e) => setActive(false)}
				></div>
				<div className='close_icon' onClick={(e) => {setAnterior(true); setActive(false)}}>
					<img src={Cerrar} alt='' />
				</div>
				<h1>
					<img src={bookmark} alt='' />
					<span>
						<span>PROMO</span>BOOSTER:
					</span>
					Catálogo
				</h1>
				<div className='oferta_buttons'>
					<button
						onClick={(e) => {
							setActive(false)
							setAnterior(true)
						}}
					>
						Crear Catálogo nuevo
					</button>
					<button className='active-btn'>
						Crear a partir de Catálogo existente
					</button>
				</div>

				<div className='box_border'>
					<div className='input_container input_container_three'>
						<div className='input_wrapper'>
							<label htmlFor=''>Nombre de Catálogo</label>
							<input
								type='text'
								placeholder='Nombre de Catálogo'
                                value={nombreCatalogoBuscar}
                                onChange={(e) => setNombreCatalogoBuscar(e.target.value)}
							/>
						</div>

						<div className='input_wrapper'>
							<label htmlFor=''>Mes</label>
							<CatálogoDropdown
								head='Todos'
								list={listaMeses}
                                element_id='month_date_buscar'
								setContenedor={setMesTextoBuscar}
							/>
                            <input type='hidden' id='month_date_buscar' />
						</div>
						{
						<div className='input_wrapper'>
							<label htmlFor=''>Línea</label>
							<CatálogoDropdown
								head='Todos'
								list={listaCadenas}
                                element_id='cadena_texto_buscar'
								setContenedor={setCadenaTextoBuscar}
							/>
                            <input type='hidden' id='cadena_texto_buscar' />
						</div>
						}
					</div>

					<div className='scroll-checker-area'>
						<div className='row'>
							<h1>Nombre</h1>
							<h1>Mes</h1>
							<h1>Línea</h1>
						</div>

                    {dataPromotions.map((item, index) => {
                        if(item.customer.name_customer !== 'Tradicional'){
                            return;
                        }
                        if(nombreCatalogoBuscar !== '' 
                        && !item.name_promotion.toLowerCase().includes(nombreCatalogoBuscar.toLowerCase())){
                            return;
                        }
                        if(mesTextoBuscar !== '' && mesTextoBuscar !== 'Todos' 
                        && 
                        (item.month_promotion_str.toLowerCase()+" "+item.year_promotion_str.toLowerCase() )
                        !== mesTextoBuscar.toLowerCase() ){
                            return;
                        }
                        if(cadenaTextoBuscar !== '' && cadenaTextoBuscar !== 'Todos'
                        && item.linea.toLowerCase() !== cadenaTextoBuscar.toLowerCase() ){
                            return;
                        }
                        return (
                            <>
                                <div className='row' onClick={(e) => {
                                            e.preventDefault();
                                            console.log(item);
                                            console.log("CATALOGO YA SELECCIONADO")
                                            setCatalogoSelected(item);
                                        }}>
                                    <li>
                                        <a href='#' onClick={(e) => {
                                            e.preventDefault();
                                            console.log(item);
                                            console.log("CATALOGO YA SELECCIONADO")
                                            setCatalogoSelected(item);
                                        }}>{item.name_promotion}</a>
                                    </li>
                                    <li>{item.month_promotion_str} {item.year_promotion_str}</li>
                                    <li>{item.linea}</li>
                                </div>
                            </>
                        )
                    })}
						
						
					</div>
				</div>

				<div className='box_border' style={{marginTop: 5}}>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={edit} alt='' />
							Nombre de Catálogo
						</label>
						<input
							type='text'
							placeholder='Nombre de Catálogo'
                            value={nombreCatalogo}
                            onChange={(e) => setNombreCatalogo(e.target.value)}
							maxLength={25}
							onBlur={(e) => {
								if (e.target.value !== '') {
									e.target.classList.remove('error_oferto')
									e.target.classList.add('success_oferto')
								} else {
									e.target.classList.add('error_oferto')
									e.target.classList.remove('success_oferto')
								}
							}}
						/>
						<img src={errorExplanation} alt='' />
					</div>
				</div>

				<div className='box_border' style={{marginTop: 5}}>
					<div className='input_wrapper fechas-wrapper'>
						<label htmlFor=''>
							<img src={calender} alt='' />
							Fechas
						</label>
					</div>

					<div className='input_container'>
						
                        <div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Fecha Vigencia</label>
							<CatálogoDropdownDateTradicional
								head='Selecciona una fecha'
								element_id='init_sellin_date'
								setDateList={setDateList}
							/>
							<input type='hidden' id='init_sellin_date' />
							<input type='hidden' id='end_sellin_date' />
						</div>

						<div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Fecha</label>
							<CatálogoDropdown
								head='Selecciona un mes'
								list={dateList}
								element_id='month_date'
								setContenedor={setMesTexto}
							/>
							<input type='hidden' id='month_date' />
						</div>
						{ /*
                        <div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Línea</label>
							<CatálogoDropdown
								head='Selecciona una Línea'
								list={lines}
								setContenedor={setLineaTexto}
								element_id='linea_texto'
							/>
							<input type='hidden' id='linea_texto' />
						</div>
						*/ }
					</div>
				</div>

				<div className='badge_long'>
					<div className='box_inner'>
						<h1>Canal</h1>
						<p>Tradicional</p>
					</div>
					<div className='box_inner'>
						<h1>Fecha</h1>
						<p>{mesTexto}</p>
					</div>
					<div className='box_inner'>
						
					</div>
				</div>

				<div className='bottom_buttton'>
					<button onClick={(e) => {
                        e.preventDefault();
                        setActive(false);
                        setAnterior(true);
                    }}>Volver atrás</button>
					<button
                    id='btnContinuarModerno'
                    onClick={(e) => {
                        if (!nombreCatalogo) {
                            setAlertInsert('*Todos los campos son obligatorios')
                            return false
                        }
                        const fields_to_validate = [
                            'init_sellin_date',
                            'end_sellin_date',
                            'month_date'
                        ]
                        let validator = true
                        
                        fields_to_validate.map((field) => {
                            console.log(
                                'Validando !!! ' +
                                    field +
                                    ' ' +
                                    document.getElementById(field).value
                            )
                            if (document.getElementById(field).value != '') {
                                document
                                    .getElementById(field)
                                    .classList.remove('error_oferto')
                                document
                                    .getElementById(field)
                                    .classList.add('success_oferto')
                            } else {
                                document.getElementById(field).classList.add('error_oferto')
                                document
                                    .getElementById(field)
                                    .classList.remove('success_oferto')
                                validator = false
                                setAlertInsert('*Todos los campos son obligatorios')
                                return false
                            }
                        }) 
                        if (!validator) {
                            setAlertInsert('*Todos los campos son obligatorios')
                            return false
                        }
                        if (nombreCatalogo && nombreCatalogo.length > 3) {
                            setAlertInsert(null)
                            addPromotion()
                            e.preventDefault()
                            e.stopPropagation()
                        }
                    }}
                    >Continuar</button>
                    
				</div>
                <p id='alertInsert' style={{ color: 'red', fontWeight: 'bold' }}>
					{alertInsert}
				</p>
			</div>
		</div>
	)
}
