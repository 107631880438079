import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Navbar } from './Navbar'
import '../assets/css/CataLogoResumen.css'
import Flecha_green from '../assets/img/Flecha_green.svg'
import Flecha_orange from '../assets/img/Flecha_orange.svg'
import { CataLogoResumenFooter } from './CataLogoResumenFooter'
import Table from 'react-bootstrap/Table'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { VienesasTable } from './VienesasTable'
import { TopNavigator } from './TopNavigator'
import { config, formatNumberES } from '../config'
import { TableHeaderCecinas } from './TableHeaderCecinas'
import { VienesasTableNew } from './VienesasTableNew'
import { MoveCataLog } from './MoveCataLog'
import { CodigoPopNew } from './CodigoPopNew'
import { Loader } from './Loader'
import { RenombrarPopup } from './RenombrarPopup'
import { CombiarPopup } from './CombiarPopup'
import { getListTooltips } from '../services/tooltips.service'
import { useModal } from '../hooks/useModal'
import { usePrintPDF } from '../hooks/usePrintPdf'
import Modal from './modal'
import { exportToExcel } from 'react-json-to-excel'
import { ToolTipCustomList } from './ToolTipCustomList'

function getPromotions() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}
}

function catalogoLineResume(subcategory, promotion_id, category) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrl +
				`/catalog/products_data/${subcategory}/${promotion_id}/${category}/all/all`,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}
	//there are no session token stored
	return {}
}

function categoryResume(category_name, promotion_id) {
	const session_token = localStorage.getItem('session')

	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrl +
				`/catalog/subcategory_data/${category_name}/${promotion_id}`,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}
}

export const Vienesas = () => {
	const [active, setActive] = useState(false)
	const [dataInit, setDataInit] = useState(null)
	const [data, setData] = useState(null)
	const [indicators, setIndicators] = useState(null)
	const { subcategoria } = useParams()
	const { id_promocion } = useParams()
	const { categoria } = useParams()
	const [promotion, setPromotion] = useState(null)
	const [dataSubCategories, setDataSubCategories] = useState(null)
	const [MouseDownState, setMouseDownState] = useState(null)
	const [CodigoPopState, setCodigoPop] = useState(false)
	const [CatalogMove, setCatalogMove] = useState(false)
	const navigate = useNavigate()
	const [loader, setloader] = useState(true)
	const [Renombrar, setRenombrar] = useState(false)
	const [combiar, setcombiar] = useState(false)
	const [dataProduct, setDataProduct] = useState(null)
	const [changeInput, setChangeInput] = useState(false)

	const { closeModal, isOpen, openModal, modalRef } = useModal()
	const { componentRef, handlePrint } = usePrintPDF(`${new Date()}`)

	// Salir sin guardar
	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (changeInput) {
				console.log("ALERTAAAAAA!!!!!!!!!");
				console.log("ALERTA TRIPLE!!!!!!!");
				event.returnValue = 'Estás a punto de abandonar este sitio. ¿Estás seguro?';
			} else {
				console.log("NO CONTABILIZADO!!!!!!!!")
			}
		};
	
		if (changeInput) {
			window.addEventListener('beforeunload', handleBeforeUnload);
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		}
	
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [changeInput]);
	
	/*
	  useEffect(() => {
		if(changeInput){
			alert("AUN NO GUARDA NADA")
		}else{
			alert("NO HAY NADA QUE GUARDAR")
		}	  
	
	}, [changeInput]);
	*/
	// Fin salir sin guardar

	useEffect(() => {
		catalogoLineResume(subcategoria, id_promocion, categoria).then((result) => {
			result.promotion_id = id_promocion
			result.cadena_summary.ben_opt = formatNumberES(
				result.cadena_summary.ben_opt,
				1
			)
			result.cadena_summary.ben_prop = result.cadena_summary.ben_prop_formated
			result.cadena_summary.res_op_prop =
				result.cadena_summary.res_op_prop_formated
			
			result.data_rows.map((row) => {
				if (!result.hasOwnProperty('ever_simulated')) {
					row.ever_simulated = 0;
				}
				/*
				row.descuento_calculado = parseFloat(
					(1 - row.precio_prop / row.precio_ba) * 100
				).toFixed(1)*/
			});
			/*
      result.data_rows.map((row) =>{
        row.mg_cad = row.mg_cad / 1000000;
        row.mg_cad_formated = formatNumberES(row.mg_cad_formated.replace(".","").replace(",","."))
      })
      */
			setData(result)
			setDataInit(result)
			setloader(false)
			console.log("SET GUARDAR INICIAL::::.");
			console.log(result);
		})

		getPromotions().then((result) => {
			const promotion_filter = result.filter(function (element) {
				return element.id == id_promocion
			})
			//console.log("*****************")
			//alert(promotion_filter[0].id)
			setPromotion(promotion_filter[0])
		})

		categoryResume(categoria, id_promocion).then((result) => {
			result.promotion_id = id_promocion
			result.category = categoria
			setDataSubCategories(result)
		})
	}, [])

	return (
		<div className='CataLogoResumen' ref={componentRef}>
			{CodigoPopState && (
				<CodigoPopNew
					setCodigoPop={setCodigoPop}
					dataProduct={dataProduct}
					data={data}
					categoria={categoria}
					subcategoria={subcategoria}
					id_promocion={id_promocion}
					promotion={promotion}
				/>
			)}
			{loader && <Loader message={'Cargando...'}></Loader>}
			{combiar && promotion && (
				<CombiarPopup setcombiar={setcombiar} promotion={promotion} />
			)}
			{Renombrar && (
				<RenombrarPopup
					setRenombrar={setRenombrar}
					data={dataSubCategories}
					setData={setDataSubCategories}
				/>
			)}

			{isOpen && (
				<Modal
					title='Exportar Vista'
					isOpen={isOpen}
					closeModal={closeModal}
					modalRef={modalRef}
					handlePrint={handlePrint}
					handleCsv={() =>
						exportToExcel(dataSubCategories.data_rows, 'downloadfilename')
					}
				/>
			)}

			{CatalogMove && promotion && (
				<MoveCataLog setActive={setCatalogMove} promotion={promotion}
				tipo_catalogo={"moderno"} 
				data={data}
				/>
			)}
			{!promotion ? '' : <TopNavigator data={promotion} dataPromotion={data}/>}
			{data && <Navbar no_need_search data={data}/>}
			<div style={{ backgroundColor: '#fff' }}>
				<div
					className='top_area top_area_differ'
					style={{ backgroundColor: '#fff' }}
				>
					{!dataSubCategories ? (
						''
					) : (
						<TableHeaderCecinas
							data={dataSubCategories}
							active_todas=''
							selected={subcategoria}
						/>
					)}
				</div>
				<div className='extralinewrapper d-flex extralinewrapperNew'>
					<div className='link_area' style={{ flex: 1 }}>
						<p
							onClick={(e) => {
								navigate('/CatalogoResumen/' + promotion.id)
							}}
							style={{ cursor: 'pointer' }}
						>
							{!promotion ? '' : promotion.name_promotion}
						</p>
						<p>/</p>
						<p
							onClick={(e) => {
								window.location.replace(
									'/catalogo/categoria/' + categoria + '/' + promotion.id
								)
							}}
							style={{ cursor: 'pointer', color: '#36a7f5' }}
						>
							{categoria}
						</p>
						<p>/</p>
						<p style={{ cursor: 'pointer', color: '#36a7f5' }}>
							{subcategoria}
						</p>
					</div>
					<nav>
						<li>
							<div
								className='box'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button != 0) {
										return false
									}
									let MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
							{data &&
								<ToolTipCustomList width={110} height={60}
                        list={[data.indicators[1].tooltips.prods_with_model.map(item => item.year_month.split(" ")[0]+":"+item.indicator_value.replace(" ","")+" ")]}
											/>
							}
								<h1>Prod. c/Mod.</h1>
								<p>{data && data.indicators[1].prod_c_mod}</p>
								{/*
              <p className="green">
                <img src={Flecha_green} alt="" />
                {data && data.indicators[1].prod_c_mod}
              </p>
              */}
							</div>
						</li>

						<li>
							<div
								className='box'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button != 0) {
										return false
									}
									let MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
							{data &&
								<ToolTipCustomList
                        list={[data.indicators[1].tooltips.n_act_imp_vol.map(item => item.year_month.split(" ")[0]+":"+item.indicator_value.replace(" ","")+" ")]}
											/>
							}
								<h1>N° Act. / Imp. Vol.</h1>
								<p>{data && data.indicators[1].n_act_imp_vol}</p>
								{/*
              <p className="orange">
                <img src={Flecha_orange} alt="" />8 (25,6%)
              </p>
              */}
							</div>
						</li>

						<li>
							<div
								className='box'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button != 0) {
										return false
									}
									let MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
							{data &&
								<ToolTipCustomList 
                        list={[data.indicators[1].tooltips.var_price.map(item => item.year_month.split(" ")[0]+":"+item.indicator_value.replace(" ","")+" ")]}
											/>
							}
								<h1>Var $.</h1>
								<p>{data && data.indicators[1].var}</p>
								{/*
              <p className="orange">
                <img src={Flecha_orange} alt="" />
                7,2%
              </p>
            */}
							</div>
						</li>

						<li>
							<div
								className='box'
								onMouseUp={(e) => {
									e.target
										.querySelector('.ToolTipCustom')
										.classList.remove('show')

									clearInterval(MouseDownState)
								}}
								onMouseDown={(e) => {
									if (e.button != 0) {
										return false
									}
									let MouseDownTimeOut = setTimeout(() => {
										e.target
											.querySelector('.ToolTipCustom')
											.classList.add('show')
									}, [500])

									setMouseDownState(MouseDownTimeOut)
								}}
							>
								{data &&
								<ToolTipCustomList 
                        list={[data.indicators[1].tooltips.var_vol.map(item => item.year_month.split(" ")[0]+":"+item.indicator_value.replace(" ","")+" ")]}
											/>
							}
								<h1>Var Vol.</h1>
								<p>{data && data.indicators[1].var_vol}</p>
								{/*
              <p className="green">
                <img src={Flecha_green} alt="" />
                -12,3%
              </p>
              */}
							</div>
						</li>
					</nav>

					<div className='breadcrumb'>
						<h1>META</h1>
						<span>Volumen</span>
						<p>{data && data.indicators[0].meta}</p>
					</div>

					<div className='nav_wrapper_box'>
						<div className='inner_box'>
							<h1>ACTUAL</h1>
							<nav>
								<li>
									<div className='box'>
										<h1>Volumen</h1>

										<p>{data && data.indicators[0].meta_actual[0]}</p>
									</div>
								</li>

								<li>
									<div className='box'>
										<h1>Meta</h1>
										<p>{data && data.indicators[0].meta_actual[1]}</p>
									</div>
								</li>
							</nav>
						</div>

						<div className='inner_box orangec'>
							<h1>ÓPTIMO</h1>
							<nav>
								<li>
									<div className='box'>
										<h1>Volumen</h1>
										<p>{data && data.indicators[0].meta_optimo[0]}</p>
									</div>
								</li>

								<li>
									<div className='box'>
										<h1>Meta</h1>
										<p>{data && data.indicators[0].meta_optimo[1]}</p>
									</div>
								</li>
							</nav>
						</div>

						<div className='inner_box green'>
							<h1>PROPUESTO</h1>
							<nav>
								<li>
									<div className='box'>
										<h1>Volumen</h1>
										<p>{data && data.indicators[0].meta_propuesto[0]}</p>
									</div>
								</li>

								<li>
									<div className='box'>
										<h1>Meta</h1>
										<p>{data && data.indicators[0].meta_propuesto[1]}</p>
									</div>
								</li>
							</nav>
						</div>
					</div>
				</div>
			</div>
			{
				//data && <VienesasTable data={data}/>
			}
			{data && (
				<VienesasTableNew
					setCodigoPop={setCodigoPop}
					data={data}
					subcategory={subcategoria}
					setData={setData}
					category={categoria}
					dataInit={dataInit}
					setDataProduct={setDataProduct}
					setloader={setloader}
					setChangeInput={setChangeInput}
				/>
			)}
			<br></br>
			<br></br>
			{data && (
				<CataLogoResumenFooter
					data={data}
					setCatalogMove={setCatalogMove}
					guardar={true}
					resumen_canal={<Link>{subcategoria}</Link>}
					setRenombrar={setRenombrar}
					setcombiar={setcombiar}
					category={categoria}
					subcategory={subcategoria}
					setData={setData}
					setloader={setloader}
					simular={true}
					openModalExport={openModal}
					id_promocion={id_promocion}
					setChangeInput={setChangeInput}
				/>
			)}
		</div>
	)
}
