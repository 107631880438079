import React, { useState, useEffect, useRef } from 'react'
import Calendar from 'react-calendar'
import moment from 'moment'
import 'react-calendar/dist/Calendar.css'
import 'moment/locale/es'

import error_explanation from '../assets/img/error_explanation.svg'

function getListMonthsYear() {
	const months_str = [
		'',
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	]

	let init_sellin_date = '2020-01-01'
	let end_sellin_date = '2035-01-01'
	if (document.getElementById('init_sellin_date')) {
		if (
			document.getElementById('init_sellin_date').value != '' &&
			document.getElementById('end_sellin_date').value != ''
		) {
			init_sellin_date = document.getElementById('init_sellin_date').value
			end_sellin_date = document.getElementById('end_sellin_date').value
		}
	}
	let four_dates = [init_sellin_date, end_sellin_date]
	const sortedDates = four_dates.sort((a, b) => a - b)
	const list_dates = dateRange(
		sortedDates[0],
		sortedDates[sortedDates.length - 1]
	)
	let final_dates = []
	list_dates.map((date) => {
		final_dates.push(
			months_str[parseInt(date.split('-')[1])] + '-' + date.split('-')[0]
		)
	})
	console.log('Filas Finalesss:::  ')
	console.log(final_dates)
	return final_dates
}

function dateRange(startDate, endDate) {
	var start = startDate.split('-')
	var end = endDate.split('-')
	var startYear = parseInt(start[0])
	var endYear = parseInt(end[0])
	var dates = []

	for (var i = startYear; i <= endYear; i++) {
		var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
		var startMon = i === startYear ? parseInt(start[1]) - 1 : 0
		for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
			var month = j + 1
			var displayMonth = month < 10 ? '0' + month : month
			dates.push([i, displayMonth, '01'].join('-'))
		}
	}
	return dates
}

export const CatálogoDropdownDateTradicional = ({
	head,
	element_id,
	setDateList,
	setInit = null,
	setEnd = null,
	setStartHtml = null,
	setEndHtml = null,
	setValue1 = null,
	setValue2 = null,
}) => {
	const [dropdown, setDropdown] = useState(false)
	const [value, onChange] = useState(new Date())
	const [dropdownValue, setdropdownValue] = useState('')
	const [response, setresponse] = useState(null)
	const [count, setCount] = useState(0)
	moment.locale('es')

	function returnMonth(mes) {
		switch (mes) {
			case 'Jan':
				mes = '01'
				break
			case 'Feb':
				mes = '02'
				break
			case 'Mar':
				mes = '03'
				break
			case 'Apr':
				mes = '04'
				break
			case 'May':
				mes = '05'
				break
			case 'Jun':
				mes = '06'
				break
			case 'Jul':
				mes = '07'
				break
			case 'Aug':
				mes = '08'
				break
			case 'Sep':
				mes = '09'
				break
			case 'Oct':
				mes = '10'
				break
			case 'Nov':
				mes = '11'
				break
			case 'Dec':
				mes = '12'
				break
		}
		return mes
	}

	useEffect(() => {
		if (count == 2) {
			if (dropdownValue == '') {
				setresponse('error')
			} else {
				setresponse('success')
			}
		}

		if (count < 2) {
			setCount(count + 1)
			setdropdownValue(`${setInit} al ${setEnd}`)
		}
	}, [dropdownValue, dropdown])

	const refCatDropDateTrad = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatDropDateTrad.current.contains(e.target)) {
			setDropdown(false)
		}
	}

	return (
		<div
			className='CatálogoDropdown CatálogoDropdownDate'
			ref={refCatDropDateTrad}
		>
			<div
				className={`head ${response} ${dropdown && 'success_open'}`}
				onClick={(e) => {
					setDropdown(!dropdown)
				}}
			>
				<p style={{ color: '#252525' }}>
					{dropdownValue === 'null al null' ? head : dropdownValue}
				</p>

				<img src={error_explanation} alt='' />

				<svg
					width='10'
					height='5'
					viewBox='0 0 10 5'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M9.35355 0.853554C9.54882 0.658291 9.54882 0.341709 9.35355 0.146447C9.15829 -0.0488152 8.84171 -0.0488153 8.64645 0.146447L9.35355 0.853554ZM5 4.5L4.64645 4.85355C4.84171 5.04882 5.15829 5.04882 5.35355 4.85355L5 4.5ZM1.35355 0.146446C1.15829 -0.0488159 0.84171 -0.048816 0.646448 0.146446C0.451185 0.341708 0.451185 0.658291 0.646448 0.853553L1.35355 0.146446ZM8.64645 0.146447L4.64645 4.14645L5.35355 4.85355L9.35355 0.853554L8.64645 0.146447ZM5.35355 4.14645L1.35355 0.146446L0.646448 0.853553L4.64645 4.85355L5.35355 4.14645Z'
						fill='#E8E8E8'
					/>
				</svg>
			</div>
			{dropdown && (
				<div>
					<Calendar
						locale={'es'}
						onChange={(e) => {
							onChange(e)
							// setDropdown(false);

							let FirstStart = String(e[0])
							let SecondStart = String(e[1])

							let FirstcorrectDay = `${FirstStart.split(' ')[2]}/${
								FirstStart.split(' ')[1]
							}/${FirstStart.split(' ')[3]}`

							let SecondcorrectDay = `${SecondStart.split(' ')[2]}/${
								SecondStart.split(' ')[1]
							}/${SecondStart.split(' ')[3]}`

							console.log(FirstcorrectDay)
							console.log(SecondcorrectDay)

							//formateo de fechas para enviar a API.
							let date_array = FirstcorrectDay.split('/')
							let date_array_ = SecondcorrectDay.split('/')
							date_array[1] = returnMonth(date_array[1])
							date_array_[1] = returnMonth(date_array_[1])
							let new_FirstcorrectDay =
								date_array[2] + '-' + date_array[1] + '-' + date_array[0]
							let new_SecondcorrectDay =
								date_array_[2] + '-' + date_array_[1] + '-' + date_array_[0]
							if (element_id == 'init_sellin_date') {
								document.getElementById('init_sellin_date').value =
									new_FirstcorrectDay
								document.getElementById('end_sellin_date').value =
									new_SecondcorrectDay
							}

							//console.log(document.getElementById("init_sellin_date").value);

							setdropdownValue(
								`${moment(FirstcorrectDay)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')} al ${moment(SecondcorrectDay)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')}`
							)

							setDateList(getListMonthsYear())
							if (setStartHtml) {
								setStartHtml(FirstcorrectDay)
							}
							if (setEndHtml) {
								setEndHtml(SecondcorrectDay)
							}
							if (setValue1) {
								setValue1(new_FirstcorrectDay)
							}
							if (setValue2) {
								setValue2(new_SecondcorrectDay)
							}
						}}
						//value={value}
						selectRange={true}
						id='fecha_uno'
					/>
				</div>
			)}
		</div>
	)
}
