import { useRef, useState } from 'react'

export const useModal = () => {
	const modalRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false)

	const openModal = () => {
		setIsOpen(true)
	}

	const closeModal = () => {
		console.log('dd');
		setIsOpen(false)
	}

	return { isOpen, openModal, closeModal, modalRef }
}
