import React, { useState, useEffect, useRef } from 'react'
import bookmark from '../assets/img/bookmark.svg'
import edit from '../assets/img/edit.svg'
import calender from '../assets/img/calender.svg'
import { config } from '../config'
import dropdown from '../assets/img/dropdown.svg'
import Cerrar from '../assets/img/Cerrar.svg'
import error_explanation from '../assets/img/error_explanation.svg'
import tick_explanation from '../assets/img/tick_explanation.svg'
import { CatálogoDropdown } from './CatálogoDropdown'
import { CatálogoDropdownDateTradicional } from './CatálogoDropdownDateTradicional'
import { Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CatalogoTradicionalCheckBox } from './CatalogoTradicionalCheckBox'
import { Loader } from './Loader'
import {CrearCatalogoExistenteTrad} from './newPFOffer/CrearCatalogoExistenteTrad'


function getDataCreate() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/traditional_promotion/data_create `, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

function getListMonthsYear() {
	const months_str = [
		'',
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	]

	let init_sellin_date = '2020-01-01'
	let end_sellin_date = '2035-01-01'
	if (document.getElementById('init_sellin_date')) {
		if (
			document.getElementById('init_sellin_date').value != '' &&
			document.getElementById('end_sellin_date').value != ''
		) {
			init_sellin_date = document.getElementById('init_sellin_date').value
			end_sellin_date = document.getElementById('end_sellin_date').value
		}
	}
	let four_dates = [init_sellin_date, end_sellin_date]
	const sortedDates = four_dates.sort((a, b) => a - b)
	const list_dates = dateRange(
		sortedDates[0],
		sortedDates[sortedDates.length - 1]
	)
	let final_dates = []
	list_dates.map((date) => {
		final_dates.push(
			months_str[parseInt(date.split('-')[1])] + '-' + date.split('-')[0]
		)
	})
	console.log('Filas Finalesss:::  ')
	console.log(final_dates)
	return final_dates
}

function dateRange(startDate, endDate) {
	var start = startDate.split('-')
	var end = endDate.split('-')
	var startYear = parseInt(start[0])
	var endYear = parseInt(end[0])
	var dates = []

	for (var i = startYear; i <= endYear; i++) {
		var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
		var startMon = i === startYear ? parseInt(start[1]) - 1 : 0
		for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
			var month = j + 1
			var displayMonth = month < 10 ? '0' + month : month
			dates.push([i, displayMonth, '01'].join('-'))
		}
	}
	return dates
}

export const CatalogoTradicional = ({ setActive , setAtras, dataPromotions}) => {
	const [FirstDropdown, setFirstDropdown] = useState(false)
	const [FirstFilter, setFirstFilter] = useState('ANTOFAGASTA')
	const [alertActive, setalertActive] = useState(false)
	const [dateList, setDateList] = useState(getListMonthsYear())
	const [mesTexto, setMesTexto] = useState('-')
	const [oficinasCheck, setOficinasCheck] = useState([]) //Debe venir el componente anterior
	const [offices, setOffices] = useState()
	const [lines, setLines] = useState(null)
	const [lineaTexto, setLineaTexto] = useState('-')
	const [zonas, setZonas] = useState([])
	const [loading, setLoading] = useState(false)
	const [activeModal, setActiveModal] = useState(true)
	const [activeExistente, setActiveExistente] = useState(false)
	const navigate = useNavigate()

	const refCatalogModal = useRef(null)
	const refDropdownRegions = useRef(null)

	useEffect(() => {
		document.addEventListener('click', closeModal, true)
		document.addEventListener('click', closeDropdownRegions, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatalogModal.current.contains(e.target)) {
			console.log('cat trad')
			setActive(false)
		}
	}

	const closeDropdownRegions = (e) => {
		if (!refDropdownRegions.current.contains(e.target)) {
			setFirstDropdown(false)
		}
	}

	useEffect(() => {
		console.log("atento cambio de estado oficinas check")
	}, [oficinasCheck])

	const isCheckedOffice = (id) => {
		let response = false
		oficinasCheck.map((of) => {
			if (of.id === id) {
				response = true
			}
		})
		return response
	}

	const isCheckedFather = (Each) => { // Each es el nombre de la oficina
		if(Each !== "Nacional"){
			return oficinasCheck.filter((of) => of.group === Each).length 
			=== offices.filter((of) => of.group === Each).length
		}else{
			return oficinasCheck.length === offices.length
		}
	}

	const addPromotionFinal = async (
		name_promotion,
		offices_,
		start,
		end,
		month,
		year,
		line,
		offices_str
	) => {
		const session_token = localStorage.getItem('session')

		const response = await fetch(
			config.apiUrl + `/traditional_promotion/create`,
			{
				//mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify([
					{
						name_promotion: name_promotion,
						offices: offices_, //array con id’s de las oficinas seleccionadas
						start_sellin: start,
						end_sellin: end,
						month_promotion_str: month,
						year_promotion_str: String(year),
						type_promotion_id: 1,
						line: line,
					},
				]),
			}
		)
		const responseJSON = await response.json()
		console.log(responseJSON)
		if (responseJSON.response == 'promotion exists') {
			setalertActive(true)
		} else {
			//Se quita mensaje de alerta.
			setalertActive(false)
		}
		///CatalogoTradicional/:id_promocion/:nombre_promocion/:oficina/:linea/:fecha
		navigate(
			'/CataLogoTradicional/' +
				responseJSON[0].inserted_promotion_id +
				'/' +
				name_promotion +
				'/' +
				offices_str +
				'/' +
				month +
				' ' +
				year +
				'/' +
				line,
			{
				catalogo_data: null,
				catalog_categories: null,
				inserted_promotion_id: responseJSON[0].inserted_promotion_id,
				promotion_id: responseJSON[0].inserted_promotion_id,
			}
		)
	}

	const addPromotionTraditional = () => {
		setLoading(true)
		console.log('Oficinas seleccionadas al final -------------')
		console.log(oficinasCheck)
		let nombre_catalogo = document.getElementById('nombre_catalogo').value
		let init_sellin_date = document.getElementById('init_sellin_date').value
		let end_sellin_date = document.getElementById('end_sellin_date').value
		let linea_texto = document.getElementById('linea_texto').value
		if(nombre_catalogo === "" || nombre_catalogo === null
			|| nombre_catalogo === undefined || nombre_catalogo.replace(" ", "") === ""){	
				alert("El nombre del catálogo es obligatorio")
				setLoading(false)
				return false;
		}
		//lineaTexto
		//mesTexto
		let arr_offices = [] //Arreglo de oficinas
		oficinasCheck.map((of) => {
			arr_offices.push(of.id)
		})

		let validator = true
		let oficina_str = ''
		const fields_to_validate = ['init_sellin_date', 'end_sellin_date']
		fields_to_validate.map((field) => {
			console.log(
				'Validando !!! ' + field + ' ' + document.getElementById(field).value
			)
			if (document.getElementById(field).value != '') {
				document.getElementById(field).classList.remove('error_oferto')
				document.getElementById(field).classList.add('success_oferto')
			} else {
				document.getElementById(field).classList.add('error_oferto')
				document.getElementById(field).classList.remove('success_oferto')
				validator = false
				setLoading(false)
				return false
			}
		})

		if (!validator) {
			setLoading(false)
			return false
		}
		if (oficinasCheck.length > 1) {
			oficina_str = 'NACIONAL'
		} else {
			oficina_str = oficinasCheck[0].office_name
		}
		console.log('CT a crear::: ')
		console.log(
			nombre_catalogo +
				' ' +
				arr_offices +
				' ' +
				init_sellin_date +
				' ' +
				end_sellin_date +
				' ' +
				mesTexto.split('-')[0] +
				' ' +
				mesTexto.split('-')[1] +
				' ' +
				lineaTexto +
				' ' +
				oficina_str
		)
		
		addPromotionFinal(
			nombre_catalogo,
			arr_offices,
			init_sellin_date,
			end_sellin_date,
			mesTexto.split('-')[0],
			mesTexto.split('-')[1],
			lineaTexto,
			oficina_str
		)
	}

	useEffect(() => {
		getDataCreate().then((result) => {
			result.offices.map((EachRow) => {
				EachRow.totalGroup = "Nacional"
			})

			// Array inicial
			const miArrayZonas = ["Nacional"];

			result.offices.map((EachRow) =>{
				// Verificar si el elemento ya existe en el array
				if (!miArrayZonas.includes(EachRow.group)) {
					// Agregar el elemento si no existe
					miArrayZonas.push(EachRow.group);
				}
			})
			
			console.log("ZONAS DETECTADAS::::::::::")
			console.log(miArrayZonas);
			console.log("AGREGADO DE NACIONAL::::::::")
			console.log(result.offices)
			setZonas(miArrayZonas)
			setOffices(result.offices)

			let arr = []
			result.categories.map((cat) => {
				arr.push(cat.linea)
			})
			setLines(arr)
		})

		setFirstFilter('Selecciona una oficina')
	}, [])

	const officesTooltip = oficinasCheck
		?.map((office) => office?.office_name)
		.join(' ')

	return (
		<>
		{loading && <Loader message={"Cargando..."}></Loader>}
{ !loading && activeModal && 
	(
		<div className='popupWrapper OfertaWrapper CatalogoNew'>
			
			<div className='popup' ref={refCatalogModal}>
				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<h1 style={{ marginTop: 20 }}>
					<img src={bookmark} alt='' />
					<span>
						<span>PROMO</span>BOOSTER:
					</span>
					Catálogo
				</h1>
				<div className='oferta_buttons'>
					<button>Crear Catálogo nuevo</button>
					<button onClick={(e) => {
						e.preventDefault()
						setActiveExistente(true)
						setActiveModal(false)
					}}>Crear a partir de Catálogo existente</button>
				</div>

				<div className='box_border' style={{ marginTop: 20 }}>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={edit} alt='' />
							Nombre del Catálogo
						</label>
						<input
							type='text'
							id='nombre_catalogo'
							placeholder='Nombre del Catálogo'
							maxLength={25}
							onBlur={(e) => {
								if (e.target.value != '') {
									e.target.classList.remove('error_oferto')
									e.target.classList.add('success_oferto')
								} else {
									e.target.classList.add('error_oferto')
									e.target.classList.remove('success_oferto')
								}
							}}
						/>
						<img src={error_explanation} alt='' />
						<img src={tick_explanation} alt='' />
					</div>
				</div>

				<div
					className='box_border'
					style={{ marginTop: 15, zIndex: 9999, position: 'relative' }}
				>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={calender} alt='' />
							Fechas
						</label>
					</div>

					<div className='input_container'>
						<div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Fecha Vigencia</label>
							<CatálogoDropdownDateTradicional
								head='Selecciona una fecha'
								element_id='init_sellin_date'
								setDateList={setDateList}
							/>
							<input type='hidden' id='init_sellin_date' />
							<input type='hidden' id='end_sellin_date' />
						</div>

						<div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Fecha</label>
							<CatálogoDropdown
								head='Selecciona un mes'
								list={dateList}
								element_id='month_date'
								setContenedor={setMesTexto}
							/>
							<input type='hidden' id='month_date' />
						</div>
					</div>
				</div>

				<div className='box_border' style={{ marginTop: 15 }}>
					<div className='input_wrapper'>
						<label htmlFor=''>
							<img src={calender} alt='' />
							Segmentación
						</label>
					</div>

					<div className='input_container' ref={refCatalogModal}>
						<div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Oficinas</label>

							<div
								className='dropdown dropdown_new dropdown_new_inner'
								style={{ maxWidth: '250px', margin: 0 }}
								ref={refDropdownRegions}
							>
								<div
									className='head'
									onClick={(e) => {
										setFirstDropdown(!FirstDropdown)
									}}
								>
									<p
										style={
											FirstFilter != 'ANTOFAGASTA222'
												? { color: '#000', fontWeight: 700 }
												: { color: '#878787', fontWeight: 500 }
										}
									>
										{offices && oficinasCheck ?
											oficinasCheck.length === 0
											? 'Seleccione oficina(s)'
											: 
												oficinasCheck.length === offices.length ? 'Nacional'
												: oficinasCheck.length+" oficina(s)"
											: 'Cargando...'
											
											}
									</p>
									<img src={dropdown} alt='' />
								</div>
								{FirstDropdown && (
									<ul style={{ maxHeight: '200px', overflow: 'auto' }}>
										{(offices && zonas) &&
											zonas.map((Each) => (
												<CatalogoTradicionalCheckBox 
													offices={offices}
													Each={Each}
													setOficinasCheck={setOficinasCheck}
													oficinasCheck={oficinasCheck}
													setFirstFilter={setFirstFilter}
													isCheckedFather={isCheckedFather}
													isCheckedOffice={isCheckedOffice}
												/>
											))}
									</ul>
								)}
							</div>
						</div>

						<div className='input_wrapper' style={{ height: 50 }}>
							<label htmlFor=''>Línea</label>
							<CatálogoDropdown
								head='Selecciona una Línea'
								list={lines}
								setContenedor={setLineaTexto}
								element_id='linea_texto'
							/>
							<input type='hidden' id='linea_texto' />
						</div>
					</div>
				</div>

				<div
					className='badge_long badge_long_catalogo'
					style={{
						gridTemplateColumns: '1fr 1fr 1fr 1fr',
						paddingLeft: '1rem',
						paddingRight: '1rem',
					}}
				>
					<div className='box_inner'>
						<h1>Canal</h1>
						<p>Tradicional</p>
					</div>
					<div className='box_inner'>
						<h1>Fecha</h1>
						<p>{mesTexto}</p>
					</div>
					<div className='box_inner'>
						<h1>Oficinas</h1>
						<Tooltip
							title={officesTooltip}
							placement='top-start'
							PopperProps={{ style: { zIndex: 99999999999 } }}
						>
							<p>
								{offices && oficinasCheck.length === 0
									? 'Seleccione oficina'
									: oficinasCheck.length}
							</p>
						</Tooltip>
					</div>
					<div className='box_inner'>
						<h1>Línea</h1>
						<p>{lineaTexto}</p>
					</div>
				</div>
				<div className={`${alertActive && 'active'} alert_wrapper_new`}>
					<svg
						width='23'
						height='23'
						viewBox='0 0 23 23'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_3028_1185323'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='23'
							height='23'
						>
							<rect width='23' height='23' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_3028_1185323)'>
							<path
								d='M11.4998 16.8428C11.8991 16.8428 12.2145 16.735 12.4461 16.5193C12.6777 16.3037 12.7935 15.9883 12.7935 15.573C12.7935 15.1737 12.6817 14.8542 12.4581 14.6146C12.2345 14.3751 11.9151 14.2553 11.4998 14.2553C11.1005 14.2553 10.785 14.3751 10.5534 14.6146C10.3218 14.8542 10.206 15.1737 10.206 15.573C10.206 15.9723 10.3178 16.2837 10.5415 16.5074C10.7651 16.731 11.0845 16.8428 11.4998 16.8428ZM11.5717 12.6501C11.8911 12.6501 12.1507 12.5502 12.3503 12.3506C12.55 12.1509 12.6498 11.8834 12.6498 11.548V7.2834C12.6498 6.96395 12.55 6.70441 12.3503 6.50475C12.1507 6.3051 11.8911 6.20527 11.5717 6.20527C11.2522 6.20527 10.9887 6.3051 10.781 6.50475C10.5734 6.70441 10.4696 6.96395 10.4696 7.2834V11.548C10.4696 11.8834 10.5774 12.1509 10.793 12.3506C11.0086 12.5502 11.2682 12.6501 11.5717 12.6501ZM11.4998 21.5865C10.1102 21.5865 8.80048 21.327 7.57062 20.8079C6.34076 20.2888 5.26663 19.57 4.34823 18.6516C3.42982 17.7332 2.71107 16.6591 2.19198 15.4292C1.67288 14.1994 1.41333 12.8896 1.41333 11.5001C1.41333 10.0945 1.67288 8.78079 2.19198 7.55892C2.71107 6.33704 3.42982 5.26691 4.34823 4.3485C5.26663 3.4301 6.34076 2.70736 7.57062 2.18027C8.80048 1.65319 10.1102 1.38965 11.4998 1.38965C12.8894 1.38965 14.2031 1.65319 15.4409 2.18027C16.6788 2.70736 17.7529 3.4301 18.6633 4.3485C19.5737 5.26691 20.2925 6.34104 20.8196 7.5709C21.3467 8.80076 21.6102 10.1105 21.6102 11.5001C21.6102 12.9056 21.3467 14.2193 20.8196 15.4412C20.2925 16.6631 19.5698 17.7292 18.6514 18.6396C17.7329 19.5501 16.6588 20.2688 15.429 20.7959C14.1991 21.323 12.8894 21.5865 11.4998 21.5865ZM11.4998 19.4063C13.688 19.4063 15.5527 18.6357 17.0941 17.0943C18.6354 15.553 19.406 13.6883 19.406 11.5001C19.406 9.31187 18.6354 7.44711 17.0941 5.90579C15.5527 4.36447 13.688 3.59382 11.4998 3.59382C9.31159 3.59382 7.44684 4.36447 5.90552 5.90579C4.3642 7.44711 3.59354 9.31187 3.59354 11.5001C3.59354 13.6883 4.3642 15.553 5.90552 17.0943C7.44684 18.6357 9.31159 19.4063 11.4998 19.4063Z'
								fill='#FFAC23'
							/>
						</g>
					</svg>

					<p>
						<span>Ya existe un Catálogo para esta línea, oficina y mes.</span>
						Seleccione otros valores.
					</p>
				</div>
				<div
					className='bottom_buttton'
					style={{
						paddingTop: 20,
						borderTop: `1px solid #aeb0b0`,
					}}
				>
					<button onClick={(e) => {
						setActive(false)
						setAtras(true)
						}}>Volver atrás</button>
					<button
						onClick={(e) => {
							//setalertActive(true);
							setLoading(true)
							addPromotionTraditional()
						}}
					>
						Continuar
					</button>
				</div>
			</div>
		</div>
	)
}
{	!loading && activeExistente && lines && <CrearCatalogoExistenteTrad setActive={setActiveExistente} 
		setAnterior={setActiveModal} dataPromotions={dataPromotions}
		lines={lines} setLoading={setLoading}/>

	}
		</>
	)
}
