import React from "react";

export const TopNavigator = ({data, dataPromotion}) => {
  return (
    <div className="TopNavigator">
      <nav>
        <li>
          <h1>Catálogo</h1>
          <p>{data?.name_promotion}</p>
        </li>
        <li>
          <h1>Fecha</h1>
          <p>{dataPromotion?.cadena_details?.month_year}</p>
        </li>
        <li>
          <h1>Canal</h1>
          <p>{data?.customer?.channel?.description_channel}</p>
        </li>
      </nav>
    </div>
  );
};
