import React from 'react'
import '../../assets/css/newPFOffer/delete.css'
import warning from '../../assets/img/newPFOffer/warning.svg'

export const DeleteImpossible = ({setActive}) => {
	return (
		<div className='popupWrapper DeleteRow DeleteImpossible'>
			<span
				className='DeleteRowbg'
				onClick={(e) => {
					setActive(false)
				}}
			></span>
			<div className='popup promocien'>
				<img src={warning} alt='' />
				<h1>No puedes eliminar este producto</h1>
				<p>
					No puedes eliminar productos en esta fase. Para desactivarlo puedes
					poner el descuento en 0%.
				</p>
				<div className='buttons-wrapper'>
					<button
						onClick={(e) => {
							setActive(false)
						}}
					>
						Aceptar
					</button>
				</div>
			</div>
		</div>
	)
}
