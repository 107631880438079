import React, { useState, useEffect } from "react";
import { Navbar } from "./Navbar";
import "../assets/css/ResumenDeCataloge.css";
import arrow_left from "../assets/img/arrow_left.svg";
import { CataLogoResumenFooter } from "./CataLogoResumenFooter";
import { Link, useParams } from "react-router-dom";
import { NombreTable } from "./NombreTable";
import Icono from "..//assets/img/Icono.svg";
import reset from "..//assets/img/reset.svg";
import time from "..//assets/img/time.svg";
import AvatarPop from "../assets/img/AvatarPop.svg";
import Avatargirl from "../assets/img/Avatargirl.svg";
import { Usuario } from "./Usuario";
import visibility from "../assets/img/visibility.svg";
import Table from "react-bootstrap/Table";
import { TopNavigator } from "./TopNavigator";
import { CombiarPopup } from "./CombiarPopup";
import { RenombrarPopup } from "./RenombrarPopup";
import {MoveCataLog} from "./MoveCataLog";
import {Loader} from "./Loader";
import { config } from "../config";

function getComments(promotion_id){
  const session_token = localStorage.getItem('session');
  //console.log(session_token);
  //if there is a session token stored
  if (session_token){
      return fetch(config.apiUrl + `/comment_pb/activity/`+promotion_id, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        //console.log(response)
        return response;
      });
  }
  return {
    //return empty json
  }

}

function getPromotions(){
  const session_token = localStorage.getItem('session');
  //console.log(session_token);
  //if there is a session token stored
  if (session_token){
      return fetch(config.apiUrl + `/promotion/listall`, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        //console.log(response)
        return response;
      });
    

  }

  //there are no session token stored
  return {

  }

}

function catalogoLineResume(promotion_id, tipo_catalogo){
  const session_token = localStorage.getItem('session');
  
  //if there is a session token stored
  if (session_token){
      let url = config.apiUrl
      switch(tipo_catalogo){
        case "moderno":
          url += `/catalog/category_data/${promotion_id}`;
          break;
        case "tradicional":
          url += `/traditional_catalog/line_summary/all/${promotion_id}`;
          break;
        default: 
          url += `/catalog/category_data/${promotion_id}`;
          break; 
      }
      return fetch( url, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        console.log(response)
        return response;
      });
  }
  //there are no session token stored
  return {

  }

}

export const ResumenDeCataloge = () => {
  const [TextareaFocus, setTextareaFocus] = useState(false);
  const [disableCheck, setdisableCheck] = useState(true);
  const[promotion, setPromotion] = useState(null);
  const [loader, setloader] = useState(true);
  const { id_promocion, tipo_catalogo } = useParams();
  const [data, setData] = useState(null);
  const [CatalogMove, setCatalogMove] = useState(false);
  const [combiar, setcombiar] = useState(false);
  const [Renombrar, setRenombrar] = useState(false);
  const [dataComments, setDataComments] = useState(false);
  const [flag, setFlag] = useState(false);
  const [nombreCliente, setNombreCliente] = useState("");
  const [nombrePromocion, setNombrePromocion] = useState("");

  useEffect(() => {
    //setData({catalog_categories: catalog_categories, promotion_id: promotion_id})
    
    catalogoLineResume(id_promocion, tipo_catalogo).then(result =>{
        //setData(result)
        if (result){
          
          console.log(result)
          result.promotion_id = id_promocion
          console.log("result promotion:::::")
          console.log(result)
          let categories_json = []
          result.data_rows.map((EachRow)=>{
            console.log(EachRow);
            categories_json.push({
              category: EachRow.category
            })
          });
          setData(result)
          //setCategories(categories_json)
        }
        else
          console.log("error")  
          //window.location.replace("/");
             
    });
    
    //catalogoLineResumeAsync(id_promocion);

    getPromotions().then(result =>{
        const promotion_filter = result.filter(function(element){
          return element.id == id_promocion;
        });
        console.log("*****************")
        console.log(promotion_filter[0])
        setNombrePromocion(promotion_filter[0].name_promotion)
        setNombreCliente(promotion_filter[0].customer.name_customer)
        //alert(promotion_filter[0].id)
        setPromotion(promotion_filter[0]) 
        setloader(false);
     });

     

  
   
  }, []);

  useEffect(() => {
    getComments(id_promocion).then(result =>{
      console.log("##########comentarios:::: ");
      console.log(result);
      setDataComments(result);  
      setFlag(false);  
    });
  }, [flag]);

  const getStrMonth3 = (month_str) => {
    const montsh_obj = {
      "Jan": "01",
      "Feb": "02",
      "Mar": "03",
      "Apr": "04",
      "May": "05",
      "Jun": "06",
      "Jul": "07",
      "Aug": "08",
      "Sep": "09",
      "Oct": "10",
      "Nov": "11",
      "Dec": "12"
    };
    return montsh_obj[month_str];
  }

  const Callback = async (promotion_id) => {
    const comment = document.getElementById("input_comment").value;    
    const session_token = localStorage.getItem('session');
    if (session_token){
        const response = await fetch(config.apiUrl + `/comment_pb/create`, {
        //mode: 'no-cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        },
        body: JSON.stringify([{
          id_promotion: promotion_id,
          comment_text: comment,
          send_notification: 1
        }])
      })
      const responseJSON = await response.json();
      document.getElementById("input_comment").value = "";
      // setDataComments(dataComments.concat(responseJSON))
      document.getElementById("input_comment").disabled = false;
      setFlag(true)

    }
  }

  const onEnter = (event, promotion_id) => {if (event.key === 'Enter') {
    document.getElementById("input_comment").disabled = true;
    Callback(promotion_id);
  }};

  return (
    <div className="CataLogoResumen ResumenDeCatalogeNew">
      {(combiar && promotion) && <CombiarPopup setcombiar={setcombiar} promotion={promotion}/>}
      {Renombrar && <RenombrarPopup setRenombrar={setRenombrar} 
      data={data} setData={setData}/>}
      {CatalogMove && promotion && <MoveCataLog setActive={setCatalogMove} promotion={promotion}/>}
      {!promotion ? '' : <TopNavigator data={promotion} dataPromotion={data}/>}
      
      {loader && <Loader message={"Cargando..."}/>}
      <Navbar no_need_search />
      <div className="top_area">
        <div className="left_side">
          {promotion &&
          <Link to={"/ResumenDeCatalogo/"+promotion.id} className="active">
            Resumen Catálogo
          </Link>
          }
          <Link to="#">Seguimiento Catálogo</Link>
          {promotion && <Link to={"/CataLogoResumen/"+promotion.id}>Resumen Línea</Link>}
        </div>
        <div className="right_side">
          <img src={arrow_left} alt="" />
          <p>Ve al Resumen de Línea para seguir editando el catálogo</p>
        </div>
      </div>
      <div className="resumen_box_shadow_wrapper">
        {(data && promotion) && <NombreTable data={data} 
        nombreCliente={nombreCliente}
        nombrePromocion={nombrePromocion}
        promotion={promotion}/>}

        <div className="grid_area_resumen_de_cataloge">
          <div className="chat_area">
            <div className="head">
              <img src={Icono} alt="" />
              <p>Usuarios</p>
            </div>
            {/*
            <Usuario />
            <Usuario />
            <Usuario />
            <Usuario />
            <Usuario />
            */}
          </div>

          <div className="chat_area profile_Area_resumen">
            <div className="head">
              <img src={reset} alt="" />
              <p>Actividad</p>
            </div>

            <div className="profile_box_wrapper profile_box_wrapperNew">
              <div className="profile_box" style={{ alignItems: "flex-start" }}>
                <img src={Avatargirl} alt="" />
                <div className="presentation" style={{ position: "relative" }}>
                  <textarea
                    cols="30"
                    rows="10"
                    className={`${TextareaFocus && "messageType"}`}
                    placeholder="Escribe un comentario..."
                    onChange={(e) => {
                      if (e.target.value != "") {
                        setdisableCheck(false);
                      } else {
                        setdisableCheck(true);
                      }
                    }}
                    onFocus={(e) => {
                      setTextareaFocus(true);
                    }}
                    onBlur={(e) => {
                      setTextareaFocus(false);
                    }}
                    onKeyPress={e => onEnter(e, promotion.id)} 
                    id="input_comment" autocomplete="off"
                  ></textarea>
                  {/*<button className="comment" disabled={disableCheck} 
                    onClick={(e) => {Callback()}}>
                    Comentar
                  </button>*/}
                </div>
              </div>

              <div id="section_comments" className="commentsMax" style={{height: "100vh"}}>
      
          {!dataComments ? 'Cargando Comentarios ...' : dataComments.sort((a,b) => {return b.id_comment - a.id_comment}).map((EachRow) => (
              EachRow.comment_text.includes("@") ? (
              <div className="profile_box" 
              id={"box_comment_"+EachRow.id_comment}
              style={{marginBottom: "10px"}}>
                  <img src={AvatarPop} alt="" />
                  <div className="presentation">
                    <div className="top_area">
                      <h1>{EachRow.name_user} {EachRow.last_name_user}</h1>
                      <p>{EachRow.comment_text} <span>{EachRow.promotional_state.state_phase_str}</span></p>
                               
                    </div>
                    <p >
                    {EachRow.date_created}
                {/*<span
                        onClick={(e) => {
                          deleteComment(EachRow.id_comment)
                        }}
                        style={{marginRight:"0px"}}
                      >Eliminar</span>
                      */}
                      </p>
                    
                  </div>
                  
              </div>
              ) :
              <div className="profile_box responder">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>{EachRow.name_user} {EachRow.last_name_user}</h1>
                  <p>
                  {EachRow.date_created}
                  </p>
                </div>
                <input type="text" placeholder={EachRow.comment_text} value={EachRow.comment_text}/>
              </div>
            </div>
            
          ))}
              </div>
            </div>
          </div>

          <div className="chat_area table_third_resumen">
            <div className="head">
              <img src={time} alt="" />
              <p>Versiones</p>
            </div>
          {/*
            <Table>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Hora</th>
                  <th>Fase</th>
                  <th>Estado</th>
                  <th>Usuario</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>18/08/2022</p>
                  </td>
                  <td>
                    <p>11:30</p>
                  </td>
                  <td>
                    <p>Propuesta Inicial</p>
                  </td>
                  <td>
                    <p>En Edición</p>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p>María M.</p>
                      <img src={visibility} alt="" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          */}
          </div>
        </div>
      </div>
      {data &&
      <CataLogoResumenFooter
        setcombiar={setcombiar}
        setRenombrar={setRenombrar}
        data={data}
        guardar={false}
        setCatalogMove={setCatalogMove}
      />}
    </div>
  );
};
