import React, { useState } from "react";
import { Navbar } from "./Navbar";
import "../assets/css/Seguimiento.css";
import { CataLogoResumenFooter } from "./CataLogoResumenFooter";
import Table from "react-bootstrap/Table";
import { Link, useParams } from "react-router-dom";
import { SeguimientoTableLinea } from "./SeguimientoTableLinea";
import { config } from "../config";
import { TopNavigator } from "./TopNavigator";
import { useEffect } from "react";

function getPromotions(){
  const session_token = localStorage.getItem('session');
  //console.log(session_token);
  //if there is a session token stored
  if (session_token){
      return fetch(config.apiUrl + `/promotion/listall`, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        //console.log(response)
        return response;
      });
    

  }

  //there are no session token stored
  return {

  }

}

function pullSeguimientoLinea(id_promotion){
  const session_token = localStorage.getItem('session');
  
  //if there is a session token stored
  if (session_token){
      return fetch(config.apiUrl + `/catalog/seguimiento_linea/${id_promotion}`, {
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then(response => {
        //console.log(response.ok)
        return response.json()
      })
      .then(response => {
        //console.log(response)
        return response;
      });
  }
}


export const SeguimientoLinea = () => {
  const [active, setActive] = useState(false);
  const [resumenData, setResumenData] = useState(null);
  const[promotion, setPromotion] = useState(null);
  const { id_promocion } = useParams();

  console.log(localStorage.getItem('created_promotion_id'))

  React.useEffect(() => {
    pullSeguimientoLinea(localStorage.getItem('created_promotion_id')).then(result =>{
      console.log(result);
      setResumenData(result);
    });
  }, []);

  useEffect(() => {

    getPromotions().then(result =>{
      const promotion_filter = result.filter(function(element){
        return element.id == id_promocion;
      });
      //console.log("*****************")
      //alert(promotion_filter[0].id)
      setPromotion(promotion_filter[0])    
   });

  });

  return (
    <div className="CataLogoResumen">
      {!promotion ? '' : <TopNavigator data={promotion}/>}
      <Navbar no_need_search />
      <div className="top_area top_area_differ" style={{ paddingBottom: 14 }}>
        <div className="left_side">
          <Link to="/Seguimiento">Seguimiento Catálogo</Link>
          <Link to="/CataLogoResumen">Resumen Línea</Link>
        </div>
        <div className="option_table">
          <Table>
            <tbody>
              <tr>
                <td>
                  <Link to="/Vienesas">Vienesas</Link>
                </td>
                <td>
                  <Link to="/">Jamones</Link>
                </td>
                <td>
                  <Link to="/">Mortadelas</Link>
                </td>
                <td>
                  <Link to="/">Longanizas</Link>
                </td>
                <td>
                  <Link to="/">Pates</Link>
                </td>
                <td>
                  <Link to="/">Madurados</Link>
                </td>
                <td>
                  <Link to="/">Arrollados</Link>
                </td>
                <td>
                  <Link to="/SeguimientoLinea" className="active">
                    Seguimiento Línea
                  </Link>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="link_area">
        <p>Jumbo Abril 2022</p>

        <p>/</p>
        <p>Cecinas</p>
        <p>/</p>
        <p>Seguimiento Línea</p>
      </div>

      <SeguimientoTableLinea data={resumenData}/>

      <CataLogoResumenFooter />
    </div>
  );
};
