import React , { useState } from "react";
import Icono_cata from "../assets/img/Icono_cata.svg";
import { config } from "../config";
import {Loader} from "./Loader";

export const SaveCatalog = ({ setActive, setSaved, data, tipo_catalogo, idOferta = null, setChangeInput = null}) => {
  const [loader, setloader] = useState(false);
  const saveDataAsync = async (dataJSON) => {
    
    const session_token = localStorage.getItem('session');
    //traditional y catalog
    let url_final = config.apiUrl;
    if (tipo_catalogo == "moderno") {
      url_final += `/catalog/update`;
    }else if(tipo_catalogo == "ofertaModerno" && idOferta){
      url_final = config.apiUrlOferta;
      url_final += `/offer/save/`+idOferta;
    }
    else{
      url_final += `/traditional_catalog/update`;
    }
    const response = await fetch(url_final, {
      //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + session_token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        },
        method: "POST",
        body: JSON.stringify(dataJSON)
      });
      const responseJSON = await response.json();
      // setChangeInput(false);
      document.getElementById("btn_guardado_save").innerHTML = "Guardar";
      setActive(false);
      setSaved(true);
      console.log("Respuesta Guardado:::");
      console.log(responseJSON);
      setloader(false);
      
  }

  return (
    <>
    {loader && <Loader message={"Cargando..."}></Loader>}
    <div
      className="popupWrapper"
      id="popupCatalog"
      style={{ position: "fixed", zIndex: 9999 }}
      onClick={(e) => {
        if (e.target.id == "popupCatalog") {
          setActive(false);
        }
      }}
    >
      <div className="popup SaveCatalog" id={"popUpCatalogBack"}>
        <img src={Icono_cata} alt="" />
        <h1>¿Estás seguro que deseas guardar {tipo_catalogo === "ofertaModerno" || tipo_catalogo === "ofertaTradicional" ? 'la Oferta' : 'el Catálogo'}?</h1>
        <p>Los cambios serán almacenados de manera inmediata.</p>
        <div className="buttons_wrapper">
          <button
            onClick={(e) => {
              setActive(false);
            }}
          >
            Seguir Editando
          </button>
          <button
            onClick={(e) => {
              // setActive(false);
              document.getElementById("popupCatalog").style.display = "none";
              document.getElementById("popUpCatalogBack").style.display = "none";
              document.getElementById("popupCatalog").style.opacity = "0";
              setloader(true);
              document.getElementById("btn_guardado_save").innerHTML = "Guardando Cambios..."; // TODO no usar mas document.getElementById!
              saveDataAsync(data)
              document.getElementById("popupCatalog").style.display = "block";
              document.getElementById("popUpCatalogBack").style.display = "block";
              // document.getElementById("popupCatalog").style.opacity = "100";
            }}
            id="btn_guardado_save"
          >
            Guardar 
          </button>
        </div>
      </div>
      
    </div>
    </>
  );
};
