import React, { useState , useEffect} from 'react'
import { useParams } from 'react-router-dom'

import { Navbar } from './Navbar'

import '../assets/css/home.css'

//import { config } from '../config'
import TableauReport from 'tableau-react'



export const Dashboard = () => {
    const [tokenTableau, setTokenTableau] = useState()
	const { pagina } = useParams()
    //PF/Promo/AnlisisROI
	let url_ = 'https://tableauserver-azure.pricemaker.io/t/PF/views/';
	switch (pagina) {
		case 'reportes':
    		url_ = url_+'Promo/AnlisisROI';
			break;
		case 'historial':
			// url_ = url_+'HistorialPromo/Historial';
			url_ = url_+'Historial_Promociones_PF_Moderno/Historial';
			break;
		case 'optimizacion':
			url_ = url_ +'Moderno/MOD-Visibilidad1';
			break;
		default:
			url_ = url_+'Promo/AnlisisROI';
			break;
	}
	/*
            parameters_ = {
			ID1: [dataProduct.code_product + '-' + data.cadena_details.name_customer],
		}
    */
	const fetchApi = async (tradicional = false) => {
		let url = 'https://optimusprice.pricemaker.io/api/getChartTrust'
		//https://tableauserver-azure.pricemaker.io/t/Soprole/views/basetest/titi2
		let dtarget_site = 'PF'
		let project = 'Historial_Promociones_PF_Moderno'
		let page = 'Historial'
		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				token: window.sessionStorage.getItem('token') + '-2chzRY3F3I9@',
				tabs_permission: 2,
				dtarget_site: dtarget_site,
				project: project,
				page: page,
			}),
		})

		console.log(response.status)
		const responseJSON = await response.json()
		setTokenTableau(responseJSON.token_server)
	}

    useEffect(() => {
		fetchApi()
    }, [])

	return (
		<div className='Home'>
            <Navbar />
            {!tokenTableau ? (
								'Cargando Dashboard...'
							) : (
								<TableauReport
									url={url_}
									token={tokenTableau}
									options={{
										width: "100%",
										height: "100vh",
									}}
									query="?:jsdebug=n"
									//parameters={parameters_}
								/>
							)}
		</div>
	)
}

//how make a function with bubble sort in js?